import { EditorState } from "draft-js";

export const getWords = (currentEditorState: EditorState) => {
  const contentState = currentEditorState.getCurrentContent();
  const selection = currentEditorState.getSelection();
  const currentBlock = contentState.getBlockForKey(selection.getStartKey());
  const currentBlockText = currentBlock.getText();
  const words = currentBlockText.split(" ");
  return words;
};

export const getCurrentWord = (currentEditorState: EditorState) => {
  const contentState = currentEditorState.getCurrentContent();
  const selection = currentEditorState.getSelection();
  const currentBlock = contentState.getBlockForKey(selection.getStartKey());
  const currentBlockText = currentBlock.getText();
  const currentWord = currentBlockText
    .substring(0, selection.getStartOffset())
    .split(" ")
    .pop() as string;
  return currentWord;
};

export const getCurrentSelection = (currentEditorState: EditorState) => {
  const selection = currentEditorState.getSelection();
  return selection;
};

export const getCurrentBlockText = (currentEditorState: EditorState) => {
  const contentState = currentEditorState.getCurrentContent();
  const selection = currentEditorState.getSelection();
  const currentBlock = contentState.getBlockForKey(selection.getStartKey());
  const currentBlockText = currentBlock.getText();
  return currentBlockText;
};

export const getTextBeforeCursor = (currentEditorState: EditorState) => {
  const selection = getCurrentSelection(currentEditorState);
  const anchorOffset = selection.getAnchorOffset();
  const currentBlockText = getCurrentBlockText(currentEditorState);
  const textBeforeCursor = currentBlockText.slice(0, anchorOffset);
  return textBeforeCursor;
};
