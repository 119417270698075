import { useState, useEffect } from "react";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  alpha,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { getDaysInMonth, format } from "date-fns";

const CustomDatePicker = ({
  selectedDate,
  onDateChange,
  backgroundColorOverride = null,
}) => {
  const theme = useTheme();
  const isValidDate =
    selectedDate instanceof Date && !isNaN(selectedDate.getTime());
  const [month, setMonth] = useState(isValidDate ? selectedDate.getMonth() : 0);
  const [year, setYear] = useState(
    isValidDate ? selectedDate.getFullYear() : new Date().getFullYear()
  );
  const [day, setDay] = useState(isValidDate ? selectedDate.getDate() : 1);
  const [days, setDays] = useState([]);

  const months = Array.from({ length: 12 }, (_, i) => i);
  const years = Array.from(
    { length: 100 },
    (_, i) => new Date().getFullYear() - i
  );

  useEffect(() => {
    if (isValidDate) {
      setMonth(selectedDate.getMonth());
      setYear(selectedDate.getFullYear());
      setDay(selectedDate.getDate());
    }
  }, [selectedDate]);

  useEffect(() => {
    const days = Array.from(
      { length: getDaysInMonth(new Date(year, month)) },
      (_, i) => i + 1
    );
    setDays(days);
  }, [month, year]);

  const handleDayChange = (event) => {
    setDay(event.target.value);
    onDateChange(new Date(year, month, event.target.value));
  };

  const handleMonthChange = (event) => {
    const newMonth = event.target.value;
    setMonth(newMonth);
    const maxDays = getDaysInMonth(new Date(year, newMonth));
    if (day > maxDays) {
      setDay(maxDays);
      onDateChange(new Date(year, newMonth, maxDays));
    } else {
      onDateChange(new Date(year, newMonth, day));
    }
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
    onDateChange(new Date(event.target.value, month, day));
  };

  const selectStyle = {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(
        theme.palette.text.placeholder,
        theme.palette.mode === "dark" ? 0.8 : 1.0
      ),
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "& .Mui-focused .MuiInputLabel-root": {
      color: theme.palette.primary.main,
    },
    "& .MuiInputLabel-root": {
      backgroundColor: backgroundColorOverride
        ? backgroundColorOverride
        : theme.palette.background.backdrop,
      paddingRight: "5px",
      paddingLeft: "5px",
    },
    background: backgroundColorOverride
      ? backgroundColorOverride
      : theme.palette.background.backdrop,
    borderRadius: "8px",
    fontSize: "14px",
    fontWeight: 600,
    height: "40px",
    color: theme.palette.text.primary,
  };

  return (
    <Grid item width={"100%"}>
      <Grid container spacing={2}>
        <Grid item width={"100px"}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel
              style={{
                color: alpha(theme.palette.text.secondary, 0.6),
                fontWeight: 700,
                fontSize: "14px",
                justifyContent: "center",
                alignContent: "center",
                textAlign: "center",
                backgroundColor: backgroundColorOverride
                  ? backgroundColorOverride
                  : theme.palette.background.backdrop,
                paddingLeft: "11px",
                paddingRight: "11px",
                marginLeft: "-3px",
                borderRadius: "8px",
              }}
            >
              Month
            </InputLabel>
            <Select value={month} onChange={handleMonthChange} sx={selectStyle}>
              {months.map((m) => (
                <MenuItem key={m} value={m}>
                  {format(new Date(0, m), "MMM")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item width={"90px"}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel
              style={{
                color: alpha(theme.palette.text.secondary, 0.6),
                fontWeight: 700,
                fontSize: "14px",
                justifyContent: "center",
                alignContent: "center",
                textAlign: "center",
                backgroundColor: backgroundColorOverride
                  ? backgroundColorOverride
                  : theme.palette.background.backdrop,
                paddingLeft: "11px",
                paddingRight: "11px",
                marginLeft: "-3px",
                borderRadius: "8px",
              }}
            >
              Day
            </InputLabel>
            {days.length > 0 && (
              <Select value={day} onChange={handleDayChange} sx={selectStyle}>
                {days.map((d) => (
                  <MenuItem key={d} value={d}>
                    {d}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </Grid>
        <Grid item width={"110px"}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel
              style={{
                color: alpha(theme.palette.text.secondary, 0.6),
                fontWeight: 700,
                fontSize: "14px",
                justifyContent: "center",
                alignContent: "center",
                textAlign: "center",
                backgroundColor: backgroundColorOverride
                  ? backgroundColorOverride
                  : theme.palette.background.backdrop,
                paddingLeft: "11px",
                paddingRight: "11px",
                marginLeft: "-3px",
                borderRadius: "8px",
              }}
            >
              Year
            </InputLabel>
            <Select value={year} onChange={handleYearChange} sx={selectStyle}>
              {years.map((y) => (
                <MenuItem key={y} value={y}>
                  {y}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomDatePicker;
