// create an enum that extends EnumType

export enum MenuItem {
  Persona = "Persona",
  Expertise = "Expertise",
  Stats = "Stats",
}

export enum AccountMenuItem {
  Settings = "Settings",
  Subscription = "Subscription",
}
