import { useEffect, useRef, useCallback } from "react";
import ReactPlayer from "react-player";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { useLocation } from "react-router-dom";
import {
  handleAudioEnded,
  setAudioAnalysisData,
} from "../../features/audio/textToSpeechSlice";

const AudioPlayer = () => {
  const dispatch = useAppDispatch();
  const play = useAppSelector((state) => state.textToSpeech.isPlaying);
  const audioUrl = useAppSelector((state) => state.textToSpeech.activeAudioUrl);
  const reactPlayerRef = useRef(null);
  const rafIdRef = useRef(null); // Reference to the requestAnimationFrame ID
  const location = useLocation(); // Use to detect route changes

  const setupAudioAnalysis = useCallback(
    (playerInstance) => {
      const audioContext = new AudioContext();
      const analyser = audioContext.createAnalyser();
      const source = audioContext.createMediaElementSource(playerInstance);

      source.connect(analyser);
      analyser.connect(audioContext.destination);

      const frequencyData = new Uint8Array(analyser.frequencyBinCount);

      const updateFrequencyData = () => {
        if (!play) {
          cancelAnimationFrame(rafIdRef.current); // Cancel the loop if not playing
          return;
        }
        analyser.getByteFrequencyData(frequencyData);
        dispatch(setAudioAnalysisData(Array.from(frequencyData)));
        rafIdRef.current = requestAnimationFrame(updateFrequencyData);
      };

      rafIdRef.current = requestAnimationFrame(updateFrequencyData);
    },
    [dispatch, play]
  );

  // Effect to check player readiness and set up the audio analysis
  useEffect(() => {
    if (play && audioUrl) {
      const playerInstance = reactPlayerRef.current?.getInternalPlayer();
      if (playerInstance instanceof HTMLMediaElement) {
        playerInstance.crossOrigin = "anonymous";
        setupAudioAnalysis(playerInstance);
      } else {
        const retryTimeout = setTimeout(() => {
          const playerInstance = reactPlayerRef.current?.getInternalPlayer();
          if (playerInstance instanceof HTMLMediaElement) {
            playerInstance.crossOrigin = "anonymous";
            playerInstance.src = audioUrl;
            setupAudioAnalysis(playerInstance);
          }
        }, 100);

        return () => {
          clearTimeout(retryTimeout);
        };
      }
    }

    if (!play && rafIdRef.current) {
      cancelAnimationFrame(rafIdRef.current);
      rafIdRef.current = null;
      dispatch(setAudioAnalysisData([]));
    }
  }, [audioUrl, play, setupAudioAnalysis]);

  const onAudioEnded = () => {
    dispatch(handleAudioEnded());

    if (rafIdRef.current) {
      cancelAnimationFrame(rafIdRef.current);
      rafIdRef.current = null;
      dispatch(setAudioAnalysisData([]));
    }
  };

  // Effect to stop audio when the route changes
  useEffect(() => {
    if (reactPlayerRef.current) {
      reactPlayerRef.current.getInternalPlayer().pause();
    }
  }, [location]);

  return (
    <div style={{ display: "none" }}>
      {audioUrl && (
        <ReactPlayer
          ref={reactPlayerRef}
          url={audioUrl}
          playing={play}
          controls
          onEnded={onAudioEnded}
          width="0"
          height="0"
          style={{ visibility: "hidden" }} // Hide the player
        />
      )}
    </div>
  );
};

export default AudioPlayer;
