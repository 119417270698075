import { RootState } from "../../../app/store";
import { ICreateCollectionPayload, IExpert } from "../../../types";
import {
  deletePreviousChat,
  hasActiveSessionChanged,
  hasMesseges,
  setPreviousChatStateToSaving,
  shouldDeletePreviousChat,
} from "./ChatProcessHelpers";
import * as api from "../../../routes/routes";
import { clearMessages } from "../messagesSlice";
import { fetchPrompts } from "../promptsSlice";
import { fetchCache } from "../cacheSlice";
import { fetchExpert } from "../../experts/expertsSlice";

// this should only call when "save" is called.  Not when experts are switched.
export async function openNewChat(
  dispatch,
  getState,
  rejectWithValue,
  handleAxiosError
) {
  try {
    const state = getState() as RootState;
    const activeChat = state.chat.activeChat;
    const activeChatId = activeChat?._id;
    const activeExpert = state.experts.activeExpert as IExpert;
    const activeExpertId = activeExpert?._id;

    const hasMessages = hasMesseges(state);
    const shouldDelete = shouldDeletePreviousChat(
      hasMessages,
      activeChat,
      false
    );

    if (shouldDelete) {
      deletePreviousChat(activeChatId, state, dispatch);
    } else {
      setPreviousChatStateToSaving(activeChatId, dispatch);
      // save the previous chat, but we won't do anything with it.
      await api.saveChat(activeChat);
    }

    // clear out the chat messages for UI. Chat, Cache, and Prompts will get replaced.
    dispatch(clearMessages());

    const userDetails = state.userDetails.userDetails;
    const userId = userDetails?.user;

    // create new chat
    const chatData = {
      expertId: activeExpertId,
      userId: userId,
      cacheToDuplicate: shouldDelete ? null : activeChat.cacheId,
    } as ICreateCollectionPayload;

    const result = await api.createChat(chatData);
    const newChat = result.data;

    if (hasActiveSessionChanged(activeExpertId, activeChatId, getState)) {
      const currentState = getState() as RootState;
      const activeChat = currentState.chat.activeChat;
      console.log(
        "Active session has changed after prompts were fetched, returning the most recent active chat."
      );
      return activeChat;
    }

    await Promise.all([
      dispatch(fetchCache(newChat.cacheId)),
      dispatch(fetchPrompts(newChat.cacheId)),
      dispatch(fetchExpert(activeExpertId)),
    ]);

    if (hasActiveSessionChanged(activeExpertId, activeChatId, getState)) {
      const currentState = getState() as RootState;
      const activeChat = currentState.chat.activeChat;
      console.log(
        "Active session has changed after prompts were fetched, returning the most recent active chat."
      );
      return activeChat;
    }

    return newChat;
  } catch (error) {
    return rejectWithValue(handleAxiosError(error));
  }
}
