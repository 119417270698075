import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IUser } from "../../types/index";
import { RootState } from "../../app/store";
import { handleAxiosError } from "../../app/ErrorHandler";
import * as api from "../../routes/routes";
import { IUsageDataReport } from "../../types/user/IUsageDataReport";

export enum UserActiveView {
  SETTINGS = "Settings",
  BILLING = "Billing",
  USAGE = "Usage",
  SUBSCRIPTION = "Subscription",
}

const initialState = {
  activeUser: null as IUser | null,
  activeView: UserActiveView.SETTINGS as UserActiveView,
  usageDataReport: null as IUsageDataReport | null,
};

export const clearAllReplyJobs = createAsyncThunk(
  "accountPage/clearAllReplyJobs",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const user = state.userAuth.userAuth?.user;
      if (user && user !== null && user !== undefined) {
        const response = await api.clearAllReplyJobs();
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const clearAllKnowledgeJobs = createAsyncThunk(
  "accountPage/clearAllKnowledgeJobs",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const user = state.userAuth.userAuth?.user;
      if (user && user !== null && user !== undefined) {
        const response = await api.clearAllKnowledgeJobs();
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const getUsageDataReport = createAsyncThunk(
  "accountPage/getUsageDataReport",
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const user = state.userAuth.userAuth?.user;
      if (user && user !== null && user !== undefined) {
        // if is logged in and user is not null
        const response = await api.getUsageDataReport();
        dispatch(setUsageDataReport(response.data));
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const accountPageSlice = createSlice({
  name: "accountPage",
  initialState: initialState,
  reducers: {
    updateActiveUser: (state, action) => {
      state.activeUser = action.payload;
    },
    setUsageDataReport: (state, action) => {
      state.usageDataReport = action.payload;
    },
    updateActiveView: (state, action) => {
      switch (action.payload) {
        case "Settings":
          state.activeView = UserActiveView.SETTINGS;
          break;
        case "Billing":
          state.activeView = UserActiveView.BILLING;
          break;
        case "Usage":
          state.activeView = UserActiveView.USAGE;
          break;
        case "Subscription":
          state.activeView = UserActiveView.SUBSCRIPTION;
          break;
        default:
          state.activeView = UserActiveView.SETTINGS;
          break;
      }
    },
  },
});

export const { updateActiveView, updateActiveUser, setUsageDataReport } =
  accountPageSlice.actions;

export default accountPageSlice.reducer;
