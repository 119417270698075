import React, { useRef, useEffect, useState } from "react";
import { Box, Divider, List, ListItem, alpha, useTheme } from "@mui/material";
import ScrollContainer from "../../pages/workspace-page/content-views/ScrollContainer";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { IChat, IErrorMessage, IExpert } from "../../../types/index";
import { showConfirmPopup } from "../../../features/ui/confirmationPopupSlice";
import SidebarChatCell from "./SidebarChatCell";
import {
  deleteChat,
  fetchChatsForExpert,
  openExistingChat,
} from "../../../features/chats/chatSlice";
import { showErrorNotification } from "../../../features/ui/errorSlice";
import { setMobileDrawerOpen } from "../../../features/chats/workspaceSlice";

interface ChatHistoryBlockProps {
  expert: IExpert;
}

const ChatHistoryBlock: React.FC<ChatHistoryBlockProps> = ({ expert }) => {
  const scrollRef: React.RefObject<HTMLDivElement> = useRef(null);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const expertSlice = useAppSelector((state) => state.experts);
  const chatSlice = useAppSelector((state) => state.chat);
  const confirmPopupSlice = useAppSelector((state) => state.confirmationPopup);
  const [selectedCell, setSelectedCell] = useState("");
  const [chats, setChats] = useState<IChat[]>([]);
  const mobileDrawerOpen = useAppSelector(
    (state) => state.workspace.mobileDrawerOpen
  );

  const closeDrawer = () => {
    dispatch(setMobileDrawerOpen(false));
  };

  // Fix for hiding/showing chat history
  useEffect(() => {
    if (expert._id !== expertSlice.activeExpert?._id) {
      return;
    }

    const sortedChats = [...chatSlice.chats].sort((a, b) => {
      if (a.chatState === "open") return -1;
      if (b.chatState === "open") return 1;
      if (a.chatState === "saving") return -1;
      if (b.chatState === "saving") return 1;
      if (a.updatedAt && b.updatedAt) {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      }
      if (a.createdAt && b.createdAt) {
        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      }

      return 0;
    });

    setChats(sortedChats);
  }, [chatSlice.chats, expert, dispatch, expertSlice.activeExpert]);

  const handleRemoveChat = async (chat: IChat) => {
    dispatch(
      showConfirmPopup({
        title: "Delete Chat?",
        message: `Are you sure you want to delete this chat? You will not be able to recover it.`,
        confirmationText: "Delete",
        isDestructive: true,
        confirmationHandler: "@deleteChat",
        payload: chat._id,
      })
    );
  };

  const handleOpenChat = async (chat: IChat) => {
    console.log("handleOpenChat", chat);
    dispatch(
      showConfirmPopup({
        title: "Open Chat?",
        message: `Warning - opening this chat will delete the current chat and replace your current prompts with this chat's prompts. If you'd like to keep the current chat and its prompts, please save it first and then open this chat.`,
        confirmationText: "Open",
        isDestructive: true,
        confirmationHandler: "@openChat",
        payload: chat._id,
      })
    );
  };

  useEffect(() => {
    if (confirmPopupSlice.onConfirm) {
      doChatHistoryAction(confirmPopupSlice);
    }
  }, [confirmPopupSlice.onConfirm]);

  const doChatHistoryAction = async (confirmPopupSlice: any) => {
    const command = confirmPopupSlice.onConfirm.command;
    const chatId = confirmPopupSlice.onConfirm.payload;
    if (command === "@deleteChat") {
      console.log("deleting chat", chatId);
      const result = (await dispatch(deleteChat(chatId))) as any;
      if (result.payload?.status === 403) {
        const errorNotification = {
          message: "Failed to delete chat",
          title:
            "Chat currently has an active reply job. Please wait until all jobs are finished and try again.",
        } as IErrorMessage;

        dispatch(showErrorNotification(errorNotification));
        return;
      }

      await dispatch(fetchChatsForExpert(null));
    }

    if (command === "@openChat") {
      const openExistingChatPayload = {
        switchingExperts: false,
        chatId: chatId,
      };

      await dispatch(openExistingChat(openExistingChatPayload));

      // Close the drawer when a chat is opened (if mobile)
      if (mobileDrawerOpen) {
        closeDrawer();
      }
    }
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      maxHeight={"800px"}
      height={"100%"}
      width={"100%"}
    >
      {chats != null && chats.length > 0 ? (
        <ScrollContainer disableScroll={chats?.length <= 1}>
          <List
            sx={{
              marginTop: "-12px",
              padding: "0px",
              marginRight: "0px",
              marginBottom: "-24px",
            }}
          >
            {chats.map((chat) => (
              <ListItem
                key={chat._id}
                sx={{
                  margin: "0px",
                  padding: "3px",
                  marginRight: "0px",
                  paddingRight: "0px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                  width: "100%",
                }}
              >
                <Divider
                  orientation="horizontal"
                  sx={{
                    marginTop: "0px",
                    marginBottom: "8px",
                    height: "1px",
                    borderColor:
                      theme.palette.mode === "dark"
                        ? alpha(theme.palette.colors.grayScale.white, 0.1)
                        : alpha(theme.palette.background.divider, 0.9),
                    width: "calc(100%)",
                  }}
                />
                <SidebarChatCell
                  key={chat._id}
                  chat={chat}
                  isSelected={selectedCell === chat._id}
                  onSelect={() =>
                    setSelectedCell(selectedCell === chat._id ? "" : chat._id)
                  }
                  onClickAway={() =>
                    selectedCell === chat._id ? setSelectedCell("") : null
                  }
                  handleRemoveChat={() => handleRemoveChat(chat)}
                  expertColor={expert?.color || "#000000"}
                  chatState={chat.chatState}
                  handleOpenChat={handleOpenChat}
                />
              </ListItem>
            ))}
          </List>
          <div ref={scrollRef} />{" "}
        </ScrollContainer>
      ) : null}
    </Box>
  );
};

export default ChatHistoryBlock;
