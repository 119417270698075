export interface IPlan {
  _id: string;
  name: string;
  creditLimit: number;
  price: number;
  description: string;
  userSubscription?: string;
  storageLimit: number;
}

export function getCreditString(creditLimit: number | string): string {
  // Ensure creditLimit is treated as a string
  const creditLimitStr = String(creditLimit);
  return creditLimitStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getPlanPriceString(price: number | string): string {
  // Ensure price is treated as a string
  const priceStr = String(price);
  const commaSeparatedPrice = priceStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `$${commaSeparatedPrice}`;
}

export function getStorageString(storageLimit: number | string): string {
  // Ensure storageLimit is treated as a string
  const storageLimitStr = String(storageLimit);
  const storageInGB = Math.round(Number(storageLimitStr) / 1024 / 1024 / 1024);
  return `${storageInGB} GB`;
}
