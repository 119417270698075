import React, { useEffect, useRef, useState } from "react";
import { Box, useTheme } from "@mui/material";
import PrimaryIcon from "./PrimaryIcon";
import { AutoPlayActive, AutoPlayInactive, AutoPlayGears } from "../../icons";
import { useAppSelector } from "../../app/store";
import RotatingIcon from "./RotatingIcon";

interface CustomSpeakIconProps {
  handleSpeakButtonClick: () => void;
}

const CustomSpeakIcon: React.FC<CustomSpeakIconProps> = ({
  handleSpeakButtonClick,
}) => {
  const workspaceSlice = useAppSelector((state) => state.workspace);
  const [isSpeaking, setIsSpeaking] = useState(
    workspaceSlice.isAutoPlayEnabled
  );
  const iconRef = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    //console.log("isSpeaking", workspaceSlice.isSpeaking);
    setIsSpeaking(workspaceSlice.isAutoPlayEnabled);
  }, [workspaceSlice.isAutoPlayEnabled]);

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    //console.log("isHovered", isHovered);
  }, [isHovered]);

  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <PrimaryIcon
        iconName="Speak"
        icon={<AutoPlayGears />}
        handleIconClick={handleSpeakButtonClick}
        rotate={isSpeaking}
        selectedIcon={isSpeaking ? "Speak" : ""}
        selectedColorOverride={
          isSpeaking
            ? theme.palette.colors.red[500]
            : theme.palette.text.secondary
        }
        sx={{
          position: "relative",
        }}
        stopPropagation={true}
      />

      <PrimaryIcon
        iconName="Speak"
        icon={!isSpeaking ? <AutoPlayInactive /> : <AutoPlayInactive />}
        selectedIcon={isSpeaking ? "Speak" : ""}
        disabled={true}
        selectedColorOverride={
          isSpeaking
            ? theme.palette.colors.red[500]
            : theme.palette.text.secondary
        }
        sx={{
          position: "absolute",
          transition: "transform 0.3s ease",
          transform: isHovered ? "scale(1.1)" : "scale(1)",
        }}
      />
    </Box>
  );
};

export default CustomSpeakIcon;
