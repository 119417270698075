import Box from "@mui/material/Box";
import HomeHeaderSection from "./home-sections/HomeHeaderSection";
import HomeExpertsSection from "./home-sections/HomeExpertsSection";

const HomePage = () => {
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      width="100%"
      alignItems={"center"}
      justifyContent={"center"}
      sx={{ mt: "16px" }}
    >
      <HomeHeaderSection />
      <HomeExpertsSection />
    </Box>
  );
};

export default HomePage;
