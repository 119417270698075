import { useEffect } from "react";
import {
  Box,
  Dialog,
  List,
  ListItem,
  Typography,
  useTheme,
  alpha,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch } from "../../../../../../app/store";
import { setAPopupIsShowing } from "../../../../../../features/ui/confirmationPopupSlice";
import { PrimaryIcon } from "../../../../../ui-elements";
import KnowledgeDocThumbnail from "../../../footer-bar/file-drawer/KnowledgeDocThumbnail";

const SourcesPopup = ({ onClose, sources }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setAPopupIsShowing(true));
    window.onfocus = () => {
      dispatch(setAPopupIsShowing(true));
    };
    return () => {
      window.onfocus = null;
    };
  }, []);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={true}
      onClose={() => handleClose()}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "300px",
          maxHeight: "360px",
          borderRadius: "16px",
          backdropFilter: "blur(20px)",
          backgroundColor: theme.palette.background.backdrop,
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        width="100%"
        paddingTop="0px"
        marginBottom="32px"
        sx={{ textAlign: "center" }}
      >
        <Typography
          variant="h6"
          sx={{
            top: "8px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          Sources
        </Typography>
        <PrimaryIcon
          icon={<CloseIcon />}
          iconName="Close"
          handleIconClick={() => handleClose()}
          stopPropagation={true}
          zIndex={10}
          sx={{ position: "absolute", right: "8px", top: "8px" }}
        />
      </Box>
      <List
        sx={{
          width: "100%",
          maxHeight: "260px",
          overflowY: "auto",
          paddingTop: 0,
          marginTop: 0,
        }}
      >
        {sources.map((source, index) => (
          <ListItem key={index} divider sx={{ marginTop: "0px" }}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              width="100%"
              overflow="hidden"
              sx={{
                padding: "4px",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: alpha(
                    theme.palette.background.backdrop,
                    0.2
                  ),
                  cursor: "pointer",
                },

                "&:active": {
                  transform: "scale(0.98)",
                },
              }}
              onClick={() => window.open(source.url, "_blank")}
            >
              <KnowledgeDocThumbnail
                title={source.title}
                knowledgeDoc={{ type: "url", url: source.url }}
                size={24}
              />
              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                overflow="hidden"
                ml={2}
              >
                <Typography variant="body2" fontWeight={700} gutterBottom>
                  {source.title}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: 400,
                    color: "inherit",
                    textDecoration: "none",
                    overflow: "hidden",
                    display: "block",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    maxWidth: "90%",
                    cursor: "pointer",
                  }}
                >
                  {source.url}
                </Typography>
              </Box>
            </Box>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

export default SourcesPopup;
