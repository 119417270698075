import { Snackbar } from "@mui/material";
import {
  ICreateKnowledgeDocPayload,
  IFilesUploadPayload as IFilesUploadPayload,
} from "../types";

export function processFilesPicked(
  files: File[],
  expertiseId?: string,
  chatId?: string
) {
  const validFiles: ICreateKnowledgeDocPayload[] = [];
  const invalidFiles: File[] = [];
  const fileLength = files.length;
  for (let i = 0; i < fileLength; i++) {
    const file = files[i];
    const pathName = file.name.split(".").shift();
    const fileType = file.name.split(".").pop() || "";
    const pathExtension = "." + fileType;
    let createKnowledgeDocPayload: ICreateKnowledgeDocPayload = {
      pathName,
      pathExtension,
      file,
      knowledgeType: fileType,
      expertiseId: expertiseId ?? undefined,
      chatId: chatId ?? undefined,
    };

    switch (fileType) {
      case "txt":
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = async () => {
          if (reader.result === null) {
            return (
              <Snackbar autoHideDuration={6000} message="Unable to load file" />
            );
          }

          const text = reader.result.toString();
          createKnowledgeDocPayload = {
            ...createKnowledgeDocPayload,
            content: text,
          };
        };
        validFiles.push(createKnowledgeDocPayload);
        break;
      case "pdf":
      case "png":
      case "jpg":
      case "jpeg":
        createKnowledgeDocPayload = {
          ...createKnowledgeDocPayload,
          file,
        };
        validFiles.push(createKnowledgeDocPayload);
        break;
      default:
        invalidFiles.push(file);
        break;
    }
  }

  const filesUploadPayload = {
    files: validFiles,
    invalidFileCount: invalidFiles.length,
  } as IFilesUploadPayload;
  return filesUploadPayload;
}
