import { alpha, Box, Divider, lighten, Typography } from "@mui/material";
import { PrimaryIcon } from "../../../../ui-elements";
import { Logo } from "../../../../../icons";
import { NumberFormatterService } from "../../../../../services/NumberFormatterService";
import { useEffect } from "react";

export const CurrentSubscription = ({
  userSubscriptionSlice,
  usageDataReport,
  theme,
}) => {
  useEffect(() => {
    //
  }, [userSubscriptionSlice, usageDataReport]);

  const gradient = `linear-gradient(90deg, ${
    theme.palette.primary.main
  } 0%, ${lighten(theme.palette.colors.blue[500], 0.5)} 100%)`;

  const daysRemaining = () => {
    const simulationDate = usageDataReport?.simulationDate;
    const currentDate = simulationDate ? new Date(simulationDate) : new Date();
    const renewalDate = new Date(usageDataReport?.periodEnd as string);
    const diffTime = renewalDate.getTime() - currentDate.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    const formattedDate = renewalDate.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });

    const simulationSuffix = simulationDate ? " - simulated" : "";

    return `${formattedDate} (in ${diffDays} days ${simulationSuffix})`;
  };

  const ItemKey = (props: { title: string }) => {
    return (
      <Typography fontSize={"0.8em"} fontWeight={600} textAlign={"left"}>
        {props.title}
      </Typography>
    );
  };

  const ItemValue = (props: { value: string }) => {
    return (
      <Typography
        variant="body1"
        fontSize={"0.7em"}
        fontWeight={200}
        textAlign={"left"}
      >
        {props.value}
      </Typography>
    );
  };

  const ItemCell = (props: { title: string; value: string }) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          borderRadius: "8px",
        }}
      >
        <PrimaryIcon
          iconName={"Logo"}
          icon={<Logo />}
          disabled={true}
          handleIconClick={null}
          defaultColorOverride="primary"
          size="32px"
          sx={{
            marginTop: "-4px",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginBottom: "8px",
          }}
        >
          <ItemKey title={props.title} />
          <ItemValue value={props.value} />
        </Box>
      </Box>
    );
  };

  const getFormattedUsed = () => {
    if (
      usageDataReport?.creditUsage === undefined ||
      usageDataReport?.creditUsage === null
    ) {
      return "Loading";
    }

    return NumberFormatterService.formatNumberWithComma(
      usageDataReport?.creditUsage
    );
  };

  const getFormattedLimit = () => {
    if (
      usageDataReport?.creditLimit === undefined ||
      usageDataReport?.creditLimit === null
    ) {
      return "Loading";
    }

    const creditLimit = usageDataReport?.creditLimit;
    const formatted = NumberFormatterService.formatNumberWithComma(creditLimit);

    const bonus = userSubscriptionSlice.userSubscription?.bonusCredits;
    const bonusFormatted =
      bonus != null && bonus !== 0
        ? NumberFormatterService.formatNumberWithComma(bonus)
        : "";

    const limitString =
      bonusFormatted !== ""
        ? `${formatted} (+${bonusFormatted} bonus)`
        : formatted;

    return limitString;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "100%",
        width: "100%",
        alignItems: "center", // Ensure items are centered
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent={"center"}
        alignItems={"center"}
        height="150px"
        width="150px"
        padding="8px"
        marginLeft={"16px"}
        marginBottom={"16px"}
        sx={{
          borderRadius: "16px",
          background: gradient,
        }}
      >
        <Typography
          variant="body1"
          fontSize={"12px"}
          fontWeight={200}
          textAlign={"center"}
          sx={{
            opacity: 0.8,
            color: theme.palette.colors.grayScale.white,
          }}
        >
          Current Plan
        </Typography>
        <Typography
          fontSize={"24px"}
          fontWeight={900}
          textAlign={"center"}
          sx={{
            color: theme.palette.colors.grayScale.white,
          }}
        >
          {userSubscriptionSlice.userSubscription?.planName}
        </Typography>
      </Box>
      <Divider
        orientation="vertical"
        sx={{
          display: "flex",
          height: "120px",
          width: "1px",
          marginLeft: "16px",
          marginRight: "0px",
          marginBottom: "8px",
          backgroundColor: alpha(theme.palette.colors.grayScale[500], 0.05),
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "8px",
          paddingRight: "16px",
        }}
      >
        <ItemCell title="Credits Used" value={getFormattedUsed()} />
        <ItemCell title="Available Credits" value={getFormattedLimit()} />
        <ItemCell title="Period Ends" value={daysRemaining()} />
      </Box>
    </Box>
  );
};
