import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography, CircularProgress, Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import CustomInput from "../../../ui-elements/CustomInput";
import {
  loginGoogleUser,
  login,
} from "../../../../features/users/userAuthSlice";
import { GoogleLogin, CredentialResponse } from "@react-oauth/google";
import { IAuthFormData, IGoogleResponse } from "../../../../types/index";
import { useTheme } from "@mui/material";
import AnimatedAuthView from "./AnimatedAuthView";
import { AnimatedFromDirection } from "./AnimatedViewWrapper";
import { IGoogleAuthFormData } from "../../../../types/user/IUserAuth";
import { navigateBasedOnAuthStage } from "../../../../services/navigateBasedOnAuth";

const LoginView: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDarkMode = useAppSelector((state) => state.theme.isDarkMode);
  const submitButton = useRef<HTMLButtonElement | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [currentErrorMessage, setCurrentErrorMessage] = useState<string | null>(
    null
  );
  const [loading, setLoading] = useState(false);

  const initialLoginFormData = {
    email: "",
    password: "",
  } as IAuthFormData;

  const [formData, setFormData] = useState(initialLoginFormData);

  useEffect(() => {
    // if emailVerified=true in url, show message
    const params = new URLSearchParams(location.search);
    const emailVerified = params.get("emailVerified");
    if (emailVerified) {
      setCurrentErrorMessage(
        "Your email has been verified. Please login to continue."
      );
    }
  }, []);

  const callReportError = (message: string) => {
    setCurrentErrorMessage(message);
  };

  const clearErrorMessage = () => {
    setCurrentErrorMessage(null);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    callReportError(null);
    try {
      const response = await dispatch(login(formData));

      if (response.type === "userAuth/login/rejected") {
        callReportError(response.payload);
      }
    } catch (error) {
      callReportError("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleShowPassword = () => setShowPassword((prev) => !prev);

  const googleSuccess = async (response: IGoogleResponse) => {
    try {
      setLoading(true);
      clearErrorMessage();
      const googleAuthFormData = {
        token: response.credential,
      } as IGoogleAuthFormData;
      const result = await dispatch(loginGoogleUser(googleAuthFormData));
      if (result.type === "userAuth/loginGoogleUser/rejected") {
        callReportError(result.payload);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      callReportError("Google sign in was unsuccessful. Please try again.");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const googleError = () => {
    callReportError("Google sign in was unsuccessful. Please try again.");
  };

  return (
    <AnimatedAuthView direction={AnimatedFromDirection.Left}>
      <Typography variant="h5" gutterBottom>
        {loading ? "Signing In" : "Sign In"}
      </Typography>

      {loading && (
        <CircularProgress sx={{ marginTop: "24px" }} thickness={6} size={32} />
      )}
      {currentErrorMessage && !loading && (
        <Typography
          sx={{
            color: theme.palette.error.main,
            fontSize: "12px",
            textAlign: "center",
            cursor: "pointer",
            fontWeight: "600",
            padding: "8px",
          }}
          onClick={clearErrorMessage}
        >
          {currentErrorMessage}
        </Typography>
      )}
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          width: "100%",
          height: "100%",
          marginTop: "32px",
          alignItems: "center",
          justifyContent: "center",
          display: loading ? "none" : "flex",
          flexDirection: "column",
        }}
      >
        <Box width={"100%"}>
          <CustomInput
            name="email"
            label="Email Address"
            autoComplete="email"
            handleChange={handleChange}
            type="email"
          />
          <Box height={"24px"} />
          <CustomInput
            name="password"
            label="Password"
            autoComplete="current-password"
            handleChange={handleChange}
            type={showPassword ? "text" : "password"}
            handleShowPassword={handleShowPassword}
          />
        </Box>
        <Button
          sx={{
            marginTop: "8px",
            fontSize: "10px",
            textTransform: "none",
          }}
          onClick={() => navigate("/auth/request-reset-password")}
        >
          Forgot password?
        </Button>
        <Button
          ref={submitButton}
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
          fullWidth
          sx={{
            marginTop: "24px",
            marginBottom: "16px",
            borderRadius: "6px",
            "&:hover:active": {
              transform: "scale(0.95)",
              transition: "transform 0.1s",
            },
          }}
        >
          {loading ? <CircularProgress size={24} /> : "Sign In"}
        </Button>
        <GoogleLogin
          width="194px"
          theme={isDarkMode ? "filled_black" : "outline"}
          size="large"
          onSuccess={
            googleSuccess as (credentialResponse: CredentialResponse) => void
          }
          onError={googleError}
        />
        <Button
          sx={{
            marginTop: "16px",
            fontWeight: "300",
            fontSize: "14px",
            textTransform: "none",
            color: theme.palette.text.primary,
            "&:hover": {
              backgroundColor: theme.palette.background.divider,
              color: theme.palette.text.primary,
              fontWeight: "400",
            },
          }}
          onClick={() => navigate("/auth/signup")}
          disableRipple
        >
          Don't have an account? Sign Up
        </Button>
      </Box>
    </AnimatedAuthView>
  );
};

export default LoginView;
