import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import BlockTitle from "../../../../ui-elements/BlockTitle";
import { IUserPreferences } from "../../../../../types";
import { useAppDispatch } from "../../../../../app/store";
import CustomCheckbox from "../../../../ui-elements/CustomCheckbox";
import { updateUserPreferences } from "../../../../../features/users/userPreferencesSlice";

interface NewsletterBlockProps {
  userPreferences: IUserPreferences | null;
}

const NewsletterBlock = ({ userPreferences }: NewsletterBlockProps) => {
  const dispatch = useAppDispatch();
  const isSubscribed = userPreferences?.subscribedToNewsletter;
  const [isChecked, setIsChecked] = useState(isSubscribed);

  useEffect(() => {
    //console.log("isSubscribed changed in useEffect", isSubscribed);
  }, [isSubscribed]);

  const handleCheckboxChange = async (event) => {
    const newValue = event.target.checked;
    const updatedUser = {
      ...userPreferences,
      subscribedToNewsletter: newValue,
    };

    setIsChecked(newValue);
    console.log("updatedUser", updatedUser);
    dispatch(updateUserPreferences(updatedUser));
  };

  return (
    <Grid item container alignContent={"center"} justifyContent="flex-end">
      <Grid item width={"50%"} paddingTop={"8px"}>
        <BlockTitle
          title={"Newsletter"}
          subtitle={isChecked ? "Subscribed" : "Unsubscribed"}
          overrideMarginTop={true}
        />
      </Grid>
      <Grid
        item
        container
        width={"50%"}
        justifyContent="flex-end"
        alignContent="center"
      >
        <CustomCheckbox
          isChecked={isSubscribed}
          setIsChecked={handleCheckboxChange}
        />
      </Grid>
    </Grid>
  );
};

export default NewsletterBlock;
