import { useState, useEffect } from "react";
import {
  Typography,
  Drawer,
  TextField,
  List,
  ListItem,
  Link as MuiLink,
  ClickAwayListener,
  Box,
  lighten,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Close, CreateExpert, Search } from "../../../../icons";
import SidebarCell from "./SidebarCell";
import {
  fetchExperts,
  selectExpert,
  createExpert,
} from "../../../../features/experts/expertsSlice";
import virtual_experts_logo from "../../../../images/virtual-experts-logo.png";
import { useTheme, darken } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { IExpert } from "../../../../types/index";
import PrimaryIcon from "../../../ui-elements/PrimaryIcon";
import { setAPopupIsShowing } from "../../../../features/ui/confirmationPopupSlice";
import {
  ActiveLayout,
  setMobileDrawerOpen,
} from "../../../../features/chats/workspaceSlice";
import useScreenSize from "../../../ui-elements/useScreenSize";
import { getSortedExperts } from "./getSortedExperts";

interface SidebarProps {
  showLoading?: boolean;
}

function Sidebar({ showLoading = false }: SidebarProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const screenSize = useScreenSize();

  const expertsSlice = useAppSelector((state) => state.experts);
  const chatSlice = useAppSelector((state) => state.chat);
  const workspaceSlice = useAppSelector((state) => state.workspace);
  const isMobileDrawerOpen = workspaceSlice.mobileDrawerOpen;

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([] as IExpert[]);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (searchTerm.includes("/")) {
      return;
    }

    const fetchParams = {
      fetchAll: searchTerm === "",
      searchTerm: searchTerm,
    };

    dispatch(fetchExperts(fetchParams));
  }, [searchTerm]);

  useEffect(() => {
    const sortedExperts = getSortedExperts(
      expertsSlice.experts,
      expertsSlice.activeExpert
    );

    setSearchResults(sortedExperts);
  }, [expertsSlice.experts, expertsSlice.activeExpert]);

  const handleCellClick = async (index: number) => {
    const sortedExperts = getSortedExperts(
      expertsSlice.experts,
      expertsSlice.activeExpert
    );
    const expert = sortedExperts[index];
    if (expertsSlice.activeExpert?._id !== expert._id) {
      if (isMobileDrawerOpen) {
        await dispatch(selectExpert(expert));
        dispatch(setMobileDrawerOpen(false));
      } else {
        dispatch(selectExpert(expert));
      }
    }
  };

  useEffect(() => {
    dispatch(setAPopupIsShowing(isMobileDrawerOpen));
  }, [isMobileDrawerOpen]);

  const closeMobileDrawer = () => {
    dispatch(setMobileDrawerOpen(false));
    dispatch(setAPopupIsShowing(false));
  };

  const handleCreateClick = async () => {
    if (chatSlice.isSwitchingChats) {
      return;
    }

    if (isMobileDrawerOpen) {
      await dispatch(createExpert());
      dispatch(setMobileDrawerOpen(false));
    } else {
      dispatch(createExpert());
    }
  };

  function isSelected(expertId: string) {
    return expertsSlice.activeExpert?._id === expertId;
  }

  const activeLayout = workspaceSlice.activeLayout;
  const showSidebar = activeLayout === ActiveLayout.ONE;

  return showSidebar || isMobileDrawerOpen ? (
    <ClickAwayListener onClickAway={closeMobileDrawer}>
      <Drawer
        variant={screenSize.isMobileSize ? "temporary" : "permanent"}
        open={screenSize.isMobileSize ? workspaceSlice.mobileDrawerOpen : true}
        onClose={closeMobileDrawer}
        sx={{
          ".MuiDrawer-paper": {
            width: screenSize.isSmall
              ? "100%"
              : screenSize.isMobileSize
              ? "60%"
              : (theme) => theme.drawerWidth,
            maxWidth: { xs: "100%", md: theme.drawerWidth },
            zIndex: screenSize.isMobileSize
              ? (theme) => theme.zIndex.drawer + 1
              : 0,
            backdropFilter: "none",
            borderColor: "transparent",
            boxShadow: `0px 0px 0px 0.5px ${theme.palette.background.divider}`,
            transition: "transform 0.2s ease-in-out",
          },
        }}
        BackdropProps={{
          hidden: screenSize.isMobileSize,
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            backdropFilter: "blur(5px)",
          },
        }}
      >
        <Box
          sx={{
            position: "relative",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            paddingLeft: "16px",
            paddingRight: "8px",
            paddingTop: screenSize.isMobileSize ? "16px" : "80px",
            backgroundColor: theme.palette.background.paper,
          }}
        >
          {screenSize.isMobileSize && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "16px",
                position: "relative",
                width: "100%",
              }}
            >
              <Box
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  if (isMobileDrawerOpen) {
                    closeMobileDrawer();
                  }
                  navigate("/home");
                }}
              >
                <img
                  src={virtual_experts_logo}
                  alt="virtual_experts_logo"
                  width="210"
                  height="30"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                  height: "100%",
                  width: "100%",
                  marginBottom: "8px",
                }}
              >
                <PrimaryIcon
                  iconName="close"
                  icon={<Close />}
                  handleIconClick={() => closeMobileDrawer()}
                  backgroundColorOverride={
                    theme.palette.background.buttonHighlighted
                  }
                />
              </Box>
            </Box>
          )}
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "12px",
              marginBottom: theme.spacing(2),
              color: theme.palette.text.secondary,
            }}
          >
            MY EXPERTS
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              marginBottom: 2,
            }}
          >
            <Box
              sx={{
                position: "relative",
                paddingRight: "8px",
                width: "100%",
                height: "40px",
                transition: "width 0.5s ease-in-out",
              }}
            >
              <TextField
                sx={{
                  height: "40px",
                  width: "100%",
                  backgroundColor: "transparent",
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  "& .MuiInputBase-root": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "none",
                  },
                  boxShadow: isFocused
                    ? `0px 0px 0px 2px ${theme.palette.primary.main}`
                    : `0px 0px 0px 1px ${theme.palette.text.placeholder}`,
                  borderRadius: "10px",
                  boxSizing: "border-box",
                  paddingLeft: "34px !important",
                  paddingTop: "10px",
                  paddingBottom: "8px",
                  paddingRight: "10px",
                  "& input": {
                    padding: 0,
                    height: "100%",
                    backgroundColor: theme.palette.background.paper,
                  },
                }}
                autoComplete="off"
                variant="filled"
                placeholder="Find expert..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontSize: "14px",
                    position: "relative",
                    width: "100%",
                  },
                }}
              />
              <PrimaryIcon
                iconName={"Search"}
                icon={<Search />}
                disabled={true}
                defaultColorOverride={
                  isFocused
                    ? theme.palette.primary.main
                    : theme.palette.background.button
                }
                sx={{
                  position: "absolute",
                  left: "3px",
                  top: "5px",
                }}
              />
            </Box>
            <Box
              onClick={handleCreateClick}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "40px",
                borderRadius: "8px",
                backgroundColor: theme.palette.background.buttonHighlighted,
                color: theme.palette.text.primary,
                fontWeight: 500,
                fontSize: "12px",
                cursor: "pointer",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: lighten(
                    theme.palette.background.buttonHighlighted,
                    0.15
                  ),
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingRight: isFocused ? "0px" : "10px",
                  paddingLeft: isFocused ? "4px" : "6px",
                }}
              >
                <PrimaryIcon
                  iconName="create"
                  icon={<CreateExpert />}
                  disabled={true}
                />
                {!isFocused && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 500,
                      //animation: "fadeIn 0.9s ease-in",
                    }}
                  >
                    New
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          <List
            sx={{
              flexGrow: 1,
              overflowY: "scroll !important",
              marginBottom: "0px",
            }}
          >
            {searchResults.map((expert, index) => (
              <ListItem
                key={index}
                sx={{
                  padding: "0px",
                }}
              >
                <SidebarCell
                  handleCellClick={handleCellClick}
                  isSelected={isSelected(expert._id)}
                  expert={expert}
                  index={index}
                />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            display: "flex",
            position: "absolute",
            bottom: "0px",
            width: "100%",
            height: "100%",
            zIndex: -1,
          }}
        ></Box>
      </Drawer>
    </ClickAwayListener>
  ) : null;
}

export default Sidebar;
