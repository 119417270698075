import React from "react";
import { Box, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

interface DragDropOverlayProps {
  isVisible: boolean;
}

const DragDropOverlay: React.FC<DragDropOverlayProps> = ({ isVisible }) => {
  if (!isVisible) return null;

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
      }}
    >
      <CloudUploadIcon sx={{ fontSize: 64, color: "white", mb: 2 }} />
      <Typography variant="h4" color="white" gutterBottom>
        Drop files here
      </Typography>
      <Typography variant="subtitle1" color="white">
        to upload them to your conversation
      </Typography>
    </Box>
  );
};

export default DragDropOverlay;
