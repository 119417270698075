import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import FileCard from "./FileCard";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { showConfirmPopup } from "../../../../../features/ui/confirmationPopupSlice";
import { formatBytes } from "../../../../helpers/CustomUtilities";

interface FileDrawerProps {
  files: File[];
  onFileDelete: (index: number) => void;
  setBlockGetCommand: (block: boolean) => void;
}

const FileDrawer: React.FC<FileDrawerProps> = ({
  files,
  onFileDelete,
  setBlockGetCommand,
}) => {
  const confirmationPopupSlice = useAppSelector(
    (state) => state.confirmationPopup
  );
  const dispatch = useAppDispatch();
  const [deletingIndex, setDeletingIndex] = useState<number>(-1);

  const handleDeleteIconTapped = (index: number) => {
    if (deletingIndex !== -1) return;
    setDeletingIndex(index);
    setBlockGetCommand(true);

    dispatch(
      showConfirmPopup({
        title: "Remove File",
        message: `Are you sure you want to remove ${files[index].name} from your message?`,
        confirmationText: "Remove",
        isDestructive: true,
        payload: `remove-${index}-from-message`,
      })
    );
  };

  useEffect(() => {
    if (
      confirmationPopupSlice.onConfirm.payload ===
      `remove-${deletingIndex}-from-message`
    ) {
      onFileDelete(deletingIndex);
    }

    setDeletingIndex(-1);
  }, [confirmationPopupSlice.onConfirm, confirmationPopupSlice.onCancel]);

  const fileSizeString = (size: number) => {
    return formatBytes(size);
  };

  return (
    <Grid
      container
      padding={"4px"}
      maxHeight={"200px"}
      sx={{ overflowY: "scroll" }}
    >
      {files.map((file, index) => (
        <FileCard
          key={index}
          index={index}
          title={file.name}
          subtitle={fileSizeString(file.size)}
          onFileDelete={handleDeleteIconTapped}
          file={file}
        />
      ))}
    </Grid>
  );
};

export default FileDrawer;
