import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { resendVerificationEmail } from "../../../../features/users/userCredentialsSlice";
import AnimatedAuthView from "./AnimatedAuthView";
import { AnimatedFromDirection } from "./AnimatedViewWrapper";
import { BackToLoginButton } from "./components/BackToLoginButton";

const VerificationPendingView: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const userCredentials = useAppSelector(
    (state) => state.userCredentials.userCredentials
  );
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [currentErrorMessage, setCurrentErrorMessage] = useState<string | null>(
    null
  );
  const [resendCooldown, setResendCooldown] = useState(0);

  const callReportError = (message: string) => {
    setCurrentErrorMessage(message);
  };

  const clearErrorMessage = () => {
    setCurrentErrorMessage(null);
  };

  useEffect(() => {
    if (userCredentials?.verified === true) {
      dispatch({ type: "userAuth/emailVerified" });
    }
  }, [userCredentials?.verified, dispatch]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (resendCooldown > 0) {
      interval = setInterval(() => {
        setResendCooldown((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [resendCooldown]);

  const handleResendEmail = async () => {
    if (resendCooldown > 0) return;

    setLoading(true);
    clearErrorMessage();
    try {
      const response = await dispatch(
        resendVerificationEmail(userCredentials?.email)
      );
      if (
        response.type === "userCredentials/resendVerificationEmail/fulfilled"
      ) {
        setCurrentErrorMessage("Verification email sent successfully");
        setResendCooldown(60); // Set a 60-second cooldown
      } else {
        callReportError(
          response.payload || "Failed to resend verification email"
        );
      }
    } catch (error) {
      callReportError(
        "An error occurred while resending the verification email"
      );
    }
    setLoading(false);
  };

  return (
    <AnimatedAuthView direction={AnimatedFromDirection.Right}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          width: "100%",
          padding: 3,
        }}
      >
        <Typography variant="h5" gutterBottom>
          Pending Email Verification
        </Typography>
        <Typography variant="body1" sx={{ mb: 2, mt: 2, opacity: 0.8 }}>
          We've sent you a verification email. If you don't see it in your inbox
          within the next few minutes, please check your spam folder. If no
          email has arrived in 5 minutes, please click the button below to
          resend the email.
        </Typography>
        {currentErrorMessage && (
          <Typography
            sx={{
              color: theme.palette.text.secondary,
              fontSize: "14px",
              textAlign: "center",
              fontWeight: "500",
              width: "100%",
              mb: 2,
            }}
          >
            {currentErrorMessage}
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mb: 2,
            alignSelf: "center",
          }}
          onClick={handleResendEmail}
          disabled={loading || resendCooldown > 0}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : resendCooldown > 0 ? (
            `Resend Email (${resendCooldown}s)`
          ) : (
            "Resend Email"
          )}
        </Button>
        <BackToLoginButton />
      </Box>
    </AnimatedAuthView>
  );
};

export default VerificationPendingView;
