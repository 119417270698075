// MessageService.ts
import { MiddlewareAPI } from "@reduxjs/toolkit";
import { socket } from "../../SocketProvider";
import {
  fetchMessages,
  onReceiveMessage,
  setShowFullQueue,
} from "../../features/chats/messagesSlice";
import { IMessage } from "../../types";
import {
  hydrateChat,
  updateChatWorkingState,
} from "../../features/chats/chatSlice";
import { IQueueUpdate } from "../../types/user/IQueueUpdate";
import { updateForMessageQueue } from "../../features/chats/queueUpdaterSlice";
import { IChatWorkingState } from "../../types/chat/IChat";

export class MessageService {
  private storeAPI: MiddlewareAPI;

  constructor(storeAPI: MiddlewareAPI) {
    this.storeAPI = storeAPI;
  }

  // INBOUND EVENTS
  public registerEvents() {
    socket.on("shouldRefreshActiveChat", this.handleRefreshActiveChat);
    socket.on("messageFilesProcessed", this.handleMessageFilesProcessed);
    socket.on("messageResponse", this.handleMessageResponse);
    socket.on("fullQueue", this.handleFullQueue);
    socket.on("queueUpdate", this.handleQueueUpdate);
    socket.on("updateChatWorkingState", this.handleUpdateChatWorkingState);
  }

  public unregisterEvents() {
    socket.off("shouldRefreshActiveChat", this.handleRefreshActiveChat);
    socket.off("messageFilesProcessed", this.handleMessageFilesProcessed);
    socket.off("messageResponse", this.handleMessageResponse);
    socket.off("fullQueue", this.handleFullQueue);
    socket.off("queueUpdate", this.handleQueueUpdate);
    socket.off("updateChatWorkingState", this.handleUpdateChatWorkingState);
  }

  // OUTBOUND EVENTS
  public handleActions(action: any) {
    switch (action.type) {
      case "messages/sendMessage/fulfilled":
        this.handleSendMessageAction(action.payload);
        break;
      case "messages/updateMessage/fulfilled":
        this.handleUpdateMessageAction(action.payload);
        break;
    }
  }

  // FUNCTIONS FOR SOCKET EVENTS
  private handleRefreshActiveChat = (chatId: any) => {
    //console.log("shouldRefreshActiveChat: ", chatId);
    const expertsSlice = this.storeAPI.getState().experts;
    const activeExpert = expertsSlice.activeExpert;
    const activeChat = activeExpert?.activeChat;
    const activeChatId = activeChat?._id ?? activeChat;

    if (activeChatId === chatId) {
      this.storeAPI.dispatch(hydrateChat(chatId) as any);
      this.storeAPI.dispatch(fetchMessages(chatId) as any);
    }
  };

  private handleMessageFilesProcessed = (payload: any) => {
    this.storeAPI.dispatch({ type: "loading/startLoading" });
    this.handleRefreshActiveChat(payload.chatId);
    socket.emit("sendMessage", payload);
  };

  private handleMessageResponse = (message: any) => {
    this.storeAPI.dispatch(onReceiveMessage(message) as any);
    this.storeAPI.dispatch({ type: "loading/stopLoading" });
  };

  public handleSendMessageAction(payload: IMessage) {
    this.storeAPI.dispatch({ type: "loading/startLoading" });
    if (payload.messageState === "default") {
      socket.emit("sendMessage", payload);
    }
  }

  public handleUpdateMessageAction(payload: any) {
    socket.emit("updateMessage", payload);
  }

  private handleFullQueue = (messageId: string) => {
    console.log("fullQueue: ", messageId);
    this.storeAPI.dispatch(setShowFullQueue(messageId) as any);
  };

  private handleQueueUpdate = (queueUpdate: IQueueUpdate) => {
    this.storeAPI.dispatch(updateForMessageQueue(queueUpdate) as any);
  };

  private handleUpdateChatWorkingState = (updatedState: IChatWorkingState) => {
    this.storeAPI.dispatch(updateChatWorkingState(updatedState) as any);
  };
}
