import React, { useRef, useEffect, useState } from "react";
import { Box } from "@mui/material";
import SectionBase from "../SectionBase";
import { useAppSelector, useAppDispatch } from "../../../../../../../app/store";
import { useTheme } from "@mui/material/styles";
import { Input, KnowledgeUploader } from "../../../../../../ui-elements";
import { InputData } from "../../../../../../ui-elements/CustomInput";
import {
  ICreateKnowledgeDocPayload,
  IFilesUploadPayload,
  IKnowledgeDoc,
} from "../../../../../../../types/index";
import { showConfirmPopup } from "../../../../../../../features/ui/confirmationPopupSlice";
import { createKnowledgeDocs } from "../../../../../../../features/experts/knowledgeDocsSlice";
import FileCard from "../../../../footer-bar/file-drawer/FileCard";

interface ChipContent {
  label: string;
  chipType: "skill" | "doc" | "uploading";
  knowledgeDoc?: IKnowledgeDoc | null;
}

const KnowledgeSection: React.FC = () => {
  const expertSlice = useAppSelector((state) => state.experts);
  const expertiseSlice = useAppSelector((state) => state.expertise);
  const [chipContent, setChipContent] = useState<ChipContent[]>([]);
  const chipContainerRef = useRef<HTMLDivElement | null>(null);
  const [hideUploadIcon, setHideUploadIcon] = useState(false);
  const [isUrlInputActive, setIsUrlInputActive] = useState(false);
  const dispatch = useAppDispatch();
  const theme = useTheme();

  function isUploading() {
    const chipContent = expertiseSlice.activeExpertise?.activeJobs;
    return chipContent && chipContent.length > 0;
  }
  const handleUrlInputFocus = () => {
    setIsUrlInputActive(true);
  };

  const handleUrlInputBlur = () => {
    setIsUrlInputActive(false);
  };

  useEffect(() => {
    if (expertiseSlice !== null) {
      const activeExpertise = expertiseSlice?.activeExpertise;
      const skills = (expertiseSlice.activeExpertise?.skills as string[]) ?? [];

      const knowledgeDocs =
        (expertiseSlice.activeExpertise?.knowledgeDocs as IKnowledgeDoc[]) ??
        [];

      const uploadJobs = [];
      const activeJobs = activeExpertise?.activeJobs;
      if (activeJobs && activeJobs.length > 0) {
        for (let i = 0; i < activeJobs?.length; i++) {
          const job = activeJobs[i];
          const label = job.jobTitle;
          const chipContent = {
            label: label,
            chipType: "uploading",
            tooltip: "",
          } as ChipContent;
          uploadJobs.push(chipContent);
        }
      }

      const chipContentArray: ChipContent[] = skills
        .map((skill) => {
          return { label: skill, chipType: "skill" } as ChipContent;
        })
        .concat(
          knowledgeDocs.map((doc) => {
            return {
              label: doc.title,
              knowledgeDoc: doc,
              chipType: "doc",
              tooltip: doc.url ?? doc.pathName,
            } as ChipContent;
          })
        )
        .sort((a, b) => a.label.localeCompare(b.label))
        .concat(uploadJobs)
        .reverse();

      setChipContent(chipContentArray);
    }
  }, [expertiseSlice]);

  function handleDeleteChip(chip: ChipContent) {
    if (chip.chipType === "skill") {
      dispatch(
        showConfirmPopup({
          title: "Confirm Delete",
          message: `Are you sure you want to delete ${chip.label}?`,
          confirmationText: "Delete",
          isDestructive: true,
          confirmationHandler: "@deleteSkill",
          payload: chip.label,
        })
      );
    } else {
      const knowledgeDoc = expertiseSlice.activeExpertise?.knowledgeDocs.find(
        (document) => document.title === chip.label
      ) as IKnowledgeDoc;
      const knowledgeDocId = knowledgeDoc._id;

      dispatch(
        showConfirmPopup({
          title: "Confirm Delete",
          message: `Are you sure you want to delete ${chip.label}?`,
          confirmationText: "Delete",
          isDestructive: true,
          confirmationHandler: "@deleteDoc",
          payload: knowledgeDocId,
        })
      );
    }
  }

  function checkForHideUploadIcon() {
    const container = chipContainerRef.current;
    const width = container?.clientWidth;
    setHideUploadIcon((width && width < 400) || false);
  }

  // TODO - This should be the knowledge uploader?  Or just another CreateKnowledgeDocPayload
  const handleSubmitInput = async (inputData: InputData) => {
    const expertise = expertiseSlice.activeExpertise;
    const expertiseId = expertise?._id;
    if (!expertiseId) {
      return;
    }

    const newKnowledgeDoc = {
      expertiseId: expertiseId,
      url: inputData.value,
      knowledgeType: "url",
    } as ICreateKnowledgeDocPayload;

    const fileUploadPayload = {
      urlKnowledge: newKnowledgeDoc,
    } as IFilesUploadPayload;

    dispatch(createKnowledgeDocs(fileUploadPayload));
  };

  useEffect(() => {
    checkForHideUploadIcon();
    window.addEventListener("resize", checkForHideUploadIcon);

    return () => {
      window.removeEventListener("resize", checkForHideUploadIcon);
    };
  }, [chipContainerRef.current]);

  const knowledgeDocForContext = (chip: ChipContent) => {
    if (chip.knowledgeDoc !== null && chip.knowledgeDoc !== undefined) {
      const knowledgeType = chip.knowledgeDoc.knowledgeType;
      if (knowledgeType && knowledgeType.toLocaleLowerCase() === "url") {
        return {
          type: "url",
          url: chip.knowledgeDoc.url,
        };
      }
    }

    return undefined;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-column",
        alignItems: "center",
        height: "auto",
        width: "100%",
        paddingBottom: "48px",
      }}
    >
      <SectionBase title="Knowledge" color={expertSlice.activeExpert?.color}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          minWidth="100%"
          sx={{ padding: "16px" }}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-end"
            width="100%"
            marginRight="8px"
          >
            <Input
              name="urls"
              label="URL"
              handleSubmit={handleSubmitInput}
              backgroundColorOverride={theme.palette.background.backdrop}
              selectedColorOverride={expertSlice.activeExpert?.color}
              clearOnBlur={true}
              onFocus={handleUrlInputFocus}
              onBlur={handleUrlInputBlur}
            />
          </Box>
          <KnowledgeUploader
            hideUploadButton={hideUploadIcon}
            isExpertise={true}
            isUrlInputActive={isUrlInputActive}
          />
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          alignContent={"flex-start"}
          ref={chipContainerRef}
          sx={{
            padding: "8px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            alignContent: "flex-start",
          }}
        >
          {chipContent.map((chip, index) => (
            <FileCard
              key={index}
              index={index}
              title={chip.label}
              subtitle={
                chip.knowledgeDoc
                  ? chip.knowledgeDoc?.knowledgeType
                  : chip.chipType
              }
              onFileDelete={() => handleDeleteChip(chip)}
              showLoading={isUploading() && chip.chipType === "uploading"}
              knowledgeDoc={knowledgeDocForContext(chip)}
              fullWidth={true}
              disable={chip.chipType === "uploading"}
            />
          ))}
        </Box>
      </SectionBase>
    </Box>
  );
};

export default KnowledgeSection;
