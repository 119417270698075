import React, { useEffect, useState, useRef } from "react";
import { IConversationStyle, IPersona } from "../../../../../../../types/index";
import { useAppSelector, useAppDispatch } from "../../../../../../../app/store";
import StyleCell from "./StyleCell";
import SectionBase from "../SectionBase";
import { Box, Divider } from "@mui/material";
import { updatePersona } from "../../../../../../../features/experts/personaSlice";
import CustomVoiceSelector from "../../../../../../ui-elements/CustomVoiceSelector";
import BlockTitle from "../../../../../../ui-elements/BlockTitle";

const StyleSection: React.FC = () => {
  const dispatch = useAppDispatch();
  const [containerWidth, setContainerWidth] = useState<number | null>(0);
  const expertSlice = useAppSelector((state) => state.experts);
  const personaSlice = useAppSelector((state) => state.persona);
  const voicesSlice = useAppSelector((state) => state.voices);
  const containerRef = useRef<HTMLDivElement>(null);
  const [cells, setCells] = useState<JSX.Element[]>([]);

  function handleUpdateValue(updatedStyle: IConversationStyle) {
    const updatedPersona = personaSlice.activePersona as IPersona;
    const updatedStyles = updatedPersona.conversation_styles.map(
      (style: IConversationStyle) => {
        if (style.label === updatedStyle.label) {
          return updatedStyle;
        } else {
          return style;
        }
      }
    );
    const updatedPersonaWithStyles = {
      ...updatedPersona,
      conversation_styles: updatedStyles,
    } as IPersona;
    dispatch(updatePersona(updatedPersonaWithStyles));
  }

  function hydrateCells() {
    const persona = personaSlice.activePersona as IPersona;
    if (persona) {
      const styles = persona.conversation_styles;
      setCells(
        styles
          ? styles.map((style: IConversationStyle, index: number) => (
              <StyleCell
                key={index}
                conversationStyle={style}
                containerWidth={containerWidth}
                handleUpdateValue={handleUpdateValue}
                initialValue={style.value}
              />
            ))
          : []
      );
    }
  }

  useEffect(() => {
    hydrateCells();
  }, [personaSlice]);

  useEffect(() => {
    const handleResize = () => {
      const width = containerRef?.current?.clientWidth as number;
      //console.log("width: " + width);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const voiceName = () => {
    const voice = voicesSlice.voices.find(
      (v) => v._id === expertSlice.activeExpert?.voice
    );
    return voice?.displayName;
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-column",
        alignItems: "flex-start",
        alignContent: "flex-start",
        height: "auto",
        width: "100%",
        paddingBottom: "48px",
      }}
    >
      <SectionBase title="Style" color={expertSlice.activeExpert?.color}>
        <Box padding="0px 24px 0px 24px">
          <CustomVoiceSelector activeExpert={expertSlice.activeExpert} />
          <Box height={"48px"} />
          <Divider />
          <Box height={"24px"} />
          <BlockTitle title="Conversation Styles" subtitle="" />
        </Box>
        {!expertSlice.loading && cells}
      </SectionBase>
    </Box>
  );
};

export default StyleSection;
