import React, { useEffect, useState, useRef, useCallback } from "react";
import { Typography, Box, List } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { getActionCommandsForStrings } from "../../../../../../features/commands/commandSource";
import { ICommand, IExpert } from "../../../../../../types/index";
import CommandCell from "./CommandCell";
import { IMessageBarState } from "../state-setters/MessageBarState";

interface CommandPopupProps {
  messageBarState: IMessageBarState;
  editorWrapperRef: React.MutableRefObject<any>;
  selectCellAtIndex: (index: number) => void;
  expert?: IExpert;
  isFocused: boolean;
}

const CommandPopup: React.FC<CommandPopupProps> = ({
  messageBarState,
  editorWrapperRef,
  selectCellAtIndex,
  expert,
  isFocused,
}) => {
  const commands = getActionCommandsForStrings(
    messageBarState.suggestedCommands
  ) as ICommand[];
  const theme = useTheme();
  const listRef = useRef<HTMLUListElement>(null);

  const [popupPosition, setPopupPosition] = useState({ left: 0, bottom: 0 });
  const [maxHeight, setMaxHeight] = useState(400);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    if (!isFocused) {
      if (messageBarState.showSuggestions) {
        messageBarState.showSuggestions = false;
      }
    }
  }, [isFocused]);

  const updatePopupPosition = useCallback(() => {
    if (editorWrapperRef.current) {
      const parentRect = editorWrapperRef.current.getBoundingClientRect();
      const parentWidth = parentRect.width;
      const rightMax = parentWidth - 250 - 16;
      const horizontalOffset =
        messageBarState.cursorPosition < rightMax
          ? messageBarState.cursorPosition + 16
          : rightMax + 16;

      const bottomPosition = window.innerHeight - parentRect.top;

      setPopupPosition({
        left: parentRect.left + horizontalOffset,
        bottom: bottomPosition,
      });

      setMaxHeight(Math.min(window.innerHeight - 180, 400));
    }
  }, [messageBarState.cursorPosition, editorWrapperRef, windowSize]);

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const observer = new MutationObserver(updatePopupPosition);
    if (editorWrapperRef.current) {
      observer.observe(editorWrapperRef.current, {
        childList: true,
        subtree: true,
        characterData: true,
      });
    }

    window.addEventListener("scroll", updatePopupPosition, true);
    window.addEventListener("resize", updatePopupPosition);
    window.addEventListener("focus", updatePopupPosition);

    updatePopupPosition();

    return () => {
      window.removeEventListener("scroll", updatePopupPosition, true);
      window.removeEventListener("resize", updatePopupPosition);
      window.removeEventListener("focus", updatePopupPosition);
      if (editorWrapperRef.current) {
        observer.disconnect();
      }
    };
  }, [updatePopupPosition, editorWrapperRef]);

  useEffect(() => {
    const safeHighlightedIndex = getSafeHighlightedIndex(
      messageBarState.highlightedSuggestionsIndex
    );
    if (safeHighlightedIndex !== -1 && listRef.current) {
      const currentItem = listRef.current.children[
        safeHighlightedIndex
      ] as HTMLElement;

      if (currentItem) {
        currentItem.scrollIntoView({ block: "nearest" });
      } else {
        console.error("Could not find item to scroll to");
      }
    }
  }, [
    messageBarState.suggestedCommands,
    messageBarState.showSuggestions,
    messageBarState.highlightedSuggestionsIndex,
  ]);

  function getSafeHighlightedIndex(highlightedIndex: number) {
    return Math.max(
      0,
      Math.min(highlightedIndex, messageBarState.suggestedCommands.length - 1)
    );
  }

  if (messageBarState.showSuggestions) {
    return (
      <Box
        sx={{
          display: "flex",
          position: "fixed",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          top: 0,
          left: 0,
          backgroundColor: "rgba(0, 0, 0, 0.3)",

          zIndex: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            flexDirection: "column",
            zIndex: 10,
            backgroundColor: theme.palette.background.backdrop,
            borderRadius: "12px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
            padding: "5px",
            width: `calc(100% - 16px)`,
            maxWidth: "440px",
            alignSelf: "center",
            maxHeight: `${maxHeight}px`,
            overflowY: "hidden",
            bottom: `${popupPosition.bottom + 16}px`,
          }}
        >
          <Box
            sx={{
              padding: "4px",
              paddingRight: "8px",
              fontWeight: "600",
              fontSize: "14px",
              marginRight: "8px",
              marginLeft: "8px",
              position: "sticky",
              top: 0,
              backgroundColor: theme.palette.background.backdrop,
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontWeight: 800,
                fontSize: "14px",
                color: expert?.color,
                textAlign: "center",
              }}
            >
              Commands
            </Typography>
          </Box>
          <Box
            sx={{
              height: "1px",
              width: "100%",
              backgroundColor: theme.palette.colors.grayScale[500],
              marginTop: "4px",
              marginBottom: "4px",
            }}
          />
          <List
            sx={{
              listStyleType: "none",
              padding: 0,
              margin: 0,
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              maxHeight: `${maxHeight - 40}px`, // Subtract header height
            }}
            ref={listRef}
          >
            {commands.map((command, index) => (
              <CommandCell
                key={command.command}
                command={command}
                index={index}
                messageBarState={messageBarState}
                expert={expert}
                selectCellAtIndex={selectCellAtIndex}
              />
            ))}
          </List>
        </Box>
      </Box>
    );
  } else {
    return null;
  }
};

export default CommandPopup;
