// TranscriptionService.ts
import { MiddlewareAPI } from "@reduxjs/toolkit";
import { socket } from "../../SocketProvider";
import {
  setTranscriptionData,
  throwError,
  updateTranscriptionState,
} from "../../features/audio/transcriptionSlice";

export class TranscriptionService {
  private storeAPI: MiddlewareAPI;

  constructor(storeAPI: MiddlewareAPI) {
    this.storeAPI = storeAPI;
  }

  // INBOUND EVENTS
  public registerEvents() {
    socket.on("setTranscriptionState", this.handleSetTranscriptionState);
    socket.on("transcriptionReceived", this.handleTranscriptionReceived);
    socket.on("throwTranscriptionError", this.handleTranscriptionError);
  }

  public unregisterEvents() {
    socket.off("setTranscriptionState", this.handleSetTranscriptionState);
    socket.off("transcriptionReceived", this.handleTranscriptionReceived);
    socket.off("throwTranscriptionError", this.handleTranscriptionError);
  }

  // OUTBOUND EVENTS
  public handleActions(action: any) {
    switch (action.type) {
      case "audioTranscription/setTranscriptionState":
        this.handleSetTranscriptionStateAction(action.payload, action.userId);
        break;
      case "audioTranscription/streamAudio":
        this.handleStreamAudioAction(action.payload);
        break;
      case "audioTranscription/setClearTranscriptionSegment":
        this.handleClearTranscriptionSegmentAction();
        break;
    }
  }

  // FUNCTIONS FOR SOCKET EVENTS
  private handleSetTranscriptionState = (transcriptionState: any) => {
    this.storeAPI.dispatch(updateTranscriptionState(transcriptionState));
  };

  private handleTranscriptionReceived = (transcription: any) => {
    this.storeAPI.dispatch(setTranscriptionData(transcription));
  };

  private handleTranscriptionError = (errorMessage: any) => {
    // console.log("Transcription Error Thrown: ", errorMessage);
    this.storeAPI.dispatch(throwError(errorMessage) as any);
  };

  // handle actions
  public handleSetTranscriptionStateAction(payload: any, userId: string) {
    socket.emit("setTranscriptionState", { userId, payload });
  }

  public handleStreamAudioAction(payload: any) {
    socket.emit("streamAudio", payload);
  }

  public handleClearTranscriptionSegmentAction() {
    socket.emit("clearTranscriptionSegment");
  }
}
