import {
  IExpert,
  IExpertFetchPayload,
  IExpertise,
  IPersona,
  IFetchExpertisePayload,
  IFetchPersonaPayload,
} from "../types/index";
import API from "./api";

// experts
export const fetchExperts = (fetchParams: IExpertFetchPayload) =>
  API.post("/experts/fetchExperts", fetchParams);
export const createExpert = (userId: string) =>
  API.post("/experts", { userId: userId });
export const updateExpert = (body: IExpert) =>
  API.put("/experts/updateExpert", body);
export const deleteExpert = (id: string) => API.delete("/experts/" + id);
export const fetchExpert = (id: string) =>
  API.post("/experts/fetchExpert", { id });

// API routes for Persona
export const fetchPersona = (payload: IFetchPersonaPayload) =>
  API.post(`/persona/fetchPersona`, payload);
export const createPersona = (newPersona: IPersona) =>
  API.post(`/persona/createPersona`, newPersona);
export const updatePersona = (updatedPersona: IPersona) =>
  API.patch(`/persona/updatePersona`, updatedPersona);
export const deletePersona = (personaId: string) =>
  API.delete(`/persona/${personaId}`);

// API routes for Expertise
export const fetchExpertise = (payload: IFetchExpertisePayload) =>
  API.post(`/expertise/fetchExpertise`, payload);
export const createExpertise = (newExpertise: IExpertise) =>
  API.post(`/expertise/createExpertise`, newExpertise);
export const updateExpertise = (updatedExpertise: IExpertise) =>
  API.patch(`/expertise/updateExpertise`, updatedExpertise);
export const deleteExpertise = (expertiseId: string) =>
  API.delete(`/expertise/${expertiseId}`);
