import { Circle } from "@mui/icons-material";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Box,
} from "@mui/material";
import { PrimaryIcon } from "../../ui-elements";
import { Logo } from "../../../icons";

const getTitleWithLogo = (title, theme, link, color) => {
  const content = (
    <Box display="flex" alignItems="center">
      {title}
    </Box>
  );

  return link ? (
    <a href={link} style={{ textDecoration: "none", color: color }}>
      {content}
    </a>
  ) : (
    content
  );
};

const getTextFromReactNode = (node) => {
  if (typeof node === "string") {
    return node;
  }
  if (Array.isArray(node)) {
    return node.map(getTextFromReactNode).join("");
  }
  if (node.props && node.props.children) {
    return getTextFromReactNode(node.props.children);
  }
  return "";
};

const getLinkFromReactNode = (node) => {
  if (node.props && node.props.href) {
    return node.props.href;
  }
  if (node.props && node.props.children) {
    return getLinkFromReactNode(node.props.children);
  }
  return null;
};

export const getCustomMarkdownComponents = (
  theme: Theme,
  customBullets = false
) => {
  return {
    h1: ({ node, ...props }) => {
      const title = getTextFromReactNode(props.children);
      const link = getLinkFromReactNode(props.children);
      const color = theme.palette.text.primary;
      return (
        <h1
          style={{
            marginTop: "0px",
            marginBottom: "16px",
            fontSize: "1.8em",
            fontWeight: "bold",
            color: color,
          }}
        >
          {getTitleWithLogo(title, theme, link, color)}
        </h1>
      );
    },
    h2: ({ node, ...props }) => {
      const title = getTextFromReactNode(props.children);
      const link = getLinkFromReactNode(props.children);
      const color = theme.palette.text.primary;
      return (
        <h2
          style={{
            marginTop: "24px",
            marginBottom: "4px",
            fontSize: "1.4em",
            fontWeight: "800",
            color: color,
          }}
        >
          {getTitleWithLogo(title, theme, link, color)}
        </h2>
      );
    },
    h3: ({ node, ...props }) => {
      const title = getTextFromReactNode(props.children);
      const link = getLinkFromReactNode(props.children);
      const color = theme.palette.text.primary;
      return (
        <h3
          style={{
            marginTop: "8px",
            marginBottom: "0px",
            marginLeft: "16px",
            fontSize: "1.1em",
            fontWeight: "800",
            color: color,
          }}
        >
          {getTitleWithLogo(title, theme, link, color)}
        </h3>
      );
    },
    a: ({ node, ...props }) => (
      <a
        style={{ textDecoration: "none", color: theme.palette.primary.main }}
        {...props}
      />
    ),
    p: ({ node, ...props }) => (
      <p
        style={{ marginBottom: "16px", marginLeft: "16px", fontSize: "0.85em" }}
        {...props}
      />
    ),
    ul: ({ node, ordered, children, depth, ...props }) => {
      const margin = depth * 20;
      return (
        <List
          component={ordered ? "ol" : "ul"} // Use 'ol' for ordered lists, 'ul' for unordered
          style={{
            paddingTop: customBullets ? "0px" : "0px",
            marginLeft: `${margin}px`,
          }}
          {...props} // Spread other props, but don't include 'ordered'
        >
          {children}
        </List>
      );
    },
    li: ({ node, children, ordered, ...props }) => {
      if (!customBullets) {
        return (
          <ListItem sx={{ margin: 0, padding: 0 }} {...props}>
            <ListItemIcon>
              <Circle
                sx={{
                  height: "8px",
                  width: "8px",
                  borderRadius: "50%",
                  color: theme.palette.text.primary,
                  margin: "0px",
                  marginLeft: "24px",
                  padding: "0px",
                }}
              />
            </ListItemIcon>
            <ListItemText primary={children} />
          </ListItem>
        );
      }
      return (
        <ListItem>
          <ListItemIcon>
            <PrimaryIcon icon={<Logo />} iconName="check_circle" />
          </ListItemIcon>
          <ListItemText primary={children} />
        </ListItem>
      );
    },
  };
};
