export class LocalStorageService {
  static set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static get(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  static remove(key: string) {
    localStorage.removeItem(key);
  }

  static clear() {
    localStorage.clear();
  }

  // Method for setting an array of files into localStorage
  static async setFilesForKey(key: string, files: File[]) {
    const processedFiles = await Promise.all(
      files.map((file) => LocalStorageService._fileToBase64(file))
    );

    // Store file metadata and base64 data
    const fileDataArray = files.map((file, index) => ({
      name: file.name,
      type: file.type,
      base64: processedFiles[index],
    }));

    localStorage.setItem(key, JSON.stringify(fileDataArray));
  }

  // Method for retrieving an array of files from localStorage
  static async getFilesForKey(key: string): Promise<File[]> {
    const fileDataArray = JSON.parse(localStorage.getItem(key));

    if (!fileDataArray) return [];

    // Convert base64 back to File objects
    return fileDataArray.map((fileData) => {
      const blob = LocalStorageService._base64ToBlob(
        fileData.base64,
        fileData.type
      );
      return new File([blob], fileData.name, { type: fileData.type });
    });
  }

  // Helper to convert file to base64
  static _fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }

  // Helper to convert base64 to Blob
  static _base64ToBlob(base64: string, type: string): Blob {
    const byteString = atob(base64.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type });
  }
}
