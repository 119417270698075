import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../routes/routes";
import { clearPrompts } from "./promptsSlice";
import { handleAxiosError } from "../../app/ErrorHandler";
import { ICache } from "../../types/index";

const initialState = {
  loading: false,
  activeCache: null as ICache | null,
  error: "" as unknown,
};

export const fetchCache = createAsyncThunk(
  "cache/fetchCache",
  async (cacheId: string, { rejectWithValue }) => {
    try {
      const result = await api.fetchCache(cacheId);

      return result.data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const updateCache = createAsyncThunk(
  "cache/updateCache",
  async (updatedCache: ICache, { rejectWithValue }) => {
    try {
      const result = await api.updateCache(updatedCache);
      return result.data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const deleteCache = createAsyncThunk(
  "cache/deleteCache",
  async (cacheId: string, { rejectWithValue }) => {
    try {
      await api.deleteCache(cacheId);
      return cacheId;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const resetCache = createAsyncThunk(
  "cache/resetCache",
  async (cacheId: string, { dispatch, rejectWithValue }) => {
    try {
      const result = await api.resetCache(cacheId);
      dispatch(clearPrompts());
      return result.data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const cacheSlice = createSlice({
  name: "cache",
  initialState: initialState,
  reducers: {
    selectCache: (state, action) => {
      state.activeCache = action.payload;
    },
    clearCache: (state) => {
      state.activeCache = {
        _id: "",
        cacheName: "",
        chatId: "",
        promptCount: 0,
        totalWordCount: 0,
        totalTokenCount: 0,
        lastPromptSent: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCache.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCache.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.activeCache = action.payload;
    });
    builder.addCase(fetchCache.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(updateCache.fulfilled, () => {
      // handle updated cache
    });
    builder.addCase(deleteCache.fulfilled, () => {
      // handle cache deleted
    });
    builder.addCase(resetCache.fulfilled, (state, action) => {
      state.activeCache = action.payload;
    });
  },
});

export const { selectCache, clearCache } = cacheSlice.actions;

export default cacheSlice.reducer;
