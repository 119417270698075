import { setPreparingEdit } from "../../../../../../features/chats/messagesSlice";
import {
  doAction,
  selectCommand,
} from "../../../../../../features/commands/commandsSlice";
import { ICommand, IActionData } from "../../../../../../types";
import { createEmptyDecoratedEditor } from "../commands/CommandDecorator";
import { IMessageBarState } from "../state-setters/MessageBarState";

export function handleSubmit(
  messagesSlice,
  dispatch,
  messageBarState: IMessageBarState,
  commandsSlice,
  activeExpert
) {
  return () => {
    if (messagesSlice.editing) {
      dispatch(setPreparingEdit(true));
    }
    const contentState = messageBarState.editorState.getCurrentContent();
    let currentMessage = contentState.getPlainText().trim();
    const activeCommand = commandsSlice.activeCommand as ICommand;

    if (activeCommand != null) {
      const startIndex = activeCommand.startIndex as number;
      const endIndex = activeCommand.endIndex as number;
      const beforeCommand = currentMessage.slice(0, startIndex);
      const afterCommand = currentMessage.slice(endIndex + 1);
      currentMessage = `${beforeCommand}${afterCommand}`.trim();
    }

    const command =
      activeCommand != null && activeCommand.command != null
        ? activeCommand.command
        : null;

    const message = currentMessage != null ? currentMessage : null;
    const files = messageBarState.files;
    const submitData = { message, command, files } as IActionData;

    dispatch(doAction(submitData));

    messageBarState.editorState = createEmptyDecoratedEditor(
      commandsSlice,
      activeExpert
    );

    dispatch(selectCommand(null));

    messageBarState.showPlaceholder = true;
    messageBarState.showSuggestions = false;
    messageBarState.wasShowingSuggestions = false;
  };
}
