import { Grid, ListItemIcon, Typography, Box } from "@mui/material";
import { Logo } from "../../../../../icons";
import { useTheme } from "@mui/material/styles";

interface PlanFeaturesProps {
  features: string[];
  accentColor: string;
}

const PlanFeatures: React.FC<PlanFeaturesProps> = ({
  features,
  accentColor,
}) => {
  return (
    <Grid
      container
      spacing={1}
      alignItems="flex-start"
      marginBottom={"16px"}
      marginTop={"8px"}
      paddingLeft={"24px"}
      paddingRight={"24px"}
    >
      {features.map((feature, index) => (
        <Grid
          key={index}
          item
          xs={12}
          marginBottom={"16px"}
          sx={{
            display: "flex",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: "auto",
              marginRight: "16px",
            }}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              height={"16px"}
              width={"16px"}
              color={accentColor}
            >
              <Logo />
            </Box>
          </ListItemIcon>
          <Typography
            variant="body2"
            fontSize={"14px"}
            sx={{ wordWrap: "break-word", height: "100%" }}
          >
            {feature}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default PlanFeatures;
