import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography, CircularProgress, Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import CustomInput from "../../../ui-elements/CustomInput";
import {
  signup,
  signupGoogleUser,
} from "../../../../features/users/userAuthSlice";
import { GoogleLogin, CredentialResponse } from "@react-oauth/google";
import { IAuthFormData, IGoogleResponse } from "../../../../types/index";
import { useTheme } from "@mui/material";
import AnimatedAuthView from "./AnimatedAuthView";
import { AnimatedFromDirection } from "./AnimatedViewWrapper";
import AuthCheckboxBlock from "./blocks/AuthCheckboxBlock";
import { IGoogleAuthFormData } from "../../../../types/user/IUserAuth";

const SignupView: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDarkMode = useAppSelector((state) => state.theme.isDarkMode);
  const submitButton = useRef<HTMLButtonElement | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [currentErrorMessage, setCurrentErrorMessage] = useState<string | null>(
    null
  );
  const [loading, setLoading] = useState(false);

  const initialSignupFormData = {
    email: "",
    password: "",
    confirmPassword: "",
    subscribedToNewsletter: "false",
    acceptedTerms: "false",
  } as IAuthFormData;

  const [formData, setFormData] = useState(initialSignupFormData);

  const callReportError = (message: string) => {
    setCurrentErrorMessage(message);
  };

  const clearErrorMessage = () => {
    setCurrentErrorMessage(null);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Perform basic validation checks before proceeding
    if (formData.password !== formData.confirmPassword) {
      callReportError("Passwords do not match.");
      return;
    }
    if (formData.acceptedTerms !== "true") {
      callReportError("You must accept the terms and conditions.");
      return;
    }

    setLoading(true); // Start loading
    clearErrorMessage(); // Clear any existing error messages

    try {
      // Dispatch the signup action
      const response = await dispatch(signup(formData));

      // Check the response type to determine if the signup was rejected
      if (response.type === "userAuth/signup/rejected") {
        callReportError(response.payload); // Display the error message from payload
      } else if (response.type === "userAuth/signup/fulfilled") {
        // Success - Your state management or AuthStateMachine should handle navigation
      }
    } catch (error) {
      callReportError("An error occurred during signup. Please try again.");
    } finally {
      setLoading(false); // Stop loading regardless of success or failure
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleShowPassword = () => setShowPassword((prev) => !prev);

  const googleSuccess = async (response: IGoogleResponse) => {
    try {
      setLoading(true);
      clearErrorMessage();
      const googleAuthFormData = {
        token: response.credential,
        acceptedTerms: formData.acceptedTerms,
        subscribedToNewsletter: formData.subscribedToNewsletter,
      } as IGoogleAuthFormData;
      const result = await dispatch(signupGoogleUser(googleAuthFormData));
      if (result.type === "userAuth/signupGoogleUser/rejected") {
        callReportError(result.payload);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      callReportError("Google sign up was unsuccessful. Please try again.");
    }
  };

  const googleError = () => {
    callReportError("Google sign up was unsuccessful. Please try again.");
  };

  return (
    <AnimatedAuthView direction={AnimatedFromDirection.Right}>
      <Typography variant="h5" gutterBottom>
        {loading ? "Signing Up" : "Sign Up"}
      </Typography>
      {loading && (
        <CircularProgress sx={{ marginTop: "24px" }} thickness={6} size={32} />
      )}
      {currentErrorMessage && !loading && (
        <Typography
          sx={{
            color: theme.palette.error.main,
            fontSize: "12px",
            textAlign: "center",
            cursor: "pointer",
            fontWeight: "600",
            padding: "8px",
          }}
          onClick={clearErrorMessage}
        >
          {currentErrorMessage}
        </Typography>
      )}
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          width: "100%",
          display: loading ? "none" : "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box width={"100%"} marginTop={"24px"} marginBottom={"24px"}>
          <CustomInput
            name="email"
            label="Email Address"
            autoComplete="email"
            handleChange={handleChange}
            type="email"
          />
          <Box height={"24px"} />
          <CustomInput
            name="password"
            label="Password"
            autoComplete="new-password"
            handleChange={handleChange}
            type={showPassword ? "text" : "password"}
            handleShowPassword={handleShowPassword}
          />
          <Box height={"24px"} />
          <CustomInput
            name="confirmPassword"
            label="Repeat Password"
            autoComplete="new-password"
            handleChange={handleChange}
            type={showPassword ? "text" : "password"}
          />
        </Box>
        <AuthCheckboxBlock
          title="Newsletter"
          subtitle="Stay up to date on the latest news and updates."
          setValue={(value) =>
            setFormData({ ...formData, subscribedToNewsletter: value })
          }
          value={formData.subscribedToNewsletter}
        />
        <AuthCheckboxBlock
          title="Terms & Conditions / Privacy Policy"
          subtitle={
            <>
              By signing up, you agree to our{" "}
              <span
                onClick={() => navigate("/terms")}
                style={{ cursor: "pointer", textDecoration: "underline" }}
              >
                Terms & Conditions
              </span>{" "}
              and{" "}
              <span
                onClick={() => navigate("/privacy")}
                style={{ cursor: "pointer", textDecoration: "underline" }}
              >
                Privacy Policy
              </span>
              .
            </>
          }
          setValue={(value) =>
            setFormData({ ...formData, acceptedTerms: value })
          }
          value={formData.acceptedTerms}
        />
        <Button
          ref={submitButton}
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
          fullWidth
          sx={{
            marginTop: "16px",
            marginBottom: "16px",
            borderRadius: "6px",
            "&:hover:active": {
              transform: "scale(0.9)",
              transition: "transform 0.1s",
            },
          }}
        >
          {"Sign Up"}
        </Button>
        <GoogleLogin
          width="194px"
          theme={isDarkMode ? "filled_black" : "outline"}
          size="large"
          onSuccess={
            googleSuccess as (credentialResponse: CredentialResponse) => void
          }
          onError={googleError}
          text="signup_with"
        />
        <Button
          sx={{
            marginTop: "16px",
            fontWeight: "300",
            fontSize: "14px",
            textTransform: "none",
            color: theme.palette.text.primary,
            "&:hover": {
              backgroundColor: theme.palette.background.divider,
              color: theme.palette.text.primary,
              fontWeight: "400",
            },
          }}
          onClick={() => navigate("/auth/login")}
          disableRipple
        >
          Already have an account? Sign In
        </Button>
      </Box>
    </AnimatedAuthView>
  );
};

export default SignupView;
