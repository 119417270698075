import { useEffect } from "react";
import CustomToggle from "../../../../ui-elements/CustomToggle";
import { Grid } from "@mui/material";
import BlockTitle from "../../../../ui-elements/BlockTitle";
import { IUserPreferences } from "../../../../../types";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { setDarkMode } from "../../../../../features/ui/themeSlice";
import { updateUserPreferences } from "../../../../../features/users/userPreferencesSlice";

interface DarkModeBlockProps {
  userPreferences: IUserPreferences | null;
}

const DarkModeBlock = ({ userPreferences }: DarkModeBlockProps) => {
  const dispatch = useAppDispatch();
  const isDarkMode = useAppSelector((state) => state.theme.isDarkMode);

  useEffect(() => {
    // console.log("isDarkMode", isDarkMode);
  }, [userPreferences, isDarkMode]);

  const handleToggleChange = async (event) => {
    const updatedUser = {
      ...userPreferences,
      prefersDarkMode: event.target.checked,
    };
    dispatch(setDarkMode(event.target.checked));
    dispatch(updateUserPreferences(updatedUser));
  };

  return (
    <Grid item container alignContent={"center"} justifyContent="flex-end">
      <Grid item width={"50%"} paddingTop={"8px"}>
        <BlockTitle
          title={"Dark Mode"}
          subtitle={isDarkMode ? "On" : "Off"}
          overrideMarginTop={true}
        />
      </Grid>
      <Grid
        item
        container
        width={"50%"}
        justifyContent="flex-end"
        alignContent="flex-end"
      >
        <CustomToggle checked={isDarkMode} onChange={handleToggleChange} />
      </Grid>
    </Grid>
  );
};

export default DarkModeBlock;
