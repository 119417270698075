import { RootState } from "../../../app/store";
import { selectChat } from "../chatSlice";
import { IExpert } from "../../../types";
import { handleAxiosError } from "../../../app/ErrorHandler";
import {
  deletePreviousChat,
  hasActiveSessionChanged,
  hasMesseges,
  setNewChatStateToOpening,
  setPreviousChatStateToSaving,
  shouldDeletePreviousChat,
  fetchParentObjects,
  featchChildObjects as fetchChildObjects,
} from "./ChatProcessHelpers";
import { IChat, IOpenChatPayload } from "../../../types/chat/IChat";
import * as api from "../../../routes/routes";

export async function openChat(
  chatId: string,
  switchingExperts: boolean,
  dispatch,
  getState,
  rejectWithValue
) {
  try {
    const state = getState() as RootState;
    const activeChat = state.chat.activeChat;
    const activeChatId = activeChat?._id;
    const activeExpert = state.experts.activeExpert as IExpert;
    const activeExpertId = activeExpert?._id;

    const hasMessages = hasMesseges(state);
    const shouldDelete = shouldDeletePreviousChat(
      hasMessages,
      activeChat,
      switchingExperts
    );

    if (shouldDelete) {
      await deletePreviousChat(activeChatId, state, dispatch);
    } else {
      if (!switchingExperts) {
        setPreviousChatStateToSaving(activeChatId, dispatch);
      }
    }

    setNewChatStateToOpening(chatId, dispatch);

    const includePreviousChat = !switchingExperts && hasMessages;
    const openChatPayload = {
      chatId: chatId,
      previousChatId: includePreviousChat ? activeChatId : null,
      expertId: activeExpertId,
    } as IOpenChatPayload;

    const chatToLoad = await api.openChat(openChatPayload);
    const chat = chatToLoad.data as IChat;

    // if the active expert or chat has changed, do nothing
    if (hasActiveSessionChanged(activeExpertId, activeChatId, getState)) {
      return null;
    }

    // expert, cache, chats, messages
    const cacheId = await fetchParentObjects(chat, dispatch);

    // if the active expert or chat has changed, do nothing
    if (hasActiveSessionChanged(activeExpertId, activeChatId, getState)) {
      return null;
    }

    // prompts and messages
    await fetchChildObjects(dispatch, cacheId, state, chat);

    if (hasActiveSessionChanged(activeExpertId, activeChatId, getState)) {
      return null;
    }

    dispatch(selectChat(chat));
  } catch (error) {
    return rejectWithValue(handleAxiosError(error));
  }
}
