import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../routes/routes";
import { handleAxiosError } from "../../app/ErrorHandler";
import { clearChat } from "../chats/chatSlice";
import { clearMessages } from "../chats/messagesSlice";
import { clearCache } from "../chats/cacheSlice";
import { clearPrompts } from "../chats/promptsSlice";
import { clearPersona } from "../experts/personaSlice";
import { clearExpertise } from "../experts/expertiseSlice";
import { clearExperts, clearActiveExpert } from "../experts/expertsSlice";
import {
  IAuthFormData,
  IGoogleAuthFormData,
  IUserAuth,
} from "../../types/user/IUserAuth";
import { clearUserDetails, loadUserDetails } from "./userDetailsSlice";
import {
  clearUserCredentials,
  loadUserCredentials,
} from "./userCredentialsSlice";
import {
  clearUserPreferences,
  loadUserPreferences,
} from "./userPreferencesSlice";
import {
  clearUserSubscription,
  loadUserSubscription,
} from "./userSubscriptionSlice";
import { RootState } from "../../app/store";
import { loadVoices } from "../audio/voicesSlice";
import { LocalStorageService } from "../../services/LocalStorageService";
import { getUsageDataReport } from "./accountPageSlice";

const initialState = {
  loading: false,
  userAuth: LocalStorageService.get("userAuth") as IUserAuth | null,
  manualLogout: false,
  forceLogout: false,
  loadingUser: false,
};

export const loginGoogleUser = createAsyncThunk(
  "userAuth/loginGoogleUser",
  async (
    googleAuthFormData: IGoogleAuthFormData,
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(setLoadingUser(true));
      dispatch(setForceLogout(false));
      dispatch(setManualLogout(false));
      dispatch(setLoading(true));

      const { data } = await api.loginGoogleUser(googleAuthFormData);
      LocalStorageService.set("userAuth", data);
      dispatch(setUserAuth(data));
      await dispatch(loadUser());
      dispatch(setLoadingUser(false));
      return data;
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setLoadingUser(false));
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const signupGoogleUser = createAsyncThunk(
  "userAuth/signupGoogleUser",
  async (
    googleAuthFormData: IGoogleAuthFormData,
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(setLoadingUser(true));
      dispatch(setLoading(true));
      dispatch(setForceLogout(false));
      dispatch(setManualLogout(false));
      const { data } = await api.signUpGoogleUser(googleAuthFormData);
      LocalStorageService.set("userAuth", data);
      dispatch(setUserAuth(data));
      dispatch(loadUser());
      dispatch(setLoadingUser(false));
      return data;
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setLoadingUser(false));
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const login = createAsyncThunk(
  "userAuth/login",
  async (loginFormData: IAuthFormData, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingUser(true));
      dispatch(setForceLogout(false));
      dispatch(setManualLogout(false));
      dispatch(setLoading(true));
      const { data } = await api.login(loginFormData);
      LocalStorageService.set("userAuth", data);
      dispatch(setUserAuth(data));
      dispatch(loadUser());
      dispatch(setLoadingUser(false));
      return data;
    } catch (error) {
      dispatch(setLoadingUser(false));
      dispatch(setLoading(false));
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const signup = createAsyncThunk(
  "userAuth/signup",
  async (signupFormData: IAuthFormData, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingUser(true));
      dispatch(setForceLogout(false));
      dispatch(setManualLogout(false));
      dispatch(setLoading(true));
      const { data } = await api.signUp(signupFormData);
      LocalStorageService.set("userAuth", data);
      dispatch(setUserAuth(data));
      dispatch(loadUser());
      dispatch(setLoadingUser(false));
      return data;
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setLoadingUser(false));
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const validateToken = createAsyncThunk(
  "userAuth/validateToken",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.validateToken();
      return data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const logout = createAsyncThunk(
  "userAuth/logout",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(clearChat());
      dispatch(clearMessages());
      dispatch(clearCache());
      dispatch(clearPrompts());
      dispatch(clearExpertise());
      dispatch(clearPersona());
      dispatch(clearExperts());
      dispatch(clearActiveExpert());
      dispatch(clearUserDetails());
      dispatch(clearUserCredentials());
      dispatch(clearUserPreferences());
      dispatch(clearUserSubscription());

      // no clear user?
      return "Logout successful";
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const loadUser = createAsyncThunk(
  "userAuth/loadUser",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      let user = state.userAuth.userAuth?.user;
      if (user === undefined || user === null) {
        const userAuth = LocalStorageService.get("userAuth") as IUserAuth;
        user = userAuth.user;
      }

      const userDetailsId = user?.userDetails;
      const userCredentialsId = user?.userCredentials;
      const userPreferencesId = user?.userPreferences;
      const userSubscriptionId = user?.userSubscription;

      const promises = [];
      promises.push(dispatch(loadUserDetails(userDetailsId)));
      promises.push(dispatch(loadUserCredentials(userCredentialsId)));
      promises.push(dispatch(loadUserPreferences(userPreferencesId)));
      promises.push(dispatch(loadUserSubscription(userSubscriptionId)));
      promises.push(dispatch(loadVoices()));

      await Promise.all(promises);
      await dispatch(getUsageDataReport());

      dispatch(setLoading(false));
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const userAuthSlice = createSlice({
  name: "userAuth",
  initialState: initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setUserAuth: (state, action) => {
      state.userAuth = action.payload;
    },
    setManualLogout: (state, action) => {
      state.manualLogout = action.payload;
    },
    setForceLogout: (state, action) => {
      state.forceLogout = action.payload;
    },
    setLoadingUser: (state, action) => {
      state.loadingUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      // "clear erases all local storage so only needs to be called once"
      LocalStorageService.clear();
      state.userAuth = null;
    });

    builder.addCase(loginGoogleUser.rejected, (state) => {
      LocalStorageService.clear();
      state.userAuth = null;
    });
    builder.addCase(signupGoogleUser.rejected, (state) => {
      LocalStorageService.clear();
      state.userAuth = null;
    });
    builder.addCase(login.rejected, (state) => {
      LocalStorageService.clear();
      state.userAuth = null;
    });

    builder.addCase(signup.rejected, (state) => {
      LocalStorageService.clear();
      state.userAuth = null;
    });
    builder.addCase(validateToken.rejected, (state) => {
      LocalStorageService.clear();
      state.userAuth = null;
    });
  },
});

export const {
  setManualLogout,
  setLoading,
  setUserAuth,
  setForceLogout,
  setLoadingUser,
} = userAuthSlice.actions;

export default userAuthSlice.reducer;
