import { Typography, Paper, Grid, useTheme, Box, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import PlanFeatures from "./PlanFeatures";
import FooterButtonSection from "./FooterButtonSection";
import {
  getCreditString,
  IPlan,
} from "../../../../../types/ui/pricing-page/IPlan";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { checkout } from "../../../../../features/users/userSubscriptionSlice";
import GetStartedButton from "../../home-page/home-components/GetStartedButton";
import ConfirmDowngradePopup from "./ConfirmDowngradePopup";
import { setIntendedPlanId } from "../../../../../features/ui/pages/pricingPageSlice";

interface ISubscriptionCellProps {
  plan: IPlan;
  isFeaturedPlan: boolean;
  isCurrentPlan: boolean;
  hasCurrentPlan: boolean;
}

export const SubscriptionCell: React.FC<ISubscriptionCellProps> = ({
  plan,
  isFeaturedPlan,
  isCurrentPlan,
  hasCurrentPlan,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const userAuthSlice = useAppSelector((state) => state.userAuth);
  const userDetailsSlice = useAppSelector((state) => state.userDetails);
  const userCredentialsSlice = useAppSelector((state) => state.userCredentials);
  const userAuth = userAuthSlice.userAuth;
  const isLoggedIn = userAuth?.token !== null && userAuth?.token !== undefined;
  const isBetaApproved = userCredentialsSlice.userCredentials?.betaApproved;
  const isVerified = userCredentialsSlice.userCredentials?.verified;
  const isOnboarded = userDetailsSlice.userDetails?.onboarded;

  const pricingPageSlice = useAppSelector((state) => state.pricingPage);
  const dispatch = useAppDispatch();
  const creditString = getCreditString(plan.creditLimit);
  const isDarkMode = theme.palette.mode === "dark";
  const accentColor = isFeaturedPlan
    ? theme.palette.primary.main
    : theme.palette.text.primary;

  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (pricingPageSlice.intendedPlanId !== null) {
      if (isLoggedIn) {
        dispatch(checkout(pricingPageSlice.intendedPlanId));
        navigate("/loading?upgrading=true");
      } else {
        console.log(
          "setting intended plan id to null because user is not logged in"
        );
        dispatch(setIntendedPlanId(null));
      }
    }
  }, []);

  const handlePlanSelected = () => {
    if (!isFeaturedPlan && hasCurrentPlan && !isCurrentPlan) {
      setOpenDialog(true);
      return;
    }

    if (isLoggedIn) {
      if (!isBetaApproved) {
        return navigate("/auth/beta-pending");
      }

      if (!isVerified) {
        return navigate("/auth/verification-pending");
      }

      if (!isOnboarded) {
        return navigate("/auth/user-details");
      }

      dispatch(checkout(plan._id));
      navigate("/loading?upgrading=true");
    } else {
      dispatch(setIntendedPlanId(plan._id));
      navigate("/auth?signup=true");
    }
  };

  // called when there is already a plan and user is changing it
  const handleCloseDialog = (confirm: boolean) => {
    setOpenDialog(false);
    if (confirm) {
      dispatch(checkout(plan._id));
      navigate("/loading?upgrading=true");
    }
  };

  useEffect(() => {
    // console.log("selected plan changed...");
  }, [isFeaturedPlan, isCurrentPlan, hasCurrentPlan]);

  const planFeatures = [
    "AI chats organized by virtual expert",
    "Deeper context with per-expert chat memory",
    "Persist prompts between chats with Cache",
    "Talk to text transcription powered by Deepgram",
    "Listen to messages aloud with text-to-speech",
    "Multi-voice conversation playback",
    "Realtime web results powered by Brave",
    "PDF, PNG, JPEG, (and more soon) file reading support",
    "Customizable expert personalities and knowledge",
  ];

  return (
    <Grid item xs={12}>
      <Paper
        elevation={2}
        sx={{
          p: 2,
          borderRadius: "8px",
          transition: "0.3s",
          "&:hover": {
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          },
        }}
      >
        <Typography
          variant="h6"
          fontSize={isFeaturedPlan ? "32px" : undefined}
          color={accentColor}
        >
          {plan.name}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {plan.description}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            my: 2,
            mb: 2,
            color: isFeaturedPlan
              ? isDarkMode
                ? theme.palette.colors.purple[200]
                : theme.palette.colors.purple[900]
              : undefined,
          }}
        >
          ${plan.price}/mo
        </Typography>
        <Divider sx={{ mb: 2 }} />

        <Grid
          container
          item
          display={"flex"}
          direction={"column"}
          justifyContent={"center"}
          alignContent={"center"}
          width={"100%"}
        >
          <Typography
            fontSize={isFeaturedPlan ? "42px" : "32px"}
            fontWeight={"600"}
            textAlign={"center"}
            color={accentColor}
          >
            {creditString}
          </Typography>
          <Typography
            fontSize={"14px"}
            fontWeight={"500"}
            textAlign={"center"}
            color={theme.palette.text.secondary}
          >
            credits
          </Typography>
        </Grid>
        {!isCurrentPlan &&
        (plan.name.toLowerCase() === "premium" || isFeaturedPlan) ? (
          <Box
            display="flex"
            justifyContent="center"
            marginTop={"16px"}
            marginBottom={"24px"}
            width={"100%"}
          >
            <GetStartedButton
              onClick={handlePlanSelected}
              color={
                isFeaturedPlan
                  ? theme.palette.primary.main
                  : theme.palette.background.backdrop
              }
              textColor={
                isFeaturedPlan ? undefined : theme.palette.primary.main
              }
              textOverride={
                hasCurrentPlan
                  ? isCurrentPlan
                    ? "Current"
                    : "Upgrade"
                  : "Get Started"
              }
            />
          </Box>
        ) : null}
        <Divider sx={{ mt: 2 }} />
        <Typography
          variant="body2"
          color={theme.palette.text.secondary}
          fontWeight={"900"}
          fontSize={"10px"}
          width={"100%"}
          textAlign={"center"}
          sx={{ mt: 4, opacity: 0.7 }}
        >
          - All Plans Include -
        </Typography>
        <PlanFeatures features={planFeatures} accentColor={accentColor} />
        <FooterButtonSection
          isCurrentPlan={isCurrentPlan}
          isFeaturedPlan={isFeaturedPlan}
          hasCurrentPlan={hasCurrentPlan}
          isPremiumPlan={plan.name.toLowerCase() === "premium"}
          handlePlanSelected={handlePlanSelected}
        />
      </Paper>
      <ConfirmDowngradePopup open={openDialog} onClose={handleCloseDialog} />
    </Grid>
  );
};

export default SubscriptionCell;
