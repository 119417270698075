import React from "react";
import {
  Box,
  Grid,
  Typography,
  Link,
  Divider,
  useTheme,
  alpha,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import logo_gray from "../../images/logo-gray.png";
import { useAppSelector } from "../../app/store";

const FooterMenuItem = ({ to, label }) => {
  const themeSlice = useAppSelector((state) => state.theme);
  const theme = useTheme();
  if (to === "/appvolks") {
    return (
      <Link
        href={"https://www.appvolks.com"}
        target="_blank"
        rel="noopener noreferrer"
        paddingBottom={"5px"}
        underline="hover"
        sx={{
          color: theme.palette.text.secondary,
          fontSize: "12px",
          "&:hover": {
            color: themeSlice.publicColor ?? theme.palette.primary.main,
            textDecoration: "none",
          },
          display: "block", // Makes the link take the full width of its container
        }}
      >
        {label}
      </Link>
    );
  }

  return (
    <Link
      component={RouterLink}
      to={to}
      paddingBottom={"5px"}
      underline="hover"
      sx={{
        color: theme.palette.text.secondary,
        fontSize: "12px",
        "&:hover": {
          color: themeSlice.publicColor ?? theme.palette.primary.main,
          textDecoration: "none",
        },
        display: "block",
      }}
    >
      {label}
    </Link>
  );
};

const SectionTitle = ({ children }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  return (
    <Typography
      sx={{
        fontWeight: "bold",
        fontSize: "12px",
        color: isDarkMode
          ? alpha(theme.palette.common.white, 0.7)
          : theme.palette.text.secondary,
        paddingBottom: "8px",
      }}
    >
      {children}
    </Typography>
  );
};

const SiteFooter = () => {
  const theme = useTheme();
  const userAuthSlice = useAppSelector((state) => state.userAuth);
  const userAuth = userAuthSlice.userAuth;
  const isLoggedIn = userAuth?.token !== null && userAuth?.token !== undefined;

  return (
    <Box
      component="footer"
      sx={{
        py: 2,
        px: 2,
        backgroundColor: theme.palette.background.paper,
        borderTop: `1px solid ${theme.palette.divider}`,
        width: "100%",
      }}
    >
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        paddingTop="8px"
        paddingLeft={"8px"}
        paddingRight={"8px"}
      >
        <Grid
          item
          xs={12}
          sm={1}
          marginRight={"64px"}
          marginLeft={(xs) => (xs ? "0px" : "16px")}
          marginBottom={"16px"}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <Link
              component={RouterLink}
              to="/"
              sx={{
                display: "block",
                width: "140px",
                height: "20px",
                "&:hover": {
                  textDecoration: "none",
                },
              }}
            >
              <img
                src={logo_gray}
                alt="Virtual Experts Logo Gray"
                width="140"
                height="20"
              />
            </Link>
          </Box>
        </Grid>

        <Grid item xs={12} sm={2} marginBottom={"16px"}>
          <SectionTitle>Info</SectionTitle>
          <FooterMenuItem to="/terms" label="Terms & Conditions" />
          <FooterMenuItem to="/privacy" label="Privacy Policy" />
          <FooterMenuItem to="/appvolks" label="AppVolks LLC" />
        </Grid>

        <Grid item xs={12} sm={2} marginBottom={"16px"}>
          <SectionTitle>Contact</SectionTitle>
          <FooterMenuItem to="/contact?type=questions" label="Contact" />
          <FooterMenuItem to="/contact?type=feedback" label="Feedback" />
          <FooterMenuItem to="/contact?type=bug-report" label="Bug Report" />
        </Grid>
        <Grid item xs={12} sm={2} marginBottom={"16px"} marginRight={"32px"}>
          <SectionTitle>Get Started</SectionTitle>
          <Box>
            <FooterMenuItem to="/pricing" label="Pricing" />
            {isLoggedIn && <FooterMenuItem to="/workspace" label="Workspace" />}
            {isLoggedIn && <FooterMenuItem to="/account" label="Account" />}
            {!isLoggedIn && (
              <FooterMenuItem to="/auth?signup=true" label="Sign Up" />
            )}
            {!isLoggedIn && <FooterMenuItem to="/auth" label="Sign In" />}
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ my: 2, borderColor: theme.palette.background.divider }} />

      <Typography
        variant="body2"
        sx={{
          color: theme.palette.text.secondary,
          fontSize: "0.75rem",
          textAlign: "left",
        }}
      >
        © 2024 AppVolks LLC. All rights reserved.
      </Typography>
    </Box>
  );
};

export default SiteFooter;
