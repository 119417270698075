import { createAsyncThunk } from "@reduxjs/toolkit";
import { IQueueUpdate } from "../../types/user/IQueueUpdate";
import { setStateForMessage } from "./messagesSlice";
import { RootState } from "../../app/store";
import { setReplyQueueCountForExpert } from "../experts/expertsSlice";

// Define the QueueUpdaterService
export const updateForMessageQueue = createAsyncThunk(
  "queueUpdater/processUpdate",
  async (queueUpdate: IQueueUpdate, { dispatch, getState }) => {
    const {
      userId,
      expertId,
      chatId,
      messageId,
      expertReplyQueueCount,
      chatReplyQueueCount,
      messageState,
      queueAction,
    } = queueUpdate;

    const state = getState() as RootState;
    const existingUserId = state.userAuth.userAuth?.user?._id;
    if (existingUserId !== userId) {
      //console.log("User id mismatch when updating queue. Ignoring update.");
      return;
    }

    dispatch(
      setReplyQueueCountForExpert({
        expertId,
        newCount: expertReplyQueueCount,
      })
    );

    dispatch(setStateForMessage({ messageId, messageState }));
  }
);
