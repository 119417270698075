import { EditorState } from "draft-js";
import { setCurrentString } from "../../../../../../features/chats/messagesSlice";
import { ActiveView } from "../../../../../../features/chats/workspaceSlice";

export function updateCurrentStringInState(
  activeView: ActiveView,
  newEditorState: EditorState,
  messagesSlice,
  dispatch
) {
  if (activeView === ActiveView.CHAT) {
    const currentString = newEditorState.getCurrentContent().getPlainText();
    const hasString = currentString !== "";
    const currentStringInState = messagesSlice.currentString;
    const hadString = currentStringInState !== "";

    if (hasString !== hadString) {
      dispatch(setCurrentString(currentString));
    }
  }
}
