import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { Chat, Credit, Date, Event, Expert, Message } from "../../../../icons";
import { PrimaryIcon } from "../../../ui-elements";
import { NumberFormatterService } from "../../../../services/NumberFormatterService";

interface DataCellProps {
  title: string;
  value: number;
  iconName: string;
  color: string;
}

const DataCell: React.FC<DataCellProps> = ({
  title,
  value,
  iconName,
  color,
}) => {
  const theme = useTheme();

  const DataIcon = ({ iconName, icon }) => {
    return (
      <PrimaryIcon
        iconName={iconName}
        icon={icon}
        size="64px"
        defaultColorOverride={theme.palette.colors.grayScale.white}
        backgroundColorOverride="transparent"
        disabled={true}
      />
    );
  };

  const formattedValue = NumberFormatterService.formatNumberWithComma(value);

  const icons = {
    chatCount: DataIcon({ iconName: "chatCount", icon: <Chat /> }),
    creditUsage: DataIcon({ iconName: "creditUsage", icon: <Credit /> }),
    daysActive: DataIcon({ iconName: "daysActive", icon: <Date /> }),
    expertCount: DataIcon({ iconName: "expertCount", icon: <Expert /> }),
    messageCount: DataIcon({ iconName: "messageCount", icon: <Message /> }),
    usageEvents: DataIcon({ iconName: "usageEvents", icon: <Event /> }),
  };

  return (
    <Box
      sx={{
        backgroundColor: color,
        borderRadius: "16px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "16px",
        boxSizing: "border-box",
        width: "100%",
        height: "160px",
        paddingTop: "8px",
      }}
    >
      <Box
        sx={{
          fontSize: "24px",
          marginBottom: "8px",
        }}
      >
        {icons[iconName]}
      </Box>
      <Typography
        variant="subtitle2"
        sx={{
          fontSize: "12px",
          marginBottom: "8px",
          fontWeight: 800,
          color: theme.palette.colors.grayScale.white,
          textAlign: "center",
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="h4"
        sx={{
          fontSize: "24px",
          color: theme.palette.colors.grayScale.white,
          textAlign: "center",
        }}
      >
        {formattedValue}
      </Typography>
    </Box>
  );
};

export default DataCell;
