import { EditorState, Modifier } from "draft-js";
import { AppDispatch } from "../../../../../../app/store";
import { getActionCommandStrings } from "../../../../../../features/commands/commandSource";
import { selectCommand } from "../../../../../../features/commands/commandsSlice";
import { ICommand } from "../../../../../../types";
import { IMessageBarState } from "../state-setters/MessageBarState";
import { useEffect } from "react";

interface FetchAndFormatCommandsProps {
  currentEditorState: EditorState;
  activeCommand: ICommand | null;
  isCacheView: boolean;
}

export function locateActiveCommand({
  currentEditorState,
  activeCommand,
  isCacheView,
}: FetchAndFormatCommandsProps) {
  const contentState = currentEditorState.getCurrentContent();
  const selection = currentEditorState.getSelection();
  const currentBlock = contentState.getBlockForKey(selection.getStartKey());
  const currentBlockText = currentBlock.getText();
  const commands = isCacheView ? ["@prompt"] : getActionCommandStrings();
  const words = currentBlockText.split(" ");

  if (!isCacheView) {
    if (
      activeCommand !== null &&
      activeCommand.command !== null &&
      !words.includes(activeCommand.command)
    ) {
      activeCommand = null;
    }
  }

  const hasCurrentCommand =
    activeCommand != null && activeCommand.command != null;
  let matchedCommand = null;
  let startIndex = 0;

  words.forEach((word) => {
    const endIndex = startIndex + word.length;
    const isCommandWord = commands.includes(word);

    if (word.startsWith("@") && isCommandWord) {
      if (hasCurrentCommand) {
        if (word === activeCommand?.command) {
          matchedCommand = {
            command: word,
            startIndex: startIndex,
            endIndex: endIndex,
          };
        }
      } else {
        matchedCommand = {
          command: word,
          startIndex: startIndex,
          endIndex: endIndex,
        };
      }

      // Apply formatting to partial
    }
    startIndex = endIndex + 1;
  });

  return { matchedCommand };
}

interface DeterminShowPopupProps {
  currentWord: string;
  messageBarState: IMessageBarState;
}

export function determineShowPopup({
  currentWord,
  messageBarState,
}: DeterminShowPopupProps) {
  const commands = getActionCommandStrings();
  if (currentWord.startsWith("@")) {
    const matchingCommands = commands.filter((word) =>
      word?.startsWith(currentWord)
    );

    if (matchingCommands.length > 0) {
      messageBarState.suggestedCommands = matchingCommands;
      messageBarState.showSuggestions = true;
    } else {
      messageBarState.showSuggestions = false;
    }
  } else {
    messageBarState.showSuggestions = false;
  }
}

interface FormatAndApplyCommandSelectionProps {
  messageBarState: IMessageBarState;
  activeCommand: ICommand;
  dispatch: AppDispatch;
}

export function formatAndApplyCommandSelection({
  messageBarState,
  activeCommand,
  dispatch,
}: FormatAndApplyCommandSelectionProps) {
  const contentState = messageBarState.editorState.getCurrentContent();
  const selection = messageBarState.editorState.getSelection();
  const currentBlock = contentState.getBlockForKey(selection.getStartKey());
  const currentBlockText = currentBlock.getText();
  const currentWord = currentBlockText
    .substring(0, selection.getStartOffset())
    .split(" ")
    .pop() as string;

  if (activeCommand === null || activeCommand.command === null) {
    const updatedContentState = Modifier.replaceText(
      contentState,
      selection.merge({
        anchorOffset: selection.getStartOffset() - currentWord.length,
        focusOffset: selection.getStartOffset(),
      }),
      messageBarState.suggestedCommands[
        messageBarState.highlightedSuggestionsIndex
      ]
    );

    const difference =
      messageBarState.suggestedCommands[
        messageBarState.highlightedSuggestionsIndex
      ].length - currentWord.length;

    const startIndex = selection.getStartOffset() - currentWord.length;
    const endIndex = selection.getStartOffset() + difference;

    dispatch(
      selectCommand({
        command:
          messageBarState.suggestedCommands[
            messageBarState.highlightedSuggestionsIndex
          ],
        startIndex: startIndex,
        endIndex: endIndex,
      })
    );

    const newEditorState = EditorState.set(messageBarState.editorState, {
      currentContent: updatedContentState,
    });
    return { newEditorState, selection, currentWord };
  }
  const editorState = messageBarState.editorState;
  return { editorState, selection, currentWord };
}

interface SelectCommandAtIndexProps {
  messageBarState: IMessageBarState;
  activeCommand: ICommand;
  dispatch: AppDispatch;
}

export function selectCommandAtIndex({
  messageBarState,
  activeCommand,
  dispatch,
}: SelectCommandAtIndexProps) {
  const { newEditorState, selection, currentWord } =
    formatAndApplyCommandSelection({
      messageBarState,
      activeCommand,
      dispatch,
    });

  const index = messageBarState.highlightedSuggestionsIndex;

  const startIndex =
    selection.getStartOffset() -
    currentWord.length +
    messageBarState.suggestedCommands[index].length;
  const endIndex =
    selection.getStartOffset() -
    currentWord.length +
    messageBarState.suggestedCommands[index].length;

  const newState = EditorState.forceSelection(
    newEditorState,
    selection.merge({
      anchorOffset: startIndex,
      focusOffset: endIndex,
    })
  );

  messageBarState.editorState = newState;
  messageBarState.showSuggestions = false;
  return newEditorState;
}
