import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  Grid,
  Typography,
  Snackbar,
  CircularProgress,
  Box,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import CustomInput from "../../../ui-elements/CustomInput";
import { FileUploader } from "react-drag-drop-files";
import { IUserDetails } from "../../../../types/index";
import { useTheme } from "@mui/material";
import { updateUserDetails } from "../../../../features/users/userDetailsSlice";
import BlockTitle from "../../../ui-elements/BlockTitle";
import CustomDatePicker from "../../../ui-elements/CustomDatePicker";
import { ImageBlock } from "../../account-page/settings-section";
import { BackToLoginButton } from "./components/BackToLoginButton";
import AnimatedAuthView from "./AnimatedAuthView";
import { AnimatedFromDirection } from "./AnimatedViewWrapper";
import { fileTypes } from "../AuthPageManager";

const UserDetailsView: React.FC = () => {
  const userCredentials = useAppSelector(
    (state) => state.userCredentials.userCredentials
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const submitButton = useRef<HTMLButtonElement | null>(null);
  const [currentErrorMessage, setCurrentErrorMessage] = useState<string | null>(
    null
  );
  const existingUserDetails = useAppSelector(
    (state) => state.userDetails.userDetails
  );
  const [loading, setLoading] = useState(false);

  const thirteenYearsAgo = new Date();
  thirteenYearsAgo.setFullYear(thirteenYearsAgo.getFullYear() - 16);
  thirteenYearsAgo.setHours(0, 0, 0, 0); // Ignore time part for comparison
  const [date, setDate] = useState<Date>(thirteenYearsAgo);

  const [formData, setFormData] = useState({
    firstName: existingUserDetails?.firstName || "",
    lastName: existingUserDetails?.lastName || "",
    birthday: existingUserDetails?.birthday || thirteenYearsAgo,
    picture: existingUserDetails?.picture || "",
  } as IUserDetails);

  useEffect(() => {
    if (existingUserDetails) {
      setFormData(existingUserDetails);
      if (existingUserDetails.birthday) {
        setDate(new Date(existingUserDetails.birthday));
      }
    }
  }, [existingUserDetails]);

  const callReportError = (message: string) => {
    setCurrentErrorMessage(message);
  };

  const clearErrorMessage = () => {
    setCurrentErrorMessage(null);
  };

  const postDetail = (file: File) => {
    if (file === undefined) {
      return (
        <Snackbar autoHideDuration={6000} message="Unable to upload image" />
      );
    }
    setLoading(true);
    if (file.type === "image/jpeg" || file.type === "image/png") {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        if (reader.result === null) {
          return (
            <Snackbar autoHideDuration={6000} message="Unable to read image" />
          );
        }

        const dataURL = reader.result as string;
        const response = await fetch(dataURL);
        const blob = await response.blob();

        const data = new FormData();
        data.append("file", blob);
        data.append("upload_preset", "virtual-experts");
        data.append("cloud_name", "app-volks");
        fetch("http://api.cloudinary.com/v1_1/app-volks/image/upload", {
          method: "post",
          body: data,
        })
          .then((res) => res.json())
          .then((data) => {
            setFormData({
              ...formData,
              picture: data.url,
            });
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            callReportError("Unable to upload image");
            setLoading(false);
          });
      };
    } else {
      <Snackbar autoHideDuration={6000} message="Unable to upload image" />;
      callReportError("Unable to upload image");
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Check for required fields
    if (!formData.firstName || !formData.lastName) {
      setCurrentErrorMessage("Please fill out all fields");
      return;
    }

    // Check for birthday selection
    const selectedDate = new Date(date);
    selectedDate.setHours(0, 0, 0, 0); // Normalize the time part for accurate comparison
    if (selectedDate.getTime() === thirteenYearsAgo.getTime()) {
      setCurrentErrorMessage(
        "Odds are you aren't exactly 16 years old today. Please select your birthday."
      );
      return;
    } else if (selectedDate > thirteenYearsAgo) {
      setCurrentErrorMessage(
        "You must be 16 years or older to use Virtual Experts"
      );
      return;
    }

    // Update formData state with selected birthday before submission
    setFormData((prevFormData) => ({
      ...prevFormData,
      birthday: selectedDate,
    }));

    setLoading(true);
    try {
      const response = await dispatch(
        updateUserDetails({ ...formData, birthday: selectedDate })
      );
      if (response.type === "userDetails/updateUserDetails/rejected") {
        setCurrentErrorMessage(response.payload);
      } else {
        setCurrentErrorMessage(null);
        // AuthStateMachine will handle navigation
      }
    } catch (error) {
      setCurrentErrorMessage("An error occurred, please try again.");
    }
    setLoading(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <AnimatedAuthView direction={AnimatedFromDirection.Right}>
      <Box
        component={"form"}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
        }}
        onSubmit={handleSubmit}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5">{"User Info"}</Typography>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                color: theme.palette.text.secondary,
                fontSize: "12px",
                textAlign: "center",
                fontWeight: "300",
                paddingBottom: "16px",
                paddingLeft: "8px",
                marginTop: "8px",
              }}
            >
              {`Congrats! ${userCredentials?.email} has been accepted into the beta program. Please fill out the following information in order to continue.`}
            </Typography>
          </Grid>
        </Grid>
        {currentErrorMessage && (
          <Typography
            sx={{
              color: theme.palette.error.main,
              fontSize: "12px",
              textAlign: "center",
              cursor: "pointer",
              fontWeight: "600",
              padding: "8px",
            }}
            onClick={clearErrorMessage}
          >
            {currentErrorMessage || "Something went wrong, please try again"}
          </Typography>
        )}
        {loading && (
          <Box
            sx={{
              position: "relative",
              bottom: "-25px",
            }}
          >
            <CircularProgress thickness={6} />
          </Box>
        )}
        <Box
          sx={{
            visibility: loading ? "hidden" : "visible",
          }}
        >
          {existingUserDetails?.picture ? (
            <ImageBlock size={"80px"} />
          ) : (
            <Box p={"16px"}>
              <FileUploader
                handleChange={postDetail}
                dropMessageStyle={{ display: "none" }}
                name="file"
                types={fileTypes}
                multiple={false}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignContent={"center"}
                >
                  <Grid item>
                    <Avatar
                      sx={{
                        margin: "8px",
                        backgroundColor: theme.palette.colors.grayScale[400],
                        justifySelf: "center",
                        width: "80px",
                        height: "80px",
                        "&:hover": {
                          cursor: "pointer",
                          filter: "brightness(80%)",
                        },
                        "&:hover:active": {
                          transform: "scale(0.95)",
                          transition: "transform 0.1s",
                        },
                      }}
                      src={formData.picture}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: theme.palette.primary.main,
                        fontSize: "12px",
                        textAlign: "center",
                        cursor: "pointer",
                        fontWeight: "600",
                        paddingBottom: "0px",
                      }}
                    >
                      Add
                    </Typography>
                  </Grid>
                </Grid>
              </FileUploader>
            </Box>
          )}
          <BlockTitle title="Name" subtitle={""} overrideMarginTop={true} />
          <Box sx={{ height: "10px" }} />
          <Box
            display={"flex"}
            flexDirection={"row"}
            width={"100%"}
            marginTop={"24px"}
            marginBottom={"24px"}
          >
            <CustomInput
              name="firstName"
              label="First Name"
              value={formData.firstName}
              startingValue={existingUserDetails?.firstName || ""}
              handleChange={handleChange}
              half={true}
              forceInivisible={loading}
            />
            <Box width={"16px"} />
            <CustomInput
              name="lastName"
              label="Last Name"
              value={formData.lastName}
              startingValue={existingUserDetails?.lastName || ""}
              handleChange={handleChange}
              half={true}
              forceInivisible={loading}
            />
          </Box>
          <Grid container marginTop={"20px"} spacing={1}>
            <BlockTitle title="Age" subtitle={""} overrideMarginTop={true} />
            <Typography
              sx={{
                color: theme.palette.text.secondary,
                fontSize: "12px",
                textAlign: "left",
                fontWeight: "300",
                paddingBottom: "16px",
                paddingLeft: "8px",
              }}
            >
              {"You must be 16 years or older to use Virtual Experts"}
            </Typography>
            <CustomDatePicker
              selectedDate={date}
              onDateChange={(newDate: Date) => setDate(newDate)}
              backgroundColorOverride={theme.palette.background.backdrop}
            />
          </Grid>
          <Grid
            container
            display={"flex"}
            paddingTop={"16px"}
            direction="column"
            justifyContent={"center"}
            alignContent={"center"}
            alignItems={"center"}
          >
            <Button
              ref={submitButton}
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              sx={{
                marginTop: "24px",
                marginBottom: "16px",
                borderRadius: "6px",
                width: "194px",
                "&:hover:active": {
                  transform: "scale(0.95)",
                  transition: "transform 0.1s",
                },
              }}
            >
              {loading ? <CircularProgress size={24} /> : "Confirm"}
            </Button>
            <BackToLoginButton />
          </Grid>
        </Box>
      </Box>
    </AnimatedAuthView>
  );
};

export default UserDetailsView;
