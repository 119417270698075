import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  darken,
  Grid,
  lighten,
  Typography,
  useTheme,
} from "@mui/material";
import PopupBase from "./PopupBase";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { setAPopupIsShowing } from "../../features/ui/confirmationPopupSlice";
import UpgradeButton from "../ui-elements/UpgradeButton";
import { useNavigate } from "react-router-dom";
import { SubscriptionStatus } from "../../types/user/ISubscriptionInfo";

interface NotificationPopupProps {
  triggerPopup?: boolean;
  title?: string;
  message?: string;
  onDismiss?: () => void;
}

const NotificationPopup: React.FC<NotificationPopupProps> = ({
  triggerPopup = false,
  title = "Your session has expired.",
  message = "Please log back in.",
  onDismiss,
}) => {
  const dispatch = useAppDispatch();
  const themeSlice = useAppSelector((state) => state.theme);
  const subscriptionInfo = useAppSelector(
    (state) => state.userSubscription?.userSubscription
  );
  const isDarkMode = themeSlice.isDarkMode;
  const theme = useTheme();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(triggerPopup);

  useEffect(() => {
    dispatch(setAPopupIsShowing(isOpen));
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    if (onDismiss) {
      onDismiss();
    }
    dispatch(setAPopupIsShowing(false));
  };

  function handleKeyPress(event: React.KeyboardEvent) {
    if (event.key === "Enter") {
      handleClose();
    }
  }

  const handleUpgradeClicked = () => {
    handleClose();
    navigate("/pricing");
  };

  const totalCredits =
    subscriptionInfo.currentDataLimit + subscriptionInfo.bonusCredits;
  const creditsLeft = totalCredits - subscriptionInfo.currentDataUsage;
  const percentageLeft = (creditsLeft / totalCredits) * 100;

  const shouldShowUpgrade = () => {
    if (!subscriptionInfo) return false;
    const currentStatus = subscriptionInfo.subscriptionStatus;

    if (percentageLeft > 10) return false;

    switch (currentStatus) {
      case SubscriptionStatus.ACTIVE:
      case SubscriptionStatus.PENDING_CHANGE:
      case SubscriptionStatus.PENDING_CANCEL:
        return true;
      case SubscriptionStatus.PENDING_NEW:
      case SubscriptionStatus.CANCELED:
      case SubscriptionStatus.NONE:
        return false;
      default:
        return false;
    }
  };

  return isOpen ? (
    <PopupBase open={isOpen} onClose={handleClose}>
      <Grid container direction="column" justifyContent="center" spacing={3}>
        <Grid item>
          <Typography
            sx={{
              fontWeight: 800,
              fontSize: "14px",
              textAlign: "center",
            }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              fontFamily: "Poppins-Regular",
              fontSize: "12px",
              textAlign: "center",
              paddingBottom: "6px",
            }}
          >
            {message}
          </Typography>
        </Grid>
        <Grid item sx={{ gridColumn: "1 / -1", textAlign: "center" }}>
          {shouldShowUpgrade() ? (
            <Box
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"row"}
              alignItems={"center"}
            >
              <Typography
                fontSize={"14px"}
                fontWeight={400}
                color={theme.palette.text.secondary}
                sx={{
                  cursor: "pointer",
                  fontWeight: 700,
                  marginRight: "32px",
                  "&:hover": {
                    color: isDarkMode
                      ? lighten(theme.palette.text.secondary, 0.3)
                      : darken(theme.palette.text.secondary, 0.8),
                  },
                }}
                onClick={handleClose}
              >
                {"Not Now"}
              </Typography>
              <UpgradeButton
                buttonColor={theme.palette.colors.green[600]}
                buttonText={"Upgrade"}
                onClick={handleUpgradeClicked}
              />
            </Box>
          ) : (
            <Button
              sx={{
                color: "white",
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.dark",
                },
                width: "100%",
                maxWidth: "140px",
              }}
              autoFocus={true}
              disableFocusRipple={true}
              onClick={handleClose}
              onKeyDown={handleKeyPress}
            >
              OK
            </Button>
          )}
        </Grid>
      </Grid>
    </PopupBase>
  ) : null;
};

export default NotificationPopup;
