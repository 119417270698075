import React, { useEffect } from "react";
import { alpha, Box, Button, useTheme } from "@mui/material";
import { Edit, Play, Stop } from "../../../../../../icons";
import { PrimaryIcon } from "../../../../../ui-elements";
import { CellState } from "./MCTypes";
import { PulseLoader } from "react-spinners";

interface MCActionButtonsProps {
  canPlay: boolean;
  canEdit: boolean;
  onPlay: (messageId: string) => void;
  onStop: (messageId: string) => void;
  onEdit: (messageId: string) => void;
  message: { _id: string };
  cellState: CellState;
  expert: { color: string };
}

export const MCActionButtons: React.FC<MCActionButtonsProps> = ({
  canPlay,
  canEdit,
  onPlay,
  onStop,
  onEdit,
  message,
  cellState,
  expert,
}) => {
  const theme = useTheme();
  const ROW_HEIGHT = "32px";

  useEffect(() => {
    // console.log("cellState", cellState);
  }, [cellState, canPlay, canEdit]);

  const actionButtonPressedForState = (iconName: string) => {
    switch (iconName) {
      case "Play":
      case "Stop":
        if (cellState === CellState.PLAYING) {
          onStop(message._id);
        } else {
          onPlay(message._id);
        }
        break;
      case "Edit":
        onEdit(message._id);
        break;
      default:
        break;
    }
  };

  const DefaultButtons = () => (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      width="100%"
      justifyContent="flex-end"
      marginRight={"-8px"}
      height={ROW_HEIGHT}
    >
      <PrimaryIcon
        iconName="Play"
        handleIconClick={() => actionButtonPressedForState("Play")}
        icon={<Play />}
        stopPropagation={true}
        backgroundColorOverride="transparent"
        highlightedColorOverride="transparent"
        highlightedIconColorOverride={expert.color}
        defaultColorOverride={
          canPlay ? undefined : alpha(theme.palette.text.secondary, 0.2)
        }
        disabled={!canPlay}
        sx={{
          width: "24px",
        }}
        ignoreMobile={true}
      />
      {canEdit && (
        <PrimaryIcon
          iconName="Edit"
          handleIconClick={() => actionButtonPressedForState("Edit")}
          icon={<Edit />}
          stopPropagation={true}
          backgroundColorOverride="transparent"
          highlightedColorOverride="transparent"
          highlightedIconColorOverride={expert.color}
          sx={{
            width: "24px",
          }}
          ignoreMobile={true}
        />
      )}
    </Box>
  );

  switch (cellState) {
    case CellState.DEFAULT:
      return <DefaultButtons />;
    case CellState.EDITING:
      return (
        <Button
          onClick={() => onEdit(message._id)}
          sx={{
            textTransform: "none",
            fontSize: "0.85rem",
            fontWeight: 800,
            height: ROW_HEIGHT,
            backgroundColor: "transparent",
            color: expert.color,
            borderRadius: "12px",
            paddingLeft: "10px",
            marginRight: "-8px",
            "&:hover": {
              backgroundColor: "transparent",
              transform: "scale(1.05)",
            },
            "& .MuiTouchRipple-root": {
              display: "none",
            },
            transition: "transform 0.1s ease-in-out",
          }}
        >
          Cancel
        </Button>
      );
    case CellState.PLAYING:
      return (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          width="100%"
          height={ROW_HEIGHT}
        >
          <PrimaryIcon
            iconName="Stop"
            handleIconClick={() => actionButtonPressedForState("Stop")}
            icon={<Stop />}
            backgroundColorOverride="transparent"
            highlightedColorOverride="transparent"
            highlightedIconColorOverride={expert.color}
            defaultColorOverride={expert.color}
            ignoreMobile={true}
          />
        </Box>
      );
    case CellState.LOADING:
      return (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          width="100%"
          justifyContent="flex-end"
          height={ROW_HEIGHT}
        >
          <PulseLoader color={expert.color} loading={true} size={8} />
        </Box>
      );
    default:
      return <DefaultButtons />;
  }
};
