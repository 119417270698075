import React, { useEffect } from "react";
import { Box, Divider, Grid } from "@mui/material";
import SectionBase from "../../workspace-page/content-views/profile-view/sections/SectionBase";
import DarkModeBlock from "./settings-components/DarkmodeBlock";
import CustomVoiceSelector from "../../../ui-elements/CustomVoiceSelector";
import NewsletterBlock from "./settings-components/NewsletterBlock";
import { useAppSelector } from "../../../../app/store";

interface PreferencesBlockProps {
  containerWidth: number;
}

const PreferencesBlock: React.FC<PreferencesBlockProps> = ({
  containerWidth,
}) => {
  const userDetails = useAppSelector((state) => state.userDetails.userDetails);
  const userPreferences = useAppSelector(
    (state) => state.userPreferences.userPreferences
  );

  useEffect(() => {
    //console.log("containerWidth: " + containerWidth);
  }, [containerWidth]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-column",
        alignItems: "flex-start",
        alignContent: "flex-start",
        height: "auto",
        width: "100%",
      }}
    >
      <SectionBase title="Preferences">
        <Grid
          container
          padding={"0px 16px 16px 16px"}
          direction={"column"}
          spacing={"16px"}
        >
          <CustomVoiceSelector activeUserDetails={userDetails} />
          <Grid item height={"16px"} alignContent={"center"} marginTop={"16px"}>
            <Divider orientation="horizontal" />
          </Grid>
          <DarkModeBlock userPreferences={userPreferences} />
          <Grid item height={"16px"} alignContent={"center"} marginTop={"8px"}>
            <Divider orientation="horizontal" />
          </Grid>
          <NewsletterBlock userPreferences={userPreferences} />
        </Grid>
      </SectionBase>
    </Box>
  );
};

export default PreferencesBlock;
