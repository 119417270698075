import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { listenForBetaApproval } from "../../../../features/users/userCredentialsSlice";
import AnimatedAuthView from "./AnimatedAuthView";
import { AnimatedFromDirection } from "./AnimatedViewWrapper";

const BetaPendingView: React.FC = () => {
  const userCredentials = useAppSelector(
    (state) => state.userCredentials.userCredentials
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (userCredentials?.betaApproved === true) {
      // AuthStateMachine will handle navigation
      dispatch({ type: "userAuth/betaApproved" });
    } else {
      dispatch(listenForBetaApproval());
    }
  }, [userCredentials?.betaApproved, dispatch]);

  return (
    <AnimatedAuthView direction={AnimatedFromDirection.Right}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          width: "100%",
          padding: 3,
        }}
      >
        <Typography variant="h5" gutterBottom>
          Beta Status: Pending
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 2 }}>
          We will notify you once you are approved.
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary", mb: 2 }}>
          Please note that we are approving users as quickly as possible. Thank
          you for your patience!
        </Typography>
      </Box>
    </AnimatedAuthView>
  );
};

export default BetaPendingView;
