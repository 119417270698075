import React from "react";
import { Grid, Typography, CircularProgress, Box } from "@mui/material";
import { useTheme } from "@mui/material";
import AnimatedAuthView from "./AnimatedAuthView";
import { AnimatedFromDirection } from "./AnimatedViewWrapper";

interface LoadingViewProps {
  actionMessage?: string;
  title: string;
}

const LoadingView: React.FC<LoadingViewProps> = ({ actionMessage, title }) => {
  const theme = useTheme();

  return (
    <AnimatedAuthView direction={AnimatedFromDirection.Center}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          padding: 3,
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Typography variant="h5">{title}</Typography>
          </Grid>
          {actionMessage && (
            <Grid item>
              <Typography
                sx={{
                  color: theme.palette.text.secondary,
                  fontSize: "14px",
                  textAlign: "center",
                  fontWeight: "400",
                }}
              >
                {actionMessage}
              </Typography>
            </Grid>
          )}
          <Grid item>
            <Box sx={{ mt: 4 }}>
              <CircularProgress size={40} thickness={4} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </AnimatedAuthView>
  );
};

export default LoadingView;
