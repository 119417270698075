import React, { ReactElement } from "react";
import { Tooltip, useTheme } from "@mui/material";

interface CustomTooltipProps {
  title: string;
  children: ReactElement;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ title, children }) => {
  const theme = useTheme();

  return (
    <Tooltip
      title={title}
      arrow
      sx={{
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper, // Adjusted for a standard theme value
        borderRadius: "4px",
        padding: theme.spacing(1),
      }}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
