export const termsContent = `# VirtualExperts Terms of Service

**Last Updated: September 14, 2024**

These Terms of Service ("Terms") govern your access to and use of the VirtualExperts platform and services (collectively, the "Services") provided by AppVolks LLC ("Company," "we," "us," or "our"). By using our Services, you agree to be bound by these Terms. If you don't agree to these Terms, do not use the Services.

## 1. Services Description and Eligibility

### 1.1. Platform Description
VirtualExperts is an AI chatbot SaaS platform that allows users to interact with AI-powered chatbots ("Experts") with customizable temperaments, voices, and knowledge bases.

### 1.2. Age Requirement
You must be at least 16 years old to use the Services. By using the Services, you represent and warrant that you meet this age requirement and have the right, authority, and capacity to agree to and abide by these Terms.

### 1.3. Geographic Limitations
The Services are primarily intended for use in the United States. If you access the Services from outside the United States, you are responsible for compliance with local laws.

## 2. Account Creation and Security

### 2.1. Account Registration
To access certain features, you must create an account by providing accurate, current, and complete information.

### 2.2. Account Security
You are responsible for safeguarding your account credentials and for any activities or actions under your account.

### 2.3. Account Suspension/Termination
We may suspend or terminate your account if you violate these Terms or engage in prohibited activities.

## 3. Services and Content

### 3.1. Input and Output
You can input information ("Input") into our AI systems, which will generate responses ("Output"). Your use of the Services, including any Outputs, may be subject to additional license and use restrictions.

### 3.2. Features
The Services may include, but are not limited to: Conversation Playback and Transcription, Customizable voice selection, Chat Memory, Prompt Cache, and Customizable Conversation Style and Expertise control.

### 3.3. Feature Changes
We continuously update our Services and may add, alter, or remove features at our discretion without prior notice.

### 3.4. Third-Party Services
Our Services rely on third-party products and services. These are beyond our control but may impact the use and reliability of our Services.

### 3.5. Responsibility for Content
You are solely responsible for your Input and its consequences. You warrant that you have all necessary rights to submit and use such Input.

### 3.6. Content Restrictions
You may not use the Services to generate Output that violates any laws or rights. You must cite the Services when publishing any Output.

### 3.7. Sharing Content
When you share content, it becomes accessible to anyone with the link. We are not responsible for how others may use or share your content.

### 3.8. AI-Generated Content
You acknowledge and agree that:
a) The Services utilize artificial intelligence to generate content ("AI-Generated Content").
b) AI-Generated Content may contain inaccuracies, biases, or errors.
c) You are solely responsible for evaluating, verifying, and making decisions based on AI-Generated Content.
d) We do not guarantee the accuracy, completeness, or appropriateness of AI-Generated Content for any specific purpose.
e) You should not rely on AI-Generated Content as a substitute for professional advice or services.

## 4. User Conduct and Content

### 4.1. Prohibited Uses
You agree not to use the Services in any way that is unlawful, harmful, or interferes with the Services' operation.

### 4.2. Content License
By submitting Input, you grant us a worldwide, royalty-free, sublicensable, and transferable license to use, reproduce, modify, distribute, and display the Input to operate and improve the Services.

### 4.3. Feedback
Any feedback you provide about our Services may be used by us without restriction or compensation.

## 5. Intellectual Property Rights

### 5.1. Ownership
The Services are owned by AppVolks LLC and protected by intellectual property laws.

### 5.2. Limited License
We grant you a limited, non-exclusive, non-transferable, revocable license to use our Services for personal or internal business purposes.

### 5.3. AI-Generated Content Ownership
a) You retain ownership of your Input.
b) We retain ownership of the AI models and algorithms used to generate Output.
c) Ownership of AI-Generated Content is determined as follows:
   i) If the Output is a direct transformation of your Input, you own the Output.
   ii) If the Output is substantially generated by our AI models with minimal Input from you, we own the Output, but grant you a license to use it as specified in section 5.2.
   iii) In cases of collaborative creation between your Input and our AI models, ownership may be shared, and usage rights will be granted to both parties.

## 6. Privacy and Data Usage

Your use of the Services is governed by our Privacy Policy. By using the Services, you explicitly consent to the collection, use, and processing of your data as described in the Privacy Policy. We use third-party moderation tools to ensure content appropriateness.

## 7. Subscriptions and Payments

### 7.1. Subscription Plans
We offer paid subscription plans. By subscribing, you agree to pay the associated fees.

### 7.2. Automatic Renewal
Subscriptions automatically renew unless canceled before the renewal date.

### 7.3. Refunds and Cancellations
All sales are final. We do not provide refunds for unused subscription periods or credits.

### 7.4. Changes to Pricing and Plans
We reserve the right to change our prices with at least 14 days notice.

## 8. Disclaimers and Limitations of Liability

### 8.1. Disclaimers
THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND.

### 8.2. AI-Generated Content
You acknowledge that the Services may generate incorrect, biased, or incomplete information. Do not rely on the Services for professional advice.

### 8.3. Limitation of Liability
TO THE FULLEST EXTENT PERMITTED BY LAW, APPVOLKS LLC WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES.

## 9. Indemnification

You agree to indemnify and hold harmless AppVolks LLC from any claims arising out of your violation of these Terms or use of the Services.

## 10. Modifications to the Services and Terms

We reserve the right to modify or discontinue the Services or these Terms at any time. Continued use of the Services constitutes acceptance of any changes.

## 11. Governing Law and Dispute Resolution

### 11.1. Legal Jurisdiction
These Terms are governed by the laws of the State of Oregon.

### 11.2. Arbitration Agreement
Any disputes shall be resolved by arbitration in Oregon.

### 11.3. Class Action Waiver
You agree to resolve disputes individually, not as a class action.

### 11.4. Venue for Disputes
For non-arbitration actions, jurisdiction is exclusive to courts in Multnomah County, Oregon.

## 12. GDPR Compliance

For users in the EU/EEA, we comply with the General Data Protection Regulation (GDPR). This includes:
a) Providing data subject rights as outlined in our Privacy Policy.
b) Ensuring lawful bases for processing personal data.
c) Implementing appropriate technical and organizational measures to protect personal data.
d) Maintaining records of data processing activities.
e) Conducting Data Protection Impact Assessments (DPIAs) where required.
f) Appointing a Data Protection Officer (DPO) to oversee GDPR compliance.

For more details on our data handling practices and your rights under GDPR, please refer to our Privacy Policy.

## 13. Service Level Agreement (SLA)

### 13.1 Service Availability
We strive to maintain 99.9% uptime for our Services, excluding scheduled maintenance.

### 13.2 Support
We provide customer support via email during business hours (9 AM to 5 PM Pacific Time, Monday through Friday, excluding holidays).

### 13.3 Response Times
We aim to respond to support inquiries within 24 hours during business days.

## 14. Contact Information

For questions about these Terms, contact us at:

AppVolks LLC  
1502 SE 84th Ave Unit A  
Portland, OR 97216  
Email: admin@virtualexperts.ai

By using VirtualExperts, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.`;
