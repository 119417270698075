import { useState, useRef, useEffect, useCallback } from "react";
import { EditorState } from "draft-js";
import { IExpert } from "../../../../../../types";
import { createEmptyDecoratedEditor } from "../commands/CommandDecorator";
import { ActiveView } from "../../../../../../features/chats/workspaceSlice";

export type TempTranscriptionPosition = {
  blockKey: string;
  startOffset: number;
  endOffset: number;
};

export interface IMessageBarState {
  currentTextString: string;
  suggestedCommands: string[];
  showPlaceholder: boolean;
  showSuggestions: boolean;
  highlightedSuggestionsIndex: number;
  localExpert: any;
  localView: any;
  isEditing: boolean;
  cursorPosition: number;
  wasShowingSuggestions: boolean;
  isBlurring: boolean;
  isClickingCommand: boolean;
  blockClickAwayForCommand: boolean;
  clickedInside: boolean;
  hasInsertedTemporaryTranscription: boolean;
  tempTranscriptionPosition: TempTranscriptionPosition | null;
  isProcessingTranscriptionError: boolean;
  files: File[];
  editorState: EditorState;
  isReturningFromAnotherWindow: boolean;
}

export function useMessageBarState(
  initialExpert: IExpert,
  initialView: any,
  commandsSlice: any
) {
  const [, forceUpdate] = useState({});

  const createInitialState = useCallback(
    (): IMessageBarState => ({
      currentTextString: "",
      suggestedCommands: [],
      showPlaceholder: true,
      showSuggestions: false,
      highlightedSuggestionsIndex: 0,
      localExpert: initialExpert,
      localView: initialView,
      isEditing: false,
      cursorPosition: 0,
      wasShowingSuggestions: false,
      isBlurring: false,
      isClickingCommand: false,
      blockClickAwayForCommand: false,
      clickedInside: false,
      hasInsertedTemporaryTranscription: false,
      tempTranscriptionPosition: null,
      isProcessingTranscriptionError: false,
      files: [],
      editorState: createEmptyDecoratedEditor(commandsSlice, initialExpert),
      isReturningFromAnotherWindow: false,
    }),
    [initialExpert, initialView, commandsSlice]
  );

  const stateRef = useRef<IMessageBarState>(createInitialState());

  const resetState = useCallback(
    (newExpert: IExpert) => {
      const resetState = createInitialState();
      resetState.localExpert = newExpert;
      resetState.localView = ActiveView.CHAT;
      resetState.editorState = createEmptyDecoratedEditor(
        commandsSlice,
        newExpert
      );
      Object.assign(stateRef.current, resetState);
      forceUpdate({});
    },
    [createInitialState, commandsSlice]
  );

  const proxyHandler = {
    get(target: IMessageBarState, prop: string | symbol): any {
      if (prop === "reset") {
        return resetState;
      }
      return target[prop as keyof IMessageBarState];
    },
    set(target: IMessageBarState, prop: string | symbol, value: any): boolean {
      if (prop !== "reset") {
        (target as any)[prop] = value;
        forceUpdate({});
      }
      return true;
    },
  };

  const proxyRef = useRef(new Proxy(stateRef.current, proxyHandler));

  useEffect(() => {
    proxyRef.current = new Proxy(stateRef.current, proxyHandler);
  }, []);

  return proxyRef.current as IMessageBarState & {
    reset: (newExpert: IExpert) => void;
  };
}
