import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

const isIphoneWithSupportedBrowser = () => {
  const ua = window.navigator.userAgent;
  return /iPhone/.test(ua) && (/Safari/.test(ua) || /CriOS/.test(ua));
};

const DynamicResizeWithOverlay = ({ children }) => {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [scrollPositionY, setScrollPositionY] = useState(window.scrollY);
  const [isEnabled, setIsEnabled] = useState(isIphoneWithSupportedBrowser());

  useEffect(() => {
    if (!isEnabled) return;

    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    const handleScroll = () => {
      setScrollPositionY(window.scrollY);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    // Initial setup
    handleResize();
    handleScroll();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isEnabled]);

  if (!isEnabled) {
    return <>{children}</>; // If not iPhone with Safari or Chrome, just render children without modification
  }

  const isKeyboardVisible = scrollPositionY > 0;

  return (
    <Box
      sx={{
        position: "absolute",
        top: isKeyboardVisible ? `${scrollPositionY}px` : 0,
        left: 0,
        right: 0,
        height: `${viewportHeight}px`,
        overflowY: isKeyboardVisible ? "hidden" : "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        transition: "top 0.3s ease", // Smooth transition for top adjustment
      }}
    >
      {/* Viewport Overlay - Positioned at 40% from the top */}
      {/* <Box
        sx={{
          position: "fixed",
          top: isKeyboardVisible ? "50%" : "30%",
          left: 0,
          right: 0,
          zIndex: 1000,
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          color: "white",
          padding: "10px",
          fontSize: "12px",
          textAlign: "center",
        }}
      >
        <div>Viewport Height: {viewportHeight}px</div>
        <div>Scroll Position Y: {scrollPositionY}px</div>
        <div>Keyboard Visible: {isKeyboardVisible ? "Yes" : "No"}</div>
        <div>
          Device:{" "}
          {isEnabled
            ? "iPhone with Safari or Chrome"
            : "Not iPhone with Safari or Chrome"}
        </div>
      </Box> */}

      {/* Main content of the page */}
      {children}
    </Box>
  );
};

export default DynamicResizeWithOverlay;
