export const privacyPolicyContent = `# VirtualExperts Privacy Policy

**Last Updated: September 14, 2024**

## 1. Introduction

Your privacy is important to us. This Privacy Policy explains how AppVolks LLC ("we," "us," or "our") collects, uses, and protects your information when you use our VirtualExperts platform and services (the "Services").

**Important Notice:** Our Services are primarily intended for users in the United States. If you are accessing our Services from outside the United States, you are responsible for ensuring compliance with your local laws.

## 2. Information We Collect

### 2.1 Information You Provide
- First and last name
- Email address
- Date of birth
- Payment information
- Inputs to the AI service

### 2.2 Information We Generate
- AI-generated responses (Output)
- Chat histories
- Usage data

### 2.3 Automatically Collected Information
- IP address
- Device information
- Browser type

## 3. How We Use Your Information

We use your information for:
- Providing, maintaining, and improving our Services
- Personalizing your experience
- Communicating with you about our Services
- Ensuring the security and integrity of our Services
- Analyzing usage patterns and trends
- Complying with legal obligations

## 4. Sharing Your Information

### 4.1 Third-Party Services
We use various third-party services to provide our AI services. By using our Services, you acknowledge that:
- Your information may be processed by these third-party services
- You are subject to the terms and conditions and privacy policies of these third-party services
- We are not responsible for how these third-party services handle your information

We encourage you to review the terms of service and privacy policies of our third-party service providers.

### 4.2 Public Sharing
If you choose to share your content publicly:
- It will be accessible to anyone with the link
- We cannot control how others may use this public information
- You grant us and other users a worldwide, royalty-free license to use, store, display, reproduce, modify, create derivative works, perform, and distribute your publicly shared content

### 4.3 International Data Transfers
For users in the EU/EEA, we ensure appropriate safeguards are in place for international data transfers. We use Standard Contractual Clauses approved by the European Commission for data transfers to countries outside the EU/EEA.

## 5. Data Retention and Anonymization

We retain your personal information for as long as necessary to provide our Services, comply with legal obligations, resolve disputes, and enforce our agreements. Specific retention periods are as follows:

- Account information: For as long as your account is active, plus the period necessary to fulfill all billing and subscription-related obligations.
- Payment information: As required by applicable financial regulations and for as long as necessary to process refunds, chargebacks, or resolve billing disputes.
- Usage data: We retain usage data in an anonymized form indefinitely for analytical purposes. Personal identifiers are removed within 30 days after account deletion.
- Marketing data: Until you unsubscribe or withdraw consent

We anonymize certain data after these retention periods. This anonymized data may be retained indefinitely for statistical analysis, service improvement, and research purposes.

## 6. Your Rights and Choices

### 6.1 For All Users
You may have certain rights regarding your personal information under applicable laws. We will respond to your requests in accordance with these laws.

To opt out of receiving marketing communications, click the "unsubscribe" link in any of our emails or contact us directly at admin@virtualexperts.ai.

### 6.2 For California Residents
If you are a California resident, you have the following rights under the California Consumer Privacy Act (CCPA) and the California Privacy Rights Act (CPRA):

- Right to Know: You can request information about the personal information we've collected about you in the past 12 months, including the categories of information collected, the sources of that information, the purposes for which it was collected, and any third parties with whom it was shared.
- Right to Delete: You can request that we delete the personal information we've collected about you, subject to certain exceptions.
- Right to Correct: You can request that we correct inaccurate personal information that we maintain about you.
- Right to Opt-Out of Sale or Sharing: We do not sell or share your personal information as those terms are defined under California law.
- Right to Limit Use and Disclosure of Sensitive Personal Information: We do not use or disclose sensitive personal information for purposes other than those specified by the CCPA/CPRA.

To exercise these rights, please contact us using the information provided in the "Contact Us" section. We will not discriminate against you for exercising any of these rights.

### 6.3 For EU/EEA Users (GDPR Compliance)
If you are in the EU or EEA, you have the following rights:
- Right to access your personal data
- Right to rectify inaccurate personal data
- Right to erase your personal data
- Right to restrict processing of your personal data
- Right to data portability
- Right to object to processing of your personal data
- Rights related to automated decision-making and profiling
- Right to withdraw consent at any time
- Right to lodge a complaint with a supervisory authority

To exercise these rights, please contact our Data Protection Officer at levi@appvolks.com.

## 7. Data Security

We implement reasonable security measures to protect your data. However, no method of transmission over the Internet or electronic storage is 100% secure. We cannot guarantee absolute security of your data.

## 8. Children's Privacy

Our Services are not intended for children under 16. We do not knowingly collect or solicit information from anyone under these age limits.

## 9. Consent Management

We obtain explicit consent from users upon signup for the collection and processing of personal data. You can withdraw your consent at any time by contacting our Data Protection Officer.

## 10. Automated Decision-Making

Our Services may involve automated decision-making, including profiling, to provide personalized experiences. This process may affect the content you see or the responses you receive from our AI. You have the right to request human intervention, express your point of view, and contest any decision based solely on automated processing.

## 11. Changes to This Policy

We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the new policy on this page and updating the "Last Updated" date.

## 12. Contact Us

If you have questions about this Privacy Policy, please contact our Data Protection Officer:

Levi Lais
Email: levi@appvolks.com

AppVolks LLC  
1502 SE 84th Ave Unit A  
Portland, OR 97216

For general inquiries: admin@virtualexperts.ai

By using VirtualExperts, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.
`;
