import { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import ConfirmationPopup from "../../popups/ConfirmationPopup";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { useTheme } from "@mui/material/styles";
import LoadingBar from "../workspace-page/LoadingBar";
import AccountHeader from "./AccountHeader";
import { AccountMenuItem } from "../../ui-elements/menu-tabs/MenuItemEnums";
import SettingsSection from "./settings-section/SettingsView";
import SubscriptionSection from "./subscription-section/SubscriptionSection";
import Navbar from "../../menus/Navbar";
import { getUsageDataReport } from "../../../features/users/accountPageSlice";
import useScreenSize from "../../ui-elements/useScreenSize";

const AccountPage = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const screenSize = useScreenSize();

  const userPreferences = useAppSelector((state) => state.userPreferences);
  const commandsSlice = useAppSelector((state) => state.commands);
  const userDetails = useAppSelector((state) => state.userDetails);
  const userSubscription = useAppSelector((state) => state.userSubscription);
  const userAuth = useAppSelector((state) => state.userAuth);

  const [showLoading, setShowLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(AccountMenuItem.Settings);

  useEffect(() => {
    processParams();
  }, [location]);

  useEffect(() => {
    dispatch(getUsageDataReport());
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        dispatch(getUsageDataReport());
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  async function processParams() {
    const params = new URLSearchParams(location.search);
    const subscription = params.get("subscription");
    if (subscription) {
      setSelectedTab(AccountMenuItem.Subscription);
    }
  }

  const completeAnimation = () => {
    setShowLoading(false);
  };

  useEffect(() => {
    // console.log("expertsSlice", expertsSlice);
  }, [commandsSlice]);

  //#region useEffects
  useEffect(() => {
    // todo - handle loading if necessary
    setShowLoading(false);
  }, [userPreferences, userDetails, userSubscription, userAuth]);

  useEffect(() => {
    // console.log("isDesktop", isDesktop);
  }, [screenSize.isFull]);

  //#endregion

  const scrollRef = useRef(null);

  const sectionForTab = () => {
    switch (selectedTab) {
      case AccountMenuItem.Settings:
        return <SettingsSection />;
      case AccountMenuItem.Subscription:
        return <SubscriptionSection />;
      default:
        return <SettingsSection />;
    }
  };

  const viewForSelectedIcon = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          height: "100%",
          width: `calc(100% + 6px)`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "scroll",
            overflowX: "hidden",
            maxHeight: "100%",
            alignItems: "center",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            backgroundColor: "transparent",
            width: "100%",
            height: "100%",
          }}
        >
          {sectionForTab()}
          <div ref={scrollRef} />{" "}
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Navbar />
      <Box
        sx={{
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          transition: !showLoading && "left 0.2s ease-in-out",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          marginTop: 0,
          paddingLeft: screenSize.isSmall
            ? 0
            : screenSize.isLarge
            ? "8px"
            : "16px",
          paddingRight: screenSize.isSmall
            ? 0
            : screenSize.isLarge
            ? "8px"
            : "16px",
          paddingTop: screenSize.isSmall ? 0 : screenSize.isLarge ? 0 : "68px",
          paddingBottom: screenSize.isLarge ? "8px" : "16px",
        }}
      >
        <ConfirmationPopup />
        {showLoading ? (
          <LoadingBar
            label={`Loading...`}
            showLoading={showLoading}
            completeAnimation={completeAnimation}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "fixed",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                position: "relative",
                height: "100%",
                width: "100%",
                marginTop: screenSize.isSmall ? "6px" : "12px",
                marginBottom: "0px",
                backgroundColor: theme.palette.background.paper,
                borderRadius: screenSize.isSmall ? 0 : "16px",
                boxShadow: `0px 0px 0px 1px rgba(0, 0, 0, 0.12)`,
                maxHeight: "100%",
                paddingBottom: "54px",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  alighItems: "space-between",
                  justifyContent: "flex-start",
                  width: "100%",
                  height: "100%",
                  marginTop: screenSize.isLarge ? "8px" : 0,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    height: "100%",
                    width: "100%",
                    marginBottom: "16px",
                  }}
                >
                  <AccountHeader
                    title={`My Account`}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                  />
                  {viewForSelectedIcon()}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default AccountPage;
