import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { HomeExpertsSectionData } from "../home-sections/HomeExpertsData";

interface HomeToggleProps {
  expertData: HomeExpertsSectionData;
  isSelected: boolean;
  onSelect: () => void;
}

const HomeToggle: React.FC<HomeToggleProps> = ({
  expertData,
  isSelected,
  onSelect,
}) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      alignContent={"center"}
      padding={0}
      sx={{
        width: { xs: "50px", sm: "100px" },
        height: { xs: "50px", sm: "100px" },
      }}
    >
      <Box
        onClick={onSelect}
        sx={{
          width: "100%",
          height: "100%",
          transition: "none",
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <img
          src={isSelected ? expertData.selectedImage : expertData.image}
          alt={expertData.title}
          style={{ width: "100%", height: "100%" }}
        />
      </Box>

      <Typography
        variant="body1"
        color={theme.palette.text.secondary}
        sx={{
          display: { xs: "none", sm: "block" },
        }}
      >
        {expertData.subtitle}
      </Typography>
    </Box>
  );
};

export default HomeToggle;
