import {
  IChat,
  INewMessagePayload,
  IMessage,
  ICreateCollectionPayload,
  IPrompt,
  INewPromptPayload,
  IDuplicatePromptsPayload,
  ICache,
  ICreateKnowledgeDocPayload,
} from "../types/index";
import { ITextToSpeechPayload } from "../types/audio/ITextToSpeech";
import API from "./api";
import { IOpenChatPayload } from "../types/chat/IChat";

// chats
export const createChat = (createCollectionPayload: ICreateCollectionPayload) =>
  API.post(`/chats`, createCollectionPayload);
export const saveChat = (chat: IChat) =>
  API.post(`/chats/saveChat`, { chat: chat });
export const updateChat = (updatedChat: IChat) =>
  API.put(`/chats/updateChat`, { chat: updatedChat });
export const openChat = (openChatPayload: IOpenChatPayload) =>
  API.post(`/chats/openChat`, openChatPayload);
export const deleteChat = (chatId: string) =>
  API.post(`/chats/deleteChat`, { chatId: chatId });
export const resetChat = (chatId: string) =>
  API.post(`/chats/resetChat`, { chatId: chatId });
export const hydrateChat = (chatId: string) =>
  API.post(`/chats/hydrateChat`, { chatId: chatId });
export const clearActiveJobsForChat = (chatId: string) =>
  API.post(`/chats/clearActiveJobsForChat`, { chatId: chatId });

// sidebar ui
export const fetchChatsForExpert = (expertId: string) =>
  API.post(`/chats/fetchChatsForExpert`, { expertId: expertId });

// messages
export const fetchMessages = (chatId: string) =>
  API.post(`/messages`, { chatId: chatId });
// export const sendMessage = (messageData: INewMessagePayload) =>
//   API.post(`/messages/sendMessage`, messageData);
export const updateMessage = (messageData: IMessage) =>
  API.put(`/messages/updateMessage`, messageData);
export const deleteMessage = (messageId: string) =>
  API.delete(`/messages/deleteMessage/${messageId}`);

// message with files
export const sendMessage = (messageData: INewMessagePayload) => {
  try {
    const formData = new FormData();

    // Iterate over the files and append them to formData
    messageData.messageFilesPayloads.forEach(
      (filePayload: ICreateKnowledgeDocPayload, index) => {
        // Append the file with an index
        if (filePayload.file instanceof Blob) {
          formData.append(`files`, filePayload.file, filePayload.file.name);
        }

        // Append metadata with the same index to ensure association
        Object.entries(filePayload).forEach(([key, value]) => {
          if (key !== "file" && value !== null && value !== undefined) {
            // Use a composite key that includes the index for the metadata
            formData.append(`${key}_${index}`, value.toString());
          }
        });
      }
    );

    // Append other message data to formData
    Object.entries(messageData).forEach(([key, value]) => {
      if (
        key !== "messageFilesPayloads" &&
        value !== null &&
        value !== undefined
      ) {
        formData.append(key, value.toString());
      }
    });

    return API.post(`/messages/sendMessage`, formData);
  } catch (error) {
    console.log("error: ", error);
  }
};

export const textToSpeech = (textToSpeechPayload: ITextToSpeechPayload) =>
  API.post("/messages/textToSpeech", textToSpeechPayload);

export const playDemo = (textToSpeechPayload: ITextToSpeechPayload) =>
  API.post("/messages/playDemo", textToSpeechPayload);

// cache
export const fetchCache = (cacheId: string) =>
  API.post(`/cache/fetchCache`, { cacheId: cacheId });
export const updateCache = (updatedCache: ICache) =>
  API.patch(`/cache/updateCache`, updatedCache);
export const deleteCache = (cacheId: string) => API.delete(`/cache/${cacheId}`);
export const resetCache = (cacheId: string) =>
  API.post(`/cache/resetCache`, { cacheId: cacheId });

// prompts
export const fetchPrompts = (cacheId: string) =>
  API.post(`/prompts/prompts`, { cacheId: cacheId });
export const createPrompt = (newPromptPayload: INewPromptPayload) =>
  API.post(`/prompts/createPrompt`, newPromptPayload);
export const updatePrompt = (promptData: IPrompt) =>
  API.put(`/prompts/updatePrompt`, promptData);
export const deletePrompt = (promptId: string) =>
  API.post(`/prompts/deletePrompt`, { promptId: promptId });
export const duplicatePrompts = (
  duplicatePromptsPayload: IDuplicatePromptsPayload
) => API.post(`/prompts/duplicatePrompts`, duplicatePromptsPayload);
