import React, { useState, useEffect } from "react";
import { Box, CircularProgress, alpha, useTheme } from "@mui/material";
import {
  Attachment,
  Csv,
  Jpg,
  Pdf,
  Png,
  Txt,
  Close,
} from "../../../../../icons";
import { useAppDispatch } from "../../../../../app/store";
import { fetchThumbnail } from "../../../../../features/experts/knowledgeDocsSlice";

interface KnowledgeDocThumbnailProps {
  title: string;
  knowledgeDoc?: { type: string; url?: string };
  showLoading?: boolean;
  size?: number;
  file?: File | null;
  isHovered?: boolean;
  forceNoBorderRadius?: boolean;
}

const KnowledgeDocThumbnail: React.FC<KnowledgeDocThumbnailProps> = ({
  title,
  knowledgeDoc,
  showLoading = false,
  size = 36,
  file = null,
  isHovered = false,
  forceNoBorderRadius = false,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    if (file) {
      const format = file.type.split("image/")[1];
      const approvedFormats = ["png", "jpg", "jpeg", "gif"];
      const isApproved = approvedFormats.includes(format);

      if (isApproved && file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setImageSrc(e.target?.result as string);
        };
        reader.readAsDataURL(file);
      }
    }
  }, [file]);

  // Fetch the thumbnail image if a URL is provided
  useEffect(() => {
    if (knowledgeDoc?.url) {
      setIsImageLoading(true);
      dispatch(fetchThumbnail(knowledgeDoc.url))
        .unwrap()
        .then((base64Image) => {
          setImageSrc(base64Image); // ImageSrc is now correctly formatted
        })
        .catch((error) => {
          //console.error("Failed to fetch image:", error);
        })
        .finally(() => {
          setIsImageLoading(false);
        });
    }
  }, [knowledgeDoc?.url, dispatch]);

  const getExtensionFromName = (name: string) => {
    const split = name.split(".");
    return split.length > 1 ? split[split.length - 1] : "";
  };

  const getIconForExtension = (extension: string) => {
    switch (extension.toLowerCase()) {
      case "pdf":
        return <Pdf />;
      case "png":
        return <Png />;
      case "csv":
        return <Csv />;
      case "jpg":
      case "jpeg":
        return <Jpg />;
      case "txt":
        return <Txt />;
      default:
        return <Attachment />;
    }
  };

  const getIconForState = () => {
    if (showLoading || isImageLoading) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={`${size}px`}
          height={`${size}px`}
        >
          <CircularProgress
            size={size * 0.389}
            thickness={6}
            sx={{
              color: alpha(theme.palette.text.primary, 0.7),
            }}
          />
        </Box>
      );
    }

    if (isHovered) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={`${size}px`}
          height={`${size}px`}
          sx={{
            backgroundColor: alpha(theme.palette.colors.grayScale.white, 0.3),
            borderRadius: "30%",
            color: theme.palette.colors.grayScale.white,
          }}
        >
          <Close />
        </Box>
      );
    }

    if (imageSrc) {
      return (
        <Box
          component="img"
          src={imageSrc}
          alt="Document Thumbnail"
          sx={{
            width: `${size}px`,
            height: `${size}px`,
            objectFit: "cover",
            borderRadius: forceNoBorderRadius ? "0px" : "30%",
          }}
        />
      );
    }

    return getIconForExtension(getExtensionFromName(title));
  };

  return (
    <Box
      display="flex"
      alignContent="center"
      justifyContent="center"
      alignItems="center"
      margin={`${size * 0.111}px`}
      sx={{
        height: `${size}px`,
        width: `${size}px`,
        backgroundColor: alpha(theme.palette.text.primary, 0.3),
        borderRadius: forceNoBorderRadius ? "0px" : "30%",
        color: theme.palette.colors.grayScale.white,
      }}
    >
      {getIconForState()}
    </Box>
  );
};

export default KnowledgeDocThumbnail;
