import { IExpert, IMessage, IUserDetails } from "../../../../../../types";

export enum CellState {
  DEFAULT = "default",
  EDITING = "editing",
  PLAYING = "playing",
  WORKING = "working",
  LOADING = "loading",
}

export interface MessageCellProps {
  message: IMessage;
  expert?: IExpert | null;
  userDetails?: IUserDetails | null;
  cellState: CellState;
  onSelect: (messageId: string) => void;
  onPlay: (messageId: string) => void;
  onStop: (messageId: string) => void;
  onEdit: (messgeId: string) => void;
  isSmallScreen?: boolean;
  canEdit?: boolean;
  canPlay?: boolean;
  disabled?: boolean;
}

export type NodeProps = {
  node: {
    depth?: number;
    children?: Array<{ type?: string }>;
    ordered?: boolean;
  };
  ordered?: boolean;
  inline?: boolean;
  className?: string;
  children?: React.ReactNode;
  [x: string]: unknown;
};
