import React, { useEffect, useRef } from "react";
import { Box, CircularProgress, useTheme } from "@mui/material";
import SectionBase from "../../workspace-page/content-views/profile-view/sections/SectionBase";
import DataCell from "./DataCell";

interface UsageSectionProps {
  usageDataReport: any;
}

const UsageSection: React.FC<UsageSectionProps> = ({ usageDataReport }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  useEffect(() => {
    const handleResize = () => {
      const width = containerRef?.current?.clientWidth as number;
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const colors = [
    theme.palette.colors.blue[500],
    theme.palette.colors.green[500],
    theme.palette.colors.raspberry[500],
    theme.palette.colors.teal[500],
    theme.palette.colors.skyBlue[500],
    theme.palette.colors.rosePink[500],
  ];

  const DataCells = () => {
    return (
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
          gap: "16px",
          width: "100%",
          padding: "16px",
        }}
      >
        <DataCell
          title="New Chats"
          value={usageDataReport.chatCount}
          iconName="chatCount"
          color={colors[0]}
        />
        <DataCell
          title="Credits Used"
          value={usageDataReport.creditUsage}
          iconName="creditUsage"
          color={colors[1]}
        />
        <DataCell
          title="Days Active"
          value={usageDataReport.daysActive}
          iconName="daysActive"
          color={colors[2]}
        />
        <DataCell
          title="New Experts"
          value={usageDataReport.expertCount}
          iconName="expertCount"
          color={colors[3]}
        />
        <DataCell
          title="Messages"
          value={usageDataReport.messageCount}
          iconName="messageCount"
          color={colors[4]}
        />
        <DataCell
          title="API Usage"
          value={usageDataReport.usageEvents}
          iconName="usageEvents"
          color={colors[5]}
        />
      </Box>
    );
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        alignContent: "flex-start",
        height: "auto",
        width: "100%",
      }}
    >
      <SectionBase title="Usage">
        {usageDataReport ? (
          <DataCells />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              minHeight: "100px",
              width: "100%",
              paddingBottom: "32px",
            }}
          >
            <CircularProgress
              thickness={6}
              size={50}
              sx={{
                marginBottom: "16px",
                color: theme.palette.primary.main,
              }}
            />
            Loading Usage Data
          </Box>
        )}
      </SectionBase>
    </Box>
  );
};

export default UsageSection;
