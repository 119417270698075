import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import { io, Socket } from "socket.io-client";
import { useAppSelector } from "./app/store";
import { IUserAuth } from "./types";

export let socket = null as Socket | null;

type SocketContextType = {
  socket: Socket | null;
};

const SocketContext = createContext<SocketContextType>({
  socket: null,
});

type SocketProviderProps = {
  children: ReactNode;
};

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const userAuth = useAppSelector((state) => state.userAuth.userAuth);

  // keep track of the previous user
  const [prevUserAuth, setUserAuth] = useState<IUserAuth | null>(null);

  let isNewUser = false;
  if (userAuth) {
    if (!prevUserAuth) {
      isNewUser = true;
    } else {
      isNewUser = prevUserAuth?.user?._id !== userAuth?.user?._id;
    }
  }

  useEffect(() => {
    // only create a new socket connection if a user is logged in and it's a different user than before
    if (userAuth && isNewUser) {
      // close the old socket connection if it exists
      if (socket) {
        socket.off();
        socket.disconnect();
        socket = null;
      }

      const userId = userAuth?.user?._id;
      const token = userAuth?.token;

      if (socket === null && userId && token) {
        try {
          socket = io(process.env.REACT_APP_SERVER_URL, {
            query: {
              userId: userId,
              token: token,
            },
            reconnection: true,
            reconnectionAttempts: Infinity,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            randomizationFactor: 0.5,
          });

          socket.on("connect", () => {
            socket?.emit("joinRoom", userId);
          });

          socket.on("disconnect", (reason) => {
            if (reason === "io server disconnect") {
              // the disconnection was initiated by the server, you need to reconnect manually
              socket.connect();
            }
            // else the socket will automatically try to reconnect
          });

          socket.on("error", (error) => {
            // console.log("Error:", error);
          });

          socket.on("connect_error", (error) => {
            //console.log("Connection Error:", error);
            // Implement retry logic or notify the user
          });
        } catch (error) {
          //console.log("Error creating socket", error);
        }
      }

      setUserAuth(userAuth);
    } else {
      if (!userAuth) {
        if (socket) {
          // this gets triggered when our app logs out
          socket.off();
          socket.disconnect();
          socket = null;
        }
      }
    }

    // else don't do anything because it's just user updating.
  }, [userAuth]);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
