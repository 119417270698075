import { Box, Typography } from "@mui/material";

export interface NoContentProps {
  title: string;
  line1: string;
  line2: string;
  line3: string;
}

const NoContent: React.FC<NoContentProps> = (noContentProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "auto",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: 700,
          fontSize: "12px",
          color: "#727272",
          margin: "4px 0",
        }}
      >
        {noContentProps.title}
      </Typography>
      <Box
        sx={{
          display: "column",
          padding: "16px",
          borderRadius: "12px",
          border: "0.5px solid #727272",
          backgroundColor: "transparent",
          textAlign: "center",
          margin: "20px",
        }}
      >
        <Typography
          component="ul"
          sx={{
            maxWidth: "280px",
            fontWeight: 400,
            fontSize: "12px",
            textAlign: "left",
            marginLeft: "16px",
            marginRight: "16px",
            color: "#727272",
            listStylePosition: "outside",
          }}
        >
          <li>
            <Box sx={{ padding: "4px 0" }}>{noContentProps.line1}</Box>
          </li>
          <li>
            <Box sx={{ padding: "4px 0" }}>{noContentProps.line2}</Box>
          </li>
          <li>
            <Box sx={{ padding: "0px 0" }}>{noContentProps.line3}</Box>
          </li>
        </Typography>
      </Box>
    </Box>
  );
};

export default NoContent;
