import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppBar, Box } from "@mui/material";
import virtual_experts_logo from "../../images/virtual-experts-logo.png";
import { useTheme } from "@mui/material/styles";
import { useAppSelector } from "../../app/store";
import MainMenuMobile from "./components/MainMobileMenu";
import MainMenu from "./components/MainMenu";
import { hasAccessToWorkspace } from "../../services/navigateBasedOnAuth";
import { SubscriptionStatus } from "../../types/user/ISubscriptionInfo";
import useScreenSize from "../ui-elements/useScreenSize";

const SiteHeader = () => {
  const theme = useTheme();
  const location = useLocation(); // Use React Router's location hook
  const screenSize = useScreenSize();
  const [isAuthPage, setIsAuthPage] = useState(false);

  const authSlice = useAppSelector((state) => state.userAuth);
  const userAuth = authSlice.userAuth;
  const userSubscriptionSlice = useAppSelector(
    (state) => state.userSubscription
  );
  const userDetailsSlice = useAppSelector((state) => state.userDetails);
  const userCredentialsSlice = useAppSelector((state) => state.userCredentials);

  // Check if user is logged in, onboarded, and subscription status
  const isLoggedIn = !!userAuth?.token;
  const isOnboarded = userDetailsSlice?.userDetails?.onboarded === true;
  const isBetaApproved =
    userCredentialsSlice?.userCredentials?.betaApproved === true;
  const isActiveSubscription = hasAccessToWorkspace(
    userSubscriptionSlice.userSubscription
  );

  const isPaymentFailed =
    userSubscriptionSlice.userSubscription?.subscriptionStatus ===
    SubscriptionStatus.PAYMENT_FAILED;

  const isWorkspace = location.pathname === "/workspace"; // Using location.pathname instead of window.location
  const isAccountPage = location.pathname === "/account";

  // Single useEffect for updating auth page status
  useEffect(() => {
    const pageName = location.pathname.split("/")[1];
    setIsAuthPage(pageName === "auth");
  }, [location.pathname]); // Only re-run when location.pathname changes

  // Prevent rendering header on auth pages
  // if (isAuthPage) return null;

  return (
    <Box>
      <AppBar
        position="fixed"
        enableColorOnDark={false}
        elevation={2}
        sx={{
          width: "100%",
          top: 0,
          borderRadius: 0,
          margin: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          zIndex: theme.zIndex.drawer + 1,
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.2)",
          height: "60px",
          paddingTop: "0px",
          paddingBottom: "0px",
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            position: "relative",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: screenSize.isLarge ? "center" : "space-between",
            alignItems: "center",
            paddingRight: "5px",
          }}
        >
          <Link to="/home">
            <Box
              sx={{
                marginLeft: "15px",
                marginTop: "7px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={virtual_experts_logo}
                alt="virtual_experts_logo"
                width="210"
                height="30"
              />
            </Box>
          </Link>

          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: theme.spacing(1),
            }}
          >
            {screenSize.isLarge ? (
              <MainMenuMobile
                isLoggedIn={isLoggedIn}
                isPaymentFailed={isPaymentFailed}
                isWorkspace={isWorkspace}
                isAccountPage={isAccountPage}
                isOnboarded={isOnboarded}
                isBetaApproved={isBetaApproved}
                isActiveSubscription={isActiveSubscription}
                isVerified={userCredentialsSlice?.userCredentials?.verified}
              />
            ) : (
              <MainMenu
                isLoggedIn={isLoggedIn}
                isPaymentFailed={isPaymentFailed}
                isWorkspace={isWorkspace}
                isAccountPage={isAccountPage}
                isOnboarded={isOnboarded}
                isBetaApproved={isBetaApproved}
                isActiveSubscription={isActiveSubscription}
                isVerified={userCredentialsSlice?.userCredentials?.verified}
              />
            )}
          </Box>
        </Box>
      </AppBar>
    </Box>
  );
};

export default SiteHeader;
