import { IChat, IChatState } from "../../../types/chat/IChat";
import { RootState } from "../../../app/store";
import {
  deleteChat,
  fetchChatsForExpert,
  setChatStateForChat,
  setChats,
} from "../chatSlice";
import { fetchMessages } from "../messagesSlice";
import { fetchCache } from "../cacheSlice";
import { fetchPrompts } from "../promptsSlice";
import { IErrorMessage, IExpert } from "../../../types";
import { updateExpert } from "../../experts/expertsSlice";
import * as api from "../../../routes/routes";
import { showErrorNotification } from "../../ui/errorSlice";

export async function featchChildObjects(
  dispatch,
  cacheId: string,
  state,
  chat: IChat
) {
  const promiseBatch2 = [];
  promiseBatch2.push(dispatch(fetchPrompts(cacheId)));

  const activeExpert = state.experts.activeExpert as IExpert;
  if (activeExpert) {
    // update active expert
    const updatedExpert = {
      ...activeExpert,
      activeChat: chat._id,
      activeCache: chat.cacheId,
    };

    promiseBatch2.push(dispatch(updateExpert(updatedExpert)));
  }

  if (promiseBatch2.length > 0) {
    await Promise.all(promiseBatch2);
  }
}

export async function fetchParentObjects(chat: IChat, dispatch) {
  const cacheId = chat.cacheId as string;
  const promiseBatch1 = [];
  promiseBatch1.push(dispatch(fetchChatsForExpert(null)));
  promiseBatch1.push(dispatch(fetchMessages(chat._id)));
  promiseBatch1.push(dispatch(fetchCache(cacheId)));

  await Promise.all(promiseBatch1);
  return cacheId;
}

export function setNewChatStateToOpen(chat: IChat, dispatch) {
  const setChatStatePayload = {
    chatId: chat._id,
    chatState: IChatState.OPEN,
  };
  dispatch(setChatStateForChat(setChatStatePayload));
}

export function setNewChatStateToOpening(chatId: string, dispatch) {
  const openChatStatePayload = {
    chatId: chatId,
    chatState: IChatState.OPENING,
  };

  dispatch(setChatStateForChat(openChatStatePayload));
}

export function setPreviousChatStateToSaving(activeChatId: string, dispatch) {
  const setPreviousChatStatePayload = {
    chatId: activeChatId,
    chatState: IChatState.SAVING,
  };

  dispatch(setChatStateForChat(setPreviousChatStatePayload));
}

export async function deletePreviousChat(
  activeChatId: string,
  state,
  dispatch
) {
  const result = await dispatch(deleteChat(activeChatId));
  if (result?.error) {
    const errorNotification = {
      message: "Failed to delete chat",
      title:
        "Chat currently has an active reply job. Please wait until all jobs are finished and try again.",
    } as IErrorMessage;

    dispatch(showErrorNotification(errorNotification));
    return;
  }

  const chatsWithoutActiveChat = state.chat.chats.filter(
    (chat) => chat._id !== activeChatId
  );

  // remove the chat from the store
  dispatch(setChats(chatsWithoutActiveChat));
}

export function shouldDeletePreviousChat(
  hasMessages: boolean,
  activeChat: IChat,
  switchingExperts: boolean
) {
  if (switchingExperts) {
    return false;
  }

  return !hasMessages && activeChat;
}

export function hasMesseges(state) {
  const messagesSlice = state.messages;
  // bool if there are currently messages in the active chat
  const hasMessages =
    messagesSlice.messages && messagesSlice.messages.length > 0;
  return hasMessages;
}

export function hasActiveSessionChanged(
  initialExpertId,
  initialChatId,
  getState
) {
  const currentState = getState() as RootState;
  const currentExpertId = currentState.experts.activeExpert?._id;
  const currentChatId = currentState.chat.activeChat?._id;

  // Return true if either the active expert or chat has changed
  return initialExpertId !== currentExpertId || initialChatId !== currentChatId;
}
