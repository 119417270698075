import { Theme, Typography } from "@mui/material";

export interface PageTitleProps {
  text: string;
  theme?: Theme | null;
}

export const PageTitle: React.FC<PageTitleProps> = ({ text, theme = null }) => {
  return (
    <Typography
      fontSize={"48px"}
      fontWeight={"800"}
      color={theme ? theme.palette.text.primary : undefined}
    >
      {text}
    </Typography>
  );
};

export interface PageSubtitleProps {
  text: string;
  theme?: Theme | null;
}

export const PageSubtitle: React.FC<PageSubtitleProps> = ({
  text,
  theme = null,
}) => {
  return (
    <Typography
      fontSize={"20px"}
      fontWeight={"600"}
      color={theme ? theme.palette.text.secondary : undefined}
    >
      {text}
    </Typography>
  );
};

export interface PageBodyTextProps {
  text: string;
  theme?: Theme | null;
}

export const PageBodyText: React.FC<PageBodyTextProps> = ({ text, theme }) => {
  return (
    <Typography
      fontSize={"16px"}
      fontWeight={"400"}
      color={theme ? theme.palette.text.primary : undefined}
    >
      {text}
    </Typography>
  );
};
