import { Box } from "@mui/material";
import Switch from "@mui/material/Switch";
import { useTheme } from "@mui/material/styles";

const CustomToggle = ({ checked, onChange }) => {
  const theme = useTheme();
  const transitionSpeed = 250;

  return (
    <Box display="flex" justifyContent="flex-end">
      <Switch
        checked={checked}
        onChange={onChange}
        sx={{
          width: 48,
          height: 34,
          padding: "7px",
          "&:hover": {
            "& .MuiSwitch-thumb": {
              transition: theme.transitions.create(["background-color"], {
                duration: transitionSpeed,
              }),
              backgroundColor: theme.palette.primary.main,
            },
          },
          "& .MuiSwitch-switchBase": {
            margin: 1,
            padding: 0,
            transform: "translate(-6px, -3px)",
            "&.Mui-checked": {
              color: theme.palette.background.backdrop,
              transform: "translate(14px, -3px)",
              "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.colors.green[500],
                opacity: 1,
                border: "none",
              },
            },
          },
          "& .MuiSwitch-thumb": {
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.colors.grayScale[400]
                : theme.palette.background.chatBubble,
            width: 24,
            height: 24,
          },
          "& .MuiSwitch-track": {
            borderRadius: 20 / 2,
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.colors.grayScale[600]
                : theme.palette.colors.grayScale[75],
            opacity: 1,
            transition: theme.transitions.create(["background-color"], {
              duration: transitionSpeed,
            }),
          },
        }}
      />
    </Box>
  );
};

export default CustomToggle;
