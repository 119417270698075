import React from "react";
import {
  Grid,
  LinearProgress,
  IconButton,
  Typography,
  Box,
  Tooltip,
  alpha,
} from "@mui/material";
import { Add } from "../../../icons/index";
import { useTheme } from "@mui/material/styles";
import { useAppSelector } from "../../../app/store";
import { NumberFormatterService } from "../../../services/NumberFormatterService";

interface UsageRowProps {
  onClick: () => void;
}

export const UsageRow: React.FC<UsageRowProps> = ({ onClick }) => {
  const theme = useTheme();
  const userSubscriptionSlice = useAppSelector(
    (state) => state.userSubscription
  );
  const themeSlice = useAppSelector((state) => state.theme);
  const isDarkMode = themeSlice.isDarkMode;

  const currentMonthUsage =
    userSubscriptionSlice.userSubscription?.currentDataUsage ?? 0;
  const formattedCurrentMonthUsage =
    NumberFormatterService.formatNumberWithComma(currentMonthUsage);

  const currentDataLimit =
    userSubscriptionSlice.userSubscription?.currentDataLimit ?? 0;
  const formattedCurrentDataLimit =
    NumberFormatterService.formatNumber(currentDataLimit);

  const currentBonusCredits =
    userSubscriptionSlice.userSubscription?.bonusCredits ?? 0;
  const formattedBonusDataLimit =
    NumberFormatterService.formatNumber(currentBonusCredits);

  const availableCredits = currentDataLimit + currentBonusCredits;
  const usagePercentage = (currentMonthUsage / availableCredits) * 100;
  const formattedUsagePercentage =
    NumberFormatterService.formatPercentage(usagePercentage);

  const barPercentage = usagePercentage <= 1 ? 2 : usagePercentage;

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "transparent",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "16px",
        paddingRight: "8px",
        boxSizing: "border-box",
      }}
      onClick={onClick}
    >
      <Tooltip
        title={`${formattedCurrentMonthUsage} out of ${formattedCurrentDataLimit}${
          currentBonusCredits > 0 ? ` (+${formattedBonusDataLimit} bonus)` : ""
        } credits used`}
        placement="top"
      >
        <Grid container direction="column">
          <Box
            position={"relative"}
            padding={"8px"}
            marginTop={"-4px"}
            marginBottom={"-4px"}
            marginLeft={"-4px"}
            marginRight={"-4px"}
            width={"100%"}
            height={"100%"}
            sx={{
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: alpha(
                  theme.palette.background.buttonHighlighted,
                  isDarkMode ? 1.0 : 0.8
                ),
              },
              "&:active": {
                transform: "scale(0.97)",
              },
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                marginBottom: "8px",
              }}
            >
              <Typography
                sx={{ fontWeight: 300, fontSize: "12px" }}
                variant="body2"
              >
                {`Usage:`}
              </Typography>
              <Typography
                sx={{ fontWeight: 700, fontSize: "12px", marginLeft: "4px" }}
                variant="body2"
                fontWeight="700"
              >
                {formattedUsagePercentage}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <LinearProgress
                variant="determinate"
                value={barPercentage}
                sx={{
                  width: "100%",
                  height: "16px",
                  borderRadius: "6px",
                  backgroundColor: "transparent",
                  border: `1px solid #F3A712`,
                  overflow: "hidden",
                  ".MuiLinearProgress-bar": {
                    backgroundColor: theme.palette.colors.gold[500],
                    borderRadius: "0",
                  },
                  ".MuiLinearProgress-colorPrimary": {
                    backgroundColor: "transparent",
                  },
                }}
              />
              <IconButton
                sx={{
                  backgroundColor: theme.palette.background.buttonHighlighted,
                  color: theme.palette.colors.gold[600],
                  borderRadius: "6px",
                  marginLeft: "8px",
                  padding: "2px",
                  width: "16px",
                  height: "16px",
                  fontWeight: 700,
                  "&:hover": {
                    backgroundColor: theme.palette.colors.gold[500],
                    color: theme.palette.colors.grayScale.white,
                  },
                }}
              >
                <Add />
              </IconButton>
            </Grid>
          </Box>
        </Grid>
      </Tooltip>
    </Box>
  );
};
