import React from "react";
import { alpha, Fab, useTheme } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";

const ScrollToBottomButton = ({ onClick, visible }) => {
  const theme = useTheme();

  return (
    <Fab
      size="small"
      color="primary"
      aria-label="scroll to bottom"
      onClick={onClick}
      sx={{
        position: "absolute",
        bottom: "12px",
        right: "12px",
        display: visible ? "flex" : "none",
        zIndex: 0,
        // blur 0.5
        backdropFilter: "blur(2.5px)",
        border: `1px solid ${
          theme.palette.mode === "dark"
            ? alpha(theme.palette.colors.grayScale[300], 0.8)
            : alpha(theme.palette.colors.grayScale[400], 0.8)
        }`,
        backgroundColor: alpha(theme.palette.background.backdrop, 0.6),
        "&:hover": {
          backgroundColor: alpha(theme.palette.background.backdrop, 1.0),
        },
      }}
    >
      <KeyboardArrowDown
        sx={{
          color:
            theme.palette.mode === "dark"
              ? alpha(theme.palette.colors.grayScale[300], 0.8)
              : alpha(theme.palette.colors.grayScale[400], 0.8),
        }}
      />
    </Fab>
  );
};

export default ScrollToBottomButton;
