import API from "./api";

// expert defaults for randomColor, randomName, randomImage, and randomJob
export const getRandomColor = () => API.get("/expertDefaults/randomColor");
export const getRandomName = () => API.get("/expertDefaults/randomName");
export const getRandomImage = () => API.get("/expertDefaults/randomImage");
export const getRandomJob = () => API.get("/expertDefaults/randomJob");
export const getExpertImage = (photoURL: string) =>
  API.post(
    "/expertDefaults/getExpertImage",
    { photoURL: photoURL },
    { responseType: "blob" }
  );
export const getDefaultVoiceOptions = () =>
  API.get("/expertDefaults/getDefaultVoiceOptions");
