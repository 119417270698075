import React from "react";
import { MenuItem, Button, alpha } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useAppSelector } from "../../../app/store";

export interface MenuItemRowProps {
  title: string;
  icon: React.ReactNode;
  onClick: () => void;
}

const MenuItemRow: React.FC<MenuItemRowProps> = ({ title, icon, onClick }) => {
  const theme = useTheme();
  const themeSlice = useAppSelector((state) => state.theme);
  const isDarkMode = themeSlice.isDarkMode;

  return (
    <MenuItem
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignContent: "flex-start",
        "&:hover": {
          backgroundColor: alpha(
            theme.palette.background.buttonHighlighted,
            isDarkMode ? 1.0 : 0.8
          ),
        },
        marginLeft: 0,
      }}
      disableRipple={true}
    >
      <Button
        sx={{
          height: "40px",
          justifyContent: "flex-start",
          backgroundColor: "transparent",
          width: "160px",
          color: theme.palette.text.primary,
          fontSize: "14px",
          fontWeight: 400,
          "&:hover": {
            backgroundColor: "transparent",
          },
          "& .MuiButton-startIcon": {
            marginRight: "16px",
            marginLeft: `-10px`,
            width: "24px",
            verticalAlign: "middle",
          },
          textTransform: "none",
          boxShadow: "none",
        }}
        startIcon={icon}
        fullWidth
        disableRipple={true}
        onClick={onClick}
      >
        {title}
      </Button>
    </MenuItem>
  );
};

export default MenuItemRow;
