import { useEffect, useState, ReactNode } from "react";
import { Grid, Typography, alpha, useTheme } from "@mui/material";
import BlockTitle from "../../../../ui-elements/BlockTitle";
import CustomCheckbox from "../../../../ui-elements/CustomCheckbox";

interface AuthCheckboxBlockProps {
  title: string;
  subtitle: string | ReactNode;
  setValue: (value: string) => void;
  value: string;
}

const AuthCheckboxBlock = ({
  title,
  subtitle,
  setValue,
  value,
}: AuthCheckboxBlockProps) => {
  const theme = useTheme();
  const [checked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(value === "true" ? true : false);
  }, [value]);

  const handleCheckboxChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.checked;
    setValue(newValue ? "true" : "false");
  };

  return (
    <Grid
      item
      container
      direction={"row"}
      alignContent={"center"}
      justifyContent="start"
      paddingBottom={"16px"}
    >
      <Grid item sx={{ width: "24px" }}>
        <CustomCheckbox
          isChecked={checked}
          setIsChecked={handleCheckboxChange}
          size="16px"
        />
      </Grid>
      <Grid
        item
        container
        sx={{ width: "calc(100% - 24px)" }}
        direction={"column"}
      >
        <Typography
          fontWeight={800}
          fontSize={"12px"}
          color={alpha(theme.palette.text.secondary, 0.9)}
        >{`${title}`}</Typography>
        <Typography
          fontWeight={300}
          fontSize={"12px"}
          color={alpha(theme.palette.text.secondary, 0.7)}
        >
          {subtitle}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AuthCheckboxBlock;
