import React, { useEffect, useState } from "react";
import { Divider, Menu, Typography } from "@mui/material";
import { Profile, Logout, LightMode, DarkMode, Home } from "../../../icons";
import VE_Avatar from "../../ui-elements/VE_Avatar";
import { useNavigate } from "react-router-dom";
import { googleLogout } from "@react-oauth/google";
import { logout, setManualLogout } from "../../../features/users/userAuthSlice";
import { setDarkMode } from "../../../features/ui/themeSlice";
import { useAppSelector, useAppDispatch } from "../../../app/store";
import { useTheme } from "@mui/material/styles";
import MenuItemRow from "../../ui-elements/menu-tabs/MenuItemRow";
import StyledPaper from "./StyledPaper";
import { updateUserPreferences } from "../../../features/users/userPreferencesSlice";
import { setAPopupIsShowing } from "../../../features/ui/confirmationPopupSlice";
import { UsageRow } from "./UsageRow";
import { hasAccessToWorkspace } from "../../../services/navigateBasedOnAuth";

export default function AccountPopup() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const userPreferences = useAppSelector(
    (state) => state.userPreferences.userPreferences
  );
  const userDetails = useAppSelector((state) => state.userDetails.userDetails);
  const userSubscriptionSlice = useAppSelector(
    (state) => state.userSubscription
  );
  const userSubscription = userSubscriptionSlice.userSubscription;
  const isActiveSubscriber = hasAccessToWorkspace(userSubscription);
  const isDarkMode = useAppSelector((state) => state.theme.isDarkMode);

  useEffect(() => {
    // console.log("isDarkMode", isDarkMode);
  }, [isDarkMode]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    dispatch(setAPopupIsShowing(true));
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    dispatch(setAPopupIsShowing(false));
  };

  const handleAccountClick = () => {
    handleClose();
    navigate("/account");
  };

  function signOut() {
    dispatch(setManualLogout(true));
    handleClose();
    navigate("/auth");
    googleLogout();
    dispatch(logout());
  }

  function clickUsage() {
    navigate("/pricing");
  }

  async function toggleDarkMode() {
    const newState = !isDarkMode;
    const updatedUser = { ...userPreferences, prefersDarkMode: newState };
    dispatch(setDarkMode(newState));
    dispatch(updateUserPreferences(updatedUser));
  }

  return (
    <>
      <VE_Avatar
        userDetails={userDetails}
        sx={{
          cursor: "pointer",
          width: "36px",
          height: "36px",
        }}
        onClick={handleClick}
      >
        VE
      </VE_Avatar>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            backgroundColor: "transparent",
          },
        }}
      >
        <StyledPaper>
          <Typography> </Typography>
          {isActiveSubscriber && (
            <>
              <UsageRow onClick={clickUsage} />

              <Divider
                sx={{
                  marginBottom: theme.spacing(1),
                  marginLeft: "16px",
                  marginRight: "16px",
                  backgroundColor: theme.palette.background.divider,
                  height: "1px",
                }}
              />
              <MenuItemRow
                title="Home"
                icon={<Home />}
                onClick={() => navigate("/home")}
              />
              <MenuItemRow
                title="Account"
                icon={<Profile />}
                onClick={handleAccountClick}
              />
            </>
          )}
          <MenuItemRow
            title={isDarkMode ? "Go Light" : "Go Dark"}
            icon={isDarkMode ? <LightMode /> : <DarkMode />}
            onClick={toggleDarkMode}
          />

          <MenuItemRow title="Logout" icon={<Logout />} onClick={signOut} />
        </StyledPaper>
      </Menu>
    </>
  );
}
