export interface ICommand {
  command: string | null;
  message?: string | null;
  startIndex?: number | null;
  endIndex?: number | null;
  description?: string | null;
  icon?: JSX.Element | null;
  type: ICommandType;
}

export enum ICommandType {
  MESSAGE = "MESSAGE",
  ACTION = "ACTION",
  SYSTEM = "SYSTEM",
}

export interface IActionData {
  message?: string;
  command: string;
  files?: File[];
}
