import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type SeverityType = "error" | "warning" | "info" | "success";

const debugSnackbarSlice = createSlice({
  name: "debugSnackbar",
  initialState: {
    open: false,
    message: "",
    severity: "info" as SeverityType, // Default severity is 'info'
  },
  reducers: {
    showDebugSnackbar: (
      state,
      action: PayloadAction<{ message: string; severity?: SeverityType }>
    ) => {
      state.open = true;
      state.message = action.payload.message;
      state.severity = action.payload.severity || "info";
    },
    hideDebugSnackbar: (state) => {
      state.open = false;
      state.message = "";
    },
  },
});

export const { showDebugSnackbar, hideDebugSnackbar } =
  debugSnackbarSlice.actions;
export default debugSnackbarSlice.reducer;
