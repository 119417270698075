import Grid from "@mui/material/Grid";
import { SubscriptionCell } from "./subscription-cell/SubscriptionCell";
import { Box, Button, darken, Typography, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { useEffect } from "react";
import { getPlans } from "../../../../features/ui/pages/pricingPageSlice";
import { ISubscriptionInfo } from "../../../../types";
import { IPlan } from "../../../../types/ui/pricing-page/IPlan";
import { useNavigate } from "react-router-dom";
import { SubscriptionStatus } from "../../../../types/user/ISubscriptionInfo";
import {
  checkout,
  createCustomerPortalSession,
} from "../../../../features/users/userSubscriptionSlice";
import { keyframes } from "@emotion/react";

const shimmer = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

const PricingPage = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const pricingPageSlice = useAppSelector((state) => state.pricingPage);

  const userDetailsSlice = useAppSelector((state) => state.userDetails);
  const firstName = userDetailsSlice.userDetails?.firstName;
  const hasFirstName =
    firstName !== null && firstName !== undefined && firstName !== "";

  const userSubscriptionSlice = useAppSelector(
    (state) => state.userSubscription
  );
  const userSubscription =
    userSubscriptionSlice.userSubscription as ISubscriptionInfo;
  const planId = userSubscription?.planId;
  const plans = pricingPageSlice.plans;
  const planName = userSubscription?.planName;
  const sortedPlans = [...plans].sort((a, b) => a.price - b.price);

  const isCancelled =
    userSubscription?.subscriptionStatus === SubscriptionStatus.CANCELED;

  useEffect(() => {
    dispatch(getPlans());
  }, [dispatch]);

  useEffect(() => {
    if (
      userSubscription?.subscriptionStatus === SubscriptionStatus.PAYMENT_FAILED
    ) {
      navigate("/loading");
      dispatch(createCustomerPortalSession());
    }
  }, []);

  const determineIsCurrentPlan = (plan: IPlan) => {
    if (planId === undefined || planId === null || planId === "") {
      return false;
    }

    return plan._id === planId;
  };

  const hasCurrentPlan = sortedPlans.some(
    (plan) => planId !== undefined && planId !== null && plan._id === planId
  );

  const determineIsFeaturedPlan = (plan: IPlan) => {
    if (planId === undefined || planId === null || planId === "") {
      return plan.name === "Pro";
    }

    // feature the plan that is 1 index higher than the current plan. If the current plan is the last plan, feature that one.
    const currentPlanIndex = sortedPlans.findIndex((p) => p._id === planId);
    const featuredPlanIndex =
      currentPlanIndex + 1 >= sortedPlans.length
        ? currentPlanIndex
        : currentPlanIndex + 1;

    return plan._id === sortedPlans[featuredPlanIndex]._id;
  };

  const hasUpgradeAvailable = () => {
    if (planId === undefined || planId === null || planId === "") {
      return false;
    }

    const currentPlanIndex = sortedPlans.findIndex(
      (plan) => plan._id === planId
    );

    const hasUpgradeAvailable = currentPlanIndex < sortedPlans.length - 1;
    return hasUpgradeAvailable;
  };

  const getUpgradePlanName = () => {
    if (planId === undefined || planId === null || planId === "") {
      return undefined;
    }

    const currentPlanIndex = sortedPlans.findIndex(
      (plan) => plan._id === planId
    );
    if (currentPlanIndex >= sortedPlans.length - 1) {
      return undefined;
    }

    return sortedPlans[currentPlanIndex + 1]?.name;
  };

  const titleForState = () => {
    if (hasFirstName) {
      if (isCancelled) {
        return `Welcome back, ${firstName}!`;
      }
      return hasCurrentPlan ? `Plans` : `Your Plan`;
    }

    return `Plans & Pricing`;
  };

  const subtitleForState = () => {
    if (hasFirstName) {
      if (isCancelled) {
        return `Reactivate your ${
          planName !== undefined && planName !== null && planName !== ""
            ? planName
            : ""
        } plan today!`;
      }

      if (planId === undefined || planId === null || planId === "") {
        return `${firstName}, choose the plan that's right for you.`;
      }
      return hasUpgradeAvailable()
        ? `${firstName}, do more with the ${getUpgradePlanName()} plan.`
        : `${firstName}, you already have the best plan!`;
    }

    return `Choose the plan that's right for you.`;
  };

  const reactivateClicked = () => {
    navigate("/loading");
    dispatch(checkout(planId));
  };

  return (
    <Box
      display={"flex"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      flexDirection={"column"}
      flex={1}
      sx={{ mt: 4, mb: 4 }}
    >
      <Typography variant="h1" textAlign={"center"} gutterBottom>
        {titleForState()}
      </Typography>
      <Typography
        variant="h4"
        fontSize={{ xs: "1.2rem", sm: "1.5rem" }}
        color={theme.palette.text.secondary}
        textAlign={"center"}
        paddingRight={"16px"}
        paddingLeft={"16px"}
        gutterBottom
      >
        {subtitleForState()}
      </Typography>
      {hasUpgradeAvailable() && (
        <Typography
          variant="body2"
          maxWidth={"500px"}
          textAlign={"center"}
          paddingRight={"16px"}
          paddingLeft={"16px"}
          color={theme.palette.text.secondary}
        >
          {`All plans include all features. No hidden fees. No contracts. Cancel
        anytime... or more realistically - upgrade anytime!`}
        </Typography>
      )}
      {isCancelled && (
        <Button
          variant="contained"
          sx={{
            marginTop: "24px",
            background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
            backgroundSize: "200% 100%",
            color: theme.palette.common.white,
            borderRadius: "8px",
            padding: "12px 24px",
            boxShadow: "none",
            animation: `${shimmer} 2s linear infinite`,
            "&:hover": {
              backgroundColor: darken(theme.palette.primary.main, 0.1),
              boxShadow: "none",
            },
          }}
          onClick={reactivateClicked}
        >
          Let's Gooo!
        </Button>
      )}
      <Grid
        container
        spacing={3}
        width={"100%"}
        height={"100%"}
        maxWidth={"1200px"}
        marginTop={"24px"}
        justifyContent={"center"}
        sx={{
          flexDirection: {
            xs: "column",
            lg: "row",
          },
        }}
      >
        {sortedPlans.length <= 0
          ? undefined
          : sortedPlans.map((plan, key) => (
              <Grid
                item
                alignSelf={"center"}
                sx={{ display: "flex" }}
                width={{ xs: "100%", sm: "70%", lg: "380px" }}
                key={key}
              >
                <SubscriptionCell
                  plan={plan}
                  isFeaturedPlan={determineIsFeaturedPlan(plan)}
                  isCurrentPlan={determineIsCurrentPlan(plan)}
                  hasCurrentPlan={hasCurrentPlan}
                />
              </Grid>
            ))}
      </Grid>
    </Box>
  );
};

export default PricingPage;
