import { useEffect, useRef } from "react";
import { Box } from "@mui/material";

export const AnimatedFromDirection = {
  Left: "left",
  Right: "right",
  Center: "center",
};

const AnimatedViewWrapper = ({ children, direction }) => {
  const ref = useRef(null);

  const startingTransform = () => {
    switch (direction) {
      case AnimatedFromDirection.Left:
        return `translateX(${-50}px)`;
      case AnimatedFromDirection.Right:
        return `translateX(${50}px)`;
      case AnimatedFromDirection.Center:
        return `scale(0.95)`;
      default:
        return `translateX(${-50}px)`;
    }
  };

  useEffect(() => {
    if (ref.current !== undefined && ref.current !== null) {
      if (ref.current.style === undefined || ref.current.style === null) {
        return;
      }

      ref.current.style.opacity = 0;
      ref.current.style.transform = startingTransform();

      // Animate to original position
      setTimeout(() => {
        if (ref.current === undefined || ref.current === null) {
          return;
        }
        if (ref.current.style === undefined || ref.current.style === null) {
          return;
        }

        ref.current.style.transition =
          "transform 0.5s, opacity 0.5s, scale 0.5s";
        ref.current.style.opacity = 1;
        ref.current.style.transform = "translateX(0px)";
        ref.current.style.scale = 1;
      }, 0); // Timeout set to 0 to ensure the style applies after the browser has a chance to paint
    }
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <Box
      ref={ref}
      sx={{
        willChange: "transform, opacity, scale", // Optimizes animations
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        marginTop: "60px",
        marginBottom: "60px",
      }}
    >
      {children}
    </Box>
  );
};

export default AnimatedViewWrapper;
