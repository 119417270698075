import { SelectionState, EditorState, Modifier } from "draft-js";
import { ICursorData } from "../../../../../../types";
import { IMessageBarState } from "../state-setters/MessageBarState";

// Logic to calculate current cursor position, preText, and postText
// Return an object { preText, postText, originalCursorPosition, cursorPosition }

export const getCurrentCursorData = (editorState) => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const anchorKey = selectionState.getAnchorKey();
  const currentBlock = contentState.getBlockForKey(anchorKey);
  const start = selectionState.getStartOffset();
  const end = selectionState.getEndOffset();
  const preText = currentBlock.getText().slice(0, start);
  const postText = currentBlock.getText().slice(end);
  const originalCursorPosition = preText.length;
  const cursorPosition = originalCursorPosition; // setting to originalCursorPosition for now

  return {
    preText,
    postText,
    originalCursorPosition,
    cursorPosition,
  } as ICursorData;
};

export const insertTemporaryTranscription = (
  messageBarState: IMessageBarState,
  transcription: string
): EditorState => {
  const selectionState = messageBarState.editorState.getSelection();
  const contentState = messageBarState.editorState.getCurrentContent();

  // Check if there's selected text. If so, replace it; otherwise, insert.
  let newContentState = selectionState.isCollapsed()
    ? Modifier.insertText(contentState, selectionState, transcription)
    : Modifier.replaceText(contentState, selectionState, transcription);

  // Update the temporary transcription position
  const updatedSelection = selectionState.merge({
    anchorOffset: selectionState.getStartOffset(),
    focusOffset: selectionState.getStartOffset() + transcription.length,
  });

  const newTempPos = {
    blockKey: selectionState.getAnchorKey(),
    startOffset: selectionState.getStartOffset(),
    endOffset: selectionState.getStartOffset() + transcription.length,
  };

  messageBarState.tempTranscriptionPosition = newTempPos;

  // Apply style and update the editor state
  newContentState = Modifier.applyInlineStyle(
    newContentState,
    updatedSelection as SelectionState,
    "TEMPORARY_TRANSCRIPTION"
  );

  let newEditorState = EditorState.push(
    messageBarState.editorState,
    newContentState,
    "insert-characters"
  );

  newEditorState = EditorState.forceSelection(
    newEditorState,
    updatedSelection as SelectionState
  );

  return newEditorState;
};

export const replaceTemporaryTranscription = (
  messageBarState: IMessageBarState,
  transcription: string
): EditorState => {
  if (!messageBarState.tempTranscriptionPosition)
    return messageBarState.editorState;
  // Undo previous temporary transcription

  // Create a selection state for the range to be replaced
  const tempTranscriptionSelection = SelectionState.createEmpty(
    messageBarState.tempTranscriptionPosition.blockKey
  )
    .set("anchorOffset", messageBarState.tempTranscriptionPosition.startOffset)
    .set(
      "focusOffset",
      messageBarState.tempTranscriptionPosition.endOffset
    ) as SelectionState;

  // Replace the text
  let newContentState = Modifier.replaceText(
    messageBarState.editorState.getCurrentContent(),
    tempTranscriptionSelection,
    transcription
  );

  // Update the temporary transcription position
  const newTempTranscriptionPosition = {
    ...messageBarState.tempTranscriptionPosition,
    endOffset:
      messageBarState.tempTranscriptionPosition.startOffset +
      transcription.length,
  };

  messageBarState.tempTranscriptionPosition = newTempTranscriptionPosition;

  // Apply style and update the editor state
  const styledRange = tempTranscriptionSelection.merge({
    focusOffset: newTempTranscriptionPosition.endOffset,
  });

  newContentState = Modifier.applyInlineStyle(
    newContentState,
    styledRange as SelectionState,
    "TEMPORARY_TRANSCRIPTION"
  );

  let newEditorState = EditorState.push(
    messageBarState.editorState,
    newContentState,
    "insert-characters"
  );

  newEditorState = EditorState.forceSelection(
    newEditorState,
    styledRange as SelectionState
  );

  return newEditorState;
};

export const removeTemporaryTranscriptionStyle = (
  editorState: EditorState
): EditorState => {
  const contentState = editorState.getCurrentContent();
  let newContentState = contentState;

  // Iterate over each block in the content state
  contentState.getBlockMap().forEach((block) => {
    const blockKey = block?.getKey() as string;

    // Iterate over each character in the block
    block?.findStyleRanges(
      (character) => {
        // Check if the character has the TEMPORARY_TRANSCRIPTION style
        return character.hasStyle("TEMPORARY_TRANSCRIPTION");
      },
      (start, end) => {
        // Create a selection for the styled range
        const selection = SelectionState.createEmpty(blockKey).merge({
          anchorOffset: start,
          focusOffset: end,
        });

        // Remove the TEMPORARY_TRANSCRIPTION style from the selected range
        newContentState = Modifier.removeInlineStyle(
          newContentState,
          selection,
          "TEMPORARY_TRANSCRIPTION"
        );
      }
    );
  });

  // Create a new EditorState with the updated content state
  return EditorState.push(editorState, newContentState, "change-inline-style");
};
