import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IMessage, ITextToSpeechPayload } from "../../types";
import { RootState } from "../../app/store";
import { setIsAutoPlayEnabled } from "../chats/workspaceSlice";
import { playMessage, setActiveMessage } from "../chats/messagesSlice";
import * as api from "../../routes/routes";

// Initial state for the text-to-speech slice
const initialState = {
  isPlaying: false,
  isLoading: false,
  stopOverride: false,
  activeMessage: null as IMessage | null,
  activeAudioUrl: "" as string,
  audioAnalysisData: [] as any,
  activePlayingMessage: null as string,
};

// Helper function to reset audio state
function resetAudioState(dispatch: any) {
  // console.log("resetAudioState called");
  dispatch(setActiveAudioUrl(""));
  dispatch(setIsPlaying(false));
  dispatch(setActiveAudioMessage(null));
}

// Play a specific message's audio
export const playAudio = createAsyncThunk(
  "audio/playAudio",
  async (message: IMessage, { dispatch }) => {
    // console.log("playAudio");
    dispatch(setActiveAudioMessage(message));
    dispatch(setActiveAudioUrl(message.tempAudioUrl as string));
    dispatch(setIsPlaying(true));
    dispatch(setIsLoading(false));
  }
);

// Play a demo audio for a selected voice
export const playDemoAudio = createAsyncThunk(
  "audio/playDemoAudio",
  async (demoVoicePayload: ITextToSpeechPayload, { getState, dispatch }) => {
    const state = getState() as RootState;

    // Stop any active audio
    if (state.textToSpeech.activePlayingMessage) {
      dispatch(forceStopAudio()); // Stop currently playing audio
    }

    try {
      dispatch(setIsPlaying(false));
      dispatch(setIsLoading(true));
      demoVoicePayload.isDemo = true;

      const response = await api.playDemo(demoVoicePayload);
      const audioUrl = response.data.audioUrl;

      // Set the new active voice being played
      dispatch(setActivePlayingMessage(demoVoicePayload.voiceId));
      dispatch(setActiveAudioUrl(audioUrl));
      dispatch(setIsPlaying(true));
      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(forceStopAudio());
      dispatch(setIsLoading(false));
      dispatch(setStopOverride(false));
    }
  }
);

// Forcefully stop any active audio playback
export const forceOverrideStopAudio = createAsyncThunk(
  "audio/forceOverrideStopAudio",
  async (_, { dispatch }) => {
    dispatch(setStopOverride(true));
    resetAudioState(dispatch);
    dispatch(setIsAutoPlayEnabled(false)); // Disable auto-play if needed
  }
);

// General stop audio function
export const forceStopAudio = createAsyncThunk(
  "audio/forceStopAudio",
  async (_, { dispatch }) => {
    resetAudioState(dispatch);
    dispatch(setIsAutoPlayEnabled(false)); // Disable auto-play if needed
  }
);

// Handle when audio ends, either naturally or by user input
export const handleAudioEnded = createAsyncThunk(
  "audio/handleAudioEnded",
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;

    // Reset state if auto-play is disabled
    if (!state.workspace.isAutoPlayEnabled) {
      resetAudioState(dispatch);
      return;
    }

    // Play the next message if auto-play is enabled
    const messages = state.messages.messages;
    const activeMessage = state.textToSpeech.activeMessage;
    const activeMessageIndex = messages.findIndex(
      (message) => message._id === activeMessage?._id
    );
    const nextIndex = activeMessageIndex - 1;
    const nextMessageExists = nextIndex >= 0;

    resetAudioState(dispatch);

    if (!nextMessageExists) {
      dispatch(setActiveMessage(null));
      return;
    }

    const nextMessage = messages[nextIndex];

    if (nextMessage !== undefined && nextMessage !== null) {
      dispatch(setActiveMessage(nextMessage));
      dispatch(playMessage(nextMessage));
    }
  }
);

// The slice for text-to-speech audio management
export const textToSpeechSlice = createSlice({
  name: "textToSpeech",
  initialState: initialState,
  reducers: {
    setActiveAudioUrl: (state, action) => {
      state.activeAudioUrl = action.payload;
    },
    setIsPlaying: (state, action) => {
      state.isPlaying = action.payload;
    },
    setActiveAudioMessage: (state, action) => {
      state.activeMessage = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setStopOverride: (state, action) => {
      state.stopOverride = action.payload;
    },
    setAudioAnalysisData: (state, action) => {
      state.audioAnalysisData = action.payload;
    },
    setActivePlayingMessage: (state, action) => {
      state.activePlayingMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(playAudio.fulfilled, (state) => {
      state.isPlaying = true;
      state.isLoading = false;
    });
    builder.addCase(forceStopAudio.fulfilled, (state) => {
      state.isPlaying = false;
      state.isLoading = false;
      state.activeAudioUrl = "";
      state.activePlayingMessage = null;
    });
    builder.addCase(handleAudioEnded.fulfilled, (state) => {
      state.isPlaying = false;
      state.activeAudioUrl = "";
      state.activePlayingMessage = null;
    });
  },
});

export const {
  setActiveAudioUrl,
  setIsPlaying,
  setActiveAudioMessage,
  setIsLoading,
  setStopOverride,
  setAudioAnalysisData,
  setActivePlayingMessage,
} = textToSpeechSlice.actions;

export default textToSpeechSlice.reducer;
