import React, { useEffect, useState, useRef } from "react";
import { Grid, Typography, Divider, Box, Tab } from "@mui/material";
import { Settings, Credit, ArrowLeft } from "../../../icons";
import { useTheme } from "@mui/material/styles";
import MobileMenuTabs from "../../ui-elements/menu-tabs/MobileMenuTabs";
import { MenuTabs } from "../../ui-elements/menu-tabs/MenuTabs";
import { AccountMenuItem } from "../../ui-elements/menu-tabs/MenuItemEnums";
import { PrimaryIcon } from "../../ui-elements";
import { useNavigate } from "react-router-dom";

interface AccountHeaderProps {
  title: string;
  selectedTab?: AccountMenuItem;
  setSelectedTab?: (tab: AccountMenuItem) => void;
}

const AccountHeader: React.FC<AccountHeaderProps> = ({
  selectedTab,
  setSelectedTab,
}) => {
  const theme = useTheme();
  const [containerWidth, setContainerWidth] = useState<number | null>(0);
  const [showMobileMenu, setShowMobileMenu] = useState(
    containerWidth && containerWidth < 600
  );
  const [showDesktopMenu, setShowDesktopMenu] = useState(
    containerWidth && containerWidth > 600
  );
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const width = containerRef?.current?.clientWidth as number;
    setContainerWidth(width);
    setShowMobileMenu(width < 600);
    setShowDesktopMenu(width > 600);
  }, [containerRef]);

  useEffect(() => {
    const handleResize = () => {
      const width = containerRef?.current?.clientWidth as number;
      setContainerWidth(width);
      setShowMobileMenu(width < 600);
      setShowDesktopMenu(width > 600);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleTabChange = (tab: AccountMenuItem) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    //console.log("new tab: " + selectedTab);
  }, [selectedTab]);

  const navigate = useNavigate();

  const handleBackClicked = () => {
    navigate(-1);
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: `calc(100% + 16px)`,
        marginBottom: "-1px",
      }}
    >
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: showDesktopMenu ? "space-between" : "flex-start",
            marginLeft: "8px",
          }}
        >
          <PrimaryIcon
            iconName={"handle"}
            icon={<ArrowLeft />}
            size="32px"
            animated={true}
            handleIconClick={handleBackClicked}
          />
          <Typography
            sx={{
              display: "inline-block",
              fontWeight: 700 + "!important",
              fontSize: "20px",
              color: theme.palette.primary.main,
              marginRight: "16px",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            {`Account`}
          </Typography>

          {showMobileMenu && (
            <Grid
              item
              display={"flex"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <Divider
                orientation="vertical"
                sx={{
                  height: "24px",
                  marginLeft: "-2px",
                  marginRight: "18px",
                  backgroundColor: theme.palette.background.divider,
                }}
              />
              <MobileMenuTabs
                selectedTab={selectedTab}
                onItemSelected={handleTabChange}
                mobileMenuItems={[
                  {
                    title: "Settings",
                    icon: <Settings />,
                    onClick: () => handleTabChange(AccountMenuItem.Settings),
                  },

                  {
                    title: "Subscription",
                    icon: <Credit />,
                    onClick: () =>
                      handleTabChange(AccountMenuItem.Subscription),
                  },
                ]}
              />
            </Grid>
          )}
        </Grid>
        {showDesktopMenu && (
          <Grid
            item
            display={"flex"}
            alignContent={"center"}
            alignItems={"center"}
            marginRight={"16px"}
          >
            <MenuTabs
              selectedTab={selectedTab}
              onChange={handleTabChange}
              color={theme.palette.primary.main}
              tabs={[
                <Tab key={0} value={0} label="Settings" disableRipple />,
                <Tab key={1} value={1} label="Subscription" disableRipple />,
              ]}
            />
          </Grid>
        )}
        {showDesktopMenu && <Box sx={{ width: "90px" }} />}
      </Grid>
      <Divider
        sx={{
          position: "relative",
          top: "-1px",
          backgroundColor: theme.palette.background.divider,
          marginRight: "16px",
          height: "0.5px",
        }}
      />
    </Box>
  );
};

export default AccountHeader;
