import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as api from "../../routes/routes";
import { openExistingChat, clearChat, setChats } from "../chats/chatSlice";
import { clearMessages } from "../chats/messagesSlice";
import { clearCache } from "../chats/cacheSlice";
import { clearPrompts } from "../chats/promptsSlice";
import { IExpert, IExpertFetchPayload, IUserDetails } from "../../types/index";
import {
  handleAxiosError,
  handleAxiosErrorWithStatus,
} from "../../app/ErrorHandler";
import { clearPersona, fetchPersona } from "./personaSlice";
import { clearExpertise, fetchExpertise } from "./expertiseSlice";
import { RootState } from "../../app/store";
import { updateActiveExpert } from "../chats/workspaceSlice";
import { updateUserDetails } from "../users/userDetailsSlice";
import { fetchIsUserDirty } from "../users/isUserDirtySlice";

const initialState = {
  loading: false as boolean,
  creating: false as boolean,
  isRemoving: false as boolean,
  experts: [] as IExpert[],
  activeExpert: null as IExpert | null,
  selectingExpert: false as boolean,
  error: "" as unknown,
};

const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const selectExpert = createAsyncThunk(
  "experts/selectExpert",
  async (expert: IExpert, { getState, dispatch, rejectWithValue }) => {
    try {
      dispatch(selectingExpert(true));
      const state = getState() as RootState;
      const userDetails = state.userDetails.userDetails as IUserDetails;
      const updatedUser = { ...userDetails, activeExpert: expert?._id };
      await dispatch(updateUserDetails(updatedUser));
      dispatch(updateActiveExpert(expert));
      dispatch(setActiveExpert(expert));

      clearState(dispatch);

      const openExistingChatPayload = {
        switchingExperts: true,
        chatId: expert?.activeChat,
      };

      await Promise.all([
        dispatch(openExistingChat(openExistingChatPayload)),
        dispatch(fetchPersona(expert)),
        dispatch(fetchExpertise(expert)),
      ]);

      dispatch(selectingExpert(false));
      return expert;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const fetchExperts = createAsyncThunk(
  "experts/fetchExperts",
  async (
    expertFetchPayload: IExpertFetchPayload,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const result = await api.fetchExperts(expertFetchPayload);
      if (result.data.length === 0) {
        return [];
      }

      const photoURLPromises = result.data.map(async (expert) => {
        const cachedImage = localStorage.getItem(expert._id);

        if (cachedImage) {
          return cachedImage;
        } else {
          const response = await api.getExpertImage(expert.photoURL);
          const base64Image = await blobToBase64(response.data);
          localStorage.setItem(expert._id, base64Image);
          return base64Image;
        }
      });

      const pictures = await Promise.all(photoURLPromises);

      const experts = result.data.map((expert, index) => {
        expert.picture = pictures[index];
        return expert;
      });

      const state = getState() as RootState;
      const userDetails = state.userDetails?.userDetails as IUserDetails;
      const activeExpertId = userDetails?.activeExpert;

      const activeExpertIndex = experts.findIndex(
        (expert) => expert._id === activeExpertId
      );

      const updatedPayload = {
        experts: [...experts] as IExpert[],
        activeExpertIndex: activeExpertIndex as number,
      };

      const currentActiveExpert = state.experts.activeExpert;
      if (!currentActiveExpert) {
        const activeExpert = experts[activeExpertIndex] as IExpert;
        dispatch(selectExpert(activeExpert));
      }

      return updatedPayload;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const createExpert = createAsyncThunk(
  "experts/createExpert",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const userDetails = state.userDetails.userDetails;
      const userId = userDetails.user;
      const result = await api.createExpert(userId);
      const photoURL = result.data.photoURL;
      const response = await api.getExpertImage(photoURL);
      const base64Image = await blobToBase64(response.data);
      const newExpert = result.data as IExpert;
      newExpert.picture = base64Image;

      // newExpert has an activeChat but it hasn't been set yet. We'll now selectExpert to set it.
      await dispatch(fetchIsUserDirty());
      await dispatch(selectExpert(newExpert));
      return newExpert;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const updateExpert = createAsyncThunk(
  "experts/updateExpert",
  async (updatedExpert: IExpert, { getState, rejectWithValue }) => {
    try {
      const { picture, ...expertWithoutPicture } = updatedExpert;

      const result = await api.updateExpert(expertWithoutPicture as IExpert);

      const state = getState() as RootState;
      const expertsSlice = state.experts;
      const payload = result.data;

      if (expertsSlice.activeExpert?._id === payload._id) {
        const photoURL = payload.photoURL;
        if (expertsSlice.activeExpert?.photoURL !== photoURL) {
          const response = await api.getExpertImage(photoURL);
          const base64Image = await blobToBase64(response.data);
          payload.picture = base64Image;
        } else {
          payload.picture = updatedExpert.picture;
        }
      }

      return payload;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const fetchExpert = createAsyncThunk(
  "experts/fetchExpert",
  async (expertId: string, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const expertsSlice = state.experts;

      const result = await api.fetchExpert(expertId);
      const fetchedExpert = result.data;

      // Preserve the picture if it's already set
      if (expertsSlice.activeExpert?._id === fetchedExpert._id) {
        fetchedExpert.picture =
          expertsSlice.activeExpert.picture || fetchedExpert.picture;
      }

      return fetchedExpert;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const deleteExpert = createAsyncThunk(
  "experts/deleteExpert",
  async (id: string, { getState, dispatch, rejectWithValue }) => {
    try {
      const result = await api.deleteExpert(id);
      if (result.status === 200) {
        const state = getState() as RootState;
        const experts = state.experts?.experts;
        if (experts && experts.length > 0) {
          /// can delete expert

          const expertToDeleteIndex = experts.findIndex(
            (expert) => expert._id === id
          );
          // get a copy of the experts array without the expert to delete
          const updatedExperts = experts.filter((expert) => expert._id !== id);
          dispatch(setExperts(updatedExperts));

          const expertIndex = experts?.findIndex((expert) => expert._id !== id);
          const openExpert = experts[expertIndex];

          await dispatch(selectExpert(openExpert));
        }
        return "success";
      } else {
        console.log("failed to delete: ", result);
        return "unsuccessful";
      }
    } catch (error) {
      return rejectWithValue(handleAxiosErrorWithStatus(error));
    }
  }
);

export const expertsSlice = createSlice({
  name: "experts",
  initialState: initialState,
  reducers: {
    setActiveExpert: (state, action: PayloadAction<IExpert>) => {
      state.activeExpert = action.payload;
    },
    clearActiveExpert: (state) => {
      state.loading = false;
      state.activeExpert = null;
      state.error = "";
    },
    clearExperts: (state) => {
      state.loading = false;
      state.experts = [];
      state.error = "";
    },
    setIsRemoving: (state, action: PayloadAction<boolean>) => {
      state.isRemoving = action.payload;
    },
    selectingExpert: (state, action: PayloadAction<boolean>) => {
      state.selectingExpert = action.payload;
    },
    setActiveChatForExpert: (state, action: PayloadAction<string>) => {
      state.activeExpert.activeChat = action.payload;
    },
    setActiveCacheForExpert: (state, action: PayloadAction<string>) => {
      state.activeExpert.activeCache = action.payload;
    },
    setExperts: (state, action: PayloadAction<IExpert[]>) => {
      state.experts = action.payload;
    },
    setReplyQueueCountForExpert: (
      state,
      action: PayloadAction<{ expertId: string; newCount: number }>
    ) => {
      state.experts.map((expert) => {
        if (expert._id === action.payload.expertId) {
          expert.replyQueueCount = action.payload.newCount;
        }
        return expert;
      });

      if (state.activeExpert?._id === action.payload.expertId) {
        state.activeExpert.replyQueueCount = action.payload.newCount;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createExpert.pending, (state) => {
      state.creating = true;
    });
    builder.addCase(
      createExpert.fulfilled,
      (state, action: PayloadAction<IExpert>) => {
        state.experts.unshift(action.payload);
        state.creating = false;
      }
    );
    builder.addCase(createExpert.rejected, (state, action) => {
      state.creating = false;
      state.error = action.error.message;
    });
    builder.addCase(fetchExperts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchExperts.fulfilled, (state, action) => {
      state.loading = false;
      const { activeExpertIndex, experts } = action.payload as {
        experts: IExpert[];
        activeExpertIndex: number;
      };

      if (experts && experts !== undefined && experts.length > 0) {
        if (activeExpertIndex !== -1) {
          state.activeExpert = experts[activeExpertIndex];
        }

        if (activeExpertIndex !== -1) {
          const activeExpert = experts.splice(activeExpertIndex, 1)[0];
          experts.push(activeExpert);
        }

        state.experts = experts.reverse();
      } else {
        state.experts = [];
      }

      state.error = "";
    });
    builder.addCase(fetchExperts.rejected, (state, action) => {
      state.loading = false;
      state.experts = [];
      state.error = action.error.message;
    });

    builder.addCase(
      updateExpert.fulfilled,
      (state, action: PayloadAction<IExpert>) => {
        const newExperts = state.experts.map((expert) =>
          expert._id === action.payload._id ? action.payload : expert
        );
        state.experts = newExperts;
        if (state.activeExpert?._id === action.payload._id) {
          state.activeExpert = action.payload;
        }
      }
    );
    builder.addCase(fetchExpert.fulfilled, (state, action) => {
      if (state.activeExpert?._id === action.payload._id) {
        const newExperts = state.experts.map((expert) =>
          expert._id === action.payload._id ? action.payload : expert
        );
        state.experts = newExperts;
        if (state.activeExpert?._id === action.payload._id) {
          state.activeExpert = action.payload;
        }
      }
    });
    builder.addCase(
      deleteExpert.fulfilled,
      (state, action: PayloadAction<string>) => {
        const newExperts = state.experts.filter(
          (expert) => expert._id !== action.payload
        );
        state.experts = newExperts;
      }
    );
    builder.addCase(
      selectExpert.fulfilled,
      (state, action: PayloadAction<IExpert>) => {
        state.loading = false;
        state.activeExpert = action.payload;
        state.error = "";
      }
    );
  },
});

export const {
  setActiveExpert,
  clearActiveExpert,
  clearExperts,
  setIsRemoving,
  selectingExpert,
  setActiveChatForExpert,
  setActiveCacheForExpert,
  setExperts,
  setReplyQueueCountForExpert,
} = expertsSlice.actions;

export default expertsSlice.reducer;

function clearState(dispatch) {
  dispatch(setChats([]));
  dispatch(selectingExpert(true));
  dispatch(clearChat());
  dispatch(clearMessages());
  dispatch(clearCache());
  dispatch(clearPrompts());
  dispatch(clearExpertise());
  dispatch(clearPersona());
}
