import { IExpert } from "../../../../types";

export const getSortedExperts = (experts: IExpert[], activeExpert: IExpert) => {
  const sortedExperts = [...experts].sort((a: IExpert, b: IExpert) => {
    // 1. Active expert at the top
    if (activeExpert?._id === a._id) return -1;
    if (activeExpert?._id === b._id) return 1;

    // 2. Sort by lastUpdated if both have it
    if (a.lastUpdated && b.lastUpdated) {
      return (
        new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime()
      );
    }

    // 3. If one has lastUpdated and the other doesn't, prioritize the one with lastUpdated
    if (a.lastUpdated) return -1;
    if (b.lastUpdated) return 1;

    // 4. Sort by createdAt if lastUpdated is missing for both
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  return sortedExperts;
};
