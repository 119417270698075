import React, { useEffect } from "react";
import { Box, Divider, Typography, Skeleton, alpha } from "@mui/material";
import { Chat, Profile, Prompt } from "../../../../icons";
import AccountPopup from "../../../popups/account-popup/AccountPopup";
import ExpertAvatar from "../../../ui-elements/ExpertAvatar";
import { useTheme } from "@mui/material/styles";
import PrimaryIcon from "../../../ui-elements/PrimaryIcon";
import { useAppSelector } from "../../../../app/store";
import {
  ActiveLayout,
  ActiveView,
} from "../../../../features/chats/workspaceSlice";
import LayoutToggleIcon from "../../../ui-elements/LayoutToggleIcon";
import useScreenSize from "../../../ui-elements/useScreenSize";

interface TitleBarProps {
  handleIconClick: (iconName: string) => void;
  handleExpertClick: () => void;
}

const TitleBar: React.FC<TitleBarProps> = ({
  handleIconClick,
  handleExpertClick,
}) => {
  const theme = useTheme();
  const screenSize = useScreenSize();

  const workspaceSlice = useAppSelector((state) => state.workspace);
  const activeExpert = useAppSelector((state) => state.experts.activeExpert);
  const activeView = workspaceSlice.activeView;

  useEffect(() => {
    //console.log("active expert changed");
  }, [activeExpert]);

  const getIconNameForSelectedView = () => {
    switch (activeView) {
      case ActiveView.CHAT:
        return "Chat";
      case ActiveView.CACHE:
        return "Cache";
      case ActiveView.PROFILE:
        return "Profile";
      default:
        return "Chat";
    }
  };

  const showCacheIcon = () => {
    return (
      (screenSize.isFull &&
        workspaceSlice.activeLayout === ActiveLayout.THREE) ||
      screenSize.isLarge
    );
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "50px",
        marginTop: screenSize.isLarge ? "4px" : "72px",
        paddingRight: "6px",
        paddingLeft: "8px",
      }}
    >
      {activeExpert ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <ExpertAvatar
            expert={activeExpert}
            handleExpertClick={handleExpertClick}
            backgroundSize={40}
          />
          {!screenSize.isLarge && (
            <Typography
              sx={{
                textAlign: "left",
                color: theme.palette.text.secondary,
                paddingLeft: "16px",
                fontSize: "20px",
                fontWeight: 700,
                marginLeft: "0px",
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
              component="div"
            >
              {activeExpert.name}
            </Typography>
          )}
        </Box>
      ) : (
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Skeleton
            sx={{
              borderRadius: "50%",
              overflow: "hidden",
              height: "40px !important",
              width: "24px !important",
              marginRight: "12px",
            }}
          />
          <Skeleton
            sx={{
              textAlign: "left",
              color: theme.palette.text.secondary,
              paddingLeft: "16px",
              fontSize: "24px",
              fontWeight: 700,
              marginLeft: "0px",
              height: "54px",
              display: "flex",
              alignItems: "center",
            }}
            width={"80px"}
            height={"32px"}
          />
        </Box>
      )}
      {!screenSize.isSmall && (
        <Divider
          sx={{
            backgroundColor: alpha(theme.palette.background.divider, 0.5),
            marginLeft: "24px",
            height: "40px",
            width: "1px",
          }}
          orientation="vertical"
        />
      )}

      <Box display="flex" alignItems="center" marginLeft="16px">
        <PrimaryIcon
          iconName="Profile"
          icon={<Profile />}
          handleIconClick={handleIconClick}
          selectedIcon={getIconNameForSelectedView()}
          selectedColorOverride={activeExpert?.color}
        />
        <PrimaryIcon
          iconName="Chat"
          icon={<Chat />}
          handleIconClick={handleIconClick}
          selectedIcon={getIconNameForSelectedView()}
          selectedColorOverride={activeExpert?.color}
        />
        {showCacheIcon() && (
          <PrimaryIcon
            iconName="Cache"
            icon={<Prompt />}
            handleIconClick={handleIconClick}
            selectedIcon={getIconNameForSelectedView()}
            selectedColorOverride={activeExpert?.color}
          />
        )}
      </Box>
      <Box flex={1} />
      {screenSize.isFull && <LayoutToggleIcon />}
      {screenSize.isLarge && <AccountPopup />}
    </Box>
  );
};

export default TitleBar;
