import React, { useState, useEffect } from "react";
import { Box, Typography, LinearProgress, Avatar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { keyframes } from "@emotion/react";
import defaultImage from "../../../images/defaultImage.png";
import { useAppSelector } from "../../../app/store";

interface LoadingBarProps {
  label: string;
  showLoading: boolean;
  completeAnimation: () => void;
}

const pulseAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
`;

const LoadingBar: React.FC<LoadingBarProps> = ({
  label,
  showLoading,
  completeAnimation,
}) => {
  const theme = useTheme();
  const workspaceSlice = useAppSelector((state) => state.workspace);
  const [progress, setProgress] = useState(0);
  const [animating, setAnimating] = useState(false); // [TODO
  const [pulse, setPulse] = useState(false);

  useEffect(() => {
    if (showLoading && animating) return;

    if (showLoading) {
      setPulse(false);
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress >= 100) {
            clearInterval(timer);
            completeAnimation();
            return 0;
          }
          if (oldProgress >= 80 && !showLoading) return oldProgress;
          if (oldProgress >= 80) {
            setPulse(true);
            return oldProgress;
          }
          return Math.min(oldProgress + 20, 100);
        });
      }, 200);
      return () => {
        clearInterval(timer);
      };
    } else {
      if (animating) {
        setAnimating(false);
        setProgress(0);
        // delay to allow animation to complete
        setTimeout(() => {
          setPulse(false);
          setProgress(100);
          completeAnimation();
        }, 1000);
      }
    }
  }, [showLoading, completeAnimation]);

  useEffect(() => {
    if (progress >= 100) {
      completeAnimation();
    }
  }, [progress, completeAnimation]);

  return (
    <Box
      sx={{
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        display={"flex"}
        sx={{
          backgroundColor: theme.palette.primary.main,
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <Avatar
          src={defaultImage}
          sx={{
            width: `${(120 * 90) / 100}px`,
            height: `${(120 * 90) / 100}px`,
          }}
        />
      </Box>
      <Typography
        sx={{
          fontSize: "24px",
          fontWeight: 600,
          marginBottom: "16px",
        }}
      >
        {label}
      </Typography>
      <Box
        sx={{
          height: "24px",
          width: "40%",
          borderRadius: "16px",
          backgroundColor: theme.palette.background.backdrop,
          position: "relative",
        }}
      >
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            height: "100%",
            borderRadius: "inherit",
            backgroundColor: theme.palette.background.buttonHighlighted,
            animation: pulse ? `${pulseAnimation} 1s infinite` : "none",
          }}
        />
      </Box>
    </Box>
  );
};

export default LoadingBar;
