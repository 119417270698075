import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { Box, Grid, useMediaQuery, Typography } from "@mui/material";
import { IExpert } from "../../../../../../types/index";
import { Input } from "../../../../../ui-elements";
import { InputData } from "../../../../../ui-elements/CustomInput";
import PrimaryIcon from "../../../../../ui-elements/PrimaryIcon";
import {
  ColorBrush,
  ColorHandle,
  Credit,
  Shuffle,
} from "../../../../../../icons/index";
import { useTheme } from "@mui/material/styles";
import { MenuItem } from "../../../../../ui-elements/menu-tabs/MenuItemEnums";
import { useAppDispatch } from "../../../../../../app/store";
import { updateExpert } from "../../../../../../features/experts/expertsSlice";
import { alpha } from "@mui/system";
import useScreenSize from "../../../../../ui-elements/useScreenSize";
import { UtilityService } from "../../../../../../services/UtilityService";

interface BodyHeaderProps {
  expert?: IExpert;
  selectedTab: MenuItem;
}

const BodyHeader: React.FC<BodyHeaderProps> = ({ expert, selectedTab }) => {
  const screenSize = useScreenSize();
  const isSmallScreen = screenSize.isSmall;
  const isMobile = UtilityService.getIsMobile();
  const breakRoundCorners = useMediaQuery("(max-width: 330px)");
  const creditButtonRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState("100%");
  const [showIcon, setShowIcon] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const expertColor = expert?.color;
  const picture = expert?.picture;
  const dispatch = useAppDispatch();
  const theme = useTheme();

  useLayoutEffect(() => {
    setButtonWidth(getJobInputWidth());
  }, [creditButtonRef]);

  useLayoutEffect(() => {
    setButtonWidth(getJobInputWidth());
  }, [selectedTab]);

  const handleSubmitInput = async (inputData: InputData) => {
    const { name, value } = inputData;
    if (name === "name") {
      await dispatch(updateExpert({ ...expert, name: value }));
    } else if (name === "job") {
      await dispatch(updateExpert({ ...expert, job: value }));
    }
  };

  const shuffleExpertImage = () => {
    const updatedExpert = { ...expert, photoURL: "random" };
    dispatch(updateExpert(updatedExpert));
  };

  const handleMouseEnter = () => {
    if (isMobile) return;
    setShowIcon(true);
  };

  const handleMouseLeave = () => {
    if (isMobile) return;
    setShowIcon(false);
  };

  const handleMouseDown = () => {
    if (isMobile) return;
    setIsMouseDown(true);
  };

  const handleMouseUp = () => {
    if (isMobile) return;
    setIsMouseDown(false);
    setIsHovered(false);
  };

  function getJobInputWidth() {
    if (creditButtonRef) {
      return "100%";
    } else {
      const width = `calc(100% - 84px)`;
      return width;
    }
  }

  const clickColorPicker = async () => {
    const updatedExpert = { ...expert, color: "random" };
    dispatch(updateExpert(updatedExpert));
  };

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    // console.log("isHovered", isHovered);
  }, [isHovered]);

  const primaryInputForTab = () => {
    switch (selectedTab) {
      case MenuItem.Persona:
        return (
          <>
            <Grid item width={"calc(100% - 48px)"}>
              <Input
                startingValue={expert?.name}
                name="name"
                label="Name"
                selectedColorOverride={expertColor}
                handleSubmit={handleSubmitInput}
              />
            </Grid>
            <Grid item>
              <Box
                width={"40px"}
                height={"40px"}
                sx={{
                  display: "flex",
                  borderRadius: "8px",
                  "&:hover": !isMobile && {
                    backgroundColor: theme.palette.background.buttonHighlighted,
                    transition: "padding 0.2s ease-in-out",
                  },
                }}
                onClick={clickColorPicker}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                onMouseEnter={() => setIsHovered(true)}
              >
                <PrimaryIcon
                  iconName={"Random Color"}
                  icon={<ColorHandle />}
                  size="40px"
                  animated={false}
                  sx={{
                    transform: isMouseDown
                      ? "scale(0.8)"
                      : isHovered
                      ? "scale(1.1)"
                      : "scale(1)",
                    transition: "transform 0.05s ease-in-out",
                  }}
                />

                <PrimaryIcon
                  iconName={"Random Color"}
                  defaultColorOverride={expertColor}
                  icon={<ColorBrush />}
                  size="40px"
                  disabled={true}
                  sx={{
                    left: "-44px",
                    transform: isMouseDown
                      ? "scale(0.8)"
                      : isHovered
                      ? "scale(1.1)"
                      : "scale(1)",
                    transition: "transform 0.05s ease-in-out",
                  }}
                  animated={false}
                />
              </Box>
            </Grid>
          </>
        );

      case MenuItem.Expertise:
        return (
          <>
            <Grid item width={buttonWidth}>
              <Input
                startingValue={expert?.job}
                name="job"
                label="Job"
                handleSubmit={handleSubmitInput}
                selectedColorOverride={expertColor}
              />
            </Grid>
          </>
        );
      case MenuItem.Stats:
        return <></>;
      default:
        return <></>;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "55px",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          position: "relative",
          display: "flex",
          backgroundColor: expertColor,
          borderRadius: breakRoundCorners ? "0" : "26px",
          width: "100%",
          maxWidth: breakRoundCorners ? "330px" : "300px",
          height: "160px",
          justifyContent: "center",
        }}
      >
        <Box
          component="img"
          src={picture}
          alt="Expert Profile"
          sx={{
            position: "relative",
            bottom: "40px",

            height: "200px",
            width: "200px",
            borderRadius: "50px 50px 0px 0px",
          }}
        />
        {showIcon && (
          <Box
            sx={{
              position: "absolute",
              bottom: "8px",
              right: "8px",
              cursor: "pointer",
            }}
          >
            <PrimaryIcon
              iconName={"Random Image"}
              icon={<Shuffle />}
              size="32px"
              animated={true}
              defaultColorOverride={theme.palette.colors.grayScale.white}
              highlightedColorOverride={alpha(
                theme.palette.colors.grayScale.black,
                0.2
              )}
              handleIconClick={() => {
                shuffleExpertImage();
              }}
            />
          </Box>
        )}
      </Box>
      <Grid
        container
        spacing={0}
        sx={{
          display: "flex",
          marginTop: "16px",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "center",
          width: isSmallScreen ? "calc(100% - 24px)" : "100%",
          maxWidth: "300px",
          marginLeft: isSmallScreen ? "24px" : "16px",
          marginRight: "16px",
        }}
      >
        {primaryInputForTab()}
      </Grid>
    </Box>
  );
};

export default BodyHeader;
