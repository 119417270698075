import React, { useEffect, useState } from "react";
import {
  IconButton,
  Typography,
  Divider,
  Container,
  Box,
  ClickAwayListener,
  CircularProgress,
} from "@mui/material";
import { Checkbox, CheckboxChecked, Delete } from "../../../../../icons";
import { useTheme } from "@mui/material/styles";
import { alpha } from "@mui/material";
import { IPrompt } from "../../../../../types";
import { PrimaryIcon } from "../../../../ui-elements";

interface PromptCellProps {
  prompt: IPrompt;
  isSelected: boolean;
  onSelect: () => void;
  onClickAway: () => void;
  handleCheckboxChecked: (prompt: IPrompt) => void;
  adjustCheckboxForMediumScreen?: boolean;
  handleDelete: (prompt: IPrompt) => void;
  expertColor: string;
}

const PromptCell: React.FC<PromptCellProps> = ({
  prompt,
  isSelected,
  onSelect,
  onClickAway,
  handleCheckboxChecked,
  adjustCheckboxForMediumScreen = false,
  handleDelete,
  expertColor,
}) => {
  const theme = useTheme();

  const [checked, setChecked] = useState(prompt.isActive);
  const [disabled, setDisabled] = useState(false);
  const [isTapped, setIsTapped] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setChecked(prompt.isActive);
  }, [prompt.isActive]);

  const handleCheckboxChange = async () => {
    if (!disabled) {
      setDisabled(true);
      const updatedPrompt = { ...prompt, isActive: !checked };
      await handleCheckboxChecked(updatedPrompt);
      setDisabled(false);
    }
  };

  const handleSelect = () => {
    setIsTapped(!isTapped);
    onSelect();
  };

  const handleClickAway = () => {
    setIsTapped(false);
    onClickAway();
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        display="flex"
        width={"100%"}
        flexDirection={"column"}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            paddingBottom: "16px",
            paddingLeft: adjustCheckboxForMediumScreen ? "24px" : "0px",
            paddingRight: "0px",
            marginLeft: "0px",
            position: "relative",
          }}
          onClick={handleSelect}
        >
          <Box
            sx={{
              position: "absolute",
              top: "-8px",
              left: "16px",
              right: "-8px",
              bottom: "8px",
              borderRadius: "8px",
              backgroundColor: isTapped
                ? alpha(theme.palette.common.white, 0.05)
                : "transparent",
            }}
          />
          <IconButton
            onClick={handleCheckboxChange}
            disableTouchRipple
            sx={{
              color: theme.palette.text.secondary,
              height: "32px",
              width: "32px",
              padding: "6px",
              borderRadius: "8px",
            }}
          >
            {checked ? <CheckboxChecked /> : <Checkbox />}
          </IconButton>
          <Typography
            sx={{
              fontSize: "12px",
              marginLeft: "10px",
              width: "auto",
              maxWidth: "100%",
              fontWeight: "500",
              display: "-webkit-box",
              padding: 0,
              marginTop: 0,
              marginBottom: 0,
              border: "none",
              color: theme.palette.text.secondary,
              marginRight: "60px",
              lineHeight: "20px",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordWrap: "break-word",
            }}
          >
            <span
              style={{
                color: theme.palette.text.secondary,
                fontWeight: "700",
                marginRight: "2px",
                alignItems: "flex-end",
              }}
            >
              {prompt.words + " "}
            </span>
            <span>{prompt.message}</span>
          </Typography>
          {prompt.deleting ? (
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"flex-end"}
              sx={{ position: "absolute", right: "16px" }}
            >
              <CircularProgress
                thickness={6}
                size={16}
                sx={{ color: expertColor }}
              />
            </Box>
          ) : (
            (isHovered || isTapped) && (
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
                sx={{ position: "absolute", right: "0px" }}
              >
                <PrimaryIcon
                  iconName={"Delete Prompt"}
                  icon={<Delete />}
                  handleIconClick={() => handleDelete(prompt)}
                />
              </Box>
            )
          )}
        </Container>
        <Divider
          orientation="horizontal"
          sx={{
            height: "1px",
            borderColor:
              theme.palette.mode === "dark"
                ? theme.palette.background.buttonHighlighted
                : alpha(theme.palette.background.divider, 0.9),
            width: "calc(100% - 8px)",
            marginLeft: "16px",
          }}
        />
      </Box>
    </ClickAwayListener>
  );
};

export default PromptCell;
