import { Middleware } from "@reduxjs/toolkit";
import { socket } from "../SocketProvider";
import { MessageService } from "./socket-services/MessageService";
import { TranscriptionService } from "./socket-services/TranscriptionService";
import { ExpertiseService } from "./socket-services/ExpertiseService";
import { UserAuthService } from "./socket-services/UserAuthService";

const socketMiddleware: Middleware = (storeAPI) => {
  let isEventsRegistered = false;
  const messageService = new MessageService(storeAPI);
  const transcriptionService = new TranscriptionService(storeAPI);
  const expertiseService = new ExpertiseService(storeAPI);
  const userAuthService = new UserAuthService(storeAPI);

  return (next) => (action) => {
    const userId = storeAPI.getState().userAuth.userAuth?.user?._id;

    if (!socket) {
      isEventsRegistered = false;
      return next(action);
    }

    if (!userId && isEventsRegistered) {
      // Unregister events if the user logs out
      messageService.unregisterEvents();
      transcriptionService.unregisterEvents();
      expertiseService.unregisterEvents();
      userAuthService.unregisterEvents();
      isEventsRegistered = false;
      return next(action);
    }

    if (userId && !isEventsRegistered) {
      // Register events when the user logs in
      messageService.registerEvents();
      transcriptionService.registerEvents();
      expertiseService.registerEvents();
      userAuthService.registerEvents();
      isEventsRegistered = true;
    }

    if (typeof action === "function") {
      return action(storeAPI.dispatch, storeAPI.getState);
    }

    // Centralized action handling
    action.userId = userId;
    messageService.handleActions(action);
    transcriptionService.handleActions(action);
    expertiseService.handleActions(action);
    userAuthService.handleActions(action);

    return next(action);
  };
};

export default socketMiddleware;
