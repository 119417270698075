import { Snackbar, Alert } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { hideDebugSnackbar } from "../../features/ui/debugSnackbarSlice";

const DebugSnackbar = () => {
  const dispatch = useAppDispatch();
  const { open, message, severity } = useAppSelector(
    (state) => state.debugSnackbar
  );

  const handleClose = () => {
    dispatch(hideDebugSnackbar());
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default DebugSnackbar;
