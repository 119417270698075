import React from "react";
import {
  Box,
  Button,
  darken,
  lighten,
  Typography,
  useTheme,
} from "@mui/material";
import PopupBase from "./PopupBase";
import { useAppSelector } from "../../app/store";

interface CancelPopupProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  description?: string;
  confirmText?: string;
  cancelText?: string;
  featureCancel?: boolean;
}

const CancelPopup: React.FC<CancelPopupProps> = ({
  open,
  onClose,
  onConfirm,
  title = "Are You Sure?",
  description = "Are you sure you want to cancel your subscription? This action cannot be undone.",
  confirmText = "Confirm",
  cancelText = "JK. Keep it.",
  featureCancel = true,
}) => {
  const theme = useTheme();
  const themeSlice = useAppSelector((state) => state.theme);
  const isDarkMode = themeSlice.isDarkMode;

  const FeaturedButton = () => {
    return (
      <Button
        variant="contained"
        sx={{
          color: theme.palette.colors.grayScale.white,
          backgroundColor: theme.palette.primary.main,
          textTransform: "none",
          borderRadius: "8px",
          padding: "8px 16px",
        }}
        onClick={featureCancel ? onClose : onConfirm}
      >
        {featureCancel ? cancelText : confirmText}
      </Button>
    );
  };

  const NormalButton = () => {
    return (
      <Typography
        fontSize={"14px"}
        fontWeight={400}
        color={theme.palette.text.secondary}
        sx={{
          cursor: "pointer",
          "&:hover": {
            color: isDarkMode
              ? lighten(theme.palette.text.secondary, 0.3)
              : darken(theme.palette.text.secondary, 0.8),
          },
        }}
        onClick={featureCancel ? onConfirm : onClose}
      >
        {featureCancel ? confirmText : cancelText}
      </Typography>
    );
  };

  return (
    <PopupBase open={open} onClose={onClose}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        maxWidth={"400px"}
      >
        <Typography variant="h6">{title}</Typography>
        <Typography
          fontSize="14px"
          textAlign={"center"}
          marginBottom={"32px"}
          marginTop={"16px"}
        >
          {description}
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          alignItems={"center"}
          width="100%"
          mt={2}
        >
          {featureCancel ? <FeaturedButton /> : <NormalButton />}
          <Box width={"16px"} />
          {featureCancel ? <NormalButton /> : <FeaturedButton />}
        </Box>
      </Box>
    </PopupBase>
  );
};

export default CancelPopup;
