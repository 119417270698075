export class NumberFormatterService {
  static formatNumber(number: number): string {
    if (number < 1000) {
      return number.toString();
    }
    if (number < 1000000) {
      return (number / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    }
    if (number < 1000000000) {
      return (number / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    }
    return (number / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
  }

  static formatNumberWithComma(number: string | number): string {
    const roundedNumber =
      Math.round(parseFloat(number.toString().replace(/,/g, "")) * 10) / 10;

    if (roundedNumber < 0.1) {
      if (roundedNumber === 0) {
        return "0";
      }

      return "<0.1";
    }

    return roundedNumber.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    });
  }

  static formatCurrency(number: number): string {
    return "$" + number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  }

  static formatPercentage(number: number): string {
    return number.toFixed(0) + "%";
  }
}
