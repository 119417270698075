import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../routes/routes";
import { handleAxiosError } from "../../app/ErrorHandler";
import { IUserPreferences } from "../../types/user/IUserPreferences";
import { LocalStorageService } from "../../services/LocalStorageService";
import { setDarkMode } from "../ui/themeSlice";

const initialState = {
  userPreferences: LocalStorageService.get(
    "userPreferences"
  ) as IUserPreferences | null,
};

export const loadUserPreferences = createAsyncThunk(
  "userPreferences/loadUserPreferences",
  async (userPreferencesId: string, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.loadUserPreferences(userPreferencesId);
      dispatch(setUserPreferences(data));
      LocalStorageService.set("userPreferences", data);
      dispatch(setDarkMode(data?.prefersDarkMode));
      return data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const updateUserPreferences = createAsyncThunk(
  "userPreferences/updateUserPreferences",
  async (userPreferences: IUserPreferences, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.updateUserPreferences(userPreferences);
      dispatch(setUserPreferences(data));
      LocalStorageService.set("userPreferences", data);
      return data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const clearUserPreferences = createAsyncThunk(
  "userPreferences/clearUserPreferences",
  async (_, { dispatch }) => {
    LocalStorageService.remove("userPreferences");
    dispatch(setUserPreferences(null));
  }
);

export const userPreferencesSlice = createSlice({
  name: "userPreferences",
  initialState: initialState,
  reducers: {
    setUserPreferences: (state, action) => {
      state.userPreferences = action.payload;
    },
  },
});

export const { setUserPreferences } = userPreferencesSlice.actions;

export default userPreferencesSlice.reducer;
