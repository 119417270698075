import { useTheme } from "@mui/material";

export const usePrismTheme = () => {
  const theme = useTheme();
  const { colors } = theme.palette;

  // Constants for easy tweaking
  const FONT_FAMILY = "Poppins, monospace";
  const FONT_SIZE = "0.8rem";
  const LINE_HEIGHT = "1.5em";
  const BACKGROUND_COLOR = colors.grayScale[900];
  const TEXT_COLOR = colors.grayScale[50];

  // Syntax highlighting colors
  const COMMENT_COLOR = colors.green[300];
  const PUNCTUATION_COLOR = colors.grayScale[200];
  const PROPERTY_COLOR = colors.blue[300];
  const TAG_COLOR = colors.red[300];
  const BOOLEAN_COLOR = colors.purple[300];
  const NUMBER_COLOR = colors.orange[300];
  const STRING_COLOR = colors.green[300];
  const OPERATOR_COLOR = colors.magenta[300];
  const KEYWORD_COLOR = colors.pink[300];
  const FUNCTION_COLOR = colors.skyBlue[300];
  const VARIABLE_COLOR = colors.tangerine[300];

  const baseStyle = {
    fontFamily: FONT_FAMILY,
    fontSize: FONT_SIZE,
    lineHeight: LINE_HEIGHT,
    direction: "ltr",
    textAlign: "left",
    whiteSpace: "pre",
    wordSpacing: "normal",
    wordBreak: "normal",
    MozTabSize: "4",
    OTabSize: "4",
    tabSize: "4",
    WebkitHyphens: "none",
    MozHyphens: "none",
    msHyphens: "none",
    hyphens: "none",
  };

  return {
    'code[class*="language-"]': {
      ...baseStyle,
      color: TEXT_COLOR,
    },
    'pre[class*="language-"]': {
      ...baseStyle,
      color: TEXT_COLOR,
      padding: "1em",
      margin: "0",
      overflow: "auto",
      background: BACKGROUND_COLOR,
    },
    comment: { color: COMMENT_COLOR },
    prolog: { color: COMMENT_COLOR },
    doctype: { color: COMMENT_COLOR },
    cdata: { color: COMMENT_COLOR },
    punctuation: { color: PUNCTUATION_COLOR },
    namespace: { opacity: 0.7 },
    property: { color: PROPERTY_COLOR },
    tag: { color: TAG_COLOR },
    boolean: { color: BOOLEAN_COLOR },
    number: { color: NUMBER_COLOR },
    constant: { color: BOOLEAN_COLOR },
    symbol: { color: NUMBER_COLOR },
    selector: { color: TAG_COLOR },
    "attr-name": { color: PROPERTY_COLOR },
    string: { color: STRING_COLOR },
    char: { color: STRING_COLOR },
    builtin: { color: PROPERTY_COLOR },
    inserted: { color: STRING_COLOR },
    operator: { color: OPERATOR_COLOR },
    entity: { color: PROPERTY_COLOR, cursor: "help" },
    url: { color: PROPERTY_COLOR },
    ".language-css .token.string": { color: TAG_COLOR },
    ".style .token.string": { color: TAG_COLOR },
    atrule: { color: KEYWORD_COLOR },
    "attr-value": { color: STRING_COLOR },
    keyword: { color: KEYWORD_COLOR },
    function: { color: FUNCTION_COLOR },
    "class-name": { color: FUNCTION_COLOR },
    regex: { color: VARIABLE_COLOR },
    important: { color: KEYWORD_COLOR },
    variable: { color: VARIABLE_COLOR },
    bold: { fontWeight: "bold" },
    italic: { fontStyle: "italic" },
    deleted: { color: TAG_COLOR, fontStyle: "italic" },
  };
};
