import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Tooltip,
  Typography,
  alpha,
  darken,
  lighten,
  useTheme,
} from "@mui/material";
import { Attachment } from "../../../../../icons";

interface FileCardProps {
  onClick: () => void;
  fileCount: number;
  placeholderFiles?: File[];
}

const CellFileCard: React.FC<FileCardProps> = ({
  onClick,
  fileCount,
  placeholderFiles,
}) => {
  const theme = useTheme();
  useEffect(() => {
    // console.log("placeholderFiles", placeholderFiles);
  }, [placeholderFiles]);

  return (
    <Tooltip
      title={
        placeholderFiles?.length > 0
          ? `Processing files...`
          : `${fileCount} ${fileCount === 1 ? "source" : "sources"}`
      }
      placement="top"
    >
      <Grid
        item
        display={"flex"}
        onClick={onClick}
        sx={{
          justtifyContent: "center",
          maxWidth: "90px",
          textAlign: "center",
          height: `24px`,
          padding: "2px",
          margin: "4px",
          marginTop: "0px",
          "&:active": {
            transform: "scale(0.95)",
          },
          transition: "transform 0.1s ease",
          color: theme.palette.text.secondary,
          backgroundColor: alpha(theme.palette.text.secondary, 0.05),
          borderRadius: "12px",
          "&:hover": {
            cursor: "pointer",
            backgroundColor: lighten(
              alpha(theme.palette.text.secondary, 0.15),
              0.1
            ),
          },
        }}
        justifyContent={"center"}
        alignContent={"center"}
        alignItems={"center"}
      >
        <Box
          display={"flex"}
          alignContent={"center"}
          justifyContent={"center"}
          alignItems={"center"}
          paddingLeft={"4px"}
          sx={{
            height: `20px`,
            width: `20px`,
            backgroundColor: "transparent",
            borderRadius: "30%",
          }}
        >
          <Attachment />
        </Box>

        <Box
          flexDirection={"column"}
          display={"flex"}
          justifyContent={"center"}
          sx={{ width: "100%", height: "100%", padding: "4px" }}
        >
          <Typography
            fontSize={"12px"}
            fontWeight={500}
            textAlign={"left"}
            paddingRight={"2px"}
          >
            {placeholderFiles?.length > 0 ? "Loading..." : "Sources"}
          </Typography>
        </Box>
      </Grid>
    </Tooltip>
  );
};

export default CellFileCard;
