import React from "react";
import { Paper, PaperProps } from "@mui/material";
import { SxProps, Theme } from "@mui/system";

interface StyledPaperProps extends PaperProps {
  sx?: SxProps<Theme>;
}

const customBoxShadow =
  "0px 4px 6px 0px rgba(0, 0, 0, 0.1), 5px 5px 8px 0px rgba(0, 0, 0, 0.08)";

const StyledPaper: React.FC<StyledPaperProps> = (props) => {
  return (
    <Paper
      {...props}
      elevation={3}
      sx={{
        backgroundColor: (theme) => theme.palette.background.popup,
        borderRadius: "16px",
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "8px",
        paddingBottom: "8px",
        overflow: "auto",
        margin: "8px",
        boxShadow: customBoxShadow,
        ...props.sx,
      }}
    />
  );
};

export default StyledPaper;
