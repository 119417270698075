import { CompositeDecorator, EditorState } from "draft-js";

const customStyleMap = {
  PARTIAL_COMMAND: {
    fontWeight: 700,
  },
  ACTIVE_COMMAND: {
    color: "#FF8400",
    fontWeight: 700,
  },
  TEMPORARY_TRANSCRIPTION: {
    color: "rgba(0, 0, 0, 0.7)",
  },
};

export const createEmptyDecoratedEditor = (commandsSlice, expertSlice) => {
  const activeExpertColor = expertSlice?.color ?? "";
  const commands = commandsSlice.commands;

  return EditorState.createEmpty(
    createCommandDecorator(commands, activeExpertColor)
  );
};

const ActiveCommandSpan = ({ style, children }) => {
  return <span style={style}>{children}</span>;
};

const PartialCommandSpan = (props) => {
  const style = customStyleMap["PARTIAL_COMMAND"];
  return <span style={style}>{props.children}</span>;
};

const findActiveCommandText = (
  contentBlock,
  callback,
  contentState,
  commands
) => {
  const commandWords = commands.map((command) => command.command);
  const text = contentBlock.getText();
  const words = text.split(" ");
  let startIndex = 0;

  words.forEach((word, index) => {
    const endIndex = startIndex + word.length;
    const isCommandWord = commandWords.includes(word);

    if (word.startsWith("@") && isCommandWord) {
      callback(startIndex, endIndex, "ACTIVE_COMMAND");
    }

    startIndex = endIndex + 1;
  });
};

const findPartialCommandSpan = (
  contentBlock,
  callback,
  contentState,
  commands
) => {
  const commandWords = commands.map((command) => command.command);
  const text = contentBlock.getText();
  const words = text.split(" ");
  let startIndex = 0;

  words.forEach((word, index) => {
    const endIndex = startIndex + word.length;
    const isCommandWord = commandWords.includes(word);
    const isNextSpace = index < words.length - 1 || text[endIndex] === " ";
    // loop through the commandWords and check if any of the words starts with the current word
    // if so, then it's a partial command

    const isPartialCommand = commandWords.some((commandWord) =>
      commandWord.startsWith(word)
    );

    if (
      word.startsWith("@") &&
      !isNextSpace &&
      !isCommandWord &&
      isPartialCommand
    ) {
      callback(startIndex, endIndex, "PARTIAL_COMMAND");
    }

    startIndex = endIndex + 1;
  });
};

const createCommandDecorator = (commands, activeExpertColor = "") => {
  return new CompositeDecorator([
    {
      strategy: (contentBlock, callback, contentState) =>
        findActiveCommandText(contentBlock, callback, contentState, commands),
      component: (props) => (
        <ActiveCommandSpan
          {...props}
          style={{
            ...customStyleMap["ACTIVE_COMMAND"],
            color: activeExpertColor ?? customStyleMap["ACTIVE_COMMAND"].color,
          }}
        />
      ),
    },
    {
      strategy: (contentBlock, callback, contentState) =>
        findPartialCommandSpan(contentBlock, callback, contentState, commands),
      component: PartialCommandSpan,
    },
  ]);
};

export { createCommandDecorator, customStyleMap };
