import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as api from "../../routes/routes";
import { handleAxiosError } from "../../app/ErrorHandler";
import { IPersona, IFetchPersonaPayload, IExpert } from "../../types/index";

const initialState = {
  loading: false,
  activePersona: null as IPersona | null,
  error: "" as unknown,
};

export const fetchPersona = createAsyncThunk(
  "persona/fetchPersona",
  async (expert: IExpert, { rejectWithValue }) => {
    try {
      const payload = {
        expertId: expert._id,
        personaId: expert.persona,
      } as IFetchPersonaPayload;
      const result = await api.fetchPersona(payload);
      return result.data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const createPersona = createAsyncThunk(
  "persona/createPersona",
  async (newPersona: IPersona, { rejectWithValue }) => {
    try {
      const result = await api.createPersona(newPersona);
      return result.data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const updatePersona = createAsyncThunk(
  "persona/updatePersona",
  async (updatedPersona: IPersona, { rejectWithValue }) => {
    try {
      const result = await api.updatePersona(updatedPersona);
      return result.data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const deletePersona = createAsyncThunk(
  "persona/deletePersona",
  async (personaId: string, { rejectWithValue }) => {
    try {
      await api.deletePersona(personaId);
      return personaId;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const personaSlice = createSlice({
  name: "persona",
  initialState: initialState,
  reducers: {
    selectPersona: (state, action) => {
      state.activePersona = action.payload;
    },
    clearPersona: (state) => {
      state.activePersona = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPersona.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchPersona.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.activePersona = action.payload;
    });
    builder.addCase(fetchPersona.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(createPersona.fulfilled, (state, action) => {
      // handle new persona created
    });
    builder.addCase(
      updatePersona.fulfilled,
      (state, action: PayloadAction<IPersona>) => {
        state.activePersona = action.payload;
      }
    );
    builder.addCase(deletePersona.fulfilled, (state, action) => {
      // handle persona deleted
    });
  },
});

export const { selectPersona, clearPersona } = personaSlice.actions;

export default personaSlice.reducer;
