import { createSlice } from "@reduxjs/toolkit";
import { LocalStorageService } from "../../services/LocalStorageService";

const getIsDarkModeFromLocalStorage = () => {
  const userPreferences = LocalStorageService.get("userPreferences");
  const isDarkMode = userPreferences?.prefersDarkMode;
  return isDarkMode === undefined ? true : isDarkMode;
};

const initialState = {
  isDarkMode:
    getIsDarkModeFromLocalStorage() === null
      ? true
      : getIsDarkModeFromLocalStorage(),
  publicColor: "#9E00FF",
};

const themeSlice = createSlice({
  name: "theme",
  initialState: initialState,
  reducers: {
    setDarkMode: (state, action) => {
      state.isDarkMode = action.payload;
    },
    setPublicColor: (state, action) => {
      state.publicColor = action.payload;
    },
  },
});

export const { setDarkMode, setPublicColor } = themeSlice.actions;
export default themeSlice.reducer;
