import { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Modal,
  Backdrop,
  useTheme,
  Button,
} from "@mui/material";
import { keyframes } from "@emotion/react";
import { useAppDispatch } from "../../../app/store";
import { createCustomerPortalSession } from "../../../features/users/userSubscriptionSlice";
import { fetchIsUserDirty } from "../../../features/users/isUserDirtySlice";
import { useNavigate } from "react-router-dom";

const shimmer = (color) => keyframes`
  0% { background-position: -100%; }
  100% { background-position: 100%; }
`;

const PaymentFailed = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const timeoutRef = useRef<number | null>(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleUpdatePaymentClicked = () => {
    handleClose();
    navigate("/loading");
    dispatch(createCustomerPortalSession());
  };

  useEffect(() => {
    pollForIsUserDirty();
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const pollForIsUserDirty = async () => {
    await dispatch(fetchIsUserDirty());
    timeoutRef.current = window.setTimeout(pollForIsUserDirty, 5000);
  };

  return (
    <>
      <Box
        sx={{
          fontWeight: 700,
          padding: "8px 16px",
          backgroundColor: theme.palette.colors.red[500],
          color: "white",
          cursor: "pointer",
          animation: `${shimmer(
            theme.palette.colors.red[500]
          )} 2s linear infinite`,
          background: `linear-gradient(to right, ${theme.palette.colors.red[500]} 20%, ${theme.palette.colors.red[300]} 40%, ${theme.palette.colors.red[500]} 60%)`,
          backgroundSize: "200% 100%",
          borderRadius: "8px",
          "&:hover": {
            animationPlayState: "paused",
          },
        }}
        onClick={handleOpen}
      >
        Payment Failed!
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          sx: {
            backdropFilter: "blur(10px)",
          },
        }}
        aria-labelledby="payment-failed-modal"
        aria-describedby="payment-failed-description"
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "16px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            id="payment-failed-modal"
            variant="h6"
            component="h2"
            textAlign={"center"}
          >
            Payment Failed
          </Typography>
          <Typography
            id="payment-failed-description"
            sx={{ mt: 2, fontSize: "12px", textAlign: "center" }}
          >
            The most recent payment attempt has failed. The account is currently
            suspended until this is fixed. Please click on the button below to
            update your payment details.
          </Typography>
          <Button
            sx={{
              mt: 4,
              background: theme.palette.colors.red[500],
              fontSize: "12px",
              fontWeight: 700,
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: theme.palette.colors.red[600],
              },
              textTransform: "none",
            }}
            variant="contained"
            disableElevation
            disableFocusRipple
            disableRipple
            onClick={() => {
              handleUpdatePaymentClicked();
            }}
          >
            Update Payment
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default PaymentFailed;
