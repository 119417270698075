import { MiddlewareAPI } from "@reduxjs/toolkit";
import { socket } from "../../SocketProvider";
import { loadUserCredentials } from "../../features/users/userCredentialsSlice";
import { fetchIsUserDirty } from "../../features/users/isUserDirtySlice";
import { showOutOfDataForSocket } from "../../features/ui/errorSlice";

export class UserAuthService {
  private storeAPI: MiddlewareAPI;

  constructor(storeAPI: MiddlewareAPI) {
    this.storeAPI = storeAPI;
  }

  // INBOUND EVENTS
  public registerEvents() {
    socket.on("user-verified", this.handleUserVerified);
    socket.on("beta-approved", this.handleBetaApproved);
    socket.on("userIsDirty", this.userIsDirty);
    socket.on("outOfData", this.handleOutOfData);
  }

  public unregisterEvents() {
    socket.off("user-verified", this.handleUserVerified);
    socket.off("beta-approved", this.handleBetaApproved);
    socket.off("userIsDirty", this.userIsDirty);
    socket.off("outOfData", this.handleOutOfData);
  }

  // OUTBOUND EVENTS
  public handleActions(action: any) {
    // Add outbound actions here
  }

  // FUNCTIONS FOR SOCKET EVENTS
  private handleUserVerified = (userCredentialsId: string) => {
    this.storeAPI.dispatch(loadUserCredentials(userCredentialsId) as any);
  };

  private handleBetaApproved = (userCredentialsId: string) => {
    this.storeAPI.dispatch(loadUserCredentials(userCredentialsId) as any);
  };

  private userIsDirty = (userIsDirty: boolean) => {
    //console.log("userIsDirty", userIsDirty);
    this.storeAPI.dispatch(fetchIsUserDirty() as any);
  };

  private handleOutOfData = (outOfData: boolean) => {
    this.storeAPI.dispatch(showOutOfDataForSocket() as any);
  };
}
