import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const useScreenSize = () => {
  const theme = useTheme();

  const isExtraSmall = useMediaQuery(theme.breakpoints.down("xs"));
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));
  const isLarge = useMediaQuery(theme.breakpoints.down("lg"));
  const isFull = useMediaQuery(theme.breakpoints.up("lg"));
  const isMobileSize = useMediaQuery(theme.breakpoints.down("lg")); // used to determine mobile ui

  return {
    isExtraSmall,
    isSmall,
    isMedium,
    isLarge,
    isFull,
    isMobileSize,
  };
};

export default useScreenSize;
