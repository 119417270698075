import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import expertsReducer from "../features/experts/expertsSlice";
import chatReducer from "../features/chats/chatSlice";
import messagesReducer from "../features/chats/messagesSlice";
import commandsReducer from "../features/commands/commandsSlice";
import cacheReducer from "../features/chats/cacheSlice";
import promptsReducer from "../features/chats/promptsSlice";
import themeReducer from "../features/ui/themeSlice";
import personaReducer from "../features/experts/personaSlice";
import expertiseReducer from "../features/experts/expertiseSlice";
import workspaceReducer from "../features/chats/workspaceSlice";
import knowledgeDocReducer from "../features/experts/knowledgeDocsSlice";
import textToSpeechReducer from "../features/audio/textToSpeechSlice";
import audioTranscriptionReducer from "../features/audio/transcriptionSlice";
import errorMessengerReducer from "../features/ui/errorSlice";
import socketMiddleware from "../socket_middleware/SocketMiddleware";
import confirmationPopupReducer from "../features/ui/confirmationPopupSlice";
import accountPageReducer from "../features/users/accountPageSlice";
import voicesReducer from "../features/audio/voicesSlice";
import userAuthReducer from "../features/users/userAuthSlice";
import userCredentialsReducer from "../features/users/userCredentialsSlice";
import userPreferencesReducer from "../features/users/userPreferencesSlice";
import userSubscriptionReducer from "../features/users/userSubscriptionSlice";
import userDetailsReducer from "../features/users/userDetailsSlice";
import pricingPageReducer from "../features/ui/pages/pricingPageSlice";
import isUserDirtyReducer from "../features/users/isUserDirtySlice";
import imageCacheReducer from "../features/images/imageCacheSlice";
import debugSnackbarReducer from "../features/ui/debugSnackbarSlice";

export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const store = configureStore({
  reducer: {
    experts: expertsReducer,
    chat: chatReducer,
    messages: messagesReducer,
    commands: commandsReducer,
    cache: cacheReducer,
    prompts: promptsReducer,
    theme: themeReducer,
    persona: personaReducer,
    expertise: expertiseReducer,
    workspace: workspaceReducer,
    knowledgeDocs: knowledgeDocReducer,
    textToSpeech: textToSpeechReducer,
    audioTranscription: audioTranscriptionReducer,
    errorMessenger: errorMessengerReducer,
    confirmationPopup: confirmationPopupReducer,
    accountPage: accountPageReducer,
    voices: voicesReducer,
    userAuth: userAuthReducer,
    userCredentials: userCredentialsReducer,
    userPreferences: userPreferencesReducer,
    userSubscription: userSubscriptionReducer,
    userDetails: userDetailsReducer,
    pricingPage: pricingPageReducer,
    isUserDirty: isUserDirtyReducer,
    imageCache: imageCacheReducer,
    debugSnackbar: debugSnackbarReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(socketMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export default store;
