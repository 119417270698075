import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { validatePayment } from "../../../features/users/userSubscriptionSlice";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { useNavigate } from "react-router-dom";
import { loadUser } from "../../../features/users/userAuthSlice";
import { hasAccessToWorkspace } from "../../../services/navigateBasedOnAuth";

function LoadingPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const userSubscriptionSlice = useAppSelector(
    (state) => state.userSubscription
  );
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [context, setContext] = useState("");
  const [hasCheckedBackNavigation, setHasCheckedBackNavigation] =
    useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get("session_id");

    if (sessionId) {
      handlePaymentValidation(sessionId);
      return;
    }

    const selectedPlanId = urlParams.get("plan_id");
    if (selectedPlanId) {
      dispatch(validatePayment(selectedPlanId));
      return;
    }

    setContext("loading");
    setTitle("One moment please");
    setBody("Loading customer portal");
  }, []);

  async function handlePaymentValidation(sessionId: string) {
    setContext("payment");
    setTitle("Welcome to Virtual Experts!");
    setBody("Preparing your workspace");
    await dispatch(validatePayment(sessionId));
    await dispatch(loadUser());
    navigate("/workspace");
  }

  useEffect(() => {
    if (context === "loading") {
      return;
    }

    const userSubscription = userSubscriptionSlice.userSubscription;
    if (hasAccessToWorkspace(userSubscription)) {
      // get parameters
      const urlParams = new URLSearchParams(window.location.search);
      // if upgrading, redirect to workspace
      if (!urlParams.get("upgrading")) {
        navigate("/workspace");
      }
    }
  }, [userSubscriptionSlice.userSubscription?.subscriptionStatus]);

  useEffect(() => {
    if (!hasCheckedBackNavigation) {
      const entries = window.performance.getEntriesByType("navigation");
      const navigationEntry = entries[0] as PerformanceNavigationTiming;

      if (navigationEntry.type === "back_forward") {
        navigate("/home"); // Redirect to home immediately if back navigation is detected
      }

      setHasCheckedBackNavigation(true);
    }
  }, [navigate, hasCheckedBackNavigation]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: theme.palette.background.backdrop,
      }}
    >
      <Typography
        variant="h4"
        textAlign={"center"}
        sx={{ marginBottom: "16px" }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        textAlign={"center"}
        sx={{ marginBottom: "48px" }}
      >
        {body}
      </Typography>
      <CircularProgress size={"48px"} thickness={8} />
    </Box>
  );
}

export default LoadingPage;
