import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IExpert, IMessage, IPrompt } from "../../types/index";

export enum ActiveView {
  CHAT = "Chat",
  CACHE = "Cache",
  PROFILE = "Profile",
}

export enum ActiveLayout {
  ONE = 1,
  TWO = 2,
  THREE = 3,
}

const initialState = {
  isAutoPlayEnabled: false,
  isTranscribing: false,
  activeExpert: null as IExpert | null,
  activeMessage: null as IMessage | null,
  activePrompt: null as IPrompt | null,
  activeView: ActiveView.CHAT as ActiveView,
  currentMessageText: "",
  currentPromptText: "",
  clearMessageFiles: false,
  activeLayout: ActiveLayout.ONE as ActiveLayout,
  mobileDrawerOpen: false,
};

export const workspaceSlice = createSlice({
  name: "workspace",
  initialState: initialState,
  reducers: {
    updateActiveExpert: (state, action) => {
      state.activeExpert = action.payload;
    },
    updateActiveMessage: (state, action) => {
      state.activeMessage = action.payload;
    },
    updateActivePrompt: (state, action) => {
      state.activePrompt = action.payload;
    },
    updateActiveView: (state, action) => {
      switch (action.payload) {
        case "Chat":
          state.activeView = ActiveView.CHAT;
          break;
        case "Cache":
          state.activeView = ActiveView.CACHE;
          break;
        case "Profile":
          state.activeView = ActiveView.PROFILE;
          break;
        default:
          state.activeView = ActiveView.CHAT;
          break;
      }
    },
    setCurrentMessageText: (state, action) => {
      state.currentMessageText = action.payload;
    },
    setCurrentPromptText: (state, action) => {
      state.currentPromptText = action.payload;
    },
    setIsTranscribing: (state, action: PayloadAction<boolean>) => {
      state.isTranscribing = action.payload;
    },
    setIsAutoPlayEnabled: (state, action) => {
      state.isAutoPlayEnabled = action.payload;
    },
    clearMessageFiles: (state, action) => {
      state.clearMessageFiles = action.payload;
    },
    setActiveLayout: (state, action) => {
      state.activeLayout = action.payload;
    },
    setMobileDrawerOpen: (state, action) => {
      state.mobileDrawerOpen = action.payload;
    },
  },
});

export const {
  updateActiveExpert,
  updateActiveMessage,
  updateActivePrompt,
  updateActiveView,
  setIsTranscribing,
  setCurrentMessageText,
  setCurrentPromptText,
  setIsAutoPlayEnabled,
  clearMessageFiles,
  setActiveLayout,
  setMobileDrawerOpen,
} = workspaceSlice.actions;

export default workspaceSlice.reducer;
