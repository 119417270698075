import { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { Check } from "@mui/icons-material";
import { PrimaryIcon } from "../../../../../ui-elements";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { setIsCopying } from "../../../../../../features/chats/messagesSlice";
import { Copy } from "../../../../../../icons";
import { usePrismTheme } from "./usePrismTheme";

const CodeBlock = ({ code, language = "javascript", message }) => {
  const dispatch = useAppDispatch();
  const messagesSlice = useAppSelector((state) => state.messages);
  const codeBlockRef = useRef(null);
  const [buttonPosition, setButtonPosition] = useState(28);
  const [isScrolling, setIsScrolling] = useState(false);
  let scrollTimeout;

  const customStyle = usePrismTheme();

  const handleCopy = async () => {
    try {
      dispatch(setIsCopying(message._id));
      await navigator.clipboard.writeText(code);
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  const wait2Seconds = async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
  };

  const finishCopy = async () => {
    await wait2Seconds();
    const thisMessage = message._id;
    if (thisMessage === messagesSlice.copyMessage) {
      dispatch(setIsCopying(null));
    }
  };

  function iconForState() {
    if (
      messagesSlice.copyMessage &&
      messagesSlice.copyMessage === message._id
    ) {
      return <Check />;
    } else {
      return <Copy />;
    }
  }

  useEffect(() => {
    if (messagesSlice.copyMessage) {
      finishCopy();
    }
  }, [messagesSlice.copyMessage]);

  useEffect(() => {
    const updateButtonPosition = () => {
      if (codeBlockRef.current) {
        const codeBlockRect = codeBlockRef.current.getBoundingClientRect();
        const scrollContainer = document.getElementById("scroll-container-id");
        if (scrollContainer) {
          const scrollContainerRect = scrollContainer.getBoundingClientRect();
          const hiddenHeight = Math.max(
            0,
            codeBlockRect.bottom - scrollContainerRect.bottom
          );
          const topCap = Math.max(16, codeBlockRect.height - 48);

          const newBottom = hiddenHeight > 0 ? 18 + hiddenHeight : 18;
          const clampedBottom = Math.min(newBottom, topCap);

          setButtonPosition(clampedBottom);
        }
      }
    };

    const scrollContainer = document.getElementById("scroll-container-id");
    if (scrollContainer) {
      const handleScroll = () => {
        setIsScrolling(true);
        updateButtonPosition();
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => {
          setIsScrolling(false);
        }, 300);
      };
      scrollContainer.addEventListener("scroll", handleScroll);

      updateButtonPosition();

      return () => {
        scrollContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  return (
    <Box
      ref={codeBlockRef}
      sx={{
        position: "relative",
        padding: 0,
        marginTop: 2,
        marginBottom: 2,
        borderRadius: "8px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          bottom: `${buttonPosition}px`,
          right: 16,
          transition: isScrolling ? "bottom 0.3s ease" : "none",
          pointerEvents: "none",
          zIndex: 1,
        }}
      >
        <PrimaryIcon
          iconName="Copy"
          icon={iconForState()}
          handleIconClick={handleCopy}
          stopPropagation={true}
          sx={{ pointerEvents: "auto" }}
        />
      </Box>
      <SyntaxHighlighter
        language={language}
        style={customStyle}
        customStyle={{
          margin: 0,
          borderRadius: "8px",
        }}
      >
        {code}
      </SyntaxHighlighter>
    </Box>
  );
};

export default CodeBlock;
