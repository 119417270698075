import React, { ReactNode, useEffect } from "react";
import { Box, styled, keyframes } from "@mui/system";

interface RotatingIconProps {
  isRotating: boolean;
  children: ReactNode;
}

const spin = keyframes`
  0% {
    transform: rotate(0deg);
    transform-origin: center;
  }
  100% {
    transform: rotate(360deg);
    transform-origin: center;
  }
`;

const SpinningBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isRotating",
})<{ isRotating: boolean }>(({ isRotating }) => ({
  position: "relative",
  transformOrigin: "center",
  animation: `${isRotating ? `${spin} 3s linear infinite` : "none"}`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const RotatingIcon: React.FC<RotatingIconProps> = ({
  isRotating,
  children,
}) => {
  useEffect(() => {
    //console.log("isRotating", isRotating);
  }, [isRotating]);

  return <SpinningBox isRotating={isRotating}>{children}</SpinningBox>;
};

export default RotatingIcon;
