import { Avatar, Box, Typography } from "@mui/material";
import { useAppSelector } from "../../../../app/store";
import VE_Avatar from "../../../ui-elements/VE_Avatar";

const ImageBlock = (size = null) => {
  const userDetails = useAppSelector((state) => state.userDetails.userDetails);
  const picture = userDetails?.picture;
  const pictureExists = () => {
    return picture !== null && picture !== undefined && picture !== "";
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: pictureExists() ? "55px" : "34px",
        alignItems: "center",
        width: "100%",
      }}
    >
      {!pictureExists() ? (
        <VE_Avatar
          sx={{ height: "100px", width: "100px" }}
          userDetails={userDetails}
        />
      ) : (
        <>
          <Box
            component="img"
            src={picture}
            alt="Expert Profile"
            sx={{
              position: "relative",
              bottom: "10px",
              height: size ? size : "150px",
              width: size ? size : "150px",
              borderRadius: "50%",
            }}
          />
          <Typography
            fontWeight={800}
          >{`Welcome, ${userDetails?.firstName}!`}</Typography>
        </>
      )}
    </Box>
  );
};

export default ImageBlock;
