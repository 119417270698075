import { useState, useRef, useEffect, useCallback } from "react";
import { Box } from "@mui/material";
import FooterBar from "./footer-bar/FooterBar";
import TitleBar from "./title-bar/TitleBar";
import Sidebar from "./side-bar/Sidebar";
import ConfirmationPopup from "../../popups/ConfirmationPopup";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { useTheme } from "@mui/material/styles";
import LoadingBar from "./LoadingBar";
import {
  ActiveLayout,
  ActiveView,
  clearMessageFiles,
  setActiveLayout,
  setMobileDrawerOpen,
  updateActiveView,
} from "../../../features/chats/workspaceSlice";
import AudioRecorder from "../../misc/AudioRecorder";
import Navbar from "../../menus/Navbar";
import DragDropOverlay from "./DragDropOverlay";
import { fetchExperts } from "../../../features/experts/expertsSlice";
import { ContentPanel } from "./content-panel/ContentPanel";
import useScreenSize from "../../ui-elements/useScreenSize";
import { DetailsPanel } from "./details-panel/DetailsPanel";
import { UploadValidatorService } from "../../../services/UploadValidatorService";
import { IErrorMessage } from "../../../types";
import { showErrorNotification } from "../../../features/ui/errorSlice";

const WorkspacePage = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const screenSize = useScreenSize();

  const expertsSlice = useAppSelector((state) => state.experts);
  const commandsSlice = useAppSelector((state) => state.commands);
  const workspaceSlice = useAppSelector((state) => state.workspace);

  // const [footerHeight, setFooterHeight] = useState(0);
  const [showLoading, setShowLoading] = useState(true);

  const [messageFiles, setMessageFiles] = useState([] as File[]);
  const [isDragging, setIsDragging] = useState(false);
  const [dragCounter, setDragCounter] = useState(0);
  const [mostRecentLayout, setMostRecentLayout] = useState(ActiveLayout.ONE);

  const mouseIsDown = useRef(false);
  // const footerSectionRef = useRef(null);

  const completeAnimation = () => {
    setShowLoading(false);
  };

  const handleDragEnter = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragCounter((prev) => prev + 1);
    if (e.dataTransfer.types.includes("Files")) {
      setIsDragging(true);
    }
  }, []);

  const handleDragLeave = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setDragCounter((prev) => prev - 1);
      if (dragCounter === 0) {
        setIsDragging(false);
      }
    },
    [dragCounter]
  );

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  }, []);

  const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    setIsDragging(false);
    setDragCounter(0);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const newFiles = Array.from(e.dataTransfer.files);
      const validation = UploadValidatorService.validateFileUpload(
        newFiles,
        messageFiles
      );

      if (!validation.isValid) {
        const errorMessage = {
          title: "Oops!",
          message: validation.message,
        } as IErrorMessage;
        dispatch(showErrorNotification(errorMessage));
        return;
      }

      handleFiles(Array.from(e.dataTransfer.files));
    }
  }, []);

  const handleFiles = (files: File[]) => {
    // Implement your file handling logic here
    // This could involve updating state, sending files to a server, etc.
    // console.log("Files dropped:", files);
    // Update your messageFiles state here
    setMessageFiles((prevFiles) => [...prevFiles, ...files]);
  };

  //#region useEffects
  useEffect(() => {
    document.addEventListener("mousedown", () => {
      mouseIsDown.current = true;
    });
    document.addEventListener("mouseup", () => {
      mouseIsDown.current = false;
      setIsDragging(false);
    });

    if (screenSize.isLarge) {
      const fetchParams = {
        fetchAll: true,
        searchTerm: "",
      };

      dispatch(fetchExperts(fetchParams));
    }
  }, []);

  useEffect(() => {
    // console.log("expertsSlice", expertsSlice);
  }, [commandsSlice]);

  useEffect(() => {
    setShowLoading(expertsSlice.creating || expertsSlice.isRemoving);
  }, [expertsSlice]);

  useEffect(() => {
    if (screenSize.isMobileSize) {
      setMostRecentLayout(workspaceSlice.activeLayout);
      dispatch(setActiveLayout(ActiveLayout.THREE));
      const activeView = workspaceSlice.activeView;
      if (activeView === ActiveView.CACHE) {
        handleIconClick("Chat");
      }
    } else {
      dispatch(setActiveLayout(mostRecentLayout));
    }
  }, [screenSize.isMobileSize]);

  useEffect(() => {
    if (workspaceSlice.clearMessageFiles) {
      setMessageFiles([]);
      dispatch(clearMessageFiles(false));
    }
  }, [workspaceSlice.clearMessageFiles]);

  useEffect(() => {
    if (screenSize.isFull) {
      handleIconClick("Chat");
    }
  }, [screenSize.isFull]);

  const handleIconClick = (iconName: string) => {
    dispatch(updateActiveView(iconName));
  };

  const handleExpertClick = () => {
    if (screenSize.isLarge) {
      dispatch(setMobileDrawerOpen(!workspaceSlice.mobileDrawerOpen));
    }
  };

  // when the view blurs, setIsDragging to false
  window.onblur = () => {
    setIsDragging(false);
  };

  // when window focuses, if mouse is not down, setIsDragging to false
  window.onfocus = () => {
    if (!mouseIsDown.current) {
      setIsDragging(false);
    }
  };

  const activeLayout = workspaceSlice.activeLayout;
  const showLeftPanel = activeLayout === ActiveLayout.ONE;
  const showRightPanel =
    activeLayout === ActiveLayout.ONE || activeLayout === ActiveLayout.TWO;

  return (
    <>
      <Navbar />
      {showLoading ? (
        <LoadingBar
          label={`${
            expertsSlice.isRemoving ? "Deleting" : "Creating"
          } expert...`}
          showLoading={showLoading}
          completeAnimation={completeAnimation}
        />
      ) : (
        <Box
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          sx={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left:
              !showLoading && showLeftPanel && screenSize.isFull
                ? theme.drawerWidth
                : 0,
            right: showRightPanel ? `${theme.rightPanelWidth}px` : 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop:
              workspaceSlice.activeView === ActiveView.PROFILE
                ? showLeftPanel
                  ? "-0.9px"
                  : "-4.9px"
                : 0,
            paddingLeft: screenSize.isLarge ? 0 : "16px",
            paddingRight: screenSize.isLarge
              ? 0
              : screenSize.isLarge
              ? "8px"
              : "16px",
            paddingTop: screenSize.isLarge ? "8px" : "16px",
            paddingBottom:
              screenSize.isLarge &&
              workspaceSlice.activeView === ActiveView.PROFILE
                ? "6px"
                : screenSize.isLarge
                ? "8px"
                : "16px",
            overflow: "hidden",
          }}
        >
          <AudioRecorder />
          <ConfirmationPopup />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%",
              maxWidth: "lg",
            }}
          >
            <Sidebar showLoading={showLoading} />
            <TitleBar
              handleIconClick={handleIconClick}
              handleExpertClick={handleExpertClick}
            />
            <ContentPanel handleIconClick={handleIconClick} />
            <FooterBar
              messageFiles={messageFiles}
              setMessageFiles={setMessageFiles}
            />
            {showRightPanel && <DetailsPanel />}
            <DragDropOverlay
              isVisible={
                isDragging && workspaceSlice.activeView === ActiveView.CHAT
              }
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default WorkspacePage;
