// ExpertiseService.ts
import { MiddlewareAPI } from "@reduxjs/toolkit";
import { socket } from "../../SocketProvider";
import { fetchExpertise } from "../../features/experts/expertiseSlice";
import { IExpert } from "../../types";
import { setChatStateForChat } from "../../features/chats/chatSlice";

export class ExpertiseService {
  private storeAPI: MiddlewareAPI;

  constructor(storeAPI: MiddlewareAPI) {
    this.storeAPI = storeAPI;
  }

  // INBOUND EVENTS
  public registerEvents() {
    socket.on("shouldRefreshExpertise", this.handleRefreshExpertise);
    socket.on("setChatState", this.handleSetChatState);
  }

  public unregisterEvents() {
    socket.off("shouldRefreshExpertise", this.handleRefreshExpertise);
    socket.off("setChatState", this.handleSetChatState);
  }

  // OUTBOUND EVENTS
  public handleActions(action: any) {
    // Add outbound actions here
  }

  // FUNCTIONS FOR SOCKET EVENTS
  private handleRefreshExpertise = (expertiseId: any) => {
    const activeExpert = this.storeAPI.getState().experts
      .activeExpert as IExpert;
    const activeExpertise = activeExpert?.expertise;
    if (activeExpertise === expertiseId)
      this.storeAPI.dispatch(fetchExpertise(null) as any);
  };

  private handleSetChatState = (payload: any) => {
    this.storeAPI.dispatch(setChatStateForChat(payload) as any);
  };
}
