import React, { useEffect, useState } from "react";
import { Box, Divider, Grid, useTheme } from "@mui/material";
import SectionBase from "../../workspace-page/content-views/profile-view/sections/SectionBase";
import { Input } from "../../../ui-elements";
import { InputData } from "../../../ui-elements/CustomInput";
import CustomDatePicker from "../../../ui-elements/CustomDatePicker";
import BlockTitle from "../../../ui-elements/BlockTitle";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { updateUserDetails } from "../../../../features/users/userDetailsSlice";

interface MyInfoBlockProps {
  containerWidth: number;
}

const MyInfoBlock: React.FC<MyInfoBlockProps> = ({ containerWidth }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const userDetails = useAppSelector((state) => state.userDetails.userDetails);
  // 13 years ago from todays date
  const thirteenYearsAgo = new Date();
  thirteenYearsAgo.setFullYear(thirteenYearsAgo.getFullYear() - 13);
  const initialDate =
    userDetails && userDetails.birthday
      ? new Date(userDetails.birthday)
      : thirteenYearsAgo;

  const [date, setDate] = useState(initialDate);

  useEffect(() => {
    // console.log("containerWidth: " + containerWidth);
  }, [containerWidth]);

  useEffect(() => {
    if (date !== initialDate) {
      const updatedUser = { ...userDetails, birthday: date };
      dispatch(updateUserDetails(updatedUser));
    }
  }, [date]);

  const handleSubmitInput = async (inputData: InputData) => {
    console.log(`inputData ${inputData}`);
  };

  function getAge() {
    // convert date into an age
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    // if the current month is less than the birth month, then subtract 1 from age
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age.toString();
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-column",
        alignItems: "flex-start",
        alignContent: "flex-start",
        height: "auto",
        width: "100%",
      }}
    >
      <SectionBase title="My Info">
        <Grid
          container
          padding={"0px 16px 16px 16px"}
          direction={"row"}
          spacing={"16px"}
        >
          <BlockTitle
            title="Name"
            subtitle={`${userDetails?.firstName} ${userDetails?.lastName}`}
            overrideMarginTop={true}
          />
          <Input
            startingValue={userDetails?.firstName}
            name="first-name"
            label="First"
            half={true}
            selectedColorOverride={theme.palette.primary.main}
            handleSubmit={handleSubmitInput}
          />

          <Input
            startingValue={userDetails?.lastName}
            name="last-name"
            label="Last"
            half={true}
            selectedColorOverride={theme.palette.primary.main}
            handleSubmit={handleSubmitInput}
          />
          <Grid
            item
            width="100%"
            height={"16px"}
            alignContent={"center"}
            marginTop={"16px"}
            marginBottom={"8px"}
          >
            <Divider orientation="horizontal" />
          </Grid>
          <BlockTitle
            title="Age"
            subtitle={getAge()}
            overrideMarginTop={true}
          />
          <CustomDatePicker
            selectedDate={date}
            onDateChange={(newDate: Date) => setDate(newDate)}
          />
        </Grid>
      </SectionBase>
    </Box>
  );
};

export default MyInfoBlock;
