import {
  IKnowledgeDoc,
  ICreateKnowledgeDocPayload,
  IFilesUploadPayload,
} from "../types/index";
import API from "./api";

export const fetchKnowledgeDoc = (docId: string) =>
  API.post(`/knowledgeDoc/fetchKnowledgeDoc`, { docId: docId });

export const createKnowledgeDocFromUrl = (
  newKnowledgeDoc: ICreateKnowledgeDocPayload
) => {
  return API.post(`/knowledgeDoc/createKnowledgeDocFromUrl`, newKnowledgeDoc);
};

export const createKnowledgeDocsFromFiles = (
  filesUploadPayload: IFilesUploadPayload
) => {
  try {
    const formData = new FormData();

    // Function to clean the file name
    const cleanFileName = (name) => {
      return name.replace(/[^a-zA-Z0-9-_.() ]/g, "");
    };

    // Iterate over the files and append them to formData
    filesUploadPayload.files.forEach(
      (filePayload: ICreateKnowledgeDocPayload, index) => {
        if (filePayload.file instanceof Blob) {
          // Clean the file name
          const cleanedFileName = cleanFileName(filePayload.file.name);

          // Create a new File object with the cleaned name
          const cleanedFile = new File([filePayload.file], cleanedFileName, {
            type: filePayload.file.type,
          });

          // Append the new file with the cleaned name
          formData.append(`files`, cleanedFile, cleanedFileName);
        }

        // Append metadata with the same index to ensure association
        Object.entries(filePayload).forEach(([key, value]) => {
          if (key !== "file" && value !== null && value !== undefined) {
            // Use a composite key that includes the index for the metadata
            formData.append(`${key}_${index}`, value.toString());
          }
        });
      }
    );

    return API.post(`/knowledgeDoc/createKnowledgeDocsFromFiles`, formData);
  } catch (error) {
    console.error("Error in createKnowledgeDocsFromFiles:", error);
  }
};

export const updateKnowledgeDoc = (updatedKnowledgeDoc: IKnowledgeDoc) =>
  API.patch(`/knowledgeDoc/updateKnowledgeDoc`, updatedKnowledgeDoc);
export const deleteKnowledgeDoc = (docId: string) =>
  API.delete(`/knowledgeDoc/${docId}`);

export const fetchKnowledgeDocImageUrl = (docId: string) =>
  API.post(`/knowledgeDoc/fetchKnowledgeDocImageUrl`, { docId: docId });

export const fetchThumbnail = (url: string) =>
  API.post(`/knowledgeDoc/fetchThumbnail`, { url });
