import { useEffect, useCallback } from "react";
import { EditorState, ContentState, SelectionState } from "draft-js";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import {
  ActiveView,
  setCurrentMessageText,
  setIsTranscribing,
  setCurrentPromptText,
} from "../../../../../../features/chats/workspaceSlice";
import { selectCommand } from "../../../../../../features/commands/commandsSlice";
import {
  createCommandDecorator,
  createEmptyDecoratedEditor,
} from "../commands/CommandDecorator";
import { IMessageBarState } from "./MessageBarState";
// Adjust the import path as needed

export const useViewSetter = (
  messageBarState: IMessageBarState,
  activeExpert: any,
  getStateWithCommands: (editorState: EditorState) => EditorState
) => {
  const dispatch = useAppDispatch();
  const { activeView, currentMessageText, currentPromptText, isTranscribing } =
    useAppSelector((state) => state.workspace);
  const commands = useAppSelector((state) => state.commands.commands);

  // Creates a new EditorState with given text
  const createEditorState = useCallback(
    (text: string, shouldDecorate = true) => {
      const contentState = ContentState.createFromText(text);
      const editorState = shouldDecorate
        ? EditorState.createWithContent(
            contentState,
            createCommandDecorator(commands, activeExpert?.color)
          )
        : EditorState.createWithContent(contentState);

      const lastBlock = contentState.getBlockMap().last();
      const selection = SelectionState.createEmpty(lastBlock.getKey()).merge({
        anchorOffset: lastBlock.getLength(),
        focusOffset: lastBlock.getLength(),
      });

      return EditorState.forceSelection(editorState, selection);
    },
    [commands, activeExpert]
  );

  // Updates editor state with new text and view settings
  const updateEditorState = useCallback(
    (text: string, view: ActiveView, showPlaceholder = false) => {
      const newEditorState = createEditorState(text);
      const formattedEditor = getStateWithCommands(newEditorState);

      return {
        localView: view,
        showPlaceholder,
        editorState: formattedEditor,
      };
    },
    [createEditorState, getStateWithCommands]
  );

  // Handles state changes when switching views
  const handleViewChange = useCallback(() => {
    const currentText = messageBarState.editorState
      .getCurrentContent()
      .getPlainText();

    if (
      activeView === ActiveView.CACHE &&
      messageBarState.localView === ActiveView.CHAT
    ) {
      dispatch(setCurrentMessageText(currentText));
      if (isTranscribing) dispatch(setIsTranscribing(false));
    } else if (
      activeView === ActiveView.CHAT &&
      messageBarState.localView === ActiveView.CACHE
    ) {
      dispatch(setCurrentPromptText(currentText));
    }

    dispatch(selectCommand(null));

    if (activeView === ActiveView.CHAT) {
      return updateEditorState(
        currentMessageText,
        activeView,
        currentMessageText === ""
      );
    } else if (activeView === ActiveView.CACHE) {
      dispatch(
        selectCommand({ command: "@prompt", startIndex: 0, endIndex: 7 })
      );
      const promptText = currentPromptText.startsWith("@prompt")
        ? currentPromptText
        : `@prompt ${currentPromptText}`;
      return updateEditorState(promptText, activeView, false);
    } else {
      return {
        localView: activeView,
        editorState: createEmptyDecoratedEditor(commands, activeExpert),
      };
    }
  }, [
    activeView,
    messageBarState.localView,
    isTranscribing,
    currentMessageText,
    currentPromptText,
    updateEditorState,
    dispatch,
    commands,
    activeExpert,
  ]);

  // Trigger view change handling when active view changes
  useEffect(() => {
    if (messageBarState.localView && messageBarState.localView !== activeView) {
      const updatedState = handleViewChange();
      Object.assign(messageBarState, updatedState);
    }
  }, [activeView, messageBarState, handleViewChange]);

  return messageBarState;
};
