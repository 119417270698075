import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../routes/routes";
import { handleAxiosError } from "../../app/ErrorHandler";
import { IExpertise, IFetchExpertisePayload, IExpert } from "../../types/index";
import { RootState } from "../../app/store";

const initialState = {
  loading: false,
  activeExpertise: null as IExpertise | null,
  error: "" as unknown,
};

export const fetchExpertise = createAsyncThunk(
  "expertise/fetchExpertise",
  async (expert: IExpert | null, { getState, rejectWithValue }) => {
    try {
      if (!expert) {
        const state = getState() as RootState;
        expert = state.experts.activeExpert as IExpert;
      }
      const payload = {
        expertId: expert._id,
        expertiseId: expert.expertise,
      } as IFetchExpertisePayload;
      const result = await api.fetchExpertise(payload);
      return result.data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const createExpertise = createAsyncThunk(
  "expertise/createExpertise",
  async (newExpertise: IExpertise, { rejectWithValue }) => {
    try {
      const result = await api.createExpertise(newExpertise);
      return result.data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const updateExpertise = createAsyncThunk(
  "expertise/updateExpertise",
  async (updatedExpertise: IExpertise, { rejectWithValue }) => {
    try {
      const result = await api.updateExpertise(updatedExpertise);
      return result.data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const deleteSkill = createAsyncThunk(
  "expertise/deleteSkill",
  async (skill: string, { getState, dispatch, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const activeExpertise = state.expertise.activeExpertise as IExpertise;
      const skills = activeExpertise.skills.filter((s) => s !== skill);
      const updatedExpertise = { ...activeExpertise, skills };
      await dispatch(updateExpertise(updatedExpertise));
      console.log("deleteSkill: ", skill);
      return "success";
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const deleteExpertise = createAsyncThunk(
  "expertise/deleteExpertise",
  async (expertiseId: string, { rejectWithValue }) => {
    try {
      await api.deleteExpertise(expertiseId);
      return expertiseId;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const setIsUploading = createAsyncThunk(
  "expertise/setIsUploading",
  async (isUploading: boolean, { getState, dispatch, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const expert = state.experts.activeExpert as IExpert;
      dispatch(fetchExpertise(expert));
      return "success";
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const expertiseSlice = createSlice({
  name: "expertise",
  initialState: initialState,
  reducers: {
    selectExpertise: (state, action) => {
      state.activeExpertise = action.payload;
    },
    clearExpertise: (state) => {
      state.activeExpertise = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchExpertise.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchExpertise.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.activeExpertise = action.payload;
    });
    builder.addCase(fetchExpertise.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(createExpertise.fulfilled, (state, action) => {
      // handle new expertise created
    });
    builder.addCase(updateExpertise.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.activeExpertise = action.payload;
    });
    builder.addCase(deleteExpertise.fulfilled, (state, action) => {
      // handle expertise deleted
    });
  },
});

export const { selectExpertise, clearExpertise } = expertiseSlice.actions;

export default expertiseSlice.reducer;
