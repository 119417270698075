//#region Imports
import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { Box } from "@mui/material";
import { Editor, EditorState, SelectionState, Modifier } from "draft-js";
import CommandPopup from "./commands/CommandPopup";
import { selectCommand } from "../../../../../features/commands/commandsSlice";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { useTheme } from "@mui/material/styles";
import { ICommand, ICursorData } from "../../../../../types";
import {
  ActiveView,
  setCurrentMessageText,
  setIsTranscribing,
} from "../../../../../features/chats/workspaceSlice";
import { getCurrentSelection, getCurrentWord } from "./MessageBarHelper";
import { setCursorData } from "../../../../../features/chats/messagesSlice";
import {
  getCurrentCursorData,
  removeTemporaryTranscriptionStyle,
} from "./transcription-helpers/TranscriptionHelper";
import {
  insertTemporaryTranscription,
  replaceTemporaryTranscription,
} from "./transcription-helpers/TranscriptionHelper";
import {
  TranscriptionState,
  setClearTranscriptionSegment,
  setFinalizeTranscriptionSegment,
  setTranscriptionData,
} from "../../../../../features/audio/transcriptionSlice";
import { createEmptyDecoratedEditor } from "./commands/CommandDecorator";
import { processCommandsIconTapped } from "./commands/processCommandIconTapped";
import { handleKeyBinding } from "./Keybindings";
import { updateCurrentStringInState } from "./state-setters/updateCurrentStringInState";
import {
  getCustomStyleMap,
  getDarkCustomStyleMap,
} from "./style-maps/messageBarStyleMaps";
import { useMessageBarState } from "./state-setters/MessageBarState";
import {
  determineShowPopup,
  locateActiveCommand,
  selectCommandAtIndex,
} from "./commands/CommandHelper";
import { handleSubmit } from "./actions/handleSubmit";
import { useViewSetter } from "./state-setters/useViewSetter";
import { useExpertSetter } from "./state-setters/useExpertSetter";
import { showDebugSnackbar } from "../../../../../features/ui/debugSnackbarSlice";
import useEditModeSetter from "./state-setters/useEditModeSetter";
import { UtilityService } from "../../../../../services/UtilityService";
import { useMobileKeyboardMicRecognizer } from "./state-setters/useMobileKeyboardMicRecognizer";
//#endregion

const MessageBar: React.FC<any> = forwardRef(
  (
    {
      footerBarRef,
      setIsFocused,
      blockGetCommand,
      setBlockGetCommand,
      messageFiles,
      isFocused,
    },
    ref
  ) => {
    const editor = useRef<Editor | null>(null);
    const editorWrapperRef = useRef(null);
    const dispatch = useAppDispatch();
    const theme = useTheme();

    //#region State
    const confirmationPopupSlice = useAppSelector(
      (state) => state.confirmationPopup
    );
    const errorMessengerSlice = useAppSelector((state) => state.errorMessenger);
    const chatSlice = useAppSelector((state) => state.chat);
    const workspaceSlice = useAppSelector((state) => state.workspace);
    const messagesSlice = useAppSelector((state) => state.messages);
    const transcriptionSlice = useAppSelector(
      (state) => state.audioTranscription
    );
    const commandsSlice = useAppSelector((state) => state.commands);
    const activeExpert = useAppSelector((state) => state.experts.activeExpert);

    const customStyleMap = getCustomStyleMap(activeExpert);
    const darkCustomStyleMap = getDarkCustomStyleMap(activeExpert);

    const messageBarState = useMessageBarState(
      activeExpert,
      workspaceSlice.activeView,
      commandsSlice
    );

    const [localChat, setLocalChat] = useState(null);

    useEffect(() => {
      // message bar state updated
    }, [messageBarState]);

    //#endregion

    //#region useEffect
    useEffect(() => {
      if (editor.current) {
        if (localChat?._id !== chatSlice.activeChat?._id) {
          setLocalChat(chatSlice.activeChat);
          setFocus();
        }
      }
    }, [chatSlice.activeChat]);

    useEffect(() => {
      // if (isFocused) {
      //   setFocus();
      // } else {
      //   setBlur();
      // }
    }, [isFocused]);

    useEffect(() => {
      messageBarState.files = messageFiles;
    }, [messageFiles]);

    useEffect(() => {
      if (blockGetCommand) {
        messageBarState.isBlurring = true;
        setBlockGetCommand(false);
      }
    }, [blockGetCommand]);

    useEffect(() => {
      if (workspaceSlice.activeView !== ActiveView.CACHE) {
        if (messageBarState.isProcessingTranscriptionError) {
          dispatch(setTranscriptionData(null));
          dispatch(setCursorData(null));
          messageBarState.isProcessingTranscriptionError = false;
          return;
        }

        const transcription =
          transcriptionSlice.transcriptionData?.temporaryTranscription ?? "";

        if (!transcription) return;

        const preText = transcriptionSlice.transcriptionData?.preText ?? "";
        if (transcription === preText) return;

        let updatedEditorState;
        if (messageBarState.hasInsertedTemporaryTranscription) {
          updatedEditorState = replaceTemporaryTranscription(
            messageBarState,
            transcription
          );
        } else {
          updatedEditorState = insertTemporaryTranscription(
            messageBarState,
            transcription
          );
          messageBarState.hasInsertedTemporaryTranscription = true;
        }

        messageBarState.editorState = updatedEditorState;
        messageBarState.showPlaceholder = false;
      } else {
        messageBarState.hasInsertedTemporaryTranscription = false;
        messageBarState.tempTranscriptionPosition = null;
      }
    }, [transcriptionSlice.transcriptionData]);

    useEffect(() => {
      if (transcriptionSlice.transcriptionState === TranscriptionState.Error) {
        if (!messageBarState.isProcessingTranscriptionError) {
          // set the message bar versions
          messageBarState.isProcessingTranscriptionError = true;
          messageBarState.tempTranscriptionPosition = null;
          messageBarState.hasInsertedTemporaryTranscription = false;
          messageBarState.hasInsertedTemporaryTranscription = false;
          messageBarState.editorState = createEmptyDecoratedEditor(
            commandsSlice,
            activeExpert
          );
          messageBarState.showPlaceholder = true;
          setFocus();
        }
      }
    }, [transcriptionSlice.transcriptionState]);

    useEffect(() => {
      if (editor.current) {
        setFocus();
      }

      const onFocus = () => {
        setFocus();
        hydrateEditorUi(messageBarState.editorState);
      };

      const onBlur = () => {
        setBlur();
      };

      window.addEventListener("focus", onFocus);
      window.addEventListener("blur", onBlur);
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        window.removeEventListener("focus", onFocus);
        window.removeEventListener("blur", onBlur);
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    useEffect(() => {
      if (commandsSlice.activeCommand !== null) {
        if (commandsSlice.activeCommand.command !== null) {
          messageBarState.showSuggestions = false;
        }
      }
    }, [commandsSlice.activeCommand]);

    useViewSetter(messageBarState, activeExpert, hydrateEditorUi);
    useExpertSetter(messageBarState);
    useMobileKeyboardMicRecognizer(editorWrapperRef, messageBarState);

    useEffect(() => {
      if (messageBarState.isClickingCommand) {
        messageBarState.isClickingCommand = false;
        messageBarState.blockClickAwayForCommand = true;
        //messageBarState.blockAwayRef.current = true;
      }
    }, [messageBarState.isClickingCommand]);

    useEffect(() => {
      messageBarState.blockClickAwayForCommand = false;
      setFocus();
      messageBarState.clickedInside = true;
    }, [messageBarState.blockClickAwayForCommand]);

    useEffect(() => {
      const currentContent = messageBarState.editorState.getCurrentContent();
      const currentText = currentContent.getPlainText();

      const handleTextUpdate = () => {
        if (currentText !== messageBarState.currentTextString) {
          messageBarState.currentTextString = currentText;

          messageBarState.showPlaceholder = !currentText;
        }
      };

      const debouncedTextUpdate = UtilityService.debounce(
        handleTextUpdate,
        300
      );

      const editorIsFocused = messageBarState.editorState
        .getSelection()
        .getHasFocus();
      if (editorIsFocused && currentText) {
        debouncedTextUpdate();
      }
    }, [messageBarState.editorState]);
    // #endregion

    function selectCellAtIndex(index: number) {
      const activeCommand = commandsSlice.activeCommand as ICommand;
      messageBarState.highlightedSuggestionsIndex = index;
      selectCommandAtIndex({
        messageBarState,
        activeCommand,
        dispatch,
      }) as EditorState;

      messageBarState.suggestedCommands = [];
      messageBarState.showSuggestions = false;
      messageBarState.highlightedSuggestionsIndex = 0;
    }

    const setBlur = () => {
      if (!messageBarState.clickedInside) {
        if (messageBarState.showSuggestions) {
          messageBarState.wasShowingSuggestions = true;
          messageBarState.showSuggestions = false;
        }
      }

      messageBarState.isBlurring = true;
      editor.current.blur();
      setIsFocused(false);
    };

    const setFocus = () => {
      if (messageBarState.wasShowingSuggestions) {
        messageBarState.wasShowingSuggestions = false;
        messageBarState.showSuggestions = true;
      }

      messageBarState.isBlurring = false;
      editor.current.focus();
      setIsFocused(true);
    };

    useImperativeHandle(ref, () => ({
      submit,
      handleCommandsIconTapped,
    }));

    const handleCommandsIconTapped = processCommandsIconTapped(
      messageBarState,
      hydrateEditorUi,
      setFocus
    );

    const submit = handleSubmit(
      messagesSlice,
      dispatch,
      messageBarState,
      commandsSlice,
      activeExpert
    );

    const handleKeyCommand = (command: string) => {
      if (command === "send-message") {
        submit();
        return "handled";
      }

      return "not-handled";
    };

    function hydrateEditorUi(currentEditorState: EditorState) {
      if (
        messageBarState.isBlurring ||
        errorMessengerSlice.showErrorNotification ||
        confirmationPopupSlice.aPopupIsShowing
        // || messageBarState.isReturningFromAnotherWindow
      ) {
        messageBarState.isBlurring = false;
        // messageBarState.isReturningFromAnotherWindow = false;
        return currentEditorState;
      }

      // if the editor is focused and isFocused is false, set isFocused to true
      const editorIsFocused = currentEditorState.getSelection().getHasFocus();

      if (!editorIsFocused && !isFocused) {
        setFocus();
        // set cursor to last index
        const contentState = currentEditorState.getCurrentContent();
        const lastBlock = contentState.getLastBlock();
        const lastBlockKey = lastBlock.getKey();
        const lastBlockLength = lastBlock.getLength();
        const selectionState = new SelectionState({
          anchorKey: lastBlockKey,
          anchorOffset: lastBlockLength,
          focusKey: lastBlockKey,
          focusOffset: lastBlockLength,
        });

        const newState = EditorState.forceSelection(
          currentEditorState,
          selectionState
        );

        return newState;
      }

      const isCacheView = workspaceSlice.activeView === ActiveView.CACHE;
      let activeCommand = commandsSlice.activeCommand as ICommand;
      if (workspaceSlice.activeView === ActiveView.CACHE) {
        activeCommand = {
          command: "@prompt",
          startIndex: 0,
          endIndex: 7,
        } as ICommand;
      }

      const { matchedCommand } = locateActiveCommand({
        currentEditorState,
        activeCommand,
        isCacheView,
      });

      if (activeCommand?.command !== matchedCommand?.command) {
        dispatch(selectCommand(isCacheView ? activeCommand : matchedCommand));
      }

      const currentWord = getCurrentWord(currentEditorState);
      if (matchedCommand === null || matchedCommand.command === null) {
        if (!isCacheView) {
          determineShowPopup({
            currentWord,
            messageBarState,
          });
        }
      }

      const selection = getCurrentSelection(currentEditorState);
      const contentState = currentEditorState.getCurrentContent();
      messageBarState.showPlaceholder =
        !contentState.hasText() || contentState.getPlainText().trim() === "";

      const finalState = EditorState.forceSelection(
        currentEditorState,
        selection
      );
      updateCursorData(finalState);

      return finalState;
    }

    const findDataId = (element: HTMLElement | null): string | null => {
      while (element) {
        if (element.dataset.id) {
          return element.dataset.id;
        }
        element = element.parentElement;
      }
      return null;
    };

    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const boxId = findDataId(target);
      if (boxId === "Chat-page" || boxId === "Cache-page") {
        messageBarState.blockClickAwayForCommand = true;
      }

      const node = event.target as Node;
      const contains = footerBarRef.current?.contains(node);
      if (!contains) {
        if (!messageBarState.blockClickAwayForCommand) {
          setBlur();
          messageBarState.isBlurring = true;
          messageBarState.showSuggestions = false;
        }
      } else {
        setFocus();
        messageBarState.clickedInside = true;
        messageBarState.blockClickAwayForCommand = false;
      }

      const selection = getCurrentSelection(messageBarState.editorState);
      const hasFocus = selection?.getHasFocus();
      if (hasFocus) {
        setIsFocused(true);
      }
    };

    useEffect(() => {
      if (workspaceSlice.isTranscribing) {
        const currentCursorData = getCurrentCursorData(
          messageBarState.editorState
        );
        dispatch(setCursorData(currentCursorData));
        editor.current?.focus();
      } else {
        if (
          !messageBarState.isProcessingTranscriptionError &&
          transcriptionSlice.transcriptionState !== TranscriptionState.Error
        ) {
          if (messageBarState.hasInsertedTemporaryTranscription) {
            tryFinishTranscription();

            if (workspaceSlice.activeView !== ActiveView.CACHE) {
              const cleanEditorState = removeTemporaryTranscriptionStyle(
                messageBarState.editorState
              );

              messageBarState.editorState = cleanEditorState;
            }
          }
        }
      }
    }, [workspaceSlice.isTranscribing]);

    const tryFinishTranscription = () => {
      console.log("tryFinishTranscription called");

      const transcriptionText =
        transcriptionSlice.transcriptionData?.temporaryTranscription ?? "";

      if (transcriptionText === "") {
        return;
      }

      const currentCursorData = messagesSlice.cursorData as ICursorData;
      if (currentCursorData === null) {
        return;
      }

      const oldPreText = currentCursorData.preText;
      const oldPostText = currentCursorData.postText;
      const oldOriginalCursorPosition =
        currentCursorData.originalCursorPosition;

      const newPreText = oldPreText + transcriptionText;
      const newOriginalCursorPosition = newPreText.length;

      // Prepare to style the range of text that was transcribed
      let newContentState = messageBarState.editorState.getCurrentContent();
      const anchorKey = messageBarState.editorState
        .getSelection()
        .getAnchorKey();
      const blockLength = newContentState.getBlockForKey(anchorKey).getLength();

      // Check if the styled range is valid
      if (
        oldOriginalCursorPosition <= blockLength &&
        newOriginalCursorPosition <= blockLength
      ) {
        const styledRange = new SelectionState({
          anchorKey: anchorKey,
          anchorOffset: oldOriginalCursorPosition,
          focusKey: anchorKey,
          focusOffset: newOriginalCursorPosition,
        });

        newContentState = Modifier.removeInlineStyle(
          newContentState,
          styledRange,
          "TEMPORARY_TRANSCRIPTION"
        );
      } else {
        //console.error("Invalid styled range");
        return; // Exit the function if the range is invalid
      }

      // Move cursor to the new position
      const newCursorPosition = newOriginalCursorPosition;
      const newSelectionState = new SelectionState({
        anchorKey: anchorKey,
        anchorOffset: newOriginalCursorPosition,
        focusKey: anchorKey,
        focusOffset: newOriginalCursorPosition,
      });

      // Update editor state
      let newEditorState = EditorState.push(
        messageBarState.editorState,
        newContentState,
        "change-inline-style"
      );

      newEditorState = EditorState.forceSelection(
        newEditorState,
        newSelectionState
      );

      // Update cursor data in the Redux store
      const newCursorData = {
        ...(messagesSlice.cursorData as ICursorData),
        preText: newPreText,
        postText: oldPostText,
        originalCursorPosition: newOriginalCursorPosition,
        cursorPosition: newCursorPosition,
      };

      dispatch(setCursorData(newCursorData));

      if (workspaceSlice.activeView === ActiveView.CHAT) {
        messageBarState.editorState = newEditorState;
      } else {
        dispatch(setCurrentMessageText(newPreText + oldPostText));
      }

      messageBarState.hasInsertedTemporaryTranscription = false;
      dispatch(setFinalizeTranscriptionSegment(true));
    };

    const updateCursorData = (newEditorState: EditorState) => {
      if (workspaceSlice.isTranscribing) {
        const currentCursorData = messagesSlice.cursorData as ICursorData;
        const newCursorData = getCurrentCursorData(newEditorState);
        const oldPostiion = currentCursorData?.cursorPosition;
        const newPosition = newCursorData.cursorPosition;

        if (currentCursorData !== null && oldPostiion !== newPosition) {
          dispatch(setIsTranscribing(false));
          tryFinishTranscription();
        }
      }

      updateCurrentStringInState(
        workspaceSlice.activeView,
        newEditorState,
        messagesSlice,
        dispatch
      );
    };

    const callClearTranscriptionSegment = (onError = false) => {
      if (!onError) {
        dispatch(setClearTranscriptionSegment(true));
      }

      const clearedEditorState = replaceTemporaryTranscription(
        messageBarState,
        ""
      );

      dispatch(setTranscriptionData(null));
      messageBarState.editorState = clearedEditorState;
      messageBarState.hasInsertedTemporaryTranscription = false;
      messageBarState.tempTranscriptionPosition = null;
    };

    const scrollToBottom = () => {
      const editorContainer = editorWrapperRef.current;
      if (editorContainer) {
        setTimeout(() => {
          editorContainer.scrollTop = editorContainer.scrollHeight;
        }, 0);
      }
    };

    useEditModeSetter(messageBarState, editor, hydrateEditorUi);

    return (
      <>
        <CommandPopup
          editorWrapperRef={editorWrapperRef}
          messageBarState={messageBarState}
          selectCellAtIndex={selectCellAtIndex}
          expert={activeExpert}
          isFocused={isFocused}
        />
        <Box
          ref={editorWrapperRef}
          sx={{
            position: "relative",
            paddingLeft: "16px",
            paddingRight: "12px",
            paddingTop: "12px",
            paddingBottom: "8px",
            fontSize: "0.85rem",
            maxHeight: "200px",
            overflowY: "auto",
          }}
        >
          <Editor
            ref={editor}
            autoCorrect="off"
            spellCheck={true}
            customStyleMap={
              theme.palette.mode === "dark"
                ? darkCustomStyleMap
                : customStyleMap
            }
            editorState={messageBarState.editorState}
            onChange={(newEditorState) => {
              // Clean onChange: Just update the editor state and leave further processing to useEffect
              if (!messageBarState.isProcessingTranscriptionError) {
                messageBarState.editorState = hydrateEditorUi(newEditorState);
              }
            }}
            onFocus={setFocus}
            onBlur={setBlur}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={(e) =>
              handleKeyBinding({
                e,
                messageBarState,
                sendMessage: submit,
                selectCellAtIndex,
                tryFinishTranscription: tryFinishTranscription,
                isTranscribing: workspaceSlice.isTranscribing,
                callClearTranscriptionSegment: callClearTranscriptionSegment,
                scrollToBottom: scrollToBottom,
              })
            }
            autoCapitalize="off"
            autoComplete="off"
          />
          {messageBarState.showPlaceholder && (
            <Box
              sx={{
                position: "absolute",
                top: "12px",
                left: "16px",
                color: theme.palette.text.placeholder,
                pointerEvents: "none",
                fontSize: "0.85rem",
              }}
            >
              {workspaceSlice.activeView === ActiveView.CHAT
                ? "Type a message"
                : "Type a Prompt"}
            </Box>
          )}
        </Box>
      </>
    );
  }
);

export default MessageBar;
