import { ReactComponent as CheckboxCheckedIcon } from "./batch-1/checkbox-checked-icon.svg";
import { ReactComponent as AddAltIcon } from "./batch-1/add-alt-icon.svg";
import { ReactComponent as AddIcon } from "./batch-1/add-icon.svg";
import { ReactComponent as BackIcon } from "./batch-1/back-icon.svg";
import { ReactComponent as ChatIcon } from "./batch-1/chat-icon.svg";
import { ReactComponent as CloseIcon } from "./batch-1/close-icon.svg";
import { ReactComponent as CreditIcon } from "./batch-1/credit-icon.svg";
import { ReactComponent as DateIcon } from "./batch-1/date-icon.svg";
import { ReactComponent as DeleteIcon } from "./batch-1/delete-icon.svg";
import { ReactComponent as DocsIcon } from "./batch-1/docs-icon.svg";
import { ReactComponent as DownloadIcon } from "./batch-1/download-icon.svg";
import { ReactComponent as EditIcon } from "./batch-1/edit-icon.svg";
import { ReactComponent as HistoryIcon } from "./batch-1/history-icon.svg";
import { ReactComponent as CheckboxIcon } from "./batch-1/checkbox-icon.svg";
import { ReactComponent as LogoIcon } from "./batch-1/logo-icon.svg";
import { ReactComponent as MicrophoneIcon } from "./batch-1/microphone-icon.svg";
import { ReactComponent as MoreIcon } from "./batch-1/more-icon.svg";
import { ReactComponent as NoteIcon } from "./batch-1/note-icon.svg";
import { ReactComponent as OpenIcon } from "./batch-1/open-icon.svg";
import { ReactComponent as PeopleIcon } from "./batch-1/people-icon.svg";
import { ReactComponent as PlayIcon } from "./batch-1/play-icon.svg";
import { ReactComponent as ProfileIcon } from "./batch-1/profile-icon.svg";
import { ReactComponent as SettingsIcon } from "./batch-1/settings-icon.svg";
import { ReactComponent as StopIcon } from "./batch-1/stop-icon.svg";
import { ReactComponent as UploadIcon } from "./batch-1/upload-icon.svg";
import { ReactComponent as WindowIcon } from "./batch-1/window-icon.svg";
import { ReactComponent as ChatHistoryIcon } from "./batch-1/chat-history-icon.svg";
import { ReactComponent as SearchIcon } from "./batch-1/search-icon.svg";
import { ReactComponent as SendIcon } from "./batch-1/send-icon.svg";
import { ReactComponent as DarkModeIcon } from "./batch-1/dark-mode-icon.svg";
import { ReactComponent as LightModeIcon } from "./batch-1/light-mode-icon.svg";
import { ReactComponent as LogoutIcon } from "./batch-1/logout-icon.svg";
import { ReactComponent as PromptIcon } from "./batch-1/prompt-icon.svg";
import { ReactComponent as TranscribeOffIcon } from "./batch-1/transcribe-off-icon.svg";
import { ReactComponent as TranscribeOnIcon } from "./batch-1/transcribe-on-icon.svg";
import { ReactComponent as SpeakOffIcon } from "./batch-1/speak-off-icon.svg";
import { ReactComponent as SpeakOnIcon } from "./batch-1/speak-on-icon.svg";
import { ReactComponent as ResetIcon } from "./batch-1/reset-icon.svg";
import { ReactComponent as ColorHandleIcon } from "./batch-1/color-handle-icon.svg";
import { ReactComponent as ColorBrushIcon } from "./batch-1/color-brush-icon.svg";
import { ReactComponent as PasswordHideIcon } from "./batch-1/password-hide-icon.svg";
import { ReactComponent as PasswordShowIcon } from "./batch-1/password-show-icon.svg";
import { ReactComponent as ArrowOpenIcon } from "./batch-1/arrow-open-icon.svg";
import { ReactComponent as ArrowCloseIcon } from "./batch-1/arrow-close-icon.svg";
import { ReactComponent as StatsIcon } from "./batch-1/stats-icon.svg";
import { ReactComponent as TagIcon } from "./batch-1/tag-icon.svg";
import { ReactComponent as ExpertiseIcon } from "./batch-1/expertise-icon.svg";
import { ReactComponent as PersonaIcon } from "./batch-1/persona-icon.svg";
import { ReactComponent as SubmitIcon } from "./batch-1/submit-icon.svg";
import { ReactComponent as ShuffleIcon } from "./batch-1/shuffle-icon.svg";
import { ReactComponent as AutoPlayInactiveIcon } from "./batch-1/auto-play-inactive-icon.svg";
import { ReactComponent as AutoPlayActiveIcon } from "./batch-1/auto-play-active-icon.svg";
import { ReactComponent as AutoPlayGearsIcon } from "./batch-1/auto-play-gears-icon.svg";
import { ReactComponent as SaveIcon } from "./batch-1/save-icon.svg";
import { ReactComponent as ArrowLeftIcon } from "./batch-1/arrow-left-icon.svg";
import { ReactComponent as ArrowRightIcon } from "./batch-1/arrow-right-icon.svg";
import { ReactComponent as CommandIcon } from "./batch-1/command-icon.svg";
import { ReactComponent as AttachmentIcon } from "./batch-2/attachment-icon.svg";
import { ReactComponent as CsvIcon } from "./batch-2/csv-icon.svg";
import { ReactComponent as ImageIcon } from "./batch-2/image-icon.svg";
import { ReactComponent as JpgIcon } from "./batch-2/jpg-icon.svg";
import { ReactComponent as PdfIcon } from "./batch-2/pdf-icon.svg";
import { ReactComponent as PngIcon } from "./batch-2/png-icon.svg";
import { ReactComponent as TxtIcon } from "./batch-2/txt-icon.svg";
import { ReactComponent as ForwardIcon } from "./batch-2/forward-icon.svg";
import { ReactComponent as CopyIcon } from "./batch-2/copy-icon.svg";
import { ReactComponent as EventIcon } from "./batch-2/event-icon.svg";
import { ReactComponent as ExpertIcon } from "./batch-2/expert-icon.svg";
import { ReactComponent as MessageIcon } from "./batch-2/message-icon.svg";
import { ReactComponent as LoginIcon } from "./batch-2/login-icon.svg";
import { ReactComponent as SignupIcon } from "./batch-2/signup-icon.svg";
import { ReactComponent as HomeIcon } from "./batch-2/home-icon.svg";
import { ReactComponent as Layout1Icon } from "./batch-2/layout-1-icon.svg";
import { ReactComponent as Layout2Icon } from "./batch-2/layout-2-icon.svg";
import { ReactComponent as Layout3Icon } from "./batch-2/layout-3-icon.svg";
import { ReactComponent as CreateExpertIcon } from "./batch-2/create-expert-icon.svg";

interface IconProps {
  className?: string;
}

// checkbox-icon.svg
function Checkbox(props: IconProps) {
  return <CheckboxIcon className={props.className} />;
}

// checkbox-checked-icon.svg
function CheckboxChecked(props: IconProps) {
  return <CheckboxCheckedIcon className={props.className} />;
}

// add-alt-icon.svg
function AddAlt(props: IconProps) {
  return <AddAltIcon className={props.className} />;
}

// add-icon.svg
function Add(props: IconProps) {
  return <AddIcon className={props.className} />;
}

// back-icon.svg
function Back(props: IconProps) {
  return <BackIcon className={props.className} />;
}

// chat-icon.svg
function Chat(props: IconProps) {
  return <ChatIcon className={props.className} />;
}

// close-icon.svg
function Close(props: IconProps) {
  return <CloseIcon className={props.className} />;
}

// credit-icon.svg
function Credit(props: IconProps) {
  return <CreditIcon className={props.className} />;
}

// date-icon.svg
function Date(props: IconProps) {
  return <DateIcon className={props.className} />;
}

// delete-icon.svg
function Delete(props: IconProps) {
  return <DeleteIcon className={props.className} />;
}

// docs-icon.svg
function Docs(props: IconProps) {
  return <DocsIcon className={props.className} />;
}
// download-icon.svg
function Download(props: IconProps) {
  return <DownloadIcon className={props.className} />;
}

// edit-icon.svg
function Edit(props: IconProps) {
  return <EditIcon className={props.className} />;
}

// history-icon.svg
function History(props: IconProps) {
  return <HistoryIcon className={props.className} />;
}

// logo-icon.svg
function Logo(props: IconProps) {
  return <LogoIcon className={props.className} />;
}

// microphone-icon.svg
function Microphone(props: IconProps) {
  return <MicrophoneIcon className={props.className} />;
}

// more-icon.svg
function More(props: IconProps) {
  return <MoreIcon className={props.className} />;
}

// note-icon.svg
function Note(props: IconProps) {
  return <NoteIcon className={props.className} />;
}

// open-icon.svg
function Open(props: IconProps) {
  return <OpenIcon className={props.className} />;
}

// people-icon.svg
function People(props: IconProps) {
  return <PeopleIcon className={props.className} />;
}

// play-icon.svg
function Play(props: IconProps) {
  return <PlayIcon className={props.className} />;
}

// profile-icon.svg
function Profile(props: IconProps) {
  return <ProfileIcon className={props.className} />;
}

// settings-icon.svg
function Settings(props: IconProps) {
  return <SettingsIcon className={props.className} />;
}

// stop-icon.svg
function Stop(props: IconProps) {
  return <StopIcon className={props.className} />;
}

// upload-icon.svg
function Upload(props: IconProps) {
  return <UploadIcon className={props.className} />;
}

// window-icon.svg
function Window(props: IconProps) {
  return <WindowIcon className={props.className} />;
}
function ChatHistory(props: IconProps) {
  return <ChatHistoryIcon className={props.className} />;
}

function Search(props: IconProps) {
  return <SearchIcon className={props.className} />;
}

function Send(props: IconProps) {
  return <SendIcon className={props.className} />;
}

function DarkMode(props: IconProps) {
  return <DarkModeIcon className={props.className} />;
}

function LightMode(props: IconProps) {
  return <LightModeIcon className={props.className} />;
}

function Logout(props: IconProps) {
  return <LogoutIcon className={props.className} />;
}

function Prompt(props: IconProps) {
  return <PromptIcon className={props.className} />;
}

function TranscribeOff(props: IconProps) {
  return <TranscribeOffIcon className={props.className} />;
}

function TranscribeOn(props: IconProps) {
  return <TranscribeOnIcon className={props.className} />;
}

function SpeakOff(props: IconProps) {
  return <SpeakOffIcon className={props.className} />;
}

function SpeakOn(props: IconProps) {
  return <SpeakOnIcon className={props.className} />;
}

function Reset(props: IconProps) {
  return <ResetIcon className={props.className} />;
}

function ColorHandle(props: IconProps) {
  return <ColorHandleIcon className={props.className} />;
}

function ColorBrush(props: IconProps) {
  return <ColorBrushIcon className={props.className} />;
}

function PasswordHide(props: IconProps) {
  return <PasswordHideIcon className={props.className} />;
}

function PasswordShow(props: IconProps) {
  return <PasswordShowIcon className={props.className} />;
}

function ArrowOpen(props: IconProps) {
  return <ArrowOpenIcon className={props.className} />;
}

function ArrowClose(props: IconProps) {
  return <ArrowCloseIcon className={props.className} />;
}

function Stats(props: IconProps) {
  return <StatsIcon className={props.className} />;
}

function Tag(props: IconProps) {
  return <TagIcon className={props.className} />;
}

function Expertise(props: IconProps) {
  return <ExpertiseIcon className={props.className} />;
}

function Persona(props: IconProps) {
  return <PersonaIcon className={props.className} />;
}

function Submit(props: IconProps) {
  return <SubmitIcon className={props.className} />;
}

function Shuffle(props: IconProps) {
  return <ShuffleIcon className={props.className} />;
}

function AutoPlayActive(props: IconProps) {
  return <AutoPlayActiveIcon className={props.className} />;
}

function AutoPlayInactive(props: IconProps) {
  return <AutoPlayInactiveIcon className={props.className} />;
}

function AutoPlayGears(props: IconProps) {
  return <AutoPlayGearsIcon className={props.className} />;
}

function Save(props: IconProps) {
  return <SaveIcon className={props.className} />;
}

function ArrowLeft(props: IconProps) {
  return <ArrowLeftIcon className={props.className} />;
}

function ArrowRight(props: IconProps) {
  return <ArrowRightIcon className={props.className} />;
}

function Command(props: IconProps) {
  return <CommandIcon className={props.className} />;
}

function Attachment(props: IconProps) {
  return <AttachmentIcon className={props.className} />;
}

function Csv(props: IconProps) {
  return <CsvIcon className={props.className} />;
}

function Image(props: IconProps) {
  return <ImageIcon className={props.className} />;
}

function Jpg(props: IconProps) {
  return <JpgIcon className={props.className} />;
}

function Pdf(props: IconProps) {
  return <PdfIcon className={props.className} />;
}

function Png(props: IconProps) {
  return <PngIcon className={props.className} />;
}

function Txt(props: IconProps) {
  return <TxtIcon className={props.className} />;
}

function Forward(props: IconProps) {
  return <ForwardIcon className={props.className} />;
}

function Copy(props: IconProps) {
  return <CopyIcon className={props.className} />;
}

function Event(props: IconProps) {
  return <EventIcon className={props.className} />;
}

function Expert(props: IconProps) {
  return <ExpertIcon className={props.className} />;
}

function Message(props: IconProps) {
  return <MessageIcon className={props.className} />;
}

function Login(props: IconProps) {
  return <LoginIcon className={props.className} />;
}

function Signup(props: IconProps) {
  return <SignupIcon className={props.className} />;
}

function Home(props: IconProps) {
  return <HomeIcon className={props.className} />;
}

function Layout1(props: IconProps) {
  return <Layout1Icon className={props.className} />;
}

function Layout2(props: IconProps) {
  return <Layout2Icon className={props.className} />;
}

function Layout3(props: IconProps) {
  return <Layout3Icon className={props.className} />;
}

function CreateExpert(props: IconProps) {
  return <CreateExpertIcon className={props.className} />;
}

// export default all icons
export {
  Checkbox,
  CheckboxChecked,
  AddAlt,
  Add,
  Back,
  Chat,
  Close,
  Credit,
  Date,
  Delete,
  Docs,
  Download,
  Edit,
  History,
  Logo,
  Microphone,
  More,
  Note,
  Open,
  People,
  Play,
  Profile,
  Settings,
  Stop,
  Upload,
  Window,
  ChatHistory,
  Search,
  Send,
  DarkMode,
  LightMode,
  Logout,
  Prompt,
  TranscribeOff,
  TranscribeOn,
  SpeakOff,
  SpeakOn,
  Reset,
  ColorHandle,
  ColorBrush,
  PasswordHide,
  PasswordShow,
  ArrowOpen,
  ArrowClose,
  Stats,
  Tag,
  Expertise,
  Persona,
  Submit,
  Shuffle,
  AutoPlayActive,
  AutoPlayInactive,
  AutoPlayGears,
  Save,
  ArrowLeft,
  ArrowRight,
  Command,
  Attachment,
  Csv,
  Image,
  Jpg,
  Pdf,
  Png,
  Txt,
  Forward,
  Copy,
  Event,
  Expert,
  Message,
  Login,
  Signup,
  Home,
  Layout1,
  Layout2,
  Layout3,
  CreateExpert,
};
