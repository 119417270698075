import ReactMarkdown from "react-markdown";
import { getCustomMarkdownComponents } from "../PublicMarkdownComponents";
import { Box, useTheme } from "@mui/material";
import { privacyPolicyContent } from "./privacy";
import { useEffect } from "react";

const PrivacyPolicy = () => {
  const theme = useTheme();
  const customComponents = getCustomMarkdownComponents(theme);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 250);
  }, []);

  return (
    <Box padding={"32px"}>
      <ReactMarkdown components={customComponents}>
        {privacyPolicyContent}
      </ReactMarkdown>
    </Box>
  );
};

export default PrivacyPolicy;
