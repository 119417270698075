import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../routes/routes";
import { handleAxiosError } from "../../app/ErrorHandler";
import {
  IKnowledgeDoc,
  ICreateKnowledgeDocPayload,
  IErrorMessage,
  IFilesUploadPayload,
} from "../../types/index";
import { RootState } from "../../app/store";
import { fetchExpertise } from "./expertiseSlice";
import { IExpert } from "../../types";
import { showErrorNotification } from "../ui/errorSlice";

const initialState = {
  loading: false,
  isUploading: false,
  activeKnowledgeDoc: null as IKnowledgeDoc | null,
  error: "",
};

export const fetchKnowledgeDoc = createAsyncThunk(
  "knowledgeDoc/fetchKnowledgeDoc",
  async (docId: string, { rejectWithValue }) => {
    try {
      const result = await api.fetchKnowledgeDoc(docId);
      return result.data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const createKnowledgeDocs = createAsyncThunk(
  "knowledgeDoc/createKnowledgeDoc",
  async (
    filesToUpload: IFilesUploadPayload,
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      const expert = state.experts.activeExpert as IExpert;
      const userDetails = state.userDetails.userDetails;
      const currentUserId = userDetails?.user;

      if (
        filesToUpload.files !== undefined &&
        filesToUpload.files &&
        filesToUpload.files.length > 0
      ) {
        try {
          for (let i = 0; i < filesToUpload.files.length; i++) {
            const file = filesToUpload.files[i];
            const newKnowledgeDoc = file as ICreateKnowledgeDocPayload;
            newKnowledgeDoc.expertId = expert._id;
            newKnowledgeDoc.userId = currentUserId;
            filesToUpload.files[i] = newKnowledgeDoc;
          }
        } catch (error) {
          const errorMessage = {
            title: "Upload Failed",
            message: `Error processing files: ${error.message}`,
          } as IErrorMessage;

          dispatch(showErrorNotification(errorMessage));
          return rejectWithValue(handleAxiosError(error));
        }

        try {
          await api.createKnowledgeDocsFromFiles(filesToUpload);
        } catch (uploadError) {
          const errorMessage = {
            title: "Upload Failed",
            message: `Error uploading files: ${uploadError.message}`,
          } as IErrorMessage;

          dispatch(showErrorNotification(errorMessage));
          return rejectWithValue(handleAxiosError(uploadError));
        }

        return "success";
      } else {
        if (
          filesToUpload.urlKnowledge !== undefined &&
          filesToUpload.urlKnowledge !== null
        ) {
          const newKnowledgeDoc =
            filesToUpload.urlKnowledge as ICreateKnowledgeDocPayload;
          const payload = {
            ...newKnowledgeDoc,
            expertId: expert._id,
            userId: currentUserId,
          };

          await api.createKnowledgeDocFromUrl(payload);
          return "success";
        }
      }

      // nothing returned
      throw new Error("No files to upload.");
    } catch (error) {
      const errorMessage = {
        title: "Upload Failed",
        message: handleAxiosError(error) ?? "Error saving content.",
      } as IErrorMessage;

      if (error.response.status === 405) {
        errorMessage.message = error.response.data.message;
      }

      dispatch(showErrorNotification(errorMessage));
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const updateKnowledgeDoc = createAsyncThunk(
  "knowledgeDoc/updateKnowledgeDoc",
  async (updatedKnowledgeDoc: IKnowledgeDoc, { rejectWithValue }) => {
    try {
      const result = await api.updateKnowledgeDoc(updatedKnowledgeDoc);
      return result.data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const deleteKnowledgeDoc = createAsyncThunk(
  "knowledgeDoc/deleteKnowledgeDoc",
  async (docId: string, { getState, dispatch, rejectWithValue }) => {
    try {
      await api.deleteKnowledgeDoc(docId);
      const state = getState() as RootState;
      const expert = state.experts.activeExpert as IExpert;
      dispatch(fetchExpertise(expert));
      return "success";
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const fetchThumbnail = createAsyncThunk(
  "knowledgeDoc/fetchThumbnail",
  async (url: string, { rejectWithValue }) => {
    const cachedImage = localStorage.getItem(url);

    if (cachedImage) {
      return cachedImage;
    }

    try {
      const response = await api.fetchThumbnail(url);
      const base64Image = response.data.base64Image;

      // Save the fetched image to local storage
      localStorage.setItem(url, base64Image);

      return base64Image; // Return the base64 image string
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const knowledgeDocSlice = createSlice({
  name: "knowledgeDoc",
  initialState: initialState,
  reducers: {
    selectKnowledgeDoc: (state, action) => {
      state.activeKnowledgeDoc = action.payload;
    },
    clearKnowledgeDoc: (state) => {
      state.activeKnowledgeDoc = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchKnowledgeDoc.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchKnowledgeDoc.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.activeKnowledgeDoc = action.payload;
    });
    builder.addCase(fetchThumbnail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchThumbnail.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchThumbnail.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(fetchKnowledgeDoc.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(updateKnowledgeDoc.fulfilled, () => {
      // handle knowledge doc updated
    });
    builder.addCase(deleteKnowledgeDoc.fulfilled, () => {
      // handle knowledge doc deleted
    });
  },
});

export const { selectKnowledgeDoc, clearKnowledgeDoc } =
  knowledgeDocSlice.actions;

export default knowledgeDocSlice.reducer;
