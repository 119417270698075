import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { Play, Stop } from "../../icons";
import { PulseLoader } from "react-spinners";
import PrimaryIcon from "./PrimaryIcon";
import { useAppSelector } from "../../app/store";

export enum PlayButtonState {
  DEFAULT,
  LOADING,
  PLAYING,
}

export type PlayButtonProps = {
  onPlay: () => void;
  onStop: () => void;
  colorOverride?: string;
  buttonStateOverride?: PlayButtonState;
  activeMessageId?: string; // Add this to identify which message/voice this button controls
};

const PlayButton: React.FC<PlayButtonProps> = ({
  onPlay,
  onStop,
  colorOverride,
  buttonStateOverride,
  activeMessageId, // Identifies which message this button controls
}) => {
  const theme = useTheme();
  const textToSpeechSlice = useAppSelector((state) => state.textToSpeech);

  const [color, setColor] = useState<string>(
    colorOverride ?? theme.palette.primary.main
  );

  const [buttonState, setButtonState] = useState<PlayButtonState>(
    buttonStateOverride ?? PlayButtonState.DEFAULT
  );

  // Update color when colorOverride changes
  useEffect(() => {
    setColor(colorOverride ?? theme.palette.primary.main);
  }, [colorOverride, theme.palette.primary.main]);

  // Update button state based on global textToSpeechSlice state
  useEffect(() => {
    if (textToSpeechSlice.isPlaying) {
      setButtonState(PlayButtonState.PLAYING);
    } else if (textToSpeechSlice.isLoading) {
      setButtonState(PlayButtonState.LOADING);
    } else {
      setButtonState(PlayButtonState.DEFAULT);
    }
  }, [
    textToSpeechSlice.activePlayingMessage,
    textToSpeechSlice.isPlaying,
    textToSpeechSlice.isLoading,
    activeMessageId,
  ]);

  // Function to render the button based on the state
  const renderButton = () => {
    switch (buttonState) {
      case PlayButtonState.LOADING:
        return (
          <Box paddingRight={"4px"} paddingTop={"4px"} onClick={onStop}>
            <PulseLoader color={color} loading={true} size={"6.5px"} />
          </Box>
        );
      case PlayButtonState.PLAYING:
        return (
          <PrimaryIcon
            iconName={"Stop"}
            handleIconClick={onStop}
            icon={<Stop />}
            highlightedIconColorOverride={color}
            defaultColorOverride={color}
            backgroundColorOverride={theme.palette.background.buttonHighlighted}
          />
        );
      default:
        return (
          <PrimaryIcon
            iconName={"Play"}
            handleIconClick={onPlay}
            icon={<Play />}
            stopPropagation={true}
            highlightedIconColorOverride={color}
            backgroundColorOverride={theme.palette.background.buttonHighlighted}
          />
        );
    }
  };

  return renderButton();
};

export default PlayButton;
