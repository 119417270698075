import { useState, useEffect, useRef } from "react";
import NotificationPopup from "../popups/NotificationPopup";
import {
  logout,
  setForceLogout,
  setManualLogout,
} from "../../features/users/userAuthSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { IUserAuth } from "../../types";

const TokenValidator = () => {
  const [showModal, setShowModal] = useState(false);
  const userAuthSlice = useAppSelector((state) => state.userAuth);
  const userAuth = userAuthSlice.userAuth;
  const manualLogout = userAuthSlice.manualLogout;
  const dispatch = useAppDispatch();
  const userRef = useRef<IUserAuth | null>(null);

  useEffect(() => {
    if (userAuthSlice.forceLogout) {
      dispatch(logout());
      dispatch(setManualLogout(false));
      dispatch(setForceLogout(false));
      setShowModal(true);
    }
  }, [userAuthSlice.forceLogout]);

  useEffect(() => {
    if (userRef.current !== null) {
      if (userAuth === null) {
        if (!manualLogout) {
          dispatch(logout());
          dispatch(setManualLogout(false));
          dispatch(setForceLogout(false));
          setShowModal(true);
        }
      }
    }

    userRef.current = userAuth;
  }, [userAuth]);

  return showModal ? <NotificationPopup triggerPopup={true} /> : <div></div>;
};

export default TokenValidator;
