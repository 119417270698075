import React, { useEffect, useState } from "react";
import { Box, CircularProgress, useTheme } from "@mui/material";
import {
  PlayButtonProps,
  PlayButtonState,
} from "../../../../ui-elements/PlayButton";
import { PrimaryIcon } from "../../../../ui-elements";
import { Play, Stop } from "../../../../../icons";

const HomePlayButton: React.FC<PlayButtonProps> = ({
  onPlay,
  onStop,
  colorOverride,
  buttonStateOverride,
}) => {
  const theme = useTheme();

  const [color, setColor] = useState<string>(
    colorOverride ?? theme.palette.primary.main
  );

  const [buttonState, setButtonState] = useState<PlayButtonState>(
    buttonStateOverride ?? PlayButtonState.DEFAULT
  );

  useEffect(() => {
    setColor(colorOverride ?? theme.palette.primary.main);
  }, [colorOverride]);

  useEffect(() => {
    setButtonState(buttonStateOverride ?? PlayButtonState.DEFAULT);
  }, [buttonStateOverride]);

  const renderButton = () => {
    switch (buttonState) {
      case PlayButtonState.LOADING:
        return (
          <Box
            justifyContent={"center"}
            display={"flex"}
            alignContent={"center"}
            height="44px"
            width="44px"
            paddingTop={"6px"}
            paddingRight={"5px"}
            onClick={onStop}
          >
            <CircularProgress
              size={"32px"}
              thickness={6}
              sx={{ color: color }}
            />
          </Box>
        );
      case PlayButtonState.PLAYING:
        return (
          <PrimaryIcon
            iconName={"Stop"}
            handleIconClick={onStop}
            icon={<Stop />}
            highlightedIconColorOverride={color}
            highlightedColorOverride={"transparent"}
            defaultColorOverride={color}
            backgroundColorOverride={"transparent"}
            size="44px"
          />
        );
      default:
        return (
          <PrimaryIcon
            iconName={"Play"}
            handleIconClick={onPlay}
            icon={<Play />}
            stopPropagation={true}
            highlightedIconColorOverride={color}
            highlightedColorOverride={"transparent"}
            defaultColorOverride={color}
            backgroundColorOverride={"transparent"}
            size="44px"
          />
        );
    }
  };

  return renderButton();
};

export default HomePlayButton;
