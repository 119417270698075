import React from "react";
import { Avatar, AvatarProps, SxProps } from "@mui/material";
import { IUserDetails } from "../../types/index";

interface VEAvatarProps extends Omit<AvatarProps, "component"> {
  userDetails: IUserDetails | null;
  sx?: SxProps;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const VE_Avatar: React.FC<VEAvatarProps> = ({
  userDetails,
  sx,
  onClick,
  ...rest
}) => {
  return (
    <Avatar
      component="div"
      sx={sx}
      alt={userDetails?.firstName}
      src={userDetails?.picture}
      imgProps={{ referrerPolicy: "no-referrer" }}
      onClick={onClick}
      {...rest}
    >
      VE
    </Avatar>
  );
};

export default VE_Avatar;
