import { useState } from "react";
import {
  Box,
  Typography,
  Modal,
  Backdrop,
  useTheme,
  Button,
  lighten,
  darken,
} from "@mui/material";
import { keyframes } from "@emotion/react";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { SubscriptionStatus } from "../../types/user/ISubscriptionInfo";
import { NumberFormatterService } from "../../services/NumberFormatterService";
import { setAPopupIsShowing } from "../../features/ui/confirmationPopupSlice";
import { useNavigate } from "react-router-dom";

const shimmer = (color) => keyframes`
  0% { background-position: -100%; }
  100% { background-position: 100%; }
`;

interface UpgradeButtonProps {
  buttonText?: string;
  buttonColor?: string;
  onClick?: () => void;
}

const UpgradeButton = ({
  buttonText = "Low Data",
  buttonColor = null,
  onClick = null,
}: UpgradeButtonProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const subscriptionInfo = useAppSelector(
    (state) => state.userSubscription?.userSubscription
  );
  const baseColor = buttonColor || theme.palette.colors.gold[600];

  const currentPlanName = subscriptionInfo?.planName;
  if (currentPlanName?.toLowerCase() === "premium") {
    return null;
  }

  if (!subscriptionInfo) return null;

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    dispatch(setAPopupIsShowing(false));
  };

  const handleUpgradeClicked = () => {
    handleClose();
    dispatch(setAPopupIsShowing(true));
    navigate("/pricing");
  };

  const totalCredits =
    subscriptionInfo.currentDataLimit + subscriptionInfo.bonusCredits;
  const creditsLeft = totalCredits - subscriptionInfo.currentDataUsage;
  const percentageLeft = (creditsLeft / totalCredits) * 100;

  const shouldShowUpgrade = () => {
    if (!subscriptionInfo) return false;
    const currentStatus = subscriptionInfo.subscriptionStatus;

    if (percentageLeft > 10) return false;

    switch (currentStatus) {
      case SubscriptionStatus.ACTIVE:
      case SubscriptionStatus.PENDING_CHANGE:
      case SubscriptionStatus.PENDING_CANCEL:
        return true;
      case SubscriptionStatus.PENDING_NEW:
      case SubscriptionStatus.CANCELED:
      case SubscriptionStatus.NONE:
        return false;
      default:
        return false;
    }
  };

  if (!shouldShowUpgrade()) return null;

  const formattedBonusCredits =
    subscriptionInfo.bonusCredits > 0
      ? NumberFormatterService.formatNumberWithComma(
          subscriptionInfo.bonusCredits
        )
      : 0;
  const formattedCreditsLeft =
    NumberFormatterService.formatNumberWithComma(creditsLeft);
  const formattedTotalCredits =
    NumberFormatterService.formatNumber(totalCredits);

  return (
    <Box display={"flex"} justifyContent={"center"}>
      <Box
        sx={{
          fontWeight: 700,
          padding: "8px 16px",
          maxWidth: "160px",
          backgroundColor: baseColor,
          color: "white",
          cursor: "pointer",
          animation: `${shimmer(baseColor)} 2s linear infinite`,
          background: `linear-gradient(to right, ${baseColor} 20%, ${lighten(
            baseColor,
            0.2
          )} 40%, ${baseColor} 60%)`,
          backgroundSize: "200% 100%",
          borderRadius: "8px",
          "&:hover": {
            animationPlayState: "paused",
          },
        }}
        onClick={onClick ? onClick : handleOpen}
      >
        {buttonText ? buttonText : "Low Data!"}
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          sx: {
            backdropFilter: "blur(10px)",
          },
        }}
        aria-labelledby="upgrade-modal"
        aria-describedby="upgrade-description"
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "16px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            id="upgrade-modal"
            variant="h6"
            component="h2"
            textAlign={"center"}
          >
            Upgrade Your Plan
          </Typography>
          <Typography
            id="upgrade-description"
            sx={{ mt: 2, fontSize: "12px", textAlign: "center" }}
          >
            {`You have only ${formattedCreditsLeft} credits remaining out of ${formattedTotalCredits}.
            ${
              subscriptionInfo.bonusCredits > 0
                ? ` (Plus ${formattedBonusCredits} bonus credits.)`
                : ""
            }
              Upgrade now to continue using VirtualExperts
              services without interruption.`}
          </Typography>
          <Button
            sx={{
              mt: 4,
              background: baseColor,
              fontSize: "12px",
              fontWeight: 700,
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: darken(baseColor, 0.1),
              },
              textTransform: "none",
            }}
            variant="contained"
            disableElevation
            disableFocusRipple
            disableRipple
            onClick={() => {
              handleUpgradeClicked();
            }}
          >
            Upgrade Now
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default UpgradeButton;
