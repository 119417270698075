import { useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import RotatingIcon from "./RotatingIcon";
import CustomTooltip from "./CustomTooltip";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { UtilityService } from "../../services/UtilityService";
import { Layout1, Layout2, Layout3 } from "../../icons";
import {
  ActiveLayout,
  setActiveLayout,
} from "../../features/chats/workspaceSlice";

const LayoutToggleIcon = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const workspaceSlice = useAppSelector((state) => state.workspace);
  const activeLayout = workspaceSlice.activeLayout;
  const userPreferencesSlice = useAppSelector((state) => state.userPreferences);
  const tooltipsEnabled = userPreferencesSlice.userPreferences?.tooltipsEnabled;
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(UtilityService.getIsMobile());
  }, []);

  const handleMouseDown = () => {
    setIsMouseDown(true);
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const handleMouseOver = () => {
    if (isMouseDown) {
      setIsMouseDown(true);
    }
  };

  const handleMouseLeave = () => {
    setIsMouseDown(false);
  };

  const iconForState = (layout) => {
    switch (layout) {
      case ActiveLayout.ONE:
        return <Layout1 />;
      case ActiveLayout.TWO:
        return <Layout2 />;
      case ActiveLayout.THREE:
        return <Layout3 />;
      default:
        return <Layout1 />;
    }
  };

  const handleIconClicked = () => {
    const nextLayout = activeLayout === 3 ? 1 : activeLayout + 1;
    let nextActiveLayout = ActiveLayout.ONE;
    switch (nextLayout) {
      case 1:
        nextActiveLayout = ActiveLayout.ONE;
        break;
      case 2:
        nextActiveLayout = ActiveLayout.TWO;
        break;
      case 3:
        nextActiveLayout = ActiveLayout.THREE;
        break;
      default:
        nextActiveLayout = ActiveLayout.ONE;
        break;
    }
    dispatch(setActiveLayout(nextActiveLayout));
  };

  const children = () => {
    return (
      <IconButton
        data-id={"layout-toggle-icon"}
        component="div"
        onClick={handleIconClicked}
        sx={{
          width: "45px",
          height: "30px",
          borderRadius: "6px",
          marginRight: "4px",
          padding: "6px",
          transition: "0.1s ease-in-out",

          "&:hover": {
            backgroundColor: theme.palette.background.buttonHighlighted,
            transition: "padding 0.2s ease-in-out",
          },
        }}
        disableFocusRipple
        disableRipple
      >
        <RotatingIcon isRotating={false}>
          <Box
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
            onMouseOver={handleMouseOver}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              "&:hover": {
                transform: !isMouseDown ? "scale(1.1)" : null,
              },
              transform: isMouseDown ? "scale(0.8)" : "scale(1)",
              transition: "transform 0.1s ease-in-out",
              width: "45px",
              height: "30px",
              borderRadius: "6px",
              padding: isMobile ? "2.5px" : "6px",
              color: theme.palette.text.secondary,
            }}
          >
            {iconForState(activeLayout)}
          </Box>
        </RotatingIcon>
      </IconButton>
    );
  };

  return tooltipsEnabled ? (
    <CustomTooltip title={"Layout"}>{children()}</CustomTooltip>
  ) : (
    children()
  );
};

export default LayoutToggleIcon;
