import React from "react";
import { Button, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../app/store";

interface HeaderMenuItemProps {
  props: {
    to: string;
    label: string;
  };
  index: number;
}

const HeaderMenuItem: React.FC<HeaderMenuItemProps> = ({ props, index }) => {
  const theme = useTheme();
  const themeSlice = useAppSelector((state) => state.theme);

  return (
    <Button
      key={index}
      component={Link}
      to={props.to}
      sx={{
        paddingLeft: 0,
        paddingRight: 0,
        color: theme.palette.text.secondary,
        textTransform: "none",
        transition: "color 0.2s",
        "&:hover": {
          color: themeSlice.publicColor ?? theme.palette.primary.main,
          backgroundColor: "transparent",
          transition: "color 0.2s",
        },
      }}
    >
      {props.label}
    </Button>
  );
};

export default HeaderMenuItem;
