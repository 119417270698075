import {
  ICreateKnowledgeDocPayload,
  IKnowledgeDoc,
} from "../expert/IKnowledgeDoc";

export interface IMessage {
  _id: string;
  senderId: string;
  senderType: string;
  senderVoiceId: string;
  message: string;
  chatId: string;
  createdAt?: Date;
  tempAudioUrl?: string;
  isPlaying?: boolean;
  knowledgeDocs?: IKnowledgeDoc[] | string[];
  messageState?: MessageState;
  placeholderFiles?: File[];
}

export enum MessageState {
  Queued = "queued",
  Default = "default",
  Processing = "processing",
  Ignored = "ignored",
}

export interface INewMessagePayload {
  chatId: string;
  message: string;
  senderId: string;
  senderType: "User" | "Expert";
  senderVoiceId: string;
  messageFiles?: File[];
  messageFilesPayloads?: ICreateKnowledgeDocPayload[];
}
