// ConfirmationPopup.tsx
import React, { useEffect, useState } from "react";
import { Button, Grid, Typography, Box } from "@mui/material";
import {
  hideConfirmPopup,
  confirmPopup,
  cancelPopup,
  setAPopupIsShowing,
} from "../../features/ui/confirmationPopupSlice";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { useTheme, darken } from "@mui/material/styles";
import PopupBase from "./PopupBase";

const ConfirmationPopup: React.FC = () => {
  const popupState = useAppSelector(
    (state) => state.confirmationPopup.confirmationPopup
  );
  const isDestructive = popupState.isDestructive;
  const title = popupState.title;
  const message = popupState.message;
  const confirmationText = popupState.confirmationText;
  const [entered, setEntered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const dispatch = useAppDispatch();
  const theme = useTheme();

  useEffect(() => {
    if (popupState.show) {
      if (!isOpen) {
        setEntered(false);
        setIsOpen(true);
        dispatch(setAPopupIsShowing(true));
      }
    }
  }, [popupState.show]);

  useEffect(() => {
    if (isOpen) {
      setEntered(true);
    } else {
      setEntered(false);
    }
  }, [isOpen]);

  function handleKeyPress(event: React.KeyboardEvent) {
    console.log(event.key);
    if (event.key === "Enter") {
      handleConfirm();
    }
  }

  const handleClose = () => {
    if (isConfirming) {
      setIsConfirming(false);
    } else {
      dispatch(cancelPopup());
    }

    setEntered(false);
    setIsOpen(false);
    dispatch(hideConfirmPopup());

    dispatch(setAPopupIsShowing(false));
  };

  const handleConfirm = () => {
    setIsConfirming(true);
    dispatch(confirmPopup());
    handleClose();
  };

  return isOpen ? (
    <PopupBase open={isOpen} onClose={handleClose} entered={entered}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems={"center"}
        spacing={3}
      >
        <Grid item>
          <Typography
            sx={{
              fontWeight: 800,
              fontSize: "1.2rem",
              color: theme.palette.text.primary,
              textAlign: "center",
            }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              fontFamily: "Poppins-Regular",
              fontSize: "0.85rem",
              color: theme.palette.text.primary,
              textAlign: "center",
              paddingBottom: "6px",
            }}
          >
            {message}
          </Typography>
        </Grid>
        <Grid item sx={{ gridColumn: "1 / -1", textAlign: "center" }}>
          <Box display={"flex"} flexDirection={"row"}>
            <Button
              sx={{
                fontSize: "0.9rem",
                color: theme.palette.colors.grayScale.white,
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? theme.palette.colors.grayScale[500]
                    : theme.palette.colors.grayScale[300],
                "&:hover": {
                  backgroundColor: theme.palette.colors.grayScale[400],
                },
                width: "120px",
                marginRight: "16px",
                textTransform: "none",
              }}
              autoFocus={true}
              disableFocusRipple={true}
              onClick={(e) => {
                e.stopPropagation();
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{
                fontSize: "0.9rem",
                color: theme.palette.colors.grayScale.white,
                backgroundColor: isDestructive
                  ? theme.palette.colors.red[400]
                  : theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: isDestructive
                    ? theme.palette.error.dark
                    : darken(theme.palette.primary.main, 0.1),
                },
                width: "120px",
                textTransform: "none",
              }}
              autoFocus={true}
              disableFocusRipple={true}
              onClick={(e) => {
                e.stopPropagation();
                handleConfirm();
              }}
              onKeyDown={handleKeyPress}
            >
              {confirmationText}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </PopupBase>
  ) : (
    <div></div>
  );
};

export default ConfirmationPopup;
