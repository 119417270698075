import { useEffect } from "react";
import { EditorState, SelectionState, ContentState } from "draft-js";
import { useAppSelector } from "../../../../../../app/store";
import {
  createCommandDecorator,
  createEmptyDecoratedEditor,
} from "../commands/CommandDecorator";

function useEditModeSetter(messageBarState, editor, hydrateEditorUi) {
  const messagesSlice = useAppSelector((state) => state.messages);
  const commandsSlice = useAppSelector((state) => state.commands);
  const activeExpert = useAppSelector((state) => state.experts.activeExpert);

  useEffect(() => {
    if (messageBarState.isEditing !== messagesSlice.editing) {
      messageBarState.isEditing = messagesSlice.editing;
      if (messagesSlice.editing) {
        enableEditMode();
      } else {
        disableEditMode();
      }
    }
  }, [messagesSlice.activeMessage]);

  function disableEditMode() {
    messageBarState.isEditing = false;
    const newState = createEmptyDecoratedEditor(commandsSlice, activeExpert);
    messageBarState.editorState = newState;
    messageBarState.showPlaceholder = true;
    editor.current?.blur();
  }

  function enableEditMode() {
    messageBarState.isEditing = true;
    editor.current?.focus();
    messageBarState.showPlaceholder = false;
    const message = messagesSlice.activeMessage?.message;
    if (message === null || message === undefined) return;
    const newContent = (message ?? "").replace(/\n/g, " ");
    const contentState = ContentState.createFromText(newContent);

    // create editor state
    const newEditorState = EditorState.createWithContent(
      contentState,
      createCommandDecorator(commandsSlice.commands, activeExpert?.color)
    );

    // Create a new selection state with the last index of the content
    const lastBlock = contentState.getLastBlock();
    const lastBlockKey = lastBlock.getKey();
    const lastBlockLength = lastBlock.getLength();
    const selectionState = new SelectionState({
      anchorKey: lastBlockKey,
      anchorOffset: lastBlockLength,
      focusKey: lastBlockKey,
      focusOffset: lastBlockLength,
    });

    // Update the editor state with the new selection state
    const editorStateWithCursorAtEnd = EditorState.forceSelection(
      newEditorState,
      selectionState
    );

    messageBarState.editorState = editorStateWithCursorAtEnd;
    hydrateEditorUi(messageBarState.editorState);
    // setFocus();
  }
}

export default useEditModeSetter;
