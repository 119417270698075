import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  MenuItem,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { PageTitle } from "../../../customTheme/CustomTextComponents";

const ContactPage = () => {
  const location = useLocation();
  const inboundContactType = new URLSearchParams(location.search).get("type");

  const [contactType, setContactType] = useState(
    inboundContactType || "feedback"
  );
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [messageSent, setMessageSent] = useState(false);

  useEffect(() => {
    if (inboundContactType) {
      setContactType(inboundContactType);
    }
  }, [inboundContactType]);

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleContactTypeChange = (event) => {
    setContactType(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const sendMessage = async () => {
    setIsSending(true);
    // Simulate API call
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSending(false);
    setMessageSent(true);
    // Here you would typically make an API call to the feedback endpoint
    // const response = await yourApiService.sendFeedback({ firstName, email, contactType });
    // Check the response and act accordingly
  };

  const handleCloseSnackbar = () => {
    setMessageSent(false);
  };

  return (
    <Container component="main" maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <PageTitle text="Contact" />
      <Grid container spacing={3} paddingTop={"16px"}>
        <Grid item xs={12} md={6}>
          <TextField
            select
            fullWidth
            label="What's this about?"
            value={contactType}
            onChange={handleContactTypeChange}
          >
            <MenuItem value="feedback">I have feedback</MenuItem>
            <MenuItem value="questions">I have a question</MenuItem>
            <MenuItem value="bug-report">I want to report a bug</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="First Name"
            value={firstName}
            onChange={handleFirstNameChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Email Address"
            value={email}
            onChange={handleEmailChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={sendMessage}
            disabled={isSending}
          >
            {isSending ? <CircularProgress size={24} /> : "Send Message"}
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={messageSent}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Message sent successfully!"
      />
    </Container>
  );
};

export default ContactPage;
