// PopupBase.tsx
import React from "react";
import { Card, Modal, Backdrop, alpha } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface PopupBaseProps {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  entered?: boolean;
}

const PopupBase: React.FC<PopupBaseProps> = ({
  children,
  open,
  onClose,
  entered,
}) => {
  const theme = useTheme();

  return (
    <Modal
      open={open}
      onClose={onClose}
      components={{
        Backdrop: Backdrop,
      }}
      componentsProps={{
        backdrop: {
          sx: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: alpha(theme.palette.background.backdrop, 0.3),
            backdropFilter: "blur(2px)",
            zIndex: 0,
          },
        },
      }}
    >
      <Card
        sx={{
          position: "fixed",
          maxWidth: "400px",
          minWidth: "260px",
          top: "50%",
          left: "50%",
          transform:
            entered != null
              ? entered
                ? "translate(-50%, -50%) scale(1)"
                : "translate(-50%, -50%) scale(0.5)"
              : "translate(-50%, -50%) scale(1)",
          transformOrigin: "center center",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          backgroundColor: `${theme.palette.background.paper} !important`,
          padding: "20px",
          borderRadius: "20px !important",
          boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.5)",
          transition: "transform 0.1s ease-out",
        }}
      >
        {children}
      </Card>
    </Modal>
  );
};

export default PopupBase;
