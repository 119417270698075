import { useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../../../../app/store";
import { navigateBasedOnAuthStage } from "../../../../services/navigateBasedOnAuth";

const AuthStateMachine: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Select necessary state slices
  const userCredentials = useAppSelector(
    (state) => state.userCredentials.userCredentials
  );
  const userDetails = useAppSelector((state) => state.userDetails.userDetails);
  const userSubscription = useAppSelector(
    (state) => state.userSubscription.userSubscription
  );
  const userAuth = useAppSelector((state) => state.userAuth.userAuth);

  // Memoize isDataLoaded to prevent recalculating on every render
  const isDataLoaded = useMemo(() => {
    // Check that all required data exists and is not null
    return (
      userCredentials !== null &&
      userCredentials !== undefined &&
      userDetails !== null &&
      userDetails !== undefined &&
      userSubscription !== null &&
      userSubscription !== undefined &&
      userAuth !== null &&
      userAuth !== undefined
    );
  }, [userCredentials, userDetails, userSubscription, userAuth]);

  useEffect(() => {
    if (isDataLoaded) {
      navigateBasedOnAuthStage(
        navigate,
        {
          userCredentials,
          userDetails,
          subscriptionInfo: userSubscription,
          userAuth,
        },
        location.pathname // Pass the current path as the third argument
      );
    }
  }, [
    isDataLoaded,
    navigate,
    userCredentials,
    userDetails,
    userSubscription,
    userAuth,
    location.pathname, // Ensure location.pathname is part of the dependencies
  ]);

  return null;
};

export default AuthStateMachine;
