export function pluralize(
  count: number,
  singular: string,
  plural: string
): string {
  if (count === 1) {
    return count + " " + singular;
  } else {
    return count + " " + plural;
  }
}

export const timeAgo = (sinceDate) => {
  if (!sinceDate) {
    return "Invalid date";
  }

  const now = new Date();
  const date = new Date(sinceDate);
  const span = now.getTime() - date.getTime();

  // Check if the date conversion was successful
  if (isNaN(date.getTime())) {
    return "Invalid date";
  }

  const seconds = Math.floor(span / 1000);

  // Check if the date is in the future
  if (seconds < 0) {
    return "Date is in the future";
  }

  let interval = Math.floor(seconds / 31536000);

  if (interval >= 1) {
    return `${interval}y`;
  }

  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return `${interval}mo`;
  }

  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return `${interval}d`;
  }

  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return `${interval}h`;
  }

  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return `${interval}m`;
  }

  return `${Math.floor(seconds)}s`;
};

export function formatBytes(bytes, decimals = 0) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}
