// src/redux/slices/imageCacheSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchKnowledgeDocImageUrl } from "../../routes/knowledgeRoutes";
import { RootState } from "../../app/store";

export const fetchImageUrl = createAsyncThunk(
  "imageCache/fetchImageUrl",
  async (docId: string, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const { urls } = state.imageCache;

    if (urls[docId]) {
      return { docId, url: urls[docId] }; // Return cached URL
    }

    try {
      const response = await fetchKnowledgeDocImageUrl(docId);
      return { docId, url: response.data.url };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const imageCacheSlice = createSlice({
  name: "imageCache",
  initialState: {
    urls: {},
    status: "idle",
    error: null,
  },
  reducers: {
    clearImageUrl: (state, action) => {
      const docId = action.payload;
      delete state.urls[docId];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchImageUrl.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchImageUrl.fulfilled, (state, action) => {
        const { docId, url } = action.payload;
        state.status = "succeeded";
        state.urls[docId] = url; // Cache the URL
      })
      .addCase(fetchImageUrl.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { clearImageUrl } = imageCacheSlice.actions;
export default imageCacheSlice.reducer;
