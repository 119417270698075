export enum SubscriptionStatus {
  NONE = "NONE",
  PENDING_NEW = "PENDING_NEW",
  PENDING_CHANGE = "PENDING_CHANGE",
  PENDING_CANCEL = "PENDING_CANCEL",
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  SUSPENDED = "SUSPENDED",
  PAYMENT_FAILED = "PAYMENT_FAILED",
}

export interface ISubscriptionInfo {
  subscriptionStatus: SubscriptionStatus | string;
  planName: string;
  planDescription: string;
  planId: string;
  desiredPlanId: string;
  currentDataUsage: number;
  currentDataLimit: number;
  bonusCredits: number;
  currentStorageUsage: number;
  currentStorageLimit: number;
  currentPeriodStart: Date | null;
  currentPeriodEnd: Date | null;
}
