import {
  chai,
  rai,
  kai,
  chaiSelected,
  raiSelected,
  kaiSelected,
  chaiCard,
  raiCard,
  kaiCard,
} from "../../../../../images/home-page";

export interface HomeExpertsSectionData {
  title: string;
  subtitle: string;
  cardText: string;
  dialogText: string;
  name: string;
  image: any;
  selectedImage: any;
  cardImage: any;
  color: string;
  voiceName: string;
}

export const homeExpertsSectionData: HomeExpertsSectionData[] = [
  {
    title: "Kai",
    subtitle: "conversational",
    cardText: `VirtualExperts.AI enhances your experience with features like <strong style="color: white;">Conversation Playback</strong> and <strong style="color: white;">Transcription</strong>. Choose your voice and your expert's for a personalized audio recap. Enjoy AI chat that feels more like a real conversation.`,
    dialogText: `Hey, I'm Kai! I'm here to tell you a little about how conversational VirtualExperts.AI is. Features like Conversation Playback and Transcription are super helpful. Choose your voice and your expert's voice for a personalized audio recap. Chat back and forth using a combo of the 2. This is your chance to enjoy an AI chat experience that feels a lot more like a real conversation.`,
    name: "Kai",
    image: kai,
    selectedImage: kaiSelected,
    cardImage: kaiCard,
    color: "#9E00FF",
    voiceName: "fable",
  },

  {
    title: "Rai",
    subtitle: "persistent",
    cardText: `Enjoy seamless continuity with our <strong style="color: white;">Chat Memory</strong> feature that organizes all past convos by Expert. Plus, with the <strong style="color: white;">Prompt Cache</strong>, retyping prompts over and over again is a thing of the past.`,
    dialogText: `Hey, I'm Ray! I'm here to tell you a little about a couple persistence features in VirtualExperts.AI. First is Chat Memory. Each expert has their own chat history and as you chat, your expert will recall and use information from those previous conversations. Another huge feature is the Prompt Cache. As you go from chat to chat, you can save prompts that you want to keep using. The idea of retyping prompts over and over again is a thing of the past.`,
    name: "Rai",
    image: rai,
    selectedImage: raiSelected,
    cardImage: raiCard,
    color: "#20BF55",
    voiceName: "alloy",
  },
  {
    title: "Chai",
    subtitle: "personalized",
    cardText: `Customize your experts with full <strong style="color: white;">Conversation Style</strong> and <strong style="color: white;">Expertise</strong> control, making every workspace unique and entertaining.`,
    dialogText: `Hey, I'm Chai! I'm here to tell you a little about how you can personalize your experts. Conversation Style controls allow you to craft your expert's personality and temperment.  Wanna laugh out loud?  Make your expert more humorous. Sick of boilerplate AI disclaimers? Set the response type to short.  Also - every expert has their own knoweldgebase. You can organize websites and document uploads by expert so that when you're chatting, your expert has that knowledge to pull from. Every expert's workspace is unique and entertaining. It's a pretty magical experience.`,
    name: "Chai",
    image: chai,
    selectedImage: chaiSelected,
    cardImage: chaiCard,
    color: "#FF8400",
    voiceName: "echo",
  },
];
