import { Box, Button, Divider, darken } from "@mui/material";
import { Link } from "react-router-dom";
import HeaderMenuItem from "./HeaderMenuItem";
import { useAppSelector } from "../../../app/store";
import AccountPopup from "../../popups/account-popup/AccountPopup";
import PaymentFailed from "../payment-failed/PaymentFailed";
import UpgradeButton from "../../ui-elements/UpgradeButton";

interface MainMenuProps {
  isLoggedIn: boolean;
  isPaymentFailed: boolean;
  isWorkspace: boolean;
  isAccountPage: boolean;
  isOnboarded: boolean;
  isBetaApproved: boolean;
  isActiveSubscription: boolean;
  isVerified: boolean;
}

const MainMenu = ({
  isLoggedIn,
  isPaymentFailed,
  isWorkspace,
  isAccountPage,
  isOnboarded,
  isBetaApproved,
  isActiveSubscription,
  isVerified,
}: MainMenuProps) => {
  const themeSlice = useAppSelector((state) => state.theme);
  const buttons = [];

  if (isLoggedIn && isOnboarded && isBetaApproved && !isActiveSubscription) {
    buttons.push({ label: "Choose Plan", to: "/pricing" });
  } else {
    // if the current page is the pricing page, don't show any buttons
    if (
      !isWorkspace &&
      !isAccountPage &&
      isOnboarded &&
      isBetaApproved &&
      isVerified
    ) {
      buttons.push({ label: "Plans", to: "/pricing" });
    }
  }

  if (isActiveSubscription) {
    if (!isWorkspace) {
      buttons.push({ label: "Workspace", to: "/workspace" });
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        gap: 2,
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      {buttons.map((button, index) => (
        <HeaderMenuItem key={index} props={button} index={index} />
      ))}
      {!isWorkspace && <Divider orientation="vertical" flexItem />}
      {isLoggedIn ? (
        <Box sx={{ display: "flex" }}>
          <AccountPopup />
        </Box>
      ) : (
        <>
          <HeaderMenuItem
            props={{ to: "/auth/login", label: "Sign In" }}
            index={0}
          />
          <Button
            component={Link}
            to="/auth/signup"
            sx={{
              backgroundColor: themeSlice.publicColor,
              transition: "none",
              "&:hover": {
                backgroundColor: darken(themeSlice.publicColor, 0.3),
              },
            }}
            variant="contained"
          >
            Sign Up
          </Button>
        </>
      )}
      {isPaymentFailed ? <PaymentFailed /> : <UpgradeButton />}
    </Box>
  );
};

export default MainMenu;
