export function getDarkCustomStyleMap(activeExpert) {
  return {
    PARTIAL_COMMAND: {
      fontWeight: 700,
    },
    ACTIVE_COMMAND: {
      color: activeExpert?.color ?? "#FF8400",
      fontWeight: 700,
    },
    TEMPORARY_TRANSCRIPTION: {
      color: "rgba(255, 255, 255, 0.6)",
    },
  };
}

export function getCustomStyleMap(activeExpert) {
  return {
    PARTIAL_COMMAND: {
      fontWeight: 700,
    },
    ACTIVE_COMMAND: {
      color: activeExpert?.color ?? "#FF8400",
      fontWeight: 700,
    },
    TEMPORARY_TRANSCRIPTION: {
      color: "rgba(0, 0, 0, 0.7)",
    },
  };
}
