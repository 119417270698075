import { lighten, darken } from "@mui/material/styles";

export const colorBases = {
  blue: "#016FB9",
  gold: "#F3A712",
  green: "#20BF55",
  orange: "#FF8400",
  purple: "#9E00FF",
  red: "#FF1D15",
  teal: "#0B889A",
  magenta: "#FF00FF",
  pink: "#FF6EC7",
  skyBlue: "#1E90FF",
  yellowGreen: "#9ACD32",
  coral: "#FF7F50",
  deepPink: "#FF1493",
  raspberry: "#E91E63",
  amber: "#FFC107",
  deepOrange: "#FF5722",
  turquoise: "#009688",
  tangerine: "#FF9800",
  rosePink: "#F06292",
  orchid: "#BA68C8",
  cornflowerBlue: "#5C6BC0",
  mediumTurquoise: "#4DB6AC",
  dodgerBlue: "#64B5F6",
  darkerLime: "#6BD200",
  lightDeepPurple: "#9575CD",
  lightBrown: "#A1887F",
  lightRoyalBlue: "#5C7CFF",
  rosyBrown: "#BC8F8F",
  burlyWood: "#DEB887",
  saddleBrown: "#C19A6B",
  lightRed: "#E57373",
  darkRed: "#C62828",
  grayScale: {
    50: "#F7F7F7",
    75: "#F0F0F0",
    100: "#D3D3D3",
    200: "#A9A9A9",
    300: "#808080",
    400: "#585858",
    500: "#303030",
    600: "#282828",
    700: "#1F1F1F",
    800: "#171717",
    900: "#0F0F0F",
    white: "#FFFFFF",
    black: "#000000",
  },
};

const createShades = (color: string) => {
  return {
    50: lighten(color, 0.85),
    100: lighten(color, 0.7),
    200: lighten(color, 0.5),
    300: lighten(color, 0.3),
    400: lighten(color, 0.1),
    500: color,
    600: darken(color, 0.1),
    700: darken(color, 0.3),
    800: darken(color, 0.5),
    900: darken(color, 0.7),
  };
};

export const colors = {
  blue: createShades(colorBases.blue),
  gold: createShades(colorBases.gold),
  green: createShades(colorBases.green),
  orange: createShades(colorBases.orange),
  purple: createShades(colorBases.purple),
  red: createShades(colorBases.red),
  teal: createShades(colorBases.teal),
  magenta: createShades(colorBases.magenta),
  pink: createShades(colorBases.pink),
  skyBlue: createShades(colorBases.skyBlue),
  yellowGreen: createShades(colorBases.yellowGreen),
  coral: createShades(colorBases.coral),
  deepPink: createShades(colorBases.deepPink),
  raspberry: createShades(colorBases.raspberry),
  amber: createShades(colorBases.amber),
  deepOrange: createShades(colorBases.deepOrange),
  turquoise: createShades(colorBases.turquoise),
  tangerine: createShades(colorBases.tangerine),
  rosePink: createShades(colorBases.rosePink),
  orchid: createShades(colorBases.orchid),
  cornflowerBlue: createShades(colorBases.cornflowerBlue),
  mediumTurquoise: createShades(colorBases.mediumTurquoise),
  dodgerBlue: createShades(colorBases.dodgerBlue),
  darkerLime: createShades(colorBases.darkerLime),
  lightDeepPurple: createShades(colorBases.lightDeepPurple),
  lightBrown: createShades(colorBases.lightBrown),
  lightRoyalBlue: createShades(colorBases.lightRoyalBlue),
  rosyBrown: createShades(colorBases.rosyBrown),
  burlyWood: createShades(colorBases.burlyWood),
  saddleBrown: createShades(colorBases.saddleBrown),
  lightRed: createShades(colorBases.lightRed),
  darkRed: createShades(colorBases.darkRed),
  grayScale: {
    ...colorBases.grayScale,
  },
} as const;
