import React, { useEffect, useState } from "react";
import { Box, IconButton, Menu, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ArrowOpen } from "../../../icons";
import MenuItemRow, { MenuItemRowProps } from "./MenuItemRow";
import StyledPaper from "../../popups/account-popup/StyledPaper";
import { MenuItem } from "./MenuItemEnums";
import { MenuType } from "./MenuTabs";

export interface MobileMenuTabsProps {
  onItemSelected: (selectedTab: MenuType) => void;
  selectedTab: MenuType;
  mobileMenuItems?: MenuItemRowProps[];
}

const MobileMenuTabs: React.FC<MobileMenuTabsProps> = ({
  onItemSelected,
  selectedTab,
  mobileMenuItems,
}) => {
  const theme = useTheme();
  const [currentTab, setCurrentTab] = useState(selectedTab);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (tab: MenuItem | null) => {
    setAnchorEl(null);
    if (tab === null) return;
    if (tab === selectedTab) return;
  };

  useEffect(() => {
    if (currentTab !== selectedTab) {
      setCurrentTab(selectedTab);
      handleClose(null);
    }
  }, [selectedTab]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
        marginLeft: "-12px",
        marginTop: "4px",
        marginBottom: "4px",
        borderRadius: "4px",
        paddingRight: "6px",
        paddingTop: "2px",
        paddingBottom: "2px",
        "&:hover": {
          backgroundColor: theme.palette.background.buttonHighlighted,
          cursor: "pointer",
        },
      }}
    >
      <Typography
        onClick={handleClick}
        sx={{
          fontSize: "14px",
          fontWeight: 500,
          paddingLeft: "8px",
          paddingRight: "28px",
        }}
      >
        {selectedTab?.toString() ?? "n/a"}
      </Typography>
      <IconButton
        onClick={handleClick}
        disableRipple
        color="inherit"
        sx={{
          height: "16px",
          width: "16px",
          marginLeft: "-20px",
          marginTop: "-6px",
          marginBottom: "-8px",
          padding: "0px",
          background: "transparent",
          "&:hover": {
            background: "transparent",
          },
        }}
      >
        <ArrowOpen />
      </IconButton>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleClose(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            backgroundColor: "transparent",
          },
        }}
      >
        <StyledPaper>
          {mobileMenuItems?.map((item) => (
            <MenuItemRow
              key={item.title}
              title={item.title}
              icon={item.icon}
              onClick={item.onClick}
            />
          ))}
        </StyledPaper>
      </Menu>
    </Box>
  );
};

export default MobileMenuTabs;
