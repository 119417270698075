import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import WorkspacePage from "./workspace-page/WorkspacePage";
import HomePage from "./public-pages/home-page/HomePage";
import PricingPage from "./public-pages/pricing/PricingPage";
import AccountPage from "./account-page/AccountPage";
import { useAppSelector } from "../../app/store";
import PublicLayout from "./public-pages/PublicLayout";
import ProtectedLayout from "./ProtectedLayout";
import TermsPage from "./public-pages/terms/TermsPage";
import PrivacyPage from "./public-pages/privacy/PrivacyPage";
import ContactPage from "./public-pages/ContactPage";
import LoadingPage from "./public-pages/LoadingPage";
import { hasAccessToWorkspace } from "../../services/navigateBasedOnAuth";
import DynamicResizeWithOverlay from "../../DynamicResizeWithOverlay";
import AuthPageManager from "./auth-page/AuthPageManager";

const PageManager: React.FC = () => {
  const userDetailsSlice = useAppSelector((state) => state.userDetails);
  const userCredentialsSlice = useAppSelector((state) => state.userCredentials);
  const userSubscriptionSlice = useAppSelector(
    (state) => state.userSubscription
  );

  const isAuthenticated =
    userDetailsSlice?.userDetails?.onboarded === true &&
    userCredentialsSlice?.userCredentials?.betaApproved === true &&
    userCredentialsSlice?.userCredentials?.verified === true;

  const hasAccess = hasAccessToWorkspace(
    userSubscriptionSlice.userSubscription
  );

  const RootRouteHandler: React.FC = () => {
    if (isAuthenticated && hasAccess) {
      return <Navigate to="/workspace" replace />;
    }
    return <HomePage />;
  };

  return (
    <DynamicResizeWithOverlay>
      <Routes>
        <Route path="/" element={<PublicLayout />}>
          <Route index element={<RootRouteHandler />} />
          <Route path="home" element={<HomePage />} />
          <Route path="pricing" element={<PricingPage />} />
          <Route path="terms" element={<TermsPage />} />
          <Route path="privacy" element={<PrivacyPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="loading" element={<LoadingPage />} />
          <Route path="stripe/payment-success" element={<LoadingPage />} />
          <Route
            path="stripe/payment-cancelled"
            element={<AuthPageManager />}
          />
          <Route path="auth/*" element={<AuthPageManager />} />
        </Route>
        <Route
          element={
            isAuthenticated && hasAccess ? (
              <ProtectedLayout />
            ) : (
              <Navigate to="/home" replace />
            )
          }
        >
          <Route path="workspace" element={<WorkspacePage />} />
          <Route path="account" element={<AccountPage />} />
        </Route>
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
    </DynamicResizeWithOverlay>
  );
};

export default PageManager;
