export class UtilityService {
  static getIsMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    // iOS and iPadOS detection based on user agent and touch capability
    const isIOS =
      /iPad|iPhone|iPod/.test(userAgent) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

    // Android detection
    const isAndroid = /Android/i.test(userAgent);

    // General mobile detection
    const isMobileDevice =
      isIOS ||
      isAndroid ||
      /webOS|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

    return isMobileDevice;
  };

  static debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
}
