import React, { useEffect, useState, useRef } from "react";
import {
  TextField,
  Grid,
  InputAdornment,
  IconButton,
  Typography,
  Button,
  Box,
  Tooltip,
} from "@mui/material";
import { useTheme, darken } from "@mui/material/styles";
import { alpha } from "@mui/system";
import { PasswordShow, PasswordHide, Submit } from "../../icons";
import CustomTooltip from "./CustomTooltip";

export interface InputData {
  name: string;
  value: string;
}

interface CustomInputProps {
  name: string;
  half?: boolean;
  handleChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  label: string;
  autoFocus?: boolean;
  handleShowPassword?: () => void;
  type?: string;
  autoComplete?: string;
  value?: string;
  placeholder?: string;
  startingValue?: string;
  backgroundColorOverride?: string;
  selectedColorOverride?: string;
  handleSubmit?: (inputData: InputData) => void;
  clearOnBlur?: boolean;
  forceInivisible?: boolean;
  disabled?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
}

const CustomInput: React.FC<CustomInputProps> = ({
  name,
  half,
  handleChange,
  label,
  autoFocus,
  handleShowPassword,
  type,
  autoComplete = "off",
  value,
  placeholder,
  startingValue,
  backgroundColorOverride,
  selectedColorOverride,
  handleSubmit,
  clearOnBlur = false,
  forceInivisible,
  disabled = false,
  onFocus,
  onBlur,
}) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(type === "password");
  const isPassword = name === "password";
  const [currentValue, setCurrentValue] = useState(value ?? "");
  const [showPlaceholder, setShowPlaceholder] = useState(
    !currentValue || currentValue === ""
  );
  const [showButton, setShowButton] = useState(false);
  const textfieldRef = useRef(null);
  const [inputPadding, setInputPadding] = useState("0px");

  async function handleButtonClick() {
    if (handleSubmit) {
      setCurrentValue(currentValue);
      await handleSubmit({ name: name, value: currentValue } as InputData);
      setShowButton(false);
      if (textfieldRef.current && textfieldRef.current.nextSibling) {
        (textfieldRef.current.nextSibling as HTMLElement).focus();
      }
    }
  }

  useEffect(() => {
    setInputPadding(showButton ? "60px" : "0px");
  }, [showButton]);

  useEffect(() => {
    if (startingValue && currentValue !== startingValue) {
      setCurrentValue(startingValue);
      const hasCurrentValue = !currentValue || currentValue === "";
      const hasStartingValue = startingValue && startingValue !== "";
      const hidePlaceholder = hasCurrentValue || hasStartingValue;
      setShowPlaceholder(!hidePlaceholder);
    }
  }, [startingValue]);

  const [color, setColor] = useState(null);

  useEffect(() => {
    setColor(selectedColorOverride);
  }, [selectedColorOverride]);

  useEffect(() => {
    setShowPassword(type === "password");
  }, [type]);

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCurrentValue(event.target.value);
    setShowPlaceholder(event.target.value === "");
    handleChange ? handleChange(event) : null;
  };

  return (
    <Box width={half ? "50%" : "100%"}>
      <div
        style={{ position: "relative", display: "inline-block", width: "100%" }}
      >
        <TextField
          ref={textfieldRef}
          autoComplete={autoComplete}
          name={name}
          disabled={disabled}
          onKeyDown={(event) => {
            if (handleSubmit) {
              if (event.key === "Enter") {
                handleButtonClick();
              }
            }
          }}
          onChange={(event) => {
            onChange(event);
            if (handleSubmit) {
              setShowButton(event.target.value !== startingValue);
            }
          }}
          onFocus={() => {
            setShowPlaceholder(false);
            if (handleSubmit) {
              setShowButton(currentValue !== startingValue);
            }
            onFocus && onFocus();
          }}
          onBlur={() => {
            setShowPlaceholder(
              currentValue === "" ||
                currentValue === undefined ||
                currentValue === null
            );

            if (startingValue && currentValue !== startingValue) {
              setCurrentValue(startingValue ?? "");
            }
            setShowButton(false);
            clearOnBlur ? setCurrentValue("") : null;
            clearOnBlur ? setShowPlaceholder(true) : null;
            onBlur && onBlur();
          }}
          variant="outlined"
          sx={{
            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: color,
            },
            "& .Mui-focused .MuiInputLabel-root": {
              color: color,
            },
          }}
          required
          fullWidth
          value={currentValue}
          InputLabelProps={{
            shrink: true,
            style: {
              color: alpha(theme.palette.text.secondary, 0.6),
              fontWeight: 700,
              fontSize: "14px",
              justifyContent: "center",
              alignContent: "center",
              textAlign: "center",
              backgroundColor:
                backgroundColorOverride !== null
                  ? backgroundColorOverride
                  : theme.palette.background.paper,
              paddingLeft: "11px",
              paddingRight: "11px",
              marginLeft: "-3px",
              borderRadius: "8px",
            },
          }}
          label={label}
          autoFocus={autoFocus}
          type={type}
          InputProps={{
            style: {
              paddingRight: inputPadding,
              fontSize: "14px",
              fontWeight: 600,
              textAlign: "center",
              justifyContent: "center",
              height: "40px",
              background:
                backgroundColorOverride !== null
                  ? backgroundColorOverride
                  : theme.palette.background.paper,
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              color: theme.palette.text.primary,
            },
            endAdornment: isPassword ? (
              <InputAdornment position="end">
                <CustomTooltip title={showPassword ? "Hide" : "Show"}>
                  <IconButton
                    onClick={handleShowPassword}
                    color="secondary"
                    disableRipple
                    sx={{
                      width: "32px",
                      height: "32px",
                      borderRadius: "6px",
                      color: theme.palette.text.secondary,
                      marginRight: "4px",
                      padding: "6px",
                      "& .MuiSvgIcon-root": {
                        fontSize: "14px",
                        color: theme.palette.text.secondary,
                        transition: "color 0.2s ease-in-out",
                      },
                      "&:hover": {
                        backgroundColor: "transparent",
                        transition: "padding 0.2s ease-in-out",
                        padding: "3px",
                      },
                    }}
                  >
                    {showPassword ? <PasswordHide /> : <PasswordShow />}
                  </IconButton>
                </CustomTooltip>
              </InputAdornment>
            ) : null,
          }}
        />
        <Button
          onMouseDown={(e) => {
            e.preventDefault(); // Prevents focus from leaving the TextField
            handleButtonClick();
          }}
          sx={{
            position: "absolute",
            top: "50%",
            right: "0px",
            height: "40px",
            width: "20px",
            transform: "translateY(-50%)",
            backgroundColor: color,
            color: "white",
            borderRadius: "0 8px 8px 0",
            transition: "background-color 0.1s ease-in-out",
            "& .MuiSvgIcon-root": {
              fontSize: "20px",
              height: "14px",
              width: "14px",
              icon: {
                fontSize: "14px",
                height: "14px",
                width: "14px",
              },
            },
            "&:hover": {
              backgroundColor: color ? darken(color, 0.1) : undefined,
            },
            visibility: showButton && !forceInivisible ? "visible" : "hidden",
          }}
        >
          <Box
            sx={{
              width: "20px",
              height: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Submit />
          </Box>
        </Button>
      </div>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 400,
          position: "relative",
          top: "-30px",
          left: "14px",
          visibility:
            showPlaceholder && !forceInivisible ? "visible" : "hidden",
          color: theme.palette.text.placeholder,
          pointerEvents: "none",
          height: "0px",
        }}
      >
        {placeholder ?? "Enter " + label}
      </Typography>
    </Box>
  );
};

export default CustomInput;
