import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Tooltip,
  Typography,
  alpha,
  lighten,
  useTheme,
} from "@mui/material";
import { Close } from "../../../../../icons";
import KnowledgeDocThumbnail from "./KnowledgeDocThumbnail";
import { UtilityService } from "../../../../../services/UtilityService";

interface FileCardProps {
  index: number;
  title: string;
  subtitle: string;
  onFileDelete: (index: number) => void;
  disable?: boolean;
  showLoading?: boolean;
  knowledgeDoc?: { type: string; url?: string };
  fullWidth?: boolean;
  file?: File | null;
}

const FileCard: React.FC<FileCardProps> = ({
  index,
  title,
  subtitle,
  onFileDelete,
  disable = false,
  showLoading = false,
  knowledgeDoc,
  fullWidth = false,
  file = null,
}) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);

  const isMobile = UtilityService.getIsMobile();

  useEffect(() => {
    const handleWindowBlur = () => {
      if (isMobile) {
        return;
      }
      setIsHovered(false);
      setIsMouseDown(false);
    };

    window.addEventListener("blur", handleWindowBlur);
    return () => window.removeEventListener("blur", handleWindowBlur);
  }, []);

  const getNameWithoutExtension = (name: string) => {
    const split = name.split(".");
    return split.slice(0, -1).join(".");
  };

  const onMouseLeave = () => {
    if (isMobile) {
      return;
    }
    setIsHovered(false);
  };

  return (
    <Tooltip title={title} placement="top" disableInteractive>
      <Grid
        item
        display="flex"
        onClick={() => !isMobile && !disable && onFileDelete(index)}
        onMouseEnter={() => !isMobile && !disable && setIsHovered(true)}
        onMouseLeave={() => !isMobile && !disable && onMouseLeave()}
        onMouseDown={() => !isMobile && !disable && setIsMouseDown(true)}
        onMouseUp={() => !isMobile && !disable && setIsMouseDown(false)}
        sx={{
          width: fullWidth ? `calc(100% - 8px)` : "auto",
          textAlign: "center",
          height: "48px",
          padding: "2px",
          margin: "4px",
          transform: isMouseDown ? "scale(0.93)" : "scale(1)",
          transition: "transform 0.1s ease",
          borderRadius: "12px",
          cursor: isHovered ? "pointer" : "default",
          color: theme.palette.text.secondary,
          backgroundColor: alpha(theme.palette.text.secondary, 0.05),
          "&:hover": !isMobile
            ? {
                cursor: disable ? undefined : "pointer",
                backgroundColor: disable
                  ? undefined
                  : lighten(alpha(theme.palette.text.secondary, 0.15), 0.1),
              }
            : undefined,
        }}
        justifyContent={fullWidth ? "flex-start" : "center"}
        alignContent="center"
        alignItems="center"
      >
        {isHovered ? (
          <Box
            display="flex"
            alignContent="center"
            justifyContent="center"
            alignItems="center"
            padding="4px"
            margin="4px"
            sx={{
              height: "36px",
              width: "36px",
              backgroundColor: theme.palette.colors.red[500],
              borderRadius: "30%",
              color: theme.palette.colors.grayScale.white,
            }}
          >
            <Close />
          </Box>
        ) : (
          <KnowledgeDocThumbnail
            title={title}
            knowledgeDoc={knowledgeDoc}
            showLoading={showLoading}
            file={file}
          />
        )}
        <Box
          flexDirection="column"
          display="flex"
          justifyContent="center"
          sx={{
            width: "100%",
            height: "100%",
            padding: "4px",
            maxWidth: fullWidth ? "undefined" : "150px",
          }}
        >
          <Typography
            fontSize="12px"
            fontWeight={700}
            textAlign="left"
            paddingRight="2px"
            width={"100%"}
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {getNameWithoutExtension(title)}
          </Typography>
          <Typography
            fontSize="10px"
            fontWeight={200}
            textAlign="left"
            color={theme.palette.text.secondary}
          >
            {subtitle}
          </Typography>
        </Box>
      </Grid>
    </Tooltip>
  );
};

export default FileCard;
