import { Box, Button, darken } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Forward } from "../../../../../icons";
import { styled } from "@mui/material/styles";
import { useAppSelector } from "../../../../../app/store";

// Styled component for the animated button
const AnimatedButton = styled(Button)(({ theme }) => ({
  // Define the hover state styles for the button
  "&:hover .MuiButton-endIcon": {
    animation: "moveArrow 0.3s ease-in-out forwards",
  },
  "@keyframes moveArrow": {
    "0%": {
      transform: "translateX(0)",
    },

    "100%": {
      transform: "translateX(8px)",
    },
  },
}));

const SeePlansButton = () => {
  const themeSlice = useAppSelector((state) => state.theme);
  return (
    <RouterLink to="/pricing" style={{ textDecoration: "none" }}>
      <AnimatedButton
        //color={"primary"}
        variant="contained"
        endIcon={
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ height: "16px", width: "16px" }}
          >
            <Forward />
          </Box>
        }
        sx={{
          height: "40px",
          width: "160px",
          borderRadius: "6px",
          backgroundColor: themeSlice.publicColor,
          "&:hover": {
            backgroundColor: darken(themeSlice.publicColor, 0.3),
          },
          transition: "none",
        }}
      >
        See Plans
      </AnimatedButton>
    </RouterLink>
  );
};

export default SeePlansButton;
