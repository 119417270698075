import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { Box } from "@mui/material";
import MessageBar from "./message-bar/MessageBar";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { useTheme } from "@mui/material/styles";
import FileDrawer from "./file-drawer/FileDrawer";
import { UploadValidatorService } from "../../../../services/UploadValidatorService";
import { showErrorNotification } from "../../../../features/ui/errorSlice";
import { IErrorMessage } from "../../../../types";
import ActionButtons from "./message-bar/actions/ActionButtons";
import useScreenSize from "../../../ui-elements/useScreenSize";
import { ActiveView } from "../../../../features/chats/workspaceSlice";
import { UtilityService } from "../../../../services/UtilityService";

interface MessageBarRef {
  submit: () => void;
  handleCommandsIconTapped: () => void;
}

// footerbar props
interface FooterBarProps {
  messageFiles: File[];
  setMessageFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

const FooterBar: React.FC<FooterBarProps> = ({
  messageFiles,
  setMessageFiles,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const screenSize = useScreenSize();

  const workspaceSlice = useAppSelector((state) => state.workspace);
  const activeView = workspaceSlice.activeView;
  const shouldShowFooter =
    activeView === ActiveView.CACHE || activeView === ActiveView.CHAT;

  const activeExpert = useAppSelector((state) => state.experts.activeExpert);
  const confirmationPopup = useAppSelector((state) => state.confirmationPopup);
  const commandsSlice = useAppSelector((state) => state.commands);
  const isActiveCommand = commandsSlice.activeCommand != null;

  const fileInputRef = useRef<HTMLInputElement>(null);
  const messageBarRef = useRef<MessageBarRef>(null);
  const footerBarRef = useRef<HTMLDivElement>(null);

  const [isFocused, setIsFocused] = useState(false);

  const acceptTypes = useMemo(() => {
    const types = UploadValidatorService.ValidFileTypes;
    // Ensure no spaces in the formatted string
    return types.map((type) => `.${type}`).join(", ");
  }, []);

  useEffect(() => {
    //console.log("workspaceSlice", workspaceSlice);
  }, [messageFiles]);

  useEffect(() => {
    //console.log("commandsSlice", commandsSlice);
  }, [commandsSlice]);

  useEffect(() => {
    // console.log("isFocused changed ", isFocused);
  }, [isFocused]);

  useEffect(() => {
    // console.log("confirmationPopup", confirmationPopup);
  }, [confirmationPopup]);

  const handleSendIconTapped = () => {
    messageBarRef.current?.submit();
  };

  const handleCommandsIconTapped = () => {
    messageBarRef.current?.handleCommandsIconTapped();
  };

  const handleUploadClick = () => {
    if (UtilityService.getIsMobile()) {
      setTimeout(() => {
        fileInputRef.current?.click();
      }, 200);
    } else {
      fileInputRef.current?.click();
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("handleFileChange");
    const target = e.target as HTMLInputElement;
    const newFiles = target.files ? Array.from(target.files) : [];

    const validation = UploadValidatorService.validateFileUpload(
      newFiles,
      messageFiles
    );

    if (!validation.isValid) {
      const errorMessage = {
        title: "Oops!",
        message: validation.message,
      } as IErrorMessage;
      dispatch(showErrorNotification(errorMessage));

      target.value = ""; // Reset the input
      return;
    }

    setMessageFiles((prevFiles) => [...prevFiles, ...newFiles]);
    target.value = ""; // Ensure the input is reset
  };

  const onFileDelete = (index: number) => {
    const newFiles = [...messageFiles];
    newFiles.splice(index, 1);
    setMessageFiles(newFiles.length > 0 ? newFiles : []);
  };

  const [blockGetCommand, setBlockGetCommand] = useState(false);

  const getBoxShadow = () => {
    if (workspaceSlice.isTranscribing) {
      return `0px 0px 0px 2px ${theme.palette.colors.red[500]}`;
    }

    if (isActiveCommand || isFocused) {
      return `0px 0px 0px 2px ${activeExpert?.color ?? "transparent"}`;
    }

    return `0px 0px 0px 1px rgba(0, 0, 0, 0.12)`;
  };

  return shouldShowFooter ? (
    <Box
      // ref={footerBarRef}
      sx={{
        maxWidth: "100%",
        marginLeft: screenSize.isLarge ? "8px" : 0,
        marginRight: screenSize.isLarge ? "8px" : 0,
        height: "auto",
        display: "flex",
        boxShadow: getBoxShadow(),
        background: theme.palette.background.paper,
        borderRadius: "16px",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <FileDrawer
          files={messageFiles}
          onFileDelete={onFileDelete}
          setBlockGetCommand={setBlockGetCommand}
        />
        <MessageBar
          ref={messageBarRef}
          expert={activeExpert}
          footerBarRef={footerBarRef}
          setIsFocused={setIsFocused}
          blockGetCommand={blockGetCommand}
          setBlockGetCommand={setBlockGetCommand}
          messageFiles={messageFiles}
          isFocused={isFocused}
        />
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: "none" }}
          multiple
          accept={acceptTypes}
        />
        <ActionButtons
          handleUploadClick={handleUploadClick}
          handleSendIconTapped={handleSendIconTapped}
          handleCommandsIconTapped={handleCommandsIconTapped}
          isFocused={isFocused}
        />
      </Box>
    </Box>
  ) : null;
};

export default FooterBar;
