import * as React from "react";
import { Tabs } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AccountMenuItem, MenuItem } from "./MenuItemEnums";

export type MenuType = MenuItem | AccountMenuItem;

interface TabPanelProps<T extends MenuType> {
  children?: React.ReactNode;
  selectedTab: T;
  onChange: (newTab: T) => void;
  color?: string;
  tabs: React.ReactNode;
}

// Updated to use the generic type
export function MenuTabs<T extends MenuType>(props: TabPanelProps<T>) {
  const { children, selectedTab, onChange, color, ...other } = props;

  // Determine the appropriate enum based on the selectedTab type
  const enumType = Object.values(MenuItem).includes(selectedTab as MenuItem)
    ? MenuItem
    : AccountMenuItem;
  const index = Object.values(enumType).indexOf(selectedTab);

  const theme = useTheme();

  const handleTabSelected = (
    event: React.SyntheticEvent<Element, Event>,
    tab: number | null
  ) => {
    if (tab === null) return;
    const newTab = Object.values(enumType)[tab] as T;
    if (newTab === selectedTab) return;
    onChange(newTab);
  };

  return (
    <Tabs
      value={index}
      onChange={handleTabSelected}
      sx={{
        marginLeft: "-60px",
        "& .MuiTab-root": {
          padding: "12px",
          color: theme.palette.secondary.main,
          fontWeight: 700,
          textTransform: "none",
          minWidth: 0,
          height: "40px",
          fontSize: "12px",
          "&:hover": {
            color: theme.palette.text.secondary,
          },
        },
        "& .MuiTabs-flexContainer": {
          justifyContent: "center",
          height: "100%",
        },
        "& .Mui-selected": {
          backgroundColor: "transparent",
          color: theme.palette.text.primary,
          fontWeight: 800,
        },

        "& .Mui-hover, & .Mui-focusVisible": {
          color: theme.palette.secondary.main,
          fontWeight: 800,
        },
        "& .MuiTab-textColorPrimary.Mui-selected": {
          color: theme.palette.text.primary,
          fontWeight: 800,
        },
        "& .MuiTabs-indicator": {
          backgroundColor: color ? color : theme.palette.secondary.main,
          height: "3px",
          bottom: "0px",
          borderRadius: "10px",
          zIndex: 1,
        },
      }}
    >
      {props.tabs}
    </Tabs>
  );
}
