import React, { useEffect, useState } from "react";
import { Typography, Box, alpha } from "@mui/material";
import ExpertAvatar from "../../../ui-elements/ExpertAvatar";
import { useTheme } from "@mui/material/styles";
import { IExpert } from "../../../../types/index";
import { PrimaryIcon } from "../../../ui-elements";
import { ArrowClose, ArrowOpen } from "../../../../icons";
import ChatHistoryBlock from "../../../menus/sidebar/ChatHistoryBlock";
import { useAppSelector } from "../../../../app/store";

interface SidebarCellProps {
  handleCellClick: (index: number) => void;
  isSelected: boolean;
  expert: IExpert;
  index: number;
}

const SidebarCell: React.FC<SidebarCellProps> = ({
  handleCellClick,
  isSelected,
  expert,
  index,
}) => {
  const theme = useTheme();
  const expertSlice = useAppSelector((state) => state.experts);
  const [showSelected, setShowSelected] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [canClick, setCanClick] = useState(false);

  useEffect(() => {
    if (!isSelected) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
    setShowSelected(isSelected);
  }, [isSelected]);

  useEffect(() => {
    // if expert is this cell, can select - if not, block
    setCanClick(!expertSlice.selectingExpert);
  }, [expertSlice.selectingExpert]);

  const toggleShowHistory = () => {
    setExpanded(!expanded);
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
      alignItems={"flex-start"}
      padding={"8px"}
      marginTop={"8px"}
      width={"100%"}
      onClick={() => {
        if (canClick) {
          if (!showSelected) {
            handleCellClick(index);
          }
        } else {
          console.log("Can't click");
        }
      }}
      sx={{
        borderRadius: "8px",
        cursor: "pointer",
        backgroundColor: showSelected
          ? theme.palette.background.buttonHighlighted
          : "transparent",
        zIndex: 2,
        "&:hover": {
          backgroundColor: showSelected
            ? theme.palette.background.buttonHighlighted
            : alpha(theme.palette.background.buttonHighlighted, 0.3),
        },
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        width={"100%"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <ExpertAvatar expert={expert} backgroundSize={45} />
        </Box>
        <Box
          display={"flex"}
          width={"100%"}
          flexDirection={"column"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "18px",
              color: theme.palette.text.primary,
              marginLeft: "16px",
            }}
          >
            {expert.name}
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "12px",
              color: theme.palette.text.secondary,
              marginLeft: "16px",
            }}
          >
            {expert.job}
          </Typography>
        </Box>

        {showSelected &&
          (expanded ? (
            <PrimaryIcon
              icon={<ArrowClose />}
              iconName="Hide"
              handleIconClick={() => toggleShowHistory()}
              stopPropagation={true}
              zIndex={3}
            />
          ) : (
            <PrimaryIcon
              icon={<ArrowOpen />}
              iconName="Show"
              handleIconClick={() => toggleShowHistory()}
              stopPropagation={true}
              zIndex={3}
            />
          ))}

        {showSelected && (
          <Box
            sx={{
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              borderRadius: "8px",
              backgroundColor: theme.palette.background.backdrop,
              opacity: "10%",
            }}
          />
        )}
      </Box>
      {expanded && <ChatHistoryBlock expert={expert} />}
    </Box>
  );
};

export default SidebarCell;
