import { AxiosError } from "axios";
import store from "./store";
import { setForceLogout } from "../features/users/userAuthSlice";
import { fetchIsUserDirty } from "../features/users/isUserDirtySlice";

interface ErrorResponseData {
  message: string;
  status?: number;
}

export const handleAxiosError = (error: unknown): string => {
  const e = error as AxiosError<ErrorResponseData>;

  if (e.response) {
    if (e.response.status === 405) {
      console.log("user is dirty");
      store.dispatch(fetchIsUserDirty());
      return "UserIsDirty";
    }

    if (e.response.status === 498) {
      store.dispatch(setForceLogout(true));
      return "Invalid Token";
    }

    if (e.response.status === 429) {
      return "Too many requests";
    }

    return e.response.data.message;
  } else {
    // Network error or other unknown errors
    return "An unknown error occurred";
  }
};

export const handleAxiosErrorWithStatus = (
  error: unknown
): ErrorResponseData => {
  const e = error as AxiosError<ErrorResponseData>;

  if (e.response) {
    if (e.response.status === 498) {
      store.dispatch(setForceLogout(true));
      return {
        message: "Invalid Token",
        status: 498,
      };
    }

    if (e.response.status === 405) {
      console.log("user is dirty");
      return {
        message: "UserIsDirty",
        status: 405,
      };
    }

    if (e.response.status === 429) {
      return {
        message: "Too many requests",
        status: 429,
      };
    }

    if (e.response.status === 403) {
      return {
        message:
          "This expert is currently working - please wait until all jobs are completed and then try again.",
        status: 403,
      };
    }

    return {
      message: e.response.data.message,
      status: e.response.status,
    };
  } else {
    // Network error or other unknown errors
    return {
      message: "An unknown error occurred",
      status: undefined,
    };
  }
};
