import { useEffect } from "react";

export const TouchToClick = () => {
  useEffect(() => {
    const isMobile = "ontouchstart" in window || navigator.maxTouchPoints > 0;

    if (!isMobile) {
      return;
    }

    let startX = 0;
    let startY = 0;
    let isScrolling = false;
    let isManualClick = false;

    // Helper function for throttle
    const throttle = <T extends (...args: any[]) => void>(
      func: T,
      limit: number
    ) => {
      let lastFunc: number;
      let lastRan: number;
      return (...args: Parameters<T>): void => {
        if (!lastRan) {
          func(...args);
          lastRan = Date.now();
        } else {
          clearTimeout(lastFunc);
          lastFunc = window.setTimeout(() => {
            if (Date.now() - lastRan >= limit) {
              func(...args);
              lastRan = Date.now();
            }
          }, limit - (Date.now() - lastRan));
        }
      };
    };

    const preventZoom = (event: TouchEvent) => {
      if (event.touches.length > 1 || event.detail === 2) {
        event.preventDefault();
      }
    };

    const isElementScrollable = (element: HTMLElement) => {
      const hasScrollableContent =
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth;

      const isScrollAllowed =
        window.getComputedStyle(element).overflowY !== "hidden" ||
        window.getComputedStyle(element).overflowX !== "hidden";

      return hasScrollableContent && isScrollAllowed;
    };

    const handleTouchStart = (event: TouchEvent) => {
      startX = event.touches[0].clientX;
      startY = event.touches[0].clientY;
      isScrolling = false; // Reset scrolling flag
    };

    const handleTouchMove = (event: TouchEvent) => {
      const currentX = event.touches[0].clientX;
      const currentY = event.touches[0].clientY;

      if (
        Math.abs(currentX - startX) > 10 ||
        Math.abs(currentY - startY) > 10
      ) {
        isScrolling = true; // User is scrolling
      }

      let target = event.target as HTMLElement;

      while (target && target !== document.body) {
        if (isElementScrollable(target)) {
          return; // Allow the scroll if the target is scrollable
        }
        target = target.parentElement as HTMLElement;
      }

      if (!isScrolling) {
        event.preventDefault(); // Prevent browser-level scrolling only if not scrolling
      }
    };

    const handleTouchEnd = throttle((event: TouchEvent) => {
      if (!isScrolling) {
        const targetElement = event.target as HTMLElement;

        if (typeof targetElement.click === "function") {
          isManualClick = true;
          targetElement.click();
        }
      }
    }, 100); // Adjust throttle timing as needed for responsiveness

    const handleNativeClick = (event: MouseEvent) => {
      if (isManualClick) {
        event.stopImmediatePropagation();
        isManualClick = false;
      }
    };

    const handleGestureStart = (e: Event) => e.preventDefault();

    window.addEventListener("gesturestart", handleGestureStart);
    window.addEventListener("touchstart", handleTouchStart, { passive: false });
    window.addEventListener("touchstart", preventZoom, { passive: false });
    window.addEventListener("touchmove", handleTouchMove, { passive: false });
    window.addEventListener("touchend", handleTouchEnd, { passive: true });
    window.addEventListener("click", handleNativeClick, true);

    return () => {
      window.removeEventListener("gesturestart", handleGestureStart);
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchstart", preventZoom);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleTouchEnd);
      window.removeEventListener("click", handleNativeClick, true);
    };
  }, []);

  return null;
};
