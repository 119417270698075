import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthStateMachine from "./auth-views/AuthStateMachine";
import BetaPendingView from "./auth-views/BetaPendingView";
import LoadingView from "./auth-views/LoadingView";
import LoginView from "./auth-views/LoginView";
import RequestResetPasswordView from "./auth-views/RequestResetPasswordView";
import ResetPasswordView from "./auth-views/ResetPasswordView";
import SignupView from "./auth-views/SignupView";
import UserDetailsView from "./auth-views/UserDetailsView";
import VerificationPendingView from "./auth-views/VerificationPendingView";

export const fileTypes = ["JPEG", "PNG"];

const AuthPageManager: React.FC = () => {
  return (
    <>
      <AuthStateMachine />
      <Routes>
        <Route path="signup" element={<SignupView />} />
        <Route path="login" element={<LoginView />} />
        <Route
          path="verification-pending"
          element={<VerificationPendingView />}
        />
        <Route path="beta-pending" element={<BetaPendingView />} />
        <Route path="user-details" element={<UserDetailsView />} />
        <Route
          path="request-reset-password"
          element={<RequestResetPasswordView />}
        />
        <Route path="reset-password" element={<ResetPasswordView />} />
        <Route
          path="loading"
          element={
            <LoadingView title="Please wait" actionMessage="Loading..." />
          }
        />
        <Route path="*" element={<Navigate to="/auth/login" replace />} />
      </Routes>
    </>
  );
};

export default AuthPageManager;
