import { IActiveJob } from "../expert/IExpertise";

export interface IChat {
  _id: string;
  chatName: string;
  userId: string;
  expertId?: string;
  groupAdmin?: string;
  cacheId?: string;
  lastMessageSent?: Date | null;
  chatSummary?: string;
  chatHistoryId?: string | null;
  isActiveChatHistory?: boolean;
  isAddingToChatHistory?: boolean;
  createdAt?: Date;
  chatState?: IChatState;
  activeJobs?: IActiveJob[];
  activeReplyQueue: any;
  isWorking?: boolean;
  isWorkingMessage?: string;
  updatedAt?: Date;
}

export enum IChatState {
  SAVING = "saving",
  SAVED = "saved",
  OPENING = "opening",
  OPEN = "open",
  DELETING = "deleting",
  DELETED = "deleted",
}

export interface ISetChatStateForChatPayload {
  chatId: string;
  chatState: IChatState;
  chatName?: string;
}

export interface ISaveChatPayload {
  chatId: string;
}

export interface IDeleteChatPayload {
  chatId: string;
}

export interface IOpenChatPayload {
  chatId: string;
  previousChatId?: string | null;
}

export interface IOpenExistingChatPayload {
  switchingExperts: boolean;
  chatId: string;
}

export interface IChatWorkingState {
  isWorking: boolean;
  isWorkingMessage: string;
  chatId: string;
}
