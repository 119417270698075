import Box from "@mui/material/Box";
import { Outlet, useLocation } from "react-router-dom";
import SiteHeader from "../../menus/SiteHeader";
import SiteFooter from "../../menus/SiteFooter";
import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";

const PublicLayout = () => {
  const theme = useTheme();
  const location = useLocation();
  const [isAuthPage, setIsAuthPage] = useState(false);

  // if location is "auth", need to hide footer and change top to 0

  useEffect(() => {
    //console.log(location.pathname);
    setIsAuthPage(location.pathname.includes("auth"));
  }, [location]);

  return (
    <Box
      sx={{
        position: "fixed",
        backgroundColor: theme.palette.background.backdrop,
        display: "flex",
        flexDirection: "column",
        left: 0,
        bottom: 0,
        right: 0,
        top: isAuthPage ? "0px" : "60px",
      }}
    >
      <SiteHeader />
      <Box
        component="main"
        display={"flex"}
        flexDirection={"column"}
        sx={{ flexGrow: 1, overflow: "auto" }}
      >
        <Outlet />
        {!isAuthPage && <SiteFooter />}
      </Box>
    </Box>
  );
};

export default PublicLayout;
