import { Box, Divider } from "@mui/material";
import { useTheme, alpha } from "@mui/material/styles";
import GetStartedButton from "../../home-page/home-components/GetStartedButton";

interface FooterButtonSectionProps {
  isCurrentPlan: boolean;
  isFeaturedPlan: boolean;
  hasCurrentPlan: boolean;
  isPremiumPlan: boolean;
  handlePlanSelected: () => void;
}

const FooterButtonSection: React.FC<FooterButtonSectionProps> = ({
  isCurrentPlan,
  isFeaturedPlan,
  hasCurrentPlan,
  isPremiumPlan,
  handlePlanSelected,
}) => {
  const theme = useTheme();

  if (isCurrentPlan) {
    return (
      <>
        <Divider sx={{ mb: 2 }} />
        <Box
          display="flex"
          justifyContent="center"
          marginTop={"40px"}
          marginBottom={"24px"}
          width={"100%"}
        >
          <GetStartedButton
            onClick={handlePlanSelected}
            color={theme.palette.background.backdrop}
            textOverride={"Current"}
            disabled={true}
            hideIcon={true}
          />
        </Box>
      </>
    );
  }

  if (isFeaturedPlan && hasCurrentPlan) {
    return (
      <>
        <Divider sx={{ mb: 2 }} />
        <Box
          display="flex"
          justifyContent="center"
          marginTop={"40px"}
          marginBottom={"24px"}
          width={"100%"}
        >
          <GetStartedButton
            onClick={handlePlanSelected}
            color={theme.palette.primary.main}
            textColor={undefined}
            textOverride={"Upgrade"}
          />
        </Box>
      </>
    );
  }

  if (hasCurrentPlan && !isFeaturedPlan && !isCurrentPlan) {
    return (
      <>
        <Divider sx={{ mb: 2 }} />
        <Box
          display="flex"
          justifyContent="center"
          marginTop={"40px"}
          marginBottom={"24px"}
          width={"100%"}
        >
          <GetStartedButton
            onClick={handlePlanSelected}
            textOverride={isPremiumPlan ? "Upgrade" : "Downgrade"}
            hideIcon={true}
            color={
              isFeaturedPlan
                ? theme.palette.primary.main
                : theme.palette.background.backdrop
            }
            textColor={
              isFeaturedPlan
                ? undefined
                : alpha(theme.palette.primary.main, 0.7)
            }
          />
        </Box>
      </>
    );
  }

  return (
    <>
      <Divider sx={{ mb: 2 }} />
      <Box
        display="flex"
        justifyContent="center"
        marginTop={"40px"}
        marginBottom={"24px"}
        width={"100%"}
      >
        <GetStartedButton
          onClick={handlePlanSelected}
          color={
            isFeaturedPlan
              ? theme.palette.primary.main
              : theme.palette.background.backdrop
          }
          textColor={
            isFeaturedPlan ? undefined : alpha(theme.palette.primary.main, 0.7)
          }
          textOverride={
            hasCurrentPlan
              ? isCurrentPlan
                ? "Current"
                : "Upgrade"
              : "Get Started"
          }
        />
      </Box>
    </>
  );
};

export default FooterButtonSection;
