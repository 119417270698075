import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  darken,
  useTheme,
} from "@mui/material";
import HomeToggle from "../home-components/HomeToggle";
import {
  HomeExpertsSectionData,
  homeExpertsSectionData,
} from "./HomeExpertsData";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { setPublicColor } from "../../../../../features/ui/themeSlice";
import { useNavigate } from "react-router-dom";
import AudioVisualizer from "./AudioVisualizer";
import { PlayButtonState } from "../../../../ui-elements/PlayButton";
import HomePlayButton from "../home-components/HomePlayButton";
import {
  forceOverrideStopAudio,
  forceStopAudio,
  playDemoAudio,
  setAudioAnalysisData,
} from "../../../../../features/audio/textToSpeechSlice";
import { ITextToSpeechPayload } from "../../../../../types";
import { loadVoices } from "../../../../../features/audio/voicesSlice";

const HomeExpertsSection: React.FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [selectedExpert, setSelectedExpert] =
    useState<HomeExpertsSectionData | null>(homeExpertsSectionData[0]);
  const [playButtonState, setPlayButtonState] = useState<PlayButtonState>(
    PlayButtonState.DEFAULT
  );

  const themeSlice = useAppSelector((state) => state.theme);
  const textToSpeechSlice = useAppSelector((state) => state.textToSpeech);
  const audioAnalysisData = useAppSelector(
    (state) => state.textToSpeech.audioAnalysisData
  );

  useEffect(() => {
    dispatch(setPublicColor(selectedExpert?.color));
    dispatch(loadVoices());
  }, []);

  useEffect(() => {
    if (textToSpeechSlice.isPlaying) {
      setPlayButtonState(PlayButtonState.PLAYING);
    } else if (textToSpeechSlice.isLoading) {
      setPlayButtonState(PlayButtonState.LOADING);
    } else {
      setPlayButtonState(PlayButtonState.DEFAULT);
    }
  }, [textToSpeechSlice]);

  useEffect(() => {
    //console.log("data is updated");
  }, [audioAnalysisData]);

  const handleSelectExpert = (expert: HomeExpertsSectionData) => {
    setSelectedExpert(expert);
    dispatch(setPublicColor(expert.color));
    onStop();
  };

  const homeToggles = homeExpertsSectionData.map((expert, key) => (
    <Grid
      item
      key={key}
      justifyContent={"center"}
      display={"flex"}
      sx={{ padding: 0, margin: 0, width: { xs: undefined, sm: "180px" } }}
    >
      <HomeToggle
        expertData={expert}
        isSelected={selectedExpert === expert}
        onSelect={() => handleSelectExpert(expert)}
      />
    </Grid>
  ));

  const onPlay = () => {
    setPlayButtonState(PlayButtonState.LOADING);

    if (textToSpeechSlice.isPlaying) {
      dispatch(forceStopAudio());
    } else if (textToSpeechSlice.isLoading) {
      dispatch(forceOverrideStopAudio());
    } else {
      const voiceName = selectedExpert?.voiceName;

      const textToSpeechAudioPayload = {
        text: selectedExpert?.dialogText || "demoText",
        isPublic: true,
        demoVoiceName: voiceName,
      } as ITextToSpeechPayload;

      dispatch(playDemoAudio(textToSpeechAudioPayload));
    }
  };

  const onStop = () => {
    setPlayButtonState(PlayButtonState.DEFAULT);
    dispatch(forceStopAudio());
    dispatch(setAudioAnalysisData([]));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        mt: "16px",

        paddingTop: "48px",
        paddingBottom: "64px",
        backgroundColor: theme.palette.background.chatBubble,
        width: "100%",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          color: theme.palette.text.tertiary,
          fontWeight: "700",
          mb: 2,
          paddingLeft: "24px",
          paddingRight: "24px",
          paddingBottom: { xs: 0, sm: "24px" },
          textAlign: "center",
        }}
      >
        A New Kind of Workflow
      </Typography>

      <Grid
        container
        justifyContent="center"
        paddingLeft={"0px"}
        spacing={{ xs: 2, sm: 0 }}
        marginBottom={{ xs: "0px", sm: "16px", md: "32px" }}
      >
        {homeToggles}
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          mt: { xs: 2, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: { xs: "column", sm: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", sm: "100%", md: "70%" },
              paddingLeft: { xs: "24px", sm: "24px", md: "0px" },
              paddingRight: { xs: "24px", sm: "24px", md: "0px" },
              paddingBottom: { xs: "24px", sm: "24px", md: "0px" },
              maxWidth: { xs: "100%", sm: "550px", md: "550px" },
              flexGrow: 1,
              height: "100%",
            }}
          >
            <Stack
              sx={{
                width: "100%",
                height: "100%",
                position: "relative",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <AudioVisualizer
                audioAnalysisData={audioAnalysisData}
                barColor={themeSlice.publicColor}
              />
              <Box
                sx={{
                  position: "absolute",
                  bottom: "0",
                  left: "0",
                  paddingBottom: "20px",
                  paddingLeft: "20px",
                }}
              >
                <HomePlayButton
                  colorOverride={themeSlice.publicColor}
                  buttonStateOverride={playButtonState}
                  onPlay={onPlay}
                  onStop={onStop}
                />
              </Box>
              <img
                src={selectedExpert?.cardImage}
                alt={selectedExpert?.title}
                style={{ width: "100%", height: "auto" }}
              />
            </Stack>
          </Box>
          <Box
            alignContent={"flex-start"}
            alignItems={"flex-start"}
            display={"flex"}
            position={"relative"}
            flexDirection={"column"}
            justifyContent={"flex-start"}
            paddingTop={{ xs: "0px", sm: "0px", md: "24px" }}
            sx={{
              height: "100%",
              width: { xs: "100%", sm: "100%", md: "30%" },
              pl: "24px",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                maxWidth: "100%",
              }}
            >
              <Typography
                variant="h3"
                component="div"
                gutterBottom
                color={themeSlice.publicColor ?? theme.palette.primary.main}
              >
                {selectedExpert?.subtitle.charAt(0).toUpperCase() +
                  selectedExpert?.subtitle.slice(1) || "Subtitle"}
              </Typography>
              <Typography
                variant="body2"
                color={theme.palette.text.secondary}
                fontSize={"15px"}
                lineHeight={1.7}
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: selectedExpert?.cardText || "Card Text",
                }}
              />
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/pricing");
                }}
                sx={{
                  marginTop: "16px",
                  boxShadow: "none",
                  backgroundColor: themeSlice.publicColor,
                  "&:hover": {
                    backgroundColor: darken(themeSlice.publicColor, 0.3),
                    boxShadow: "none",
                  },
                  transition: "none",
                }}
              >
                See Plans
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HomeExpertsSection;
