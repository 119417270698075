import React, { useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import RotatingIcon from "./RotatingIcon";
import CustomTooltip from "./CustomTooltip";
import { useAppSelector } from "../../app/store";
import { UtilityService } from "../../services/UtilityService";

interface PrimaryIconProps {
  iconName: string;
  icon: React.ReactNode;
  selectedIcon?: string;
  handleIconClick?: (iconName: string) => void;
  size?: string;
  sx?: React.CSSProperties;
  disabled?: boolean;
  selectedColorOverride?: string;
  defaultColorOverride?: string;
  highlightedColorOverride?: string;
  backgroundColorOverride?: string;
  highlightedIconColorOverride?: string;
  animated?: boolean;
  forceSelected?: boolean;
  stopPropagation?: boolean;
  rotate?: boolean;
  highlightedIcon?: React.ReactNode;
  zIndex?: number;
  ignoreMobile?: boolean;
  marginRight?: string;
}

const PrimaryIcon: React.FC<PrimaryIconProps> = ({
  iconName,
  icon,
  selectedIcon = "",
  handleIconClick,
  size = "32px",
  sx = {},
  disabled = false,
  selectedColorOverride = "",
  defaultColorOverride = "",
  highlightedColorOverride = "",
  backgroundColorOverride = "",
  animated = true,
  forceSelected = false,
  stopPropagation = false,
  rotate = false,
  highlightedIconColorOverride = "",
  highlightedIcon,
  zIndex,
  ignoreMobile = false,
  marginRight,
}) => {
  const theme = useTheme();
  const userPreferencesSlice = useAppSelector((state) => state.userPreferences);
  const tooltipsEnabled = userPreferencesSlice.userPreferences?.tooltipsEnabled;
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const isMobile = UtilityService.getIsMobile();

  const handleMouseDown = () => {
    if (isMobile) return;
    setIsMouseDown(true);
  };

  const handleMouseUp = () => {
    if (isMobile) return;
    setIsMouseDown(false);
  };

  const handleMouseOver = () => {
    if (isMobile) return;
    setIsMouseOver(true);
    if (isMouseDown) {
      setIsMouseDown(true);
    }
  };

  const handleMouseLeave = () => {
    if (isMobile) return;
    setIsMouseOver(false);
    setIsMouseDown(false);
  };

  const isSelected = selectedIcon === iconName || forceSelected;

  const iconForState = () => {
    if (highlightedIcon && isMouseOver) {
      return highlightedIcon;
    } else {
      return icon;
    }
  };

  const iconColor = (isHighlighted = false) => {
    if (highlightedIconColorOverride !== "" && isHighlighted) {
      return highlightedIconColorOverride;
    } else {
      return isSelected
        ? selectedColorOverride || theme.palette.primary.main
        : defaultColorOverride || theme.palette.text.secondary;
    }
  };

  const children = () => {
    return (
      <IconButton
        data-id={iconName + "-page"}
        component="div"
        onClick={(event) => {
          if (stopPropagation) {
            event.stopPropagation();
          }
          if (handleIconClick) {
            handleIconClick(iconName);
          }
        }}
        disabled={disabled}
        sx={{
          zIndex: zIndex || 0,
          width: size,
          height: size,
          borderRadius: "6px",
          marginRight: marginRight || "4px",
          padding: "6px",
          transition: animated && !isMobile ? "0.1s ease-in-out" : "none",
          backgroundColor:
            !isMobile && backgroundColorOverride
              ? backgroundColorOverride
              : "transparent",
          "&:hover": !isMobile && {
            backgroundColor: highlightedColorOverride
              ? highlightedColorOverride
              : theme.palette.background.buttonHighlighted,
            transition: !isMobile ? "padding 0.2s ease-in-out" : "none",
          },
          ...sx,
        }}
        disableFocusRipple
        disableRipple
      >
        <RotatingIcon isRotating={rotate}>
          <Box
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
            onMouseOver={handleMouseOver}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              "&:hover": !isMobile && {
                transform: animated && !isMouseDown ? "scale(1.1)" : null,
              },
              transform:
                !isMobile && isMouseDown && animated
                  ? "scale(0.8)"
                  : "scale(1)",
              transition:
                animated && !isMobile ? "transform 0.1s ease-in-out" : "none",
              width: size,
              height: size,
              borderRadius: "6px",
              padding: isMobile && !ignoreMobile ? "2.5px" : "6px",
              color: iconColor(isMouseOver),
            }}
          >
            {iconForState()}
          </Box>
        </RotatingIcon>
      </IconButton>
    );
  };

  return tooltipsEnabled ? (
    <CustomTooltip title={iconName}>{children()}</CustomTooltip>
  ) : (
    children()
  );
};

export default PrimaryIcon;
