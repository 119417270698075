import { EditorState, getDefaultKeyBinding, Modifier } from "draft-js";
import { getActionCommandStrings } from "../../../../../features/commands/commandSource";
import { IMessageBarState } from "./state-setters/MessageBarState";

const insertSoftNewLine = (editorState: EditorState) => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const newContentState = Modifier.insertText(
    contentState,
    selectionState,
    "\n"
  );
  const newEditorState = EditorState.push(
    editorState,
    newContentState,
    "insert-characters"
  );
  return newEditorState;
};

interface HandleKeyBindingProps {
  e: React.KeyboardEvent<any>;
  messageBarState: IMessageBarState;
  sendMessage: () => void;
  selectCellAtIndex: (index: number) => void;
  tryFinishTranscription: () => void;
  isTranscribing: boolean;
  callClearTranscriptionSegment: () => void;
  scrollToBottom: () => void;
}

const isMobileDevice = () => {
  const userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera;

  // Check if it's an iPad
  const isIpad =
    /iPad/.test(userAgent) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

  // Check if it's an iPhone, iPod, Android, or another mobile device
  const isMobile =
    (/iPhone|iPod/.test(userAgent) && !(window as any).MSStream) ||
    /android/i.test(userAgent) ||
    /Mobi/i.test(userAgent);

  return isIpad || isMobile;
};

export const handleKeyBinding = ({
  e,
  messageBarState,
  sendMessage,
  selectCellAtIndex,
  tryFinishTranscription,
  isTranscribing,
  callClearTranscriptionSegment,
  scrollToBottom,
}: HandleKeyBindingProps) => {
  const commands = getActionCommandStrings();

  if ((e.key === " " || e.key === "Spacebar") && isMobileDevice()) {
    e.preventDefault();

    const contentState = messageBarState.editorState.getCurrentContent();
    const selection = messageBarState.editorState.getSelection();
    const currentBlock = contentState.getBlockForKey(selection.getStartKey());
    const currentText = currentBlock.getText();
    const endOffset = selection.getEndOffset();

    // Check if we're at the start of the text or if the previous character is not a space
    if (endOffset === 0 || currentText[endOffset - 1] !== " ") {
      // Insert a single space
      const newContentState = Modifier.insertText(contentState, selection, " ");
      const newEditorState = EditorState.push(
        messageBarState.editorState,
        newContentState,
        "insert-characters"
      );

      messageBarState.editorState = newEditorState;
      messageBarState.showPlaceholder = false;
    }
    return "handled";
  }

  if ((e.key === " " || e.key === "Spacebar") && isMobileDevice()) {
    e.preventDefault();

    const contentState = messageBarState.editorState.getCurrentContent();
    const selection = messageBarState.editorState.getSelection();
    const currentBlock = contentState.getBlockForKey(selection.getStartKey());
    const currentText = currentBlock.getText();
    const endOffset = selection.getEndOffset();

    // Check if the previous character is already a space
    if (endOffset > 0 && currentText[endOffset - 1] === " ") {
      // If it is, don't insert another space
      return "handled";
    }

    // Insert a single space
    const newContentState = Modifier.insertText(contentState, selection, " ");
    const newEditorState = EditorState.push(
      messageBarState.editorState,
      newContentState,
      "insert-characters"
    );

    messageBarState.editorState = newEditorState;
    messageBarState.showPlaceholder = false;

    return "handled";
  }

  if (messageBarState.showSuggestions) {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault(); // Prevent default behavior
      const direction = e.key === "ArrowUp" ? -1 : 1;
      const currentIndex = messageBarState.highlightedSuggestionsIndex;
      const totalSuggestions = messageBarState.suggestedCommands.length;
      const newIndex =
        (currentIndex + direction + totalSuggestions) % totalSuggestions;

      messageBarState.highlightedSuggestionsIndex = newIndex;
      return "handled";
    }
  }

  // Handle Tab key for suggestions
  if (messageBarState.showSuggestions && e.key === "Tab") {
    e.preventDefault();
    selectCellAtIndex(messageBarState.highlightedSuggestionsIndex);
    return "handled";
  } else if (e.key === "Tab") {
    e.preventDefault();
    const contentState = messageBarState.editorState.getCurrentContent();
    const selectionState = messageBarState.editorState.getSelection();
    const newContentState = Modifier.insertText(
      contentState,
      selectionState,
      "     "
    );
    const newEditorState = EditorState.push(
      messageBarState.editorState,
      newContentState,
      "insert-characters"
    );

    messageBarState.showPlaceholder = false;
    messageBarState.editorState = newEditorState;
    return "handled";
  }

  // Handle transcription-related key bindings
  if (isTranscribing && messageBarState.hasInsertedTemporaryTranscription) {
    if (e.key === "Tab" || e.key === "Enter") {
      tryFinishTranscription();
      return "handled";
    }
    if (
      e.key === "Delete" ||
      e.key === "Backspace" ||
      e.key === "Escape" ||
      e.key === "ArrowLeft"
    ) {
      callClearTranscriptionSegment();
      return "handled";
    }
  }

  // Handle Enter key for different scenarios
  if (e.key === "Enter") {
    if (e.shiftKey || isMobileDevice()) {
      // Soft new line on Shift+Enter or on mobile
      e.preventDefault();
      messageBarState.editorState = insertSoftNewLine(
        messageBarState.editorState
      );
      scrollToBottom();
      messageBarState.showPlaceholder = false;
      return "handled";
    } else {
      if (messageBarState.showSuggestions) {
        e.preventDefault();
        selectCellAtIndex(messageBarState.highlightedSuggestionsIndex);
        return "handled";
      } else {
        e.preventDefault();
        sendMessage();
        return "handled";
      }
    }
  }

  // Additional key bindings...

  return getDefaultKeyBinding(e);
};
