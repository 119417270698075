export class UploadValidatorService {
  static enableHacks = false;

  static maxFileSize = 10485760;
  static maxFileCount = 10;

  // NOTICE! The input in the FooterBar.tsx needs to be manually updated.
  static ValidFileTypes = ["pdf", "png", "jpg", "jpeg"];

  static validateFileUpload(newFiles, existingFiles, isKnowledgeDocs = false) {
    const totalFiles = newFiles.length + existingFiles.length;
    let min = 1;
    let max = UploadValidatorService.maxFileCount;
    let maxTotalFileSize = UploadValidatorService.maxFileSize;

    if (UploadValidatorService.enableHacks) {
      min = 1;
      max = 100;
      maxTotalFileSize = 10000000;
    }

    // New check for individual file size
    for (let i = 0; i < newFiles.length; i++) {
      if (newFiles[i].size > UploadValidatorService.maxFileSize) {
        return {
          isValid: false,
          message: `Each file must be smaller than ${
            UploadValidatorService.maxFileSize / 1048576
          } MB.`,
        };
      }
    }

    if (isKnowledgeDocs) {
      let totalSize = 0;
      for (let i = 0; i < newFiles.length; i++) {
        totalSize += newFiles[i].size;
      }

      for (let i = 0; i < existingFiles.length; i++) {
        totalSize += existingFiles[i].file.size;
      }

      if (totalSize > maxTotalFileSize) {
        return {
          isValid: false,
          message: "The total file size cannot exceed 25 MB.",
        };
      }
    } else {
      const newFilesSize = newFiles.reduce((acc, file) => acc + file.size, 0);
      const existingFilesSize = existingFiles.reduce(
        (acc, file) => acc + (file.file ? file.file.size : 0),
        0
      );
      const totalSize = newFilesSize + existingFilesSize;

      if (totalSize > maxTotalFileSize) {
        return {
          isValid: false,
          message: "The total file size cannot exceed 25 MB.",
        };
      }
    }

    if (totalFiles > max) {
      return { isValid: false, message: "You can only upload up to 10 files." };
    }

    return { isValid: true };
  }
}
