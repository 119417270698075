import React, {
  useRef,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import {
  Typography,
  Grid,
  Box,
  Divider,
  Button,
  alpha,
  darken,
  lighten,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ReactMarkdown from "react-markdown";
import { unified } from "unified";
import markdownParse from "remark-parse";
import markdownStringify from "remark-stringify";
import { CellState, MessageCellProps } from "../blocks/MCTypes";
import { ExpertAvatar } from "../../../../../ui-elements";
import { MCActionButtons } from "../blocks/MCActionButtons";
import ImagesBlock from "../blocks/ImagesBlock";
import useScreenSize from "../../../../../ui-elements/useScreenSize";
import { processForMarkdown } from "../blocks/MarkdownProcessor";
import SourcesBlock from "../blocks/SourcesBlock";
import { IKnowledgeDoc } from "../../../../../../types";
import { UtilityService } from "../../../../../../services/UtilityService";

const MessageCell: React.FC<MessageCellProps> = ({
  message,
  expert,
  userDetails,
  cellState,
  onPlay,
  onStop,
  onEdit,
  canEdit = false,
  canPlay = true,
}) => {
  const theme = useTheme();
  const screenSize = useScreenSize();
  const bubbleRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const isMe = userDetails?._id === message.senderId;
  const processor = useMemo(
    () => unified().use(markdownParse).use(markdownStringify),
    []
  );

  const getMaxWidth = useCallback(() => {
    if (screenSize.isMedium && !isMe) {
      return "100%";
    }
    if (screenSize.isMedium) {
      return "80%";
    }
    const words = message.message.split(" ");
    if (words.length <= 10) return "50%";
    if (words.length <= 20) return "67.5%";
    if (words.length <= 30) return "80%";
    return isMe ? "80%" : "100%";
  }, [screenSize.isMedium, isMe, message.message]);

  const getBubbleColor = useCallback(() => {
    if (isMe) {
      return theme.palette.mode === "dark"
        ? darken(theme.palette.background.buttonHighlighted, 0.35)
        : lighten(theme.palette.background.buttonHighlighted, 0.4);
    }
    return alpha(theme.palette.background.buttonHighlighted, 1.0);
  }, [isMe, theme]);

  const { components, markdownTextWithDepth } = useMemo(
    () => processForMarkdown(message, processor, theme),
    [message, processor, theme]
  );

  const isSelected = useMemo(
    () => [CellState.PLAYING, CellState.EDITING].includes(cellState),
    [cellState]
  );

  const toggleExpanded = () => setIsExpanded((prev) => !prev);

  useEffect(() => {
    const lineHeight = parseFloat(
      window.getComputedStyle(bubbleRef.current!).lineHeight
    );
    const height = bubbleRef.current?.offsetHeight || 0;
    const lines = height / lineHeight;

    // confirm there are no knowledgeDocs and no images in placeholderFiles
    const noKnowledgeDocs =
      message.knowledgeDocs && message.knowledgeDocs.length === 0;
    const noFiles =
      message.placeholderFiles && message.placeholderFiles.length === 0;

    setShowButton(isMe && lines > 10 && noKnowledgeDocs && noFiles);
  }, [
    isMe,
    markdownTextWithDepth,
    message.knowledgeDocs,
    message.placeholderFiles,
  ]);

  useEffect(() => {
    // console.log("cellState", cellState);
  }, [cellState, canPlay, canEdit]);

  const isMobile = UtilityService.getIsMobile();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: "6px",
        paddingTop: isMe ? "38px" : "16px",
        position: "relative",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent={isMe ? "flex-end" : "flex-start"}
        alignItems="center"
      >
        {!isMe && (
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              paddingBottom: "6px",
            }}
          >
            <ExpertAvatar expert={expert} backgroundSize={18} />
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "0.9rem",
                color: theme.palette.text.secondary,
              }}
            >
              {expert?.name}
            </Typography>
          </Grid>
        )}
      </Grid>

      <Box
        ref={bubbleRef}
        sx={{
          maxWidth: getMaxWidth(),
          width: "fit-content",
          minWidth: "80px",
          alignSelf: isMe ? "flex-end" : "flex-start",
          backgroundColor: getBubbleColor(),
          borderRadius: "16px",
          padding: "12px",
          paddingTop: "4px",
          paddingBottom: "4px",
          boxShadow: isSelected ? `0 0 0 2px ${expert.color}` : "none",
        }}
      >
        <Typography
          component="div"
          sx={{
            fontSize: "0.9rem",
            lineHeight: "1.8",
            width: "100%",
            textAlign: "left",
            wordBreak: "break-word",
            display: isMe ? "-webkit-box" : "block",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: isMe && !isExpanded ? "10" : "none",
            overflow: isMe && !isExpanded ? "hidden" : "visible",
          }}
        >
          <ReactMarkdown components={components as object}>
            {markdownTextWithDepth}
          </ReactMarkdown>
        </Typography>
        {showButton && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button
              size="small"
              sx={{
                marginTop: "4px",
                alignSelf: "flex-end",
                color: expert.color,
                fontWeight: "normal",
                textTransform: "none",
                padding: "2px 4px",
                minWidth: "unset",
                "&:hover": !isMobile
                  ? {
                      backgroundColor: "rgba(0, 0, 0, 0.05)",
                    }
                  : {},
              }}
              onClick={toggleExpanded}
            >
              {isExpanded ? "Show less" : "...show more"}
            </Button>
          </Box>
        )}
        <ImagesBlock
          message={message}
          knowledgeDocs={message.knowledgeDocs as IKnowledgeDoc[]}
        />
        <Divider sx={{ marginTop: "12px", opacity: "0.5" }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <SourcesBlock
            message={message}
            knowledgeDocs={message.knowledgeDocs as IKnowledgeDoc[]}
          />
          <MCActionButtons
            canPlay={canPlay}
            canEdit={canEdit}
            onPlay={onPlay}
            onStop={onStop}
            onEdit={onEdit}
            message={message}
            cellState={cellState}
            expert={expert}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MessageCell;
