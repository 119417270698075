import React, { useEffect, useRef, useState } from "react";
import { Box, BoxProps } from "@mui/material";
import ScrollToBottomButton from "./chat-view/ScrollToBottomButton";
type ScrollContainerProps = BoxProps & {
  disableScroll?: boolean;
  id?: string;
};

const ScrollContainer: React.FC<ScrollContainerProps> = ({
  children,
  disableScroll = false,
  id = null,
  ...props
}) => {
  const scrollRef = useRef<HTMLDivElement>(null); // Ref for the container
  const [isAtBottom, setIsAtBottom] = useState(true); // Track scroll position

  // Function to check scroll position and update the state
  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        setIsAtBottom(true);
      } else {
        setIsAtBottom(false);
      }
    }
  };

  // Scroll to bottom logic
  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  // Attach the scroll event listener
  useEffect(() => {
    const currentRef = scrollRef.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <>
      <Box
        id={id ? id : undefined}
        {...props}
        ref={scrollRef}
        sx={{
          overflowY: disableScroll ? "hidden" : "scroll",
          overflowX: "hidden",
          maxHeight: "100%",
          position: "relative",
          ...props.sx,
        }}
      >
        {children}
      </Box>
      {!isAtBottom && (
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            pointerEvents: "none",
            zIndex: 0,
          }}
        >
          <Box
            sx={{
              pointerEvents: "auto",
            }}
          >
            <ScrollToBottomButton onClick={scrollToBottom} visible />
          </Box>
        </Box>
      )}
    </>
  );
};

export default ScrollContainer;
