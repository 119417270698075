import React from "react";
import { Box } from "@mui/material";
import AnimatedViewWrapper, {
  AnimatedFromDirection,
} from "./AnimatedViewWrapper";

interface AnimatedAuthViewProps {
  children: React.ReactNode;
  direction: (typeof AnimatedFromDirection)[keyof typeof AnimatedFromDirection];
}

const AnimatedAuthView: React.FC<AnimatedAuthViewProps> = ({
  children,
  direction,
}) => {
  return (
    <AnimatedViewWrapper direction={direction}>
      <Box flex={1} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "auto",
          maxWidth: "500px",
          margin: "0 auto",
          padding: "48px 24px",
        }}
      >
        {children}
      </Box>
      <Box flex={1} />
    </AnimatedViewWrapper>
  );
};

export default AnimatedAuthView;
