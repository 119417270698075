import React, { useState } from "react";
import { Box, Avatar } from "@mui/material";
import { IExpert } from "../../types/index";
import PrimaryIcon from "./PrimaryIcon";
import { ArrowLeft, ArrowRight } from "../../icons";
import { useAppDispatch, useAppSelector } from "../../app/store";
import useScreenSize from "./useScreenSize";
import {
  ActiveLayout,
  setActiveLayout,
  setMobileDrawerOpen,
} from "../../features/chats/workspaceSlice";
import { UtilityService } from "../../services/UtilityService";

interface ExpertAvatarProps {
  expert?: IExpert | null;
  handleExpertClick?: () => void | null;
  backgroundSize?: number;
  sx?: React.CSSProperties;
}

const ExpertAvatar: React.FC<ExpertAvatarProps> = ({
  expert,
  handleExpertClick = null,
  backgroundSize = 60,
  sx,
}) => {
  const dispatch = useAppDispatch();
  const workspaceSlice = useAppSelector((state) => state.workspace);
  const expertsSlice = useAppSelector((state) => state.experts);
  const activeExpert = expertsSlice.activeExpert;
  const leftPandelShowing = workspaceSlice.activeLayout === ActiveLayout.ONE;
  const screenSize = useScreenSize();
  const expertColor = expert?.color;
  const isMobile = UtilityService.getIsMobile();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    if (isMobile) return;
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    if (isMobile) return;
    setIsHovered(false);
  };

  const handleIconClick = () => {
    if (screenSize.isMobileSize) {
      dispatch(setMobileDrawerOpen(!workspaceSlice.mobileDrawerOpen));
    } else {
      dispatch(
        setActiveLayout(leftPandelShowing ? ActiveLayout.TWO : ActiveLayout.ONE)
      );
    }

    setIsHovered(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: expertColor,
        borderRadius: "50%",
        width: `${backgroundSize}px`,
        height: `${backgroundSize}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        position: "relative",

        ...sx,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Avatar
        alt={expert?.name}
        src={expert?.picture || ""}
        onClick={handleExpertClick}
        sx={{
          width: `${(backgroundSize * 90) / 100}px`,
          height: `${(backgroundSize * 90) / 100}px`,
        }}
      />
      {isHovered &&
        // only show the icon if its the active expert
        activeExpert?._id === expert?._id && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              borderRadius: "50%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PrimaryIcon
              iconName={
                workspaceSlice.mobileDrawerOpen || leftPandelShowing
                  ? "Collapse"
                  : "Expand"
              }
              icon={
                workspaceSlice.mobileDrawerOpen || leftPandelShowing ? (
                  <ArrowLeft />
                ) : (
                  <ArrowRight />
                )
              }
              defaultColorOverride="white"
              highlightedColorOverride="transparent"
              handleIconClick={handleIconClick}
            />
          </Box>
        )}
    </Box>
  );
};

export default ExpertAvatar;
