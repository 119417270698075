import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { loadUser } from "./userAuthSlice";
import { clearIsUserDirty, getIsUserDirty } from "../../routes/userRoutes";

export const fetchIsUserDirty = createAsyncThunk(
  "isUserDirty/fetchIsUserDirty",
  async (_, { dispatch }) => {
    const response = await getIsUserDirty();

    if (response.data) {
      const isDirty = response.data.isDirty;
      if (isDirty) {
        await clearIsUserDirty();
        dispatch(loadUser());
      }
    }
    return response.data;
  }
);

const isUserDirtySlice = createSlice({
  name: "isUserDirty",
  initialState: {
    isDirty: false,
    polling: false,
  },
  reducers: {
    startPolling: (state) => {
      state.polling = true;
    },
    stopPolling: (state) => {
      state.polling = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchIsUserDirty.fulfilled, (state, action) => {
      state.isDirty = action.payload;
    });
  },
});

export const { startPolling, stopPolling } = isUserDirtySlice.actions;

export default isUserDirtySlice.reducer;
