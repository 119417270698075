import React, { useEffect, useState } from "react";
import { ListItem, Slider, Typography, Box } from "@mui/material";
import { IConversationStyle } from "../../../../../../../types/index";
import { useTheme } from "@mui/material/styles";
import { useAppSelector } from "../../../../../../../app/store";

interface StyleCellProps {
  containerWidth?: number | null;
  conversationStyle?: IConversationStyle | null;
  handleUpdateValue: (updatedStyle: IConversationStyle) => void;
  initialValue?: number;
}

const StyleCell: React.FC<StyleCellProps> = ({
  containerWidth,
  conversationStyle,
  handleUpdateValue,
  initialValue,
}) => {
  const marks = [
    { value: 0, label: "Not funny" },
    { value: 25, label: "2" },
    { value: 50, label: "3" },
    { value: 75, label: "4" },
    { value: 100, label: "Funny" },
  ];

  const expertSlice = useAppSelector((state) => state.experts);
  const [expertColor, setExpertColor] = useState(
    expertSlice.activeExpert?.color
  );

  useEffect(() => {
    setExpertColor(expertSlice.activeExpert?.color);
  }, [expertSlice]);

  const theme = useTheme();

  const [selectedValue, setSelectedValue] = useState<number>(
    marks[initialValue ?? 0].value
  );

  const hideMiddleValues = containerWidth && containerWidth < 500;

  useEffect(() => {
    setSelectedValue(marks[initialValue ?? 0].value);
  }, [initialValue]);

  useEffect(() => {
    //console.log("containerWidth: " + containerWidth);
  }, [containerWidth]);

  useEffect(() => {
    //console.log("cells: " + JSON.stringify(conversationStyle));
  }, [conversationStyle]);

  const onValueSelected = (value: number) => {
    const updatedStyle = { ...conversationStyle } as IConversationStyle;
    let normalizedValue = 0;
    switch (value) {
      case 0:
        normalizedValue = 0;
        break;
      case 25:
        normalizedValue = 1;
        break;
      case 50:
        normalizedValue = 2;
        break;
      case 75:
        normalizedValue = 3;
        break;
      case 100:
        normalizedValue = 4;
        break;
    }

    updatedStyle.value = normalizedValue;
    handleUpdateValue(updatedStyle as IConversationStyle);
  };

  const handleChange = (event: any, newValue: number | number[]) => {
    setSelectedValue(newValue as number);
  };

  const handleSliderChangeCommitted = () => {
    const closestMark = marks.reduce((prev, curr) =>
      Math.abs(curr.value - selectedValue) <
      Math.abs(prev.value - selectedValue)
        ? curr
        : prev
    );
    setSelectedValue(closestMark.value);
    onValueSelected(closestMark.value);
  };

  return (
    <ListItem
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        paddingLeft: "32px",
        paddingRight: "32px",
        marginBottom: "16px",
      }}
    >
      <Typography
        fontSize="14px"
        fontWeight={500}
        sx={{ mb: "2px", ml: "-8px" }}
      >
        {conversationStyle?.label}
      </Typography>
      <Slider
        value={selectedValue}
        onChange={handleChange}
        onChangeCommitted={handleSliderChangeCommitted}
        min={0}
        max={100}
        marks={marks}
        sx={{
          width: "100%",
          "& .MuiSlider-rail": {
            height: "6px",
            backgroundColor: theme.palette.text.placeholder,
            opacity: 1,
          },
          "& .MuiSlider-track": {
            height: "6px",
            backgroundColor: "transparent",
            color: "transparent",
          },
          "& .MuiSlider-thumb": {
            width: "24px",
            height: "24px",
            backgroundColor: expertColor ?? "white",
            boxShadow: 3,
            "&:hover": {
              boxShadow: 3,
            },
          },
          "& .MuiSlider-mark": {
            width: "16px",
            height: "16px",
            backgroundColor: theme.palette.text.placeholder,
            borderRadius: "50%",
            marginLeft: "-8px",
            opacity: 1,
          },
          "& .MuiSlider-markLabel": {
            fontSize: "0px",
            height: "0px",
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: "-22px",
          marginBottom: "16px",
        }}
      >
        <Typography
          sx={{
            marginLeft: "24px",
            position: "absolute",
            color:
              selectedValue === 0 || selectedValue === 25
                ? expertColor
                : theme.palette.text.placeholder,
            fontSize: "12px",
            fontWeight: 700,
            textAlign: "left",
            left: "0",
          }}
        >
          {conversationStyle?.options[1] ?? "temp"}
        </Typography>

        {!hideMiddleValues && (
          <Typography
            sx={{
              position: "relative",
              color:
                selectedValue === 50
                  ? expertColor
                  : theme.palette.text.placeholder,
              fontSize: "12px",
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            {conversationStyle?.options[2] ?? "temp"}
          </Typography>
        )}

        <Typography
          sx={{
            marginRight: "24px",
            position: "absolute",
            color:
              selectedValue === 100 || selectedValue === 75
                ? expertColor
                : theme.palette.text.placeholder,
            fontSize: "12px",
            fontWeight: 700,
            textAlign: "right",
            right: "0",
          }}
        >
          {conversationStyle?.options[3] ?? "temp"}
        </Typography>
      </Box>
    </ListItem>
  );
};

export default StyleCell;
