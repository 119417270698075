import { ICommand, ICommandType } from "../../types/index";
import {
  Send,
  TranscribeOn,
  TranscribeOff,
  SpeakOn,
  SpeakOff,
  Prompt,
  Edit,
  History,
} from "../../icons";

export function getCommands(): ICommand[] {
  return [
    {
      command: "@cache",
      description: "Saves message as a prompt in the cache",
      type: ICommandType.ACTION,
    },
    {
      command: "@prompt",
      description: "Saves message as a prompt in the cache",
      type: ICommandType.ACTION,
    },
    {
      command: "@save",
      description: "Saves current chat to chat history",
      type: ICommandType.ACTION,
    },
    {
      command: "@trash",
      description: "Deletes the current chat",
      type: ICommandType.ACTION,
    },
    {
      command: "@transcribeOn",
      description: "Turns on transcription",
      type: ICommandType.ACTION,
    },
    {
      command: "@transcribeOff",
      description: "Turns off transcription",
      type: ICommandType.ACTION,
    },
    {
      command: "@speakOn",
      description: "Activates text-to-speech",
      type: ICommandType.ACTION,
    },
    {
      command: "@speakOff",
      description: "Deactivates text-to-speech",
      type: ICommandType.ACTION,
    },
    {
      command: "@clearCache",
      description: "Deletes all prompts in the cache",
      type: ICommandType.ACTION,
    },
    {
      command: "@editMessage",
      description: "Edits the message",
      type: ICommandType.SYSTEM,
    },
    {
      command: "@editPrompt",
      description: "Edits the prompt",
      type: ICommandType.SYSTEM,
    },
    {
      command: "@sendMessage",
      description: "Adds a message to the chat",
      type: ICommandType.SYSTEM,
    },
  ];
}

export function getActionCommands() {
  return getCommands().filter(
    (command) => command.type === ICommandType.ACTION
  );
}

export function getActionCommandStrings() {
  return getActionCommands().map((command) => command.command);
}

export function getIconForCommand(command: string) {
  switch (command) {
    case "@cache":
    case "@prompt":
      return <Prompt />;
    case "@save":
      return <History />;
    case "@trash":
    case "@clearCache":
    case "@transcribeOn":
      return <TranscribeOn />;
    case "@transcribeOff":
      return <TranscribeOff />;
    case "@speakOn":
      return <SpeakOn />;
    case "@speakOff":
      return <SpeakOff />;
    case "@editMessage":
    case "@editPrompt":
      return <Edit />;
    default:
      return <Send />;
  }
}

export function getPopupDataForCommand(command: string) {
  switch (command) {
    case "@trash":
      return {
        title: "Clear Chat",
        message: "Are you sure you want to clear this chat?",
        confirmationText: "Clear",
        isDestructive: true,
        confirmationHandler: "@trash",
      };
    case "@clearCache":
      return {
        title: "Clear Cache",
        message: "Are you sure you want to clear this cache?",
        confirmationText: "Clear",
        isDestructive: true,
        confirmationHandler: "@clearCache",
      };
    case "@save":
      return {
        title: "Create New Chat",
        message: "Save this current chat and create a new one?",
        confirmationText: "Save & New",
        isDestructive: false,
        confirmationHandler: "@save",
      };
    case "@createNew":
      return {
        title: "Create New Chat",
        message:
          "This will save the current chat and create a new one. Are you sure you want to continue?",
        confirmationText: "Create New",
        isDestructive: false,
        confirmationHandler: "@createNew",
      };
    default:
      return null;
  }
}

export function getActionCommandsForStrings(
  commandStrings: string[]
): ICommand[] {
  const actionCommands = getActionCommands();
  return actionCommands.filter((command) =>
    commandStrings.includes(command.command)
  );
}
