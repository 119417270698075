import React, { useEffect, useState } from "react";
import {
  IconButton,
  Typography,
  Box,
  ClickAwayListener,
  CircularProgress,
  darken,
} from "@mui/material";
import { Add, Delete } from "../../../icons";
import { useTheme } from "@mui/material/styles";
import { IChat } from "../../../types";
import { IChatState } from "../../../types/chat/IChat";
import { timeAgo } from "../../helpers/CustomUtilities";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { PrimaryIcon } from "../../ui-elements";
import { doAction } from "../../../features/commands/commandsSlice";
import { ActiveLayout } from "../../../features/chats/workspaceSlice";

interface ChatHistoryCellProps {
  chat: IChat;
  isSelected: boolean;
  onSelect: () => void;
  onClickAway: () => void;
  handleRemoveChat: (chat: IChat) => void;
  handleOpenChat: (chat: IChat) => void;
  expertColor?: string;
  chatState?: IChatState;
}

const SidebarChatCell: React.FC<ChatHistoryCellProps> = ({
  chat,
  isSelected,
  onSelect,
  onClickAway,
  handleRemoveChat,
  expertColor,
  chatState,
  handleOpenChat,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const chatSlice = useAppSelector((state) => state.chat);
  const messagesSlice = useAppSelector((state) => state.messages);
  const expertSlice = useAppSelector((state) => state.experts);
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const enum CellState {
    SAVING = "saving",
    DELETING = "deleting",
    SELECTED = "selected",
    DEFAULT = "default",
    OPENING = "opening",
  }

  const currentState = () => {
    switch (chatState) {
      case IChatState.SAVING:
        return CellState.SAVING;
      case IChatState.DELETING:
        return CellState.DELETING;
      case IChatState.OPENING:
        return CellState.OPENING;
      default:
        return isSelected ? CellState.SELECTED : CellState.DEFAULT;
    }
  };

  useEffect(() => {
    // console.log(
    //   "chat state changed for chat in chat history cell: ",
    //   chatState
    // );
  }, [isSelected, isHovered, chatSlice.activeChat, chat, chatState]);

  const createdAt = new Date(chat.lastMessageSent);
  const dateString = timeAgo(createdAt);
  const nameString = chat.chatName ?? "...";

  function isActiveCell() {
    switch (chatState) {
      case IChatState.SAVING:
      case IChatState.DELETING:
      case IChatState.OPENING:
        return true;
      default:
        return false;
    }
  }

  function labelForState() {
    if (isActiveChat()) {
      return "Current Chat";
    }

    switch (currentState()) {
      case CellState.SAVING:
        return "Saving " + nameString;
      case CellState.DELETING:
        return "Deleting " + nameString;
      case CellState.OPENING:
        return "Opening " + nameString;
      default:
        return nameString;
    }
  }

  function isActiveChat() {
    const activeExpert = expertSlice.activeExpert;
    if (activeExpert?.activeChat === chat._id) {
      return true;
    }

    const isActiveChat = chatSlice.activeChat?._id === chat._id;

    return isActiveChat;
  }

  const deleteIconIfCan = () => {
    if (isActiveChat()) {
      return null;
    }

    // saving or opening, no delete button
    if (currentState() === CellState.SAVING) {
      return null;
    }

    if (currentState() === CellState.OPENING) {
      return null;
    }

    return (
      <Box display={"flex"} right={0} top={"-50%"} overflow={"visible"}>
        <IconButton
          onClick={(event) => {
            event.stopPropagation(); // Add this line to stop event propagation
            handleRemoveChat(chat);
          }}
          disableTouchRipple
          sx={{
            backgroundColor: "transparent",
            color: theme.palette.text.secondary,
            "&:hover": {
              color: expertColor || theme.palette.text.secondary,
              backgroundColor: "transparent",
            },
            height: "22px",
            width: "22px",
            padding: "1px",
            borderRadius: "8px",
          }}
        >
          <Delete />
        </IconButton>
      </Box>
    );
  };

  const actionButtonsForState = () => {
    switch (currentState()) {
      case CellState.SAVING:
      case CellState.DELETING:
      case CellState.SELECTED:
      case CellState.OPENING:
        return deleteIconIfCan();
      default:
        return isHovered ? deleteIconIfCan() : null;
    }
  };

  const getColorForState = () => {
    switch (currentState()) {
      case CellState.SELECTED:
        return theme.palette.background.backdrop;
      default:
        return "transparent";
    }
  };

  const tryOpenChat = () => {
    if (isActiveChat()) {
      return;
    }

    handleOpenChat(chat);
  };

  function hasMessages() {
    return messagesSlice.messages.length > 0;
  }

  const createNewChat = () => {
    let hasActiveJob = false;
    for (let i = 0; i < chatSlice.chats.length; i++) {
      const chat = chatSlice.chats[i];

      if (chat.chatState !== "saved" && chat.chatState !== "open") {
        hasActiveJob = true;
        break;
      }
    }

    if (hasActiveJob) {
      // console.log("Cannot create new chat, active job exists");
      return;
    }

    const actionData = {
      message: "",
      command: "@createNew",
    };

    dispatch(doAction(actionData));
  };

  const currentActiveLayout = useAppSelector(
    (state) => state.workspace.activeLayout
  );
  const shouldUseExpertColor = currentActiveLayout !== ActiveLayout.ONE;

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Box
        display="flex"
        flexDirection={"column"}
        justifyContent={"space-between"}
        width={"100%"}
      >
        <Box
          sx={{
            width: "100%",
            minHeight: "24px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
            backgroundColor: getColorForState(),
            color: expertColor || theme.palette.text.secondary,
            borderRadius: "8px",
            // disable if active cell
            pointerEvents: isActiveCell() ? "none" : "auto",
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => tryOpenChat()}
        >
          {!isActiveChat() && !isActiveCell() && (
            <Typography
              sx={{
                fontSize: "10px",
                fontWeight: "200",
                minWidth: "24px",

                border: "none",
                color: theme.palette.colors.grayScale[200],
                lineHeight: "20px",
              }}
            >
              {dateString}
            </Typography>
          )}
          {isActiveCell() ? (
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ height: "24px", width: "24px" }}
            >
              <CircularProgress size="16px" color={"inherit"} thickness={6} />
            </Box>
          ) : null}
          <Typography
            sx={{
              marginLeft: isActiveChat() && !isActiveCell() ? "0px" : "8px",
              fontSize: "12px",
              textAlign: "left",
              width: "100%",
              fontWeight: "500",
              border: "none",
              color: isActiveChat()
                ? expertColor || theme.palette.text.secondary
                : theme.palette.text.secondary,
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {labelForState()}
          </Typography>
          {isActiveChat() && hasMessages() && (
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ height: "24px", width: "24px" }}
            >
              <PrimaryIcon
                icon={<Add />}
                iconName="New Chat"
                size="22px"
                defaultColorOverride={theme.palette.colors.grayScale.white}
                backgroundColorOverride={
                  shouldUseExpertColor
                    ? expertColor
                    : theme.palette.mode === "dark"
                    ? theme.palette.colors.grayScale[500]
                    : theme.palette.colors.grayScale[200]
                }
                highlightedColorOverride={
                  shouldUseExpertColor
                    ? darken(expertColor, 0.2)
                    : theme.palette.mode === "dark"
                    ? darken(theme.palette.colors.grayScale[500], 0.2)
                    : darken(theme.palette.colors.grayScale[200], 0.2)
                }
                handleIconClick={() => createNewChat()}
              />
            </Box>
          )}
          {actionButtonsForState()}
        </Box>
      </Box>
    </ClickAwayListener>
  );
};
export default SidebarChatCell;
