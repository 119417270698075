import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../routes/routes";
import { handleAxiosError } from "../../app/ErrorHandler";
import { ISubscriptionInfo } from "../../types/user/ISubscriptionInfo";
import { LocalStorageService } from "../../services/LocalStorageService";

const initialState = {
  userSubscription: LocalStorageService.get(
    "userSubscription"
  ) as ISubscriptionInfo | null,
  settingToCancelled: false,
};

export const loadUserSubscription = createAsyncThunk(
  "userSubscription/loadUserSubscription",
  async (userSubscriptionId: string, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.getSubscriptionInfo(userSubscriptionId);
      dispatch(setUserSubscription(data));
      LocalStorageService.set("userSubscription", data);

      const status = data.subscriptionStatus;
      if (status === "PENDING_CANCEL" || status === "CANCELLED") {
        dispatch(setSettingToCancelled(false));
      }
      return data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const checkout = createAsyncThunk(
  "userSubscription/checkout",
  async (planId: string, { rejectWithValue }) => {
    try {
      const { data } = await api.checkout(planId);
      const url = data.url;
      window.location.href = url;

      return "Redirecting to Stripe Checkout...";
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const cancelPlan = createAsyncThunk(
  "userSubscription/cancelPlan",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.cancelPlan();
      return data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const revertScheduledCancel = createAsyncThunk(
  "userSubscription/revertScheduledCancel",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.revertScheduledCancel();
      return data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const revertScheduledDowngrade = createAsyncThunk(
  "userSubscription/revertScheduledDowngrade",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.revertScheduledDowngrade();
      return data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const createCustomerPortalSession = createAsyncThunk(
  "userSubscription/createCustomerPortalSession",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.createCustomerPortalSession();
      const url = data.url;
      window.location.href = url;

      return "Redirecting to Customer Portal...";
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const validatePayment = createAsyncThunk(
  "userSubscription/validatePayment",
  async (sessionId: string, { dispatch, rejectWithValue }) => {
    const maxRetries = 5;
    const retryDelay = 1000; // Delay between retries in milliseconds

    for (let attempt = 0; attempt <= maxRetries; attempt++) {
      try {
        const { data } = await api.validatePayment(sessionId);
        dispatch(setUserSubscription(data));
        LocalStorageService.set("userSubscription", data);
        return data;
      } catch (error) {
        if (attempt < maxRetries) {
          console.log(`Retrying... Attempt ${attempt + 1}`);
          await new Promise((resolve) => setTimeout(resolve, retryDelay));
        } else {
          console.log("Max retries reached, rejecting...");
          return rejectWithValue(handleAxiosError(error));
        }
      }
    }
  }
);

export const clearUserSubscription = createAsyncThunk(
  "userSubscription/clearUserSubscription",
  async (_, { dispatch }) => {
    LocalStorageService.remove("userSubscription");
    dispatch(setUserSubscription(null));
  }
);

export const userSubscriptionSlice = createSlice({
  name: "userSubscription",
  initialState: initialState,
  reducers: {
    setUserSubscription: (state, action) => {
      state.userSubscription = action.payload;
    },
    setSettingToCancelled: (state, action) => {
      state.settingToCancelled = action.payload;
    },
  },
});

export const { setUserSubscription, setSettingToCancelled } =
  userSubscriptionSlice.actions;

export default userSubscriptionSlice.reducer;
