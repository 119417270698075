import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../routes/routes";
import { handleAxiosError } from "../../app/ErrorHandler";
import {
  IResetPasswordPayload,
  IUserCredentials,
} from "../../types/user/IUserCredentials";
import { LocalStorageService } from "../../services/LocalStorageService";
import { RootState } from "../../app/store";

const initialState = {
  userCredentials: LocalStorageService.get(
    "userCredentials"
  ) as IUserCredentials | null,
};

export const loadUserCredentials = createAsyncThunk(
  "userCredentials/loadUserCredentials",
  async (userCredentialsId: string, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.getUserCredentialsInfo(userCredentialsId);
      dispatch(setUserCredentials(data));
      LocalStorageService.set("userCredentials", data);
      return data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const resetPassword = createAsyncThunk(
  "userCredentials/resetPassword",
  async (resetPasswordPayload: IResetPasswordPayload, { rejectWithValue }) => {
    try {
      const { data } = await api.resetPassword(resetPasswordPayload);
      return data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const listenForBetaApproval = createAsyncThunk(
  "userCredentials/listenForBetaApproval",
  async (_, { dispatch, getState }) => {
    // while betaApproved is false, keep checking user credentials every 5 seconds
    const state = getState() as RootState;
    const userCredentialsSlice = state.userCredentials;
    const userCredentials = userCredentialsSlice?.userCredentials;
    if (userCredentials?.betaApproved === true) {
      return userCredentials;
    }

    const { data } = await api.getUserCredentialsInfo(userCredentials?._id);
    if (data.betaApproved === true) {
      dispatch(setUserCredentials(data));
      LocalStorageService.set("userCredentials", data);
      return data;
    } else {
      while (data.betaApproved === false) {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        const { data } = await api.getUserCredentialsInfo(userCredentials?._id);
        dispatch(setUserCredentials(data));
        LocalStorageService.set("userCredentials", data);
      }
    }
  }
);

export const resendVerificationEmail = createAsyncThunk(
  "userCredentials/resendVerificationEmail",
  async (email: string, { rejectWithValue }) => {
    try {
      const { data } = await api.resendVerificationEmail(email);
      return data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const sendResetPasswordEmail = createAsyncThunk(
  "userCredentials/sendResetPasswordEmail",
  async (email: string, { rejectWithValue }) => {
    try {
      const payload = { email: email };
      const { data } = await api.sendResetPasswordEmail(payload);
      return data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const clearUserCredentials = createAsyncThunk(
  "userCredentials/clearUserCredentials",
  async (_, { dispatch }) => {
    LocalStorageService.remove("userCredentials");
    dispatch(setUserCredentials(null));
  }
);

export const userCredentialsSlice = createSlice({
  name: "userCredentials",
  initialState: initialState,
  reducers: {
    setUserCredentials: (state, action) => {
      state.userCredentials = action.payload;
    },
  },
});

export const { setUserCredentials } = userCredentialsSlice.actions;

export default userCredentialsSlice.reducer;
