import { Box, lighten } from "@mui/material";
import { IMessage } from "../../../../../../types";
import { alpha } from "@mui/material/styles";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { NodeProps } from "../blocks/MCTypes";
import CodeBlock from "./CodeBlock";

export function processForMarkdown(message: IMessage, processor, theme) {
  const markdownText = message.message;
  const markdownAst = processor.parse(markdownText);
  const markdownTextWithDepth = processor.stringify(markdownAst);

  const customStyle = { ...dark };
  customStyle['pre[class*="language-"]']["backgroundColor"] = alpha(
    theme.palette.colors.grayScale.black,
    1.0
  );
  // border color transparent
  customStyle['pre[class*="language-"]']["borderColor"] = "transparent";
  customStyle['pre[class*="language-"]']["fontWeight"] = 800;
  customStyle['pre[class*="language-"]']["opacity"] = 0.8;

  customStyle['pre[class*="language-"]']["lineHeight"] = "1.5";
  customStyle['pre[class*="language-"]']["boxShadow"] = "0px 0px 0px 0px #000";

  customStyle['pre[class*="language-"]']["fontSize"] = "0.75rem";
  customStyle['pre[class*="language-"]']["borderRadius"] = "16px";
  customStyle['pre[class*="language-"]']["paddingTop"] = "16px";
  customStyle['pre[class*="language-"]']["paddingLeft"] = "16px";
  customStyle['pre[class*="language-"]']["paddingRight"] = "8px";

  const components = {
    p: ({ node, ...props }: NodeProps) => (
      <Box component="p" my={1} {...props} />
    ),
    li: ({ node, ordered, ...props }: NodeProps) => (
      <Box
        component="li"
        sx={{
          ...(ordered && { listStyleType: "decimal" }),
          pl: `${node.depth + 1}em`,
          mt: node.children[0]?.type === "list" ? 1 : 0.5,
          mb:
            node.children[node.children.length - 1]?.type === "list" ? 1 : 0.5,
        }}
        {...props}
      />
    ),
    ul: ({ node, ordered, ...props }: NodeProps) => {
      const listStyleType = node.ordered ? "decimal" : "disc";
      return (
        <Box
          component="ul"
          marginLeft={"48px"}
          {...props}
          sx={{ listStyleType }}
        />
      );
    },
    // link
    a: ({ node, ...props }: NodeProps) => (
      <Box
        component="a"
        sx={{
          color:
            theme.palette.mode === "dark"
              ? lighten(theme.palette.primary.main, 0.3)
              : theme.palette.primary.main,
          textDecoration: "none",
        }}
        {...props}
      />
    ),
    ol: ({ node, ordered, ...props }: NodeProps) => (
      <Box component="ol" marginLeft={"48px"} {...props} />
    ),
    code: ({ inline, className, children, ...props }) => {
      const match = /language-(\w+)/.exec(className || "");
      const language = match ? match[1] : null;
      if (!inline && language) {
        return (
          <CodeBlock
            code={String(children)}
            language={language}
            message={message}
            key={message._id}
          />
        );
      } else {
        return (
          <code className={className} {...props}>
            {children}
          </code>
        );
      }
    },
  };
  return { components, markdownTextWithDepth };
}
