import { useEffect, useState } from "react";
import {
  Typography,
  darken,
  alpha,
  Box,
  CircularProgress,
  lighten,
} from "@mui/material";
import CancelPopup from "../../../../popups/CancelPopup";

interface BannerProps {
  showBanner: boolean;
  theme: any;
  usageDataReport: any;
  bannerType: string;
  handleActionConfirm: () => void;
  showProcessing: boolean;
}

const Banner = ({
  showBanner,
  theme,
  usageDataReport,
  bannerType,
  handleActionConfirm,
  showProcessing,
}: BannerProps) => {
  const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);
  const [bannerShowing, setBannerShowing] = useState(showBanner);

  useEffect(() => {
    setBannerShowing(showBanner);
  }, [showBanner]);

  useEffect(() => {
    // console.log("showProcessing set to: ", showProcessing);
  }, [showProcessing]);

  const getDisplayDate = (date: string) => {
    const d = new Date(date);
    return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
  };

  const bannerText =
    bannerType === "cancel"
      ? `Cancellation will take effect on ${getDisplayDate(
          usageDataReport?.periodEnd
        )}`
      : `Downgrade will take effect on ${getDisplayDate(
          usageDataReport?.periodEnd
        )}`;

  const backgroundColor =
    bannerType === "cancel"
      ? theme.palette.isDarkMode
        ? theme.palette.error.main
        : lighten(theme.palette.error.main, 0.1)
      : theme.palette.isDarkMode
      ? darken(theme.palette.warning.main, 0.1)
      : lighten(theme.palette.warning.main, 0.1);

  const handleBannerClick = () => {
    setIsCancelPopupOpen(true);
  };

  const handleConfirm = async () => {
    setIsCancelPopupOpen(false);
    await handleActionConfirm();
  };

  return bannerShowing ? (
    <Box
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      width={`calc(100% + 32px)`}
      marginTop={"0px"}
      marginLeft={"-16px"}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
      >
        <Typography
          variant="body1"
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          fontSize={"12px"}
          fontWeight={400}
          textAlign={"center"}
          marginLeft={"8px"}
          sx={{
            color: theme.palette.colors.grayScale.white,
            backgroundColor,
            cursor: "pointer",
            "&:hover": {
              backgroundColor: alpha(backgroundColor, 0.8),
            },
          }}
          onClick={handleBannerClick}
        >
          {showProcessing && (
            <Box
              component="span"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress
                size={10}
                thickness={6}
                sx={{
                  color: theme.palette.colors.grayScale.white,
                  marginRight: "8px",
                }}
              />
            </Box>
          )}
          {showProcessing ? "Processing" : bannerText}
        </Typography>
        <CancelPopup
          open={isCancelPopupOpen}
          onClose={() => setIsCancelPopupOpen(false)}
          onConfirm={handleConfirm}
          title={"Changed Your Mind?"}
          description={
            bannerType.toLowerCase() === "cancel"
              ? "Are you sure you want to reverse your cancellation and resume your plan?"
              : "Are you sure you want to cancel your downgrade request and keep your current plan?"
          }
          confirmText={"Continue"}
          cancelText={"Cancel"}
          featureCancel={false}
        />
      </Box>
    </Box>
  ) : null;
};

export default Banner;
