import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IPlan } from "../../../types/ui/pricing-page/IPlan";
import { fetchPlans } from "../../../routes/userRoutes";

// Define a type for the slice state
interface PricingPageState {
  plans: IPlan[];
  intendedPlanId: string | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: PricingPageState = {
  plans: [],
  intendedPlanId: null,
  isLoading: false,
  error: null,
};

// Create async thunk for fetching plans
export const getPlans = createAsyncThunk(
  "pricingPage/getPlans",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchPlans(); // Fetch plans from your API
      return response.data;
    } catch (err: any) {
      // Handle error (e.g., by returning a specific error message or the error object itself)
      return rejectWithValue(
        err.response?.data?.error || "An unexpected error occurred"
      );
    }
  }
);

const pricingPageSlice = createSlice({
  name: "pricingPage",
  initialState,
  reducers: {
    setIntendedPlanId(state, action) {
      state.intendedPlanId = action.payload || null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlans.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getPlans.fulfilled, (state, action) => {
        state.plans = action.payload;
        state.isLoading = false;
      })
      .addCase(getPlans.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      });
  },
});

// Export actions
export const { setIntendedPlanId } = pricingPageSlice.actions;

// Export actions and reducer
export default pricingPageSlice.reducer;
