import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { ImageBlock, PreferencesBlock, MyInfoBlock, DangerZoneBlock } from "./";

const SettingsSection: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = React.useState<number>(0);

  useEffect(() => {
    if (containerWidth === 0) {
      const width = containerRef?.current?.clientWidth as number;
      setContainerWidth(width);
    }

    const handleResize = () => {
      const width = containerRef?.current?.clientWidth as number;
      setContainerWidth(width);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-column",
        alignItems: "flex-start",
        alignContent: "flex-start",
        height: "auto",
        width: "100%",
      }}
    >
      <ImageBlock />
      <PreferencesBlock containerWidth={containerWidth} />
      <MyInfoBlock containerWidth={containerWidth} />
      <DangerZoneBlock />
    </Box>
  );
};

export default SettingsSection;
