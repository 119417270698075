import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IVoice } from "../../types/index";
import * as api from "../../routes/routes";

interface VoicesState {
  voices: IVoice[] | null;
}

const initialState: VoicesState = {
  voices: [],
};

export const loadVoices = createAsyncThunk(
  "voices/loadVoices",
  async (_, { dispatch }) => {
    await api.getDefaultVoiceOptions().then((response) => {
      dispatch(setVoices(response.data.voices));
    });
  }
);

export const voices = createSlice({
  name: "voices",
  initialState: initialState,
  reducers: {
    setVoices: (state, action) => {
      state.voices = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadVoices.fulfilled, (state, action) => {
      // not sure what to do here
    });
  },
});

export const { setVoices } = voices.actions;

export default voices.reducer;
