import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../app/store";
import SiteHeader from "./SiteHeader";
import useScreenSize from "../ui-elements/useScreenSize";

const Navbar = () => {
  const location = useLocation();
  const screenSize = useScreenSize();
  const userAuth = useAppSelector((state) => state.userAuth.userAuth);
  const [isAuthPage, setIsAuthPage] = useState(false);

  useEffect(() => {
    const pathname = location.pathname;
    const pageName = pathname.split("/")[1];
    setIsAuthPage(pageName === "auth");
  }, [userAuth]);

  useEffect(() => {
    // console.log("isAuthPage", isAuthPage);
  }, [isAuthPage]);

  useEffect(() => {
    // console.log("isAuthPage", isAuthPage);
  }, [location]);

  return screenSize.isLarge ? <></> : <SiteHeader />;
};

export default Navbar;
