import React from "react";
import { Typography, Grid, useTheme } from "@mui/material";

interface BlockTitleProps {
  title: string;
  subtitle: string;
  overrideMarginTop?: boolean;
  halfSize?: boolean;
}

const BlockTitle: React.FC<BlockTitleProps> = ({
  title,
  subtitle,
  overrideMarginTop,
  halfSize = false,
}) => {
  const theme = useTheme();
  return (
    <Grid
      item
      width={halfSize ? "50%" : "100%"}
      paddingBottom={"4px"}
      marginTop={overrideMarginTop ? "4px" : "20px"}
    >
      <Grid container spacing={"8px"}>
        <Grid item>
          <Typography
            fontWeight={800}
            color={theme.palette.text.secondary}
          >{`${title}`}</Typography>
        </Grid>
        <Grid item>
          <Typography fontWeight={300} color={theme.palette.text.secondary}>
            {subtitle}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BlockTitle;
