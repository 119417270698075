import { useEffect } from "react";
import { useAppSelector } from "../../../../../../app/store";
import { IMessageBarState } from "./MessageBarState";
import { createEmptyDecoratedEditor } from "../commands/CommandDecorator";
import { ActiveView } from "../../../../../../features/chats/workspaceSlice";

export const useExpertSetter = (
  messageBarState: IMessageBarState & { reset: (newExpert: any) => void }
) => {
  const activeExpert = useAppSelector((state) => state.experts.activeExpert);
  const commandsSlice = useAppSelector((state) => state.commands);

  useEffect(() => {
    if (
      messageBarState.localExpert &&
      messageBarState.localExpert !== activeExpert
    ) {
      // Use the reset function from messageBarState
      messageBarState.reset(activeExpert);
    } else if (activeExpert !== null && messageBarState.localExpert === null) {
      // When page refreshes, activeExpert is null for a split second
      messageBarState.localView = ActiveView.CHAT;
      messageBarState.editorState = createEmptyDecoratedEditor(
        commandsSlice,
        activeExpert
      );
      messageBarState.localExpert = activeExpert;
    }
  }, [activeExpert, messageBarState, commandsSlice]);

  return messageBarState;
};
