import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Popover,
  Typography,
  useTheme,
  Box,
} from "@mui/material";
import lolli from "../../../../../images/home-page/lolli.png";
import lolliPopup from "../../../../../images/home-page/lolli-popup.jpg";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../app/store";
import SeePlansButton from "../home-components/SeePlansButton";

const HomeHeaderSection: React.FC = () => {
  const theme = useTheme();
  const themeSlice = useAppSelector((state) => state.theme);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    //console.log("color", color);
  }, [themeSlice.publicColor]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      width="100%"
      alignItems={"center"}
      justifyContent={"center"}
      paddingLeft={"16px"}
      paddingRight={"16px"}
      sx={{ mt: "48px", mb: "48px" }}
    >
      <Typography
        variant="body2"
        sx={{
          color: theme.palette.text.tertiary,
          textAlign: "center",
        }}
      >
        {`- INTRODUCING -`}
      </Typography>
      <Typography
        color={themeSlice.publicColor ?? theme.palette.primary.main}
        textAlign={"center"}
        variant="h1"
        // set font size based on screen size
        fontSize={{ xs: "32px", sm: "60px" }}
        paddingTop={{ xs: "4px", sm: "16px" }}
      >{`AI You Can Relate To`}</Typography>
      <Grid
        container
        paddingTop={{ xs: "4px", sm: "8px" }}
        direction="row"
        width={"100%"}
        justifyContent={"center"}
        spacing={"4px"}
        marginBottom={{ xs: "32px", sm: "48px" }}
      >
        <Grid item>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.tertiary,
              textAlign: "center",
            }}
          >
            {`The only thing more human is`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "700",
              color: theme.palette.text.tertiary,
              textAlign: "center",
            }}
          >
            {`Lolli`}
          </Typography>
        </Grid>
        <Grid item>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <img
              src={lolli}
              alt="virtual_experts_logo"
              width="16"
              height="16"
            />
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
                "& .MuiPopover-paper": {
                  borderRadius: "20px",
                  bgcolor: theme.palette.background.backdrop,
                },
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Card sx={{ maxWidth: 345, pointerEvents: "auto" }}>
                <CardMedia
                  component="img"
                  height="140"
                  image={lolliPopup} // Image at the top of the popup
                  alt="Lolli"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    True Story
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Lolli had a little doggy sister named Enca (RIP). Lolli knew
                    that when she rang the bell to go outside, we'd let her out.
                    Lolli... didn't like Enca. One day, Lolli rang the bell, and
                    when we opened the door, Enca excitedly ran out. Lolli just
                    stood there. Looked up... and told us with her eyes to shut
                    the door. You can't tell me that's not a little bit human.
                  </Typography>
                </CardContent>
              </Card>
            </Popover>
          </Box>
        </Grid>
      </Grid>
      <SeePlansButton />
    </Box>
  );
};

export default HomeHeaderSection;
