import { EditorState, Modifier } from "draft-js";
import { IMessageBarState } from "../state-setters/MessageBarState";

export function processCommandsIconTapped(
  messageBarState: IMessageBarState,
  hydrateEditorUi: any,
  setFocus: any
) {
  return () => {
    const contentState = messageBarState.editorState.getCurrentContent();
    const selection = messageBarState.editorState.getSelection();
    const anchorKey = selection.getAnchorKey();
    const anchorOffset = selection.getAnchorOffset();
    const block = contentState.getBlockForKey(anchorKey);
    const text = block.getText();

    if (text === null || text === undefined || text === "") {
      setFocus();
      // insert an @ symbol and rerun
      const newContentState = Modifier.insertText(contentState, selection, "@");
      const newAnchorOffset = anchorOffset + 1;
      const newSelection = selection.merge({
        anchorOffset: newAnchorOffset,
        focusOffset: newAnchorOffset,
      });
      const newEditorState = EditorState.forceSelection(
        EditorState.push(
          messageBarState.editorState,
          newContentState,
          "insert-characters"
        ),
        newSelection
      );
      messageBarState.editorState = newEditorState;
      messageBarState.showPlaceholder = false;
      messageBarState.showSuggestions = true;
      messageBarState.highlightedSuggestionsIndex = 0;

      hydrateEditorUi(newEditorState);
      return;
    }

    if (messageBarState.showSuggestions) {
      // Find the position of the @ symbol
      const atSymbolIndex = text.lastIndexOf("@", anchorOffset - 1);

      if (atSymbolIndex === 0) {
        // Call setShowPlaceholder if @ is at index 0
        messageBarState.showPlaceholder = true;
      }

      if (atSymbolIndex !== -1) {
        // Remove from @ symbol to current cursor position
        const rangeToRemove = selection.merge({
          anchorOffset: atSymbolIndex,
          focusOffset: anchorOffset,
        });
        let newContentState = Modifier.removeRange(
          contentState,
          rangeToRemove,
          "backward"
        );

        // Adjust for any extra spaces left
        const blockTextAfterRemoval = newContentState
          .getBlockForKey(anchorKey)
          .getText();
        if (
          blockTextAfterRemoval[atSymbolIndex - 1] === " " &&
          blockTextAfterRemoval[atSymbolIndex] === " "
        ) {
          const spaceRangeToRemove = rangeToRemove.merge({
            anchorOffset: atSymbolIndex - 1,
            focusOffset: atSymbolIndex,
          });
          newContentState = Modifier.removeRange(
            newContentState,
            spaceRangeToRemove,
            "backward"
          );
        }

        const newEditorState = EditorState.push(
          messageBarState.editorState,
          newContentState,
          "apply-entity"
        );

        messageBarState.editorState = newEditorState;
      }

      messageBarState.showSuggestions = false;
      return;
    } else {
      // Handle insertion of @ symbol and manage spaces
      let insertText = "@";
      if (text[anchorOffset - 1] !== " " && anchorOffset > 0) {
        insertText = " " + insertText;
      }
      if (text[anchorOffset] !== " " && anchorOffset < text.length) {
        insertText += " ";
      }

      const newContentState = Modifier.insertText(
        contentState,
        selection,
        insertText
      );
      const newAnchorOffset =
        anchorOffset + insertText.length - (insertText.endsWith(" ") ? 1 : 0);

      // Move the selection after the '@' symbol
      const newSelection = selection.merge({
        anchorOffset: newAnchorOffset,
        focusOffset: newAnchorOffset,
      });

      // Apply the changes to the EditorState
      const newEditorState = EditorState.forceSelection(
        EditorState.push(
          messageBarState.editorState,
          newContentState,
          "insert-characters"
        ),
        newSelection
      );

      messageBarState.editorState = newEditorState;
      hydrateEditorUi(newEditorState);
    }
  };
}
