import { createSlice, createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IErrorMessage, ITranscriptionData } from "../../types";
import { setIsTranscribing } from "../chats/workspaceSlice";
import { showErrorNotification } from "../ui/errorSlice";

export interface AudioTranscriptionState {
  transcriptionData: ITranscriptionData | null;
  transcriptionState: TranscriptionState;
  finalizeTranscriptionSegment: boolean;
  clearTranscriptionSegment: boolean;
}

export enum TranscriptionState {
  Disconnected = 0,
  Connecting = 1,
  Connected = 2,
  Disconnecting = 3,
  Error = 4,
}

const initialState: AudioTranscriptionState = {
  transcriptionData: null,
  transcriptionState: TranscriptionState.Disconnected,
  finalizeTranscriptionSegment: false,
  clearTranscriptionSegment: false,
};

export const streamAudio = createAction<ITranscriptionData>(
  "audioTranscription/streamAudio"
);

export const throwError = createAsyncThunk(
  "audioTranscription/throwError",
  async (errorMessage: any, { dispatch }) => {
    dispatch(setTranscriptionData(null));
    dispatch(setTranscriptionState(TranscriptionState.Error));
    dispatch(setIsTranscribing(false));

    if (errorMessage?.error?.jobService?.job?.errorStatus == 405) {
      //console.log("We did it.");
    } else {
      if (errorMessage?.error?.jobService?.job?.errorStatus == 402) {
        const outOfDataErrorMessage = {
          title: "Out of Data!",
          message:
            "You appear to be out of data. Please either wait until your next billing cycle or upgrade your plan and try again.",
          outOfData: true,
        } as IErrorMessage;
        dispatch(showErrorNotification(outOfDataErrorMessage));
      } else {
        dispatch(showErrorNotification(errorMessage));
      }
    }
    return errorMessage;
  }
);

export const audioTranscriptionSlice = createSlice({
  name: "audioTranscription",
  initialState,
  reducers: {
    setTranscriptionData: (state, action) => {
      state.transcriptionData = action.payload as ITranscriptionData;
      // todo - update cursor with new preText / postText if finalTranscription is not null or undefined or ""
    },
    setTranscriptionState: (state, action) => {
      state.transcriptionState = action.payload;
    },
    setFinalizeTranscriptionSegment: (state, action) => {
      state.finalizeTranscriptionSegment = action.payload;
    },
    updateTranscriptionState: (state, action) => {
      state.transcriptionState = action.payload;
    },
    setClearTranscriptionSegment: (state, action) => {
      state.clearTranscriptionSegment = action.payload;
    },
  },
  // No need for extraReducers if you handle everything in middleware
});

export const {
  setTranscriptionData,
  setTranscriptionState,
  updateTranscriptionState,
  setFinalizeTranscriptionSegment,
  setClearTranscriptionSegment,
} = audioTranscriptionSlice.actions;
export default audioTranscriptionSlice.reducer;
