import React, { useEffect, useRef } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import {
  streamAudio,
  setTranscriptionState,
  TranscriptionState,
  setFinalizeTranscriptionSegment,
} from "../../features/audio/transcriptionSlice";
import { ITranscriptionData, ICursorData } from "../../types";

const SLICE_INTERVAL = 250; // Slice & send audio every 250ms

const AudioRecorder: React.FC = () => {
  const dispatch = useAppDispatch();
  const workspaceSlice = useAppSelector((state) => state.workspace);
  const messagesSlice = useAppSelector((state) => state.messages);
  const transcriptionSlice = useAppSelector(
    (state) => state.audioTranscription
  );
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const wantsToDisconnect = useRef<boolean>(false);
  const finalizeTranscriptionSegmentRef = useRef<boolean>(false);
  const messagesSliceRef = useRef(messagesSlice);

  useEffect(() => {
    if (workspaceSlice.isTranscribing) {
      if (
        transcriptionSlice.transcriptionState ===
          TranscriptionState.Disconnected ||
        transcriptionSlice.transcriptionState === TranscriptionState.Error
      ) {
        dispatch(setTranscriptionState(TranscriptionState.Connecting));
      }
    } else {
      if (
        transcriptionSlice.transcriptionState === TranscriptionState.Connected
      ) {
        stopMediaRecorderAfterDelay();
      } else if (
        transcriptionSlice.transcriptionState === TranscriptionState.Error
      ) {
        wantsToDisconnect.current = true;
        mediaRecorderRef.current?.stop();
      }
    }
  }, [workspaceSlice.isTranscribing]);

  useEffect(() => {
    const newState = transcriptionSlice.transcriptionState;
    if (newState === TranscriptionState.Connected) {
      sendAudio();
    }
  }, [transcriptionSlice.transcriptionState]);

  useEffect(() => {
    messagesSliceRef.current = messagesSlice;
  }, [messagesSlice]);

  useEffect(() => {
    // trigger a finalize event if true
    if (transcriptionSlice.finalizeTranscriptionSegment) {
      finalizeTranscriptionSegmentRef.current =
        transcriptionSlice.finalizeTranscriptionSegment;
    }
  }, [transcriptionSlice.finalizeTranscriptionSegment]);

  async function stopMediaRecorderAfterDelay() {
    await new Promise((resolve) => setTimeout(resolve, SLICE_INTERVAL));
    mediaRecorderRef.current?.stop();
  }

  useEffect(() => {
    //console.log("mediaRecorderRef.current ", mediaRecorderRef.current);
  }, [mediaRecorderRef.current]);

  const sendAudio = async () => {
    try {
      if (mediaRecorderRef.current === null) {
        const mediaRecorder = await initializeMediaRecorder();
        mediaRecorderRef.current = mediaRecorder;
      } else {
        mediaRecorderRef.current = null;
        const mediaRecorder = await initializeMediaRecorder();
        mediaRecorderRef.current = mediaRecorder;
      }
    } catch (error) {
      console.error("Error starting audio recording:", error);
    }
  };

  const intendToFinalizeTranscriptionSegmentRef = useRef<boolean>(false);

  async function initializeMediaRecorder() {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });

    const mediaRecorder = new MediaRecorder(stream);
    mediaRecorder.onstop = handleMediaRecorderStop;
    mediaRecorder.onerror = handleMediaRecorderError;
    mediaRecorder.ondataavailable = async (event) => {
      if (event.data.size > 0) {
        const audioBuffer = await event.data.arrayBuffer();
        const cursorData = messagesSliceRef.current.cursorData as ICursorData;

        const transcriptionData = {
          preText: cursorData?.preText ?? "",
          postText: cursorData?.postText ?? "",
          audioBuffer: audioBuffer,
          finalizeTranscriptionSegment:
            finalizeTranscriptionSegmentRef?.current,
        } as ITranscriptionData;

        if (intendToFinalizeTranscriptionSegmentRef.current === true) {
          finalizeTranscriptionSegmentRef.current = false;
          intendToFinalizeTranscriptionSegmentRef.current = false;
          dispatch(setFinalizeTranscriptionSegment(false));
        }

        if (finalizeTranscriptionSegmentRef.current === true) {
          intendToFinalizeTranscriptionSegmentRef.current = true;
        }

        dispatch(streamAudio(transcriptionData));
      }

      if (wantsToDisconnect.current) {
        wantsToDisconnect.current = false;
        dispatch(setTranscriptionState(TranscriptionState.Disconnecting));
      }
    };

    mediaRecorder.start(SLICE_INTERVAL);
    return mediaRecorder;
  }

  const handleMediaRecorderStop = () => {
    //console.log("handle media recorder stopped, want to disconnect = true");
    wantsToDisconnect.current = true;

    if (mediaRecorderRef.current && mediaRecorderRef.current.stream) {
      mediaRecorderRef.current.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };

  const handleMediaRecorderError = (event) => {
    console.error("MediaRecorder error:", event.error);
  };

  useEffect(() => {
    // return () => {
    //   if (
    //     mediaRecorderRef.current &&
    //     mediaRecorderRef.current.state !== "inactive"
    //   ) {
    //     mediaRecorderRef.current.stop();
    //   }
    // };
  }, []);

  // This component does not render anything; it merely starts and stops recording based on Redux state
  return null;
};

export default AudioRecorder;
