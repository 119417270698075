import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";

interface AudioVisualizerProps {
  barColor?: string;
  barCount?: number;
  audioAnalysisData?: Uint8Array; // Make this optional
  sx?: React.CSSProperties;
}

const AudioVisualizer: React.FC<AudioVisualizerProps> = ({
  barColor = "#9E00FF",
  barCount: bars = 5,
  audioAnalysisData, // This is optional, if not provided, the visualizer shows default bars
  sx,
}) => {
  const multiplier = 0.65;
  const barSpacing = 1;
  const minBarHeight = 24;
  const numBars = bars || 5; // Ensure there's a default if bars is not provided

  // Initialize audioData with a default state that shows a silent visualizer
  const [audioData, setAudioData] = useState(
    Array.from({ length: numBars }, () => minBarHeight)
  );

  // Function to rearrange bars from tallest in the center to shortest at the edges
  const rearrangeBarsCenterOut = (array) => {
    const sortedData = [...array].sort((a, b) => b - a);
    const centerOutData = [];
    for (let i = 0; i < sortedData.length; i++) {
      if (i % 2 === 0) centerOutData.push(sortedData[i]);
      else centerOutData.unshift(sortedData[i]);
    }
    return centerOutData;
  };

  useEffect(() => {
    if (audioAnalysisData && audioAnalysisData.length) {
      const step = Math.floor(audioAnalysisData.length / numBars);
      const newAudioData = [];

      for (let i = 0; i < numBars; i++) {
        const barHeight =
          audioAnalysisData[i * step] * multiplier || minBarHeight;
        newAudioData.push(Math.max(minBarHeight, barHeight));
      }

      // Sort the bars by height then rearrange for the center-out animation
      setAudioData(rearrangeBarsCenterOut(newAudioData));
    }
  }, [audioAnalysisData, multiplier, numBars]);

  return (
    <Box
      sx={{
        transform: "scale(0.5)",
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80px",
        ...sx,
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        spacing={barSpacing}
      >
        {audioData.map((height, index) => (
          <Grid item key={`bar-${index}`}>
            <Box
              sx={{
                backgroundColor: barColor,
                height: `${height}px`,
                width: "24px",
                borderRadius: "12px",
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AudioVisualizer;
