import { createTheme, Theme, darken, lighten } from "@mui/material/styles";
import { colors } from "./colors";

export default function createGlobalTheme(isDarkMode: boolean): Theme {
  return createTheme({
    drawerWidth: 280,
    rightPanelWidth: 380,
    maxContentWidth: 768,
    palette: {
      mode: isDarkMode ? "dark" : "light",
      primary: {
        main: colors.purple[500],
      },
      secondary: {
        main: isDarkMode ? colors.grayScale[400] : colors.grayScale[600],
      },
      background: {
        paper: isDarkMode ? colors.grayScale[900] : colors.grayScale[50],
        form: isDarkMode ? colors.grayScale[900] : colors.grayScale[100],
        input: isDarkMode ? colors.grayScale[800] : colors.grayScale[200],
        card: isDarkMode ? colors.grayScale[900] : colors.grayScale[100],
        chatBubble: isDarkMode ? colors.grayScale[700] : colors.grayScale[100],
        popup: isDarkMode ? colors.grayScale[900] : colors.grayScale.white,
        divider: isDarkMode ? colors.grayScale[900] : colors.grayScale[100],
        button: isDarkMode ? colors.grayScale[900] : colors.grayScale[100],
        backdrop: isDarkMode ? colors.grayScale[800] : colors.grayScale.white,
        buttonHighlighted: isDarkMode
          ? colors.grayScale[700]
          : darken(colors.grayScale[75], 0.05),
        cardDropShadow: isDarkMode
          ? colors.grayScale[800]
          : colors.grayScale[100],
      },
      text: {
        primary: isDarkMode ? colors.grayScale[100] : colors.grayScale[800],
        secondary: isDarkMode ? colors.grayScale[200] : colors.grayScale[300],
        tertiary: isDarkMode ? colors.grayScale[200] : colors.grayScale[600],
        placeholder: isDarkMode ? colors.grayScale[400] : colors.grayScale[100],
        body1: isDarkMode ? colors.grayScale[100] : colors.grayScale[800],
        h5: isDarkMode ? colors.grayScale[100] : colors.grayScale[800],
        h6: isDarkMode ? colors.grayScale[100] : colors.grayScale[800],
        purple: colors.purple[500],
      },
      colors: {
        ...colors,
      },
    },
    typography: {
      fontFamily: ["Poppins"].join(","),
      body1: {
        letterSpacing: "0.08em",
      },
      body2: {
        letterSpacing: "0.08em",
        fontSize: "0.8rem",
      },
      // add common h1 through h4
      h1: {
        fontWeight: 800,
        letterSpacing: 1.1,
        fontSize: "3.5rem",
        "@media (max-width:600px)": {
          fontSize: "2.5rem",
        },
        "@media (max-width:400px)": {
          fontSize: "2rem",
        },
      },
      h2: {
        fontWeight: 600,
        fontSize: "2.5rem",
        letterSpacing: 1.1,
      },
      h3: {
        fontWeight: 800,
        letterSpacing: 1.1,
        fontSize: "2rem",
      },
      h4: {
        fontWeight: 800,
        letterSpacing: 1.1,
        fontSize: "1.5rem",
      },
      h5: {
        fontWeight: 600,
        letterSpacing: 1.1,
        fontSize: "1.15rem",
      },
      h6: {
        fontWeight: 700,
        letterSpacing: 1.1,
      },
      button: {
        fontWeight: 600,
        letterSpacing: 1.1,
      },
    },
  });
}
