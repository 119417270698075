import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  lighten,
  useTheme,
} from "@mui/material";
import SectionBase from "../../workspace-page/content-views/profile-view/sections/SectionBase";
import { useAppDispatch } from "../../../../app/store";
import {
  logout,
  setManualLogout,
} from "../../../../features/users/userAuthSlice";
import { useNavigate } from "react-router-dom";
import {
  clearAllKnowledgeJobs,
  clearAllReplyJobs,
} from "../../../../features/users/accountPageSlice";
import { simulateMonth } from "../../../../routes/userRoutes";

const DangerZoneBlock: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const [simulating, setisSimulating] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const width = containerRef?.current?.clientWidth as number;
      //console.log("width: " + width);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const callResetPassword = () => {
    dispatch(setManualLogout(true));
    dispatch(logout());
    navigate("/auth/request-reset-password");
  };

  const callClearReplyJobs = () => {
    dispatch(clearAllReplyJobs());
  };

  const callClearAllKnowledgeJobs = () => {
    dispatch(clearAllKnowledgeJobs());
  };

  const callSimulateMonth = async () => {
    if (simulating) {
      return;
    }

    setisSimulating(true);
    const result = await simulateMonth();
    setisSimulating(false);
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-column",
        alignItems: "flex-start",
        alignContent: "flex-start",
        height: "auto",
        width: "100%",
        paddingBottom: "50px",
      }}
    >
      <SectionBase title="Danger Zone">
        <Button
          onClick={() => callResetPassword()}
          variant="contained"
          sx={{
            marginLeft: "16px",
            marginBottom: "16px",
            height: "36px",
            width: "160px",
            color: theme.palette.text.primary,
            background: theme.palette.background.buttonHighlighted,
            boxShadow: "none",
            fontWeight: 500,
            fontSize: "12px",
            textTransform: "none",
            "&:hover": {
              backgroundColor: lighten(
                theme.palette.background.buttonHighlighted,
                0.1
              ),
              boxShadow: "none",
            },
          }}
        >
          {"Reset Password"}
        </Button>
        <Button
          onClick={() => callClearReplyJobs()}
          variant="contained"
          sx={{
            marginLeft: "16px",
            marginBottom: "16px",
            height: "36px",
            width: "160px",
            color: theme.palette.text.primary,
            background: theme.palette.background.buttonHighlighted,
            boxShadow: "none",
            fontWeight: 500,
            fontSize: "12px",
            textTransform: "none",
            "&:hover": {
              backgroundColor: lighten(
                theme.palette.background.buttonHighlighted,
                0.1
              ),
              boxShadow: "none",
            },
          }}
        >
          {"Clear Reply Jobs"}
        </Button>
        <Button
          onClick={() => callClearAllKnowledgeJobs()}
          variant="contained"
          sx={{
            marginLeft: "16px",
            marginBottom: "16px",
            height: "36px",
            width: "160px",
            color: theme.palette.text.primary,
            background: theme.palette.background.buttonHighlighted,
            boxShadow: "none",
            fontWeight: 500,
            fontSize: "12px",
            textTransform: "none",
            "&:hover": {
              backgroundColor: lighten(
                theme.palette.background.buttonHighlighted,
                0.1
              ),
              boxShadow: "none",
            },
          }}
        >
          {"Clear Knowledge Jobs"}
        </Button>
        <Button
          onClick={() => callSimulateMonth()}
          variant="contained"
          disabled={simulating}
          disableRipple={simulating}
          sx={{
            marginLeft: "16px",
            marginBottom: "16px",
            height: "36px",
            width: "160px",
            color: theme.palette.text.primary,
            background: theme.palette.background.buttonHighlighted,
            boxShadow: "none",
            fontWeight: 500,
            fontSize: "12px",
            textTransform: "none",
            "&:hover": {
              backgroundColor: lighten(
                theme.palette.background.buttonHighlighted,
                0.1
              ),
              boxShadow: "none",
            },
          }}
        >
          {simulating ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress
                size={"16px"}
                thickness={6}
                sx={{
                  color: theme.palette.text.primary,
                  marginRight: "8px",
                }}
              />
              Simulating...
            </Box>
          ) : (
            "Simulate Month"
          )}
        </Button>
      </SectionBase>
    </Box>
  );
};

export default DangerZoneBlock;
